import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'welcome-step',
	template: require('./welcome-step.component.html'),
	styles: [require('./welcome-step.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeStepComponent {
	@Input() public data: { user: any; numDays: any };
	@Input() public showSelfPayStep: boolean;
	@Input() public loading$: Observable<boolean>;
	@Input() public showFreeTrialStep: boolean;
	@Output()
	public onProceed = new EventEmitter<void>();

	constructor () {}

	public proceed () {
		this.onProceed.emit();
	}
}
