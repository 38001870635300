import { downgradeComponent } from '@angular/upgrade/static';
import { LoginComponent } from './login.component';
import angular from 'angular';

export { LoginModule } from './login.module';
export { LoginComponent } from './login.component';
export type { LoginEvent } from './login.component';

export const ngxLoginModule = angular.module('ngxLoginModule', [])
	.directive('ngxLogin', downgradeComponent({component: LoginComponent})).name;
