import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';

@Directive({
	selector: '[accessibleDropList]'
})

export class AccessibleDropListDirective {
	@Input() public count: number;
	@Input() public currentIndex: number;
	@Input() public itemId: number;
	@Input() public name: string;
	@Output() public activeItemChange: EventEmitter<{ currentIndex: number; newIndex: number }> =
	new EventEmitter<{ currentIndex: number; newIndex: number }>();
	@Output() public cancelReorder: EventEmitter<{ currentIndex: number; itemId: number }> =
	new EventEmitter<{ currentIndex: number; itemId: number }>();
	@Output() public finalizePosition: EventEmitter<void> = new EventEmitter<void>();

	constructor (
		private liveAnnouncer: LiveAnnouncer,
		private translate: TranslateService
	) {}

	@HostListener('keydown', ['$event'])
	public handleKeyDown (event: KeyboardEvent) {
		const listItem = event.target as HTMLElement;

		if (event.code === 'Enter' || event.code === 'Space') {
			listItem.classList.toggle('selected');
			listItem.setAttribute('aria-grabbed', listItem.classList.contains('selected').toString());

			if (listItem.classList.contains('selected')) {
				const message = this.translate.instant('aria-ng-sortable_grabbed', {
					name: this.name,
					position: this.currentIndex + 1,
					count: this.count
				});
				this.liveAnnouncer.announce(message);
			} else {
				const message = this.translate.instant('aria-ng-sortable_dropped', {
					name: this.name,
					position: this.currentIndex + 1,
					count: this.count
				});
				this.liveAnnouncer.announce(message);
				this.finalizePosition.emit();
			}
		}

		if (listItem.classList.contains('selected')) {
			if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
				let newIndex = this.currentIndex;
				if (event.code === 'ArrowDown') {
					newIndex += 1;
				} else {
					newIndex -= 1;
				}
				const message = this.translate.instant('aria-ng-sortable_moved', {
					name: this.name,
					position: newIndex + 1,
					count: this.count
				});
				this.liveAnnouncer.announce(message);
				this.activeItemChange.emit({ currentIndex: this.currentIndex, newIndex });
			} else if (event.code === 'Escape') {
				event.stopPropagation();
				listItem.classList.remove('selected');
				listItem.setAttribute('aria-grabbed', 'false');
				const message = this.translate.instant('aria-ng-sortable_cancelled', {
					name: this.name
				});
				this.liveAnnouncer.announce(message);
				this.cancelReorder.emit({currentIndex: this.currentIndex, itemId: this.itemId});
			}
		}
	}
}
