import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCommentReactionsComponent } from './comment-reactions.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		GoTooltipModule,
		PickerModule
	],
	declarations: [
		NgxCommentReactionsComponent
	],
	entryComponents: [
		NgxCommentReactionsComponent
	],
	exports: [
		NgxCommentReactionsComponent
	]
})
export class CommentReactionsModule {}
