import { UserService } from 'go-modules/models/user/user.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import type { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import type { StateParams } from '@uirouter/angularjs';
import type { DashboardUtilsServiceInterface } from '../dashboard-root/services/dashboard-utils.service';

/* @ngInject */
export class LicenseManagementViewController implements ng.IController {
	public myAccount;

	/* @ngInject */
	constructor (
		public selectedService: SelectedService,
		public userService: UserService,
		public $stateParams: StateParams,
		private groupsIndex: GroupsIndexService,
		private dashboardUtilsService: DashboardUtilsServiceInterface
	) {}

	public $onInit (): void {
		this.myAccount = this.dashboardUtilsService.myAccount;

		if (this.$stateParams.orgId != null) {
			this.groupsIndex.resolve(this.$stateParams.orgId).then((org) => {
				this.selectedService.setOrg(org);
			});
		}
	}
}
