import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, defer, throwError } from 'rxjs';
import { filter, retry, share, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { RateLimitDialogComponent } from 'ngx/go-modules/src/components/dialogs/rate-limit-dialog/rate-limit-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class RateLimitInterceptor implements HttpInterceptor {
	private openDialogPipe$: Observable<void>;
	private retryAfter: number = 30000;
	private waitingForRetry = false;
	private retrySubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

	constructor (
		private dialog: MatDialog
	) {
		// A deferred observable to open the rate limit dialog.
		// It's shared, so it only opens once per set of 429s
		this.openDialogPipe$ = defer(() =>
			dialog.open(RateLimitDialogComponent, {
				data: { retryAfter: this.retryAfter }
			}).afterClosed().pipe(tap(() => {
				this.waitingForRetry = false;
				this.retrySubject.next(true);
			}))
		).pipe(share());
	}

	public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.waitingForRetry) {
			return this.handleRequest(req, next);
		} else {
			return this.retrySubject.pipe(
				filter((result) => result !== false),
				take(1),
				switchMap(() => {
					return this.handleRequest(req, next);
				})
			);
		}
	}

	private handleRequest (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			retry({
				delay: (error: HttpErrorResponse) => {
					if (error.status === 429) {
						this.waitingForRetry = true;
						this.retrySubject.next(false);
						this.retryAfter = parseInt(error.headers.get('Retry-After') || '30', 10) * 1000;
						return this.openDialogPipe$;
					} else {
						return throwError(() => error);
					}
				}
			})
		);
	}
}
