import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { AccountLockoutModal } from './modal.factory';
import './modal.less';

export const accountLockoutModalModule = angular.module('modal.accountLockout', [
	goModalBootstrapModule,
	angularTranslate
])
	.service(AccountLockoutModal.NG1_INJECTION_NAME, AccountLockoutModal)
	.name;
