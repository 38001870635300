import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject, take } from 'rxjs';

@Component({
	selector: 'grid-virtual-scroll-viewport',
	template: require('./grid-virtual-scroll-viewport.component.html'),
	styles: [require('./grid-virtual-scroll-viewport.component.scss')]
})
export class GridVirtualScrollViewportComponent implements AfterViewInit {
	@ViewChild(CdkVirtualScrollViewport) private cdkViewport: CdkVirtualScrollViewport;
	@Output() public onScroll = new EventEmitter<Event>();

	public cdkViewportLoaded$: Observable<CdkVirtualScrollViewport>;
	private cdkViewportLoaded$$ = new ReplaySubject<CdkVirtualScrollViewport>(1);

	constructor () {
		this.cdkViewportLoaded$ = this.cdkViewportLoaded$$.asObservable().pipe(take(1));
	}

	public ngAfterViewInit (): void {
		this.cdkViewportLoaded$$.next(this.cdkViewport);
	}

	public ngOnDestroy (): void {
		this.cdkViewportLoaded$$.complete();
	}
}
