export class CreateCourseModalController {

	public group: any;
	public duplicateFromGroup: any;
	public $close: (result?: any) => boolean;

	/* @ngInject */
	constructor (
		private $document: ng.IDocumentService
	) {}

	public $onInit () {
		if(this.duplicateFromGroup) {
			this.group.start_date = this.duplicateFromGroup.start_date;
			this.group.end_date = this.duplicateFromGroup.end_date;
		}
	}

	public submit (form: ng.IFormController) {
		if (form.$invalid) {
			const input = this.$document[0].querySelector('.modal.create-course-modal input.ng-invalid') as HTMLInputElement;
			input.focus();
			return;
		}

		return this.$close({group: this.group});
	}
}
