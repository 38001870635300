import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
	name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

	public transform (date): string {
		return dayjs(date).format('MMM D, YYYY');
	}

}
