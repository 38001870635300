export enum AvailabilityFilter {
	ALL = 'All',
	CURRENT = 'Current',
	FUTURE = 'Future',
	PAST = 'Past'
}

export const AvailabilityFilterTranslations: Record<AvailabilityFilter, string> = {
	All: 'common_all',
	Current: 'common_current',
	Future: 'common_future',
	Past: 'common_past'
};
