import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropUserDialogComponent } from './drop-user-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		A11yModule
	],
	entryComponents: [
		DropUserDialogComponent
	],
	declarations: [
		DropUserDialogComponent
	]
})
export class DropUserDialogModule {}
