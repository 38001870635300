import * as angular from 'angular';
import type { Options } from 'go-modules/models/group-dep/group.factory';
import { GroupService as GrService } from 'go-modules/services/group/group.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';

/* @ngInject */
export class CourseTemplateController {

	public duplicateFromGroup: any;
	public options: any;
	public currentGroup: any;
	public orgSettings: any;
	public groups: any;
	public group: any;
	public publishedGroups: any;
	public currentUser: any;
	public onActivityCopy: (data: any) => void;
	public isRoot: boolean;
	public getDataOptions: Options;
	public addDates$;
	public isLoading: boolean = true;

	constructor (
		private $q: ng.IQService,
		private $log: angular.ILogService,
		private Group,
		private GroupService: GrService,
		private eventService: EventService,
		private selectedService: SelectedService
	) {}

	public $onInit () {
		this.options = {
			isLti: false,
			hideName: false,
			orgSettings: this.orgSettings,
			selfRegistrationEnabled: this.group.isCourse() && this.group.self_registration_enabled
		};

		// make sure data is fresh
		if (this.currentUser.is_root_user) {
			this.getDataOptions = { includeDeletedUsers:  true };
		}
		this.currentGroup.getData(this.getDataOptions);

		const orgId = this.selectedService.getOrg().org_id;
		this.$q.all({
			groups: this.GroupService.getCourses(orgId),
			publishedGroups: this.Group.getPublishedGroups(this.currentGroup.group_id).$promise
		}).then((res) => {
			this.groups = res.groups;
			this.publishedGroups = res.publishedGroups.filter((group) => {
				return !this.groups.some((course) => {
					return course.group_id === group.group_id;
				});
			});
		}).catch(this.$log.error);;

		// Set root user status
		this.isRoot = this.currentUser.is_root_user;

		if(this.duplicateFromGroup) {
			this.group.name = this.duplicateFromGroup.name;
			this.setCopyGroup(this.duplicateFromGroup);
		} else {
			// The user needs to manually choose a group to copy from each time
			// this controller is loaded. By clearing out this value, we ensure
			// that we don't accidentally auto-select a group to copy from.
			this.setCopyGroup(null);
		}
	}

	public setCopyGroup (group) {
		this.group.copyFrom = group;
	}

	public copyActivities (group) {
		this.onActivityCopy({
			copyPromise: this.GroupService.copyActivities(group.copyFrom.group_id, group.group_id).then(() => {
				this.eventService.broadcast(EVENT_NAMES.COURSE_ACTIVITIES_COPIED);
			})
		});
	}
}
