import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';

@Component({
	selector: 'archive-zero-state',
	template: require('./archive-zero-state.component.html'),
	styles: [require('./archive-zero-state.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveZeroStateComponent {
	constructor (public archivedListDataSource: ArchivedListDataSource) {}
}
