import * as angular from 'angular';
import { activityModel } from '../models/activity';
import { CsvReportService } from './csv-report.service';
import { ActivityRubricReportService } from './activity-rubric-report/activity-rubric-report.service';
import { ActivityReportService } from './activity-report/activity-report.service';
import { OrgWideRubricReportService } from './org-wide-rubric-report/org-wide-rubric-report.service';
import { rubricTemplateModel } from 'go-modules/models/rubric-template';

export const csvReportModule = angular.module('csvReport', [
	activityModel,
	rubricTemplateModel
])
	.service('CsvReportService', CsvReportService)
	.service('ActivityRubricReportService', ActivityRubricReportService)
	.service('ActivityReportService', ActivityReportService)
	.service(OrgWideRubricReportService.NG1_INJECTION_NAME, OrgWideRubricReportService)
	.name;
