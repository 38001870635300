import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'org-context-menu',
	template: require('./org-context-menu.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class OrgContextMenuComponent {
	@Input() public canInvite;
	@Input() public canCreateCourse;
	@Output() public onInvite = new EventEmitter();
	@Output() public onCreateCourse = new EventEmitter();

	constructor () {}
}
