import { ILocationService } from 'angular';

// Hacky hack for issues with page loading
// Sometimes $location.absUrl returns undefined and angular blows up
/* @ngInject */
export const locationDecorator = ($delegate: ILocationService) => {
	const orig = $delegate.absUrl;
	$delegate.absUrl = (...args) => {
		return orig.apply($delegate, args) || '';
	};
	return $delegate;
};
