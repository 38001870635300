import template from './activity-report-panel.template.html';
import { ActivityReportPanelController as controller } from './activity-report-panel.controller';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class ActivityReportPanelService {
	public static readonly NG1_INJECTION_NAME = 'ActivityReportPanel' as const;

	/* @ngInject */
	constructor (private SidePanel) {}

	public open (options) {
		return this.SidePanel.open({
			template,
			bindings: {
				activityId: options.activityId,
				reportLevel: options.reportLevel,
				groupId: options.groupId
			},
			controller,
			windowClass: 'activity-report-panel'
		});
	}
}

export const activityReportPanelToken = upgradeNg1Dependency(ActivityReportPanelService);
