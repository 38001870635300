import { States } from 'go-modules/enums/states.enum';
import type { StateService } from '@uirouter/angularjs';

export class ForgotPasswordController {

	/* @ngInject */
	constructor (private $state: StateService) {}

	public login (): void {
		this.$state.go(States.AUTH_LOGIN, {}, {reload: true});
	}
}
