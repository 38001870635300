// See ArchivedResource
export enum ArchivedItemType {
	GROUP = 'Group',
	ACTIVITY = 'Activity',
	SESSION = 'Session',
}

export interface ArchivedPresenter {
	presenter_id: number;
	user_id: number;
	session_id: number;
	first_name: string;
	last_name: string;
	email: string;
}

export interface ArchivedItem {
	resource_type: ArchivedItemType;
	resource_id: number;
	name: string;
	owner_name: string;
	archived_at: string;
	thumbnail_url: string | null;
	duration: number | null;
	presenters: ArchivedPresenter[] | null;
}
