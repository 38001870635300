import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgxSpeedGraderComponent } from 'ngx/go-modules/src/components/feedback-session/speed-grader/speed-grader.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		NgxSpeedGraderComponent

	],
	entryComponents: [
		NgxSpeedGraderComponent
	],
	exports: [
		NgxSpeedGraderComponent
	],
	providers: [
		TranslatePipe
	]
})
export class SpeedGraderModule {}
