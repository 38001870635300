import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AIMarkersDialogComponent } from './ai-markers-dialog.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrackByPropPipeModule } from 'ngx/go-modules/src/pipes/track-by-prop/track-by-prop-pipe.module';
import { CommonModule } from '@angular/common';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { MarkerModule } from 'ngx/go-modules/src/components/marker';
import { TeaseWallModule } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		TrackByPropPipeModule,
		GoSelectModule,
		MarkerModule,
		A11yModule,
		TeaseWallModule
	],
	declarations: [
		AIMarkersDialogComponent
	],
	exports: [
		AIMarkersDialogComponent
	],
	entryComponents: [
		AIMarkersDialogComponent
	]
})
export class AIMarkersDialogModule {}
