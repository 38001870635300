import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { States } from 'go-modules/enums/states.enum';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { NgxGroupService, Org } from 'ngx/go-modules/src/services/group/group.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { BehaviorSubject, Observable, finalize, map, tap } from 'rxjs';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';

interface GroupedUseType {
	name: string;
	types: UseType[];
}

@Component({
	selector: 'admin-panel',
	template: require('./admin-panel.component.html'),
	styles: [require('./admin-panel.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPanelComponent {
	public form = new FormGroup({
		accountName: new FormControl('', [Validators.required]),
		useType: new FormControl(null, [Validators.required])
	});
	public useTypes$: Observable<GroupedUseType[]>;
	public orgs$: Observable<Org[]>;
	public orgsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public selectedOrg: Org;

	constructor (
		private groupService: NgxGroupService,
		private ngxGoToastService: NgxGoToastService,
		private signUpService: SignUpService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(groupToken) private group,
		@Inject($stateToken) private $state
	) {}

	public ngOnInit () {
		this.form.controls.accountName.setValue(this.selectedService.getAccount().name);
		this.useTypes$ = this.signUpService.getUseTypes().pipe(
			map((useTypes) => {
				const selectedUseType = useTypes.find((useType) => {
					return useType.use_type_id === this.selectedService.getAccount().use_type_id;
				});
				this.form.controls.useType.setValue(selectedUseType);

				return Object.values(useTypes.reduce((result, item) => {
					const { category } = item;
					if (!result[category]) {
						result[category] = { name: category, types: [] };
					}
					result[category].types.push(item);
					return result;
				}, {}));
			})
		);
		this.orgs$ = this.groupService.getAllOrgs(false).pipe(
			tap((orgs) => {
				orgs.sort((a, b) => a.name.localeCompare(b.name));
				this.selectedOrg = orgs.find((org) => org.group_id === this.selectedService.getAccount().org_id);
			}),
			finalize(() => {
				this.orgsLoading$.next(false);
			})
		);
	}

	public ngOnDestroy (): void {
		this.orgsLoading$.complete();
	}

	public saveAccountName () {
		const selectedAccount = this.selectedService.getAccount();
		if (!this.form.controls.accountName.valid) {
			return;
		}

		if (this.form.controls.accountName.value === selectedAccount.name) {
			return;
		}

		this.saveAccount({
			...selectedAccount,
			name: this.form.controls.accountName.value
		});
	}

	public updateUseType () {
		const selectedAccount = this.selectedService.getAccount();

		if (this.form.controls.useType.value.use_type_id === selectedAccount.use_type_id) {
			return;
		}

		this.saveAccount({
			...selectedAccount,
			use_type_id: this.form.controls.useType.value.use_type_id
		});
	}


	public moveAccount () {
		this.groupService.moveGroup(this.selectedService.getAccount().group_id, {
			new_parent_id: this.selectedOrg.group_id
		}).subscribe({
			next: (res) => {
				this.selectedService.setAccount(this.group.model(res));
				this.selectedService.setOrg(this.group.model(this.selectedOrg));
				this.$state.go(States.DASHBOARD_FOLDERS, {id: this.selectedOrg.group_id});
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'admin-panel_move-success'
				});
			},
			error: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'common-save-failed'
				});
			}
		});
	}

	public openCreditLedger () {
		const url = `${clientSettings.AdminToolsUrl}/#/global/reports/credit-ledger?beid=${this.selectedService.getAccount().billing_entity_id}`;
		window.open(url, '_blank');
	}

	private saveAccount (account) {
		this.groupService.updateAccount(account.group_id, account).subscribe({
			next: (res) => {
				this.selectedService.setAccount(this.group.model(res));
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'common-save-success'
				});
			},
			error: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'common-save-failed'
				});
			}
		});
	}
}
