import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { BehaviorSubject, Observable, finalize, tap } from 'rxjs';
import { NgxGroupService, Org } from 'ngx/go-modules/src/services/group/group.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { groupToken } from 'go-modules/models/group-dep/group.factory';

@Component({
	selector: 'ngx-org-picker',
	template: require('./org-picker.component.html'),
	styles: [require('./org-picker.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgPickerComponent implements OnInit {
	public orgs$: Observable<Org[]>;
	public selectedOrg: Org;
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	private selectedSubscription;

	constructor (
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		private groupService: NgxGroupService,
		private cdr: ChangeDetectorRef,
		@Inject($stateToken) private $state,
		@Inject(groupToken) private group
	) {
	}

	public ngOnInit (): void {
		this.orgs$ = this.groupService.getAllOrgs().pipe(
			tap((orgs) => {
				orgs.sort((a, b) => a.name.localeCompare(b.name));
			}),
			finalize(() => {
				this.loading$.next(false);
			})
		);
		this.selectedSubscription = this.selectedService.selectedSubject
			.subscribe((selected) => {
				this.selectedOrg = selected.org;
				this.cdr.detectChanges();
			});
	}

	public ngOnDestroy (): void {
		this.loading$.complete();
		this.selectedSubscription.unsubscribe();
	}

	public compareOrgs (option, value): boolean {
		return option.group_id === value.group_id;
	}

	public orgChanged (org) {
		this.selectedOrg = org;
		this.selectedService.setOrg(this.group.model(this.selectedOrg));
		this.$state.go(States.DASHBOARD_FOLDERS, {id: this.selectedOrg.group_id});
	}
}
