import groupSettingsPanelTemplate from './group-settings-panel.html';
import groupSection from './partials/group-section.html';
import usersSection from './partials/users-section.html';

/* @ngInject */
export const templatesRun = ($templateCache) => {
	$templateCache.put('components/group-settings-panel/group-settings-panel.html', groupSettingsPanelTemplate);
	$templateCache.put('group-settings-panel/users-section.html', usersSection);
	$templateCache.put('group-settings-panel/group-section.html', groupSection);
};
