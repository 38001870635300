import type { InstructorAccountSignupInterface } from '../interfaces/auth-instructor-signup.interface';

export class InstructorService {
	public instructorData: InstructorAccountSignupInterface;
	/**
	 * Get the instructor signup data request
	 *
	 * @returns {InstructorAccountSignupInterface}
	 */
	public getInstructorData (): InstructorAccountSignupInterface {
		return this.instructorData;
	}

	/**
	 * Set the instructor signup data request
	 *
	 * @param data {InstructorAccountSignupInterface}
	 * @returns {void}
	 */
	public setInstructorData (data: InstructorAccountSignupInterface): void {
		this.instructorData = data;
	}
}
