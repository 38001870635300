import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { A11yModule } from '@angular/cdk/a11y';
import { RateLimitDialogComponent } from './rate-limit-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		A11yModule
	],
	declarations: [
		RateLimitDialogComponent
	],
	entryComponents: [
		RateLimitDialogComponent
	],
	exports: [
		RateLimitDialogComponent
	]
})
export class RateLimitDialogModule {}
