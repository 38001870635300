import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output
} from '@angular/core';

@Component({
	selector: 'choose-plan',
	template: require('./choose-plan.component.html'),
	styles: [require('./choose-plan.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoosePlanComponent {
	@Output()
	public onBack = new EventEmitter<void>();
	@Output()
	public onSelfPay = new EventEmitter<void>();
	@Output()
	public onStudentPay = new EventEmitter<void>();

	constructor () {}

	public back () {
		this.onBack.emit();
	}

	public proceedSelfPay () {
		this.onSelfPay.emit();
	}

	public proceedStudentPay () {
		this.onStudentPay.emit();
	}
}
