// Angular polyfills
import 'zone.js';
import 'reflect-metadata';

import { StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeComponent, downgradeModule } from '@angular/upgrade/static';
import { AppModule } from 'ngx/dashboard/src/app.module';
import * as angular from 'angular';
import { goBootstrapComponent } from 'go-modules/go-bootstrap/go-bootstrap.component';
import { ServiceBootstrapComponent } from 'ngx/go-modules/src/components/service-bootstrap/service-bootstrap.component';

const bootstrapFn = (extraProviders: StaticProvider[]) => {
	const platformRef = platformBrowserDynamic(extraProviders);
	return platformRef.bootstrapModule(AppModule);
};
const downgradedModule = downgradeModule(bootstrapFn);

export const goBootstrapModule = angular.module('go-dashboard-bootstrap', [
	downgradedModule
])
	.component('goBootstrap', goBootstrapComponent)
	.directive('serviceBootstrap', downgradeComponent({component: ServiceBootstrapComponent}))
	.name;
