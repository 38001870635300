import { UserService } from 'go-modules/models/user/user.service';
import { FallbackRoute } from '../services/fallback-route.service';
import { States } from 'go-modules/enums/states.enum';
import { UpdateManagerService } from 'go-modules/update-manager/update-manager.service';
import { RejectType } from '@uirouter/angularjs';
import type { DashboardUtilsService } from 'dashboard/modules/dashboard-root/services/dashboard-utils.service';
import type { HookResult, StateService, TargetState, Transition, TransitionHookFn, TransitionService } from '@uirouter/angularjs';
import type { AppDataService } from 'dashboard/services/app-data.service';
import type { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';

type NgHookResult = HookResult | ng.IPromise<boolean | TargetState | void>;

/* @ngInject */
export const routesRun = (
	$rootScope,
	$state: StateService,
	$transitions: TransitionService,
	dashboardUtilsService: DashboardUtilsService,
	fallbackRouteService: FallbackRoute,
	userService: UserService,
	GoVersionManager: UpdateManagerService,
	AppData: AppDataService,
	groupsIndex: GroupsIndexService,
	$q: ng.IQService,
	selectedService: SelectedService,
	Group
) => {
	/**********************************************  Group Handler ******************************************/
	const handleLicenseManagementRouting = ((transition: Transition): NgHookResult => {
		// If they have an org selected, then we don't need to do anything special
		if (transition.params().orgId != null) {
			return;
		}

		// Otherwise, without a specified orgId. We'll need to pick an org for them.
		let groupId = null;
		if (selectedService.getOrg()) {
			groupId = selectedService.getOrg().group_id;
		}

		if (groupId != null) {
			return groupsIndex.resolve(groupId).then((group) => {
				selectedService.setOrg(Group.model(group));
				if (userService.currentUser.is_root_user) {
					return $state.target(States.LICENSE_MANAGEMENT, { orgId: group.org_id });
				}
				return;
			});
		}
		// If they don't have a selected org, let's use their account's org
		if (dashboardUtilsService.myAccount != null) {
			const org = selectedService.getMyOrgs().find((o) => {
				return parseInt(o.group_id, 10) === parseInt(dashboardUtilsService.myAccount.org_id, 10);
			});
			selectedService.setOrg(Group.model(org));
			if (userService.currentUser.is_root_user) {
				return $state.target(States.LICENSE_MANAGEMENT, {
					orgId: dashboardUtilsService.myAccount.org_id
				});
			}
			return;
		}
		// If they don't have an account, let's pick any org they have explicit access to
		if (AppData.disjoint_groups.length > 0) {
			const org = selectedService.getMyOrgs().find((o) => {
				return parseInt(o.group_id, 10) === parseInt(AppData.disjoint_groups[0].org_id, 10);
			});
			selectedService.setOrg(Group.model(org));
			if (userService.currentUser.is_root_user) {
				return $state.target(States.LICENSE_MANAGEMENT, {
					orgId: AppData.disjoint_groups[0].org_id
				});
			}
			return;
		}

		// If none of the above, give them an error so they'll be routed away
		return $q.reject();
	});

	const handleDashboardFoldersViewRouting = ((transition: Transition): NgHookResult => {
		const params = transition.params();
		const orgId = parseInt(params.id, 10);
		GoVersionManager.checkVersionAndReloadIfHasUpdates();
		return dashboardUtilsService.handleFoldersRouteParamChange(orgId);
	});

	const handleDashboardFolderViewRouting = ((transition: Transition): NgHookResult => {
		const params = transition.params();
		const folderId = parseInt(params.folder_id, 10);
		GoVersionManager.checkVersionAndReloadIfHasUpdates();
		return dashboardUtilsService.handleGroupRouteParamChange(folderId);
	});

	const handleDashboardActivityViewRouting = ((transition: Transition): NgHookResult => {
		const params = transition.params();
		const activityId = parseInt(params.activity_id, 10);
		GoVersionManager.checkVersionAndReloadIfHasUpdates();
		return dashboardUtilsService.handleActivityRouteParamChange(activityId);
	});

	const handleDashboardSessionViewRouting = ((transition: Transition): NgHookResult => {
		const params = transition.params();
		const sessionId = parseInt(params.session_id, 10);
		GoVersionManager.checkVersionAndReloadIfHasUpdates();
		return dashboardUtilsService.handleSessionRouteParamChange(sessionId);
	});

	const handleDashboardRootViewRouting = ((): NgHookResult => {
		// This one is interesting. Going to `/` is never valid by itself
		// By rejecting, onError will be run, which will run the fallback, which will redirect the user
		// to their last captured routing, or a fallback
		return $q.reject();
	});

	const onFinishDashboard = $transitions.onFinish({}, ((transition: Transition): NgHookResult => {
		if (userService.currentUser === null) {
			return; // return on empty user
		}

		if (transition.to().name === States.LICENSE_MANAGEMENT) {
			return handleLicenseManagementRouting(transition);
		}

		if (transition.to().name === States.DASHBOARD_FOLDERS) {
			return handleDashboardFoldersViewRouting(transition);
		}

		if (transition.to().name === States.DASHBOARD_FOLDER_VIEW) {
			return handleDashboardFolderViewRouting(transition);
		}

		if (transition.to().name === States.DASHBOARD_ACTIVITY_VIEW) {
			return handleDashboardActivityViewRouting(transition);
		}

		if (transition.to().name === States.DASHBOARD_SESSION_VIEW) {
			return handleDashboardSessionViewRouting(transition);
		}

		if (transition.to().name === States.DASHBOARD_ARCHIVE ||
			transition.to().name === States.DASHBOARD_SETTINGS ||
			transition.to().name === States.LICENSE_SEATS_MANAGEMENT) {
			return dashboardUtilsService.ensureOrgIsSelected();
		}

		if (transition.to().name === States.DASHBOARD_ROOT) {
			return handleDashboardRootViewRouting();
		}

		// Do nothing
		return;
	}) as TransitionHookFn);

	// On success, capture this state as good
	$transitions.onSuccess({}, (lastTransition: Transition): void => {
		fallbackRouteService.capture(lastTransition);
	});

	// When we error, send them to the last good state
	// Note that onError happens *after* the transition finishes
	// We cannot supply a new target state. `navigate` just sends them elsewhere
	$transitions.onError({}, (lastTransition: Transition): void => {
		const err = lastTransition.error();
		// We only care about Errors or Invalid routes.
		// Something like "Superseded" or "Ignored" shouldn't trigger this
		if ([RejectType.ERROR, RejectType.INVALID].includes(err.type)) {
			fallbackRouteService.navigate(lastTransition);
		}
	});

	// Normally the state service console errors every time a state transition
	// has an error. Errors are valid (like 401 if you aren't logged in) so we
	// don't need this noise in the console.
	$state.defaultErrorHandler(() => {});

	$rootScope.$on('$destroy', () => {
		onFinishDashboard();
	});
};
