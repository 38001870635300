import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ngx-suggestion-panel',
	template: require('./suggestion-panel.component.html'),
	styles: [require('./suggestion-panel.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxSuggestionPanelComponent {
	constructor () {}
}
