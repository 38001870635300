import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ResetPasswordComponent } from 'ngx/go-modules/src/components/auth/reset-password/reset-password.component';

export { ResetPasswordModule } from 'ngx/go-modules/src/components/auth/reset-password/reset-password.module';
export { ResetPasswordComponent } from 'ngx/go-modules/src/components/auth/reset-password/reset-password.component';

export const ngxResetPasswordModule = angular.module('ngxResetPasswordModule', [])
	.directive('ngxResetPassword', downgradeComponent({component: ResetPasswordComponent}))
	.name;
