import * as angular from 'angular';
import { ngxAccessDeniedModule } from 'ngx/go-modules/src/components/access-denied';
import { accessDeniedRouteComponent } from './access-denied-route.component';
import './style.less';

export const accessDeniedRouteModule = angular.module('accessDeniedRouteModule', [
	ngxAccessDeniedModule
])
	.component('accessDeniedRoute', accessDeniedRouteComponent)
	.name;
