import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FilterViewLayoutComponent } from './filter-view-layout.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		FilterViewLayoutComponent
	],
	exports: [
		FilterViewLayoutComponent
	]
})
export class FilterViewLayoutModule {}
