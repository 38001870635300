import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultActivitySettingsComponent } from './default-activity-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkerSetPanelDirective } from 'ngx/go-modules/src/angularjs-wrappers/marker-set-panel.directive';

@NgModule({
	imports: [
		CommonModule,
		GoMaterialModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule
	],
	declarations: [
		DefaultActivitySettingsComponent,
		MarkerSetPanelDirective
	],
	exports: [
		DefaultActivitySettingsComponent
	]
})
export class DefaultActivitySettingsModule {}
