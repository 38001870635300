import * as angular from 'angular';
import './style.less';
import { templatesRun } from './templates.run';
import { modelRouterModule } from '../model-router/index';
import { ngxLicenseServiceModule } from 'ngx/go-modules/src/services/license';
import { licenseSeatsManagement } from './license-seats-management.component';
import { ngxLicenseSeatsManagementModule } from 'ngx/go-modules/src/components/license-seats-management';

export const licenseSeatsManagementModule = angular
	.module('licenseSeatsManagementModule', [
		modelRouterModule,
		ngxLicenseServiceModule,
		ngxLicenseSeatsManagementModule
	])
	.component('licenseSeatsManagement', licenseSeatsManagement)
	.run(templatesRun)
	.name;
