import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';

@Component({
	selector: 'menu-button',
	template: require('./menu-button.component.html'),
	styles: [require('./menu-button.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('menuButton', {read: ElementRef}) public menuButton: ElementRef;

	constructor () {}

	public openMenu (event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();

		this.menuComponent.openMenu(event, this.menuButton.nativeElement);
	}
}
