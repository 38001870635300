// Configure theme/position of zendesk web widget
// eslint-disable-next-line dot-notation
window['zESettings'] = {
	webWidget: {
		position: {
			horizontal: 'right',
			vertical: 'top'
		},
		offset: {
			vertical: '75px'
		},
		color: {
			header: '#1C134A',
			button: '#E12A43',
			resultLists: '#000000',
			articleLinks: '#000000'
		},
		helpCenter: {
			filter: {
				category: '115000013583, 115000013563, 360001384951, 360001398752, 115000042646, 360001384971'
			}
		}
	}
};
