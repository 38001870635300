import * as angular from 'angular';
import { appData } from '../constants/app-data';
import { Subject } from 'rxjs';
import { FineUploaderService } from 'go-modules/fine-uploader/fine-uploader.service';
import { AppEnvService } from 'go-modules/go-app-env/app-env.service';
import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { AnalyticsService } from 'go-modules/services/analytics/analytics.service';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { ZendeskService } from 'go-modules/services/zendesk/zendesk.service';
import { AppSessionManagerService } from './app-session-manager.service';
import { BannerNotificationsService } from 'go-modules/services/banner-notifications/banner-notifications.service';
import { WatsonUsageTrackingService } from 'go-modules/services/watson-usage-tracking/watson-usage-tracking.service';
import { UserService } from 'go-modules/models/user/user.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Roles } from 'ngx/go-modules/src/enums';
import type { StateService } from '@uirouter/angularjs';
import { GoLocalizeHelperService, Language } from 'go-modules/translation-helper/go-localize-helper.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { GoBannerService } from 'go-modules/go-banner/go-banner.service';
import { States } from 'go-modules/enums/states.enum';

export interface InitAppDataOptions {
	minRole?: Roles;
}
export interface AppDataConfig {
	disjoint_groups;
	fine_uploader: any;
	google_analytics: any;
	language: string;
	my_account: any;
	my_orgs: any;
	partners: any;
	pubnub: any;
	user: any;
	user_pubnub_channel: string;
	site_notification?: any;
	appVersion: string;
	cacheBustSuffix: any;
};

export class AppDataService {
	public static readonly NG1_INJECTION_NAME = 'AppData' as const;
	public dataConfig;
	public disjoint_groups = [];
	public fine_uploader: any = {};
	public google_analytics: any = {};
	public language: Language = Language.EN;
	public my_account: any = false;
	public my_orgs: any = [];
	public partners: any = {};
	public pubnub: any = {};
	public user: any = {};
	public user_pubnub_channel: string = '';
	public site_notification: any = {};
	public appDataSubject = new Subject();
	public pubnubService: PubnubService;
	public appVersion: string = '';
	public cacheBustSuffix: any = {};

	/* @ngInject */
	constructor (
		private $http: angular.IHttpService,
		private $log: angular.ILogService,
		private $window: angular.IWindowService,
		private $state: StateService,
		private $q: ng.IQService,
		private sessionManager,
		public appEnv: AppEnvService,
		pubnub: PubnubService,
		public goLocalizeHelper: GoLocalizeHelperService,
		public fullstoryService: FullstoryService,
		public fineUploader: FineUploaderService,
		public zendeskService: ZendeskService,
		public appSessionManagerService: AppSessionManagerService,
		public analyticsService: AnalyticsService,
		public watsonUsageTrackingService: WatsonUsageTrackingService,
		public User,
		private bannerNotificationsService: BannerNotificationsService,
		private userService: UserService,
		public messageModal,
		private selectedService: SelectedService,
		private Group,
		private authService: AuthService,
		private $cookies: ng.cookies.ICookiesService,
		private featureFlag: FeatureFlag,
		private goBannerService: GoBannerService,
		private $translate: ng.translate.ITranslateService
	) {
		this.pubnubService = pubnub;
	}

	/**
	 * Initialize user.
	 * This setup user to app our AppData.
	 */
	public initAppData () {
		if (this.dataConfig) return;
		const headers = { headers: this.sessionManager.getRequestHeaders() };
		return this.getAppData(headers)
			.then(( { data }: any) => {

				if (this.$cookies.get('is_self_demo_experiment') === 'true') {
					// Self demo should only be on the video sharing app
					// If you got here, then you should be logged out.
					return this.authService.logout().then(() => this.$q.reject());
				}

				data.my_orgs = data.my_orgs.map((item) => {
					item.my_account = this.Group.model(data.my_account);
					item.org_id = item.group_id;
					return item;
				});

				this.selectedService.setMyOrgs(data.my_orgs);
				this.selectedService.setAccount(this.Group.model(data.my_account));
				appData.setData(data);
				this.userService.setCurrentUser(this.User.model(data.user));
				this.setupAppData(data);
				this.dataConfig = data;
				this.setupDepedencies();
			})
			.catch((error) => {
				if (error.status === 401) {
					// If we have a 401 the user isn't logged in.
					// Clear all session information and let the routing take them to the login page
					return this.authService.logout().then(() => this.$q.reject());
				}

				// Otherwise we don't know why we are exploding. Tell the user then reload the page
				this.$log.error(error.data);
				this.messageModal.open({
					modalData: {
						title: 'modal-confirm_app_data_error',
						message: 'modal-confirm_app_data_error_message',
						resolveBtnText : 'header_logout',
						rejectBtnText : 'modal-confirm_app_data_error_confirm_btn',
						resolveSecondaryBtnText : 'header_logout'
					}
				})
					.result
					.then(() => {
						// Primary button (logout) was clicked
						return this.authService.logout().then(() => {
							this.$state.go(States.AUTH_LOGIN);
						});
					})
					.catch((err) => {
						if (err === 'reject') {
							// Secondary button (refresh) was clicked
							this.$window.location.reload();
						}
					});
			});
	}

	public reset () {
		this.dataConfig = null;
		this.clearAppData();
	}

	private getAppData (headers) {
		return this.$http.get(`${clientSettings.GoReactV2API}/dashboard/data`, headers);
	}

	/**
	 * Setup data and publish for the listeners that AppData was updated.
	 *
	 * @param {AppDataConfig} data
	 */
	private setupAppData (data): void {
		this.disjoint_groups = data.disjoint_groups ?? [];
		this.fine_uploader = data.fine_uploader ?? {};
		this.google_analytics = data.googleAnalytics ?? {};
		this.language = data.language ?? '';
		this.my_account = data.my_account ?? false;
		this.my_orgs = data.my_orgs ?? [];
		this.partners = data.partners ?? {};
		this.pubnub = data.pubnub ?? {};
		this.user = data.user ?? {};
		this.user_pubnub_channel = data.user_pubnub_channel ?? '';
		this.site_notification = data.site_notification ?? {};
		this.cacheBustSuffix = data.cacheBustSuffix ?? {};
		this.appVersion = data.appVersion ?? '';
		this.watsonUsageTrackingService.savePendingTranscriptionUsage();
		this.appDataSubject.next(null);
	}

	/**
	 * Clear appdata on logout.
	 */
	private clearAppData (): void {
		this.disjoint_groups = [];
		this.fine_uploader = {};
		this.google_analytics = {};
		this.language = Language.EN;
		this.my_account = false;
		this.my_orgs = [];
		this.partners = {};
		this.pubnub = {};
		this.user = {};
		this.user_pubnub_channel = '';
		this.site_notification = {};
		this.cacheBustSuffix = {};
		this.appVersion = '';
		this.appDataSubject.next(null);
	}

	private setupDepedencies (): void {
		const user = this.User.model(this.user);
		const appEnvData = {
			partners: this.partners,
			disjoint_groups: this.disjoint_groups,
			user_pubnub_channel: this.user_pubnub_channel,
			userCreatedAt: user.created,
			userId: parseInt(user.user_id, 10)
		};
		const analyticsAppData = {
			analytics: this.google_analytics,
			user: this.user
		};
		this.pubnubService.configure(this.pubnub);
		this.appEnv.configure(appEnvData);
		this.goLocalizeHelper.configure(this.language);
		this.appSessionManagerService.configure({user: this.user});
		this.fullstoryService.configure(user);
		this.fineUploader.configure(this.fine_uploader);
		this.zendeskService.configure(user);
		this.analyticsService.configure(analyticsAppData);
		this.bannerNotificationsService.configure();
	}

	public hasInstructorRole (atleast = false) {
		return this.disjoint_groups.some((group) => {
			return group.hasInstructorRole(atleast);
		});
	}

	public hasReviewerRole (atleast = false) {
		return this.disjoint_groups.some((group) => {
			return group.hasReviewerRole(atleast);
		});
	}

	public hasPresenterRole (atleast = false) {
		return this.disjoint_groups.some((group) => {
			return group.hasPresenterRole(atleast);
		});
	}
}

export const appDataServiceToken = upgradeNg1Dependency(AppDataService);
