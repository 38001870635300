import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CustomParamsDialogComponent } from 'ngx/go-modules/src/components/dialogs/custom-params-dialog/custom-params-dialog.component';
import { CopyToClipboardDirectiveModule } from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		GoMaterialModule,
		CopyToClipboardDirectiveModule
	],
	declarations: [
		CustomParamsDialogComponent
	],
	exports: [
		CustomParamsDialogComponent
	],
	entryComponents: [
		CustomParamsDialogComponent
	]
})
export class CustomParamsDialogModule {}
