import {
	ChangeDetectionStrategy,
	Component, Inject
} from '@angular/core';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { StateService } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import {
	SelectedService,
	selectedServiceToken
} from 'go-modules/services/selected/selected.service';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { SeatOveragePolicies } from 'ngx/go-modules/src/enums/salesforce-license';

@Component({
	selector: 'licensing-breadcrumbs',
	template: require('./licensing-breadcrumbs.component.html'),
	styles: [require('./licensing-breadcrumbs.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LicensingBreadcrumbsComponent {
	public readonly SeatOveragePolicies = SeatOveragePolicies;
	public States = States;

	constructor (
		@Inject($stateToken) private $state: StateService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		public breadcrumbStateService: BreadCrumbStateService
	) {}

	public goToLicenseManagement () {
		this.$state.go(States.LICENSE_MANAGEMENT);
	}
}
