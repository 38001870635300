import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CompareProductsDialogComponent } from './compare-products-dialog.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { MonthsToYearsMonthsPipeModule } from 'ngx/go-modules/src/pipes/months-years/months-years.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		MonthsToYearsMonthsPipeModule
	],
	declarations: [
		CompareProductsDialogComponent,
		ProductCardComponent
	],
	exports: [
		CompareProductsDialogComponent,
		ProductCardComponent
	],
	entryComponents: [
		CompareProductsDialogComponent,
		ProductCardComponent
	],
	providers: [
		TranslatePipe
	]
})
export class CompareProductsDialogModule {}
