import * as angular from 'angular';

/* @ngInject */
export const revert = () => {
	return {
		restrict: 'A',
		link (scope, _element, attrs) {
			if (attrs.ngModel) {
				scope.$watch(attrs.ngModel, (_value, oldValue) => {
					const lastPeriod = attrs.ngModel.lastIndexOf('.');
					const srcObjExp = attrs.ngModel.substring(0, lastPeriod);
					const key = attrs.ngModel.substring(lastPeriod + 1, attrs.ngModel.length);
					const srcObj = scope.$eval(srcObjExp);
					if (angular.isObject(srcObj)) {
						if (!srcObj.revert) {
							srcObj.revert = {};
						}
						srcObj.revert[key] = oldValue;
					}
				});
			}
		}
	};
};
