import { Injectable } from '@angular/core';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, combineLatest, debounceTime, map, shareReplay, switchMap, tap } from 'rxjs';
import { ArchiveFilter } from 'ngx/go-modules/src/enums/archive-filter';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import { ArchiveService } from './archive.service';
import { ArchivedItem } from 'ngx/go-modules/src/interfaces/archived/archived.interface';
import { accumulateArr } from 'ngx/go-modules/src/rxjs/accumulate/accumulate';

export const DEBOUNCE_TIME = 50;
export const DEFAULT_SORT_ORDER = SortOrder.NEWEST;
export const DEFAULT_FILTER = ArchiveFilter.ALL;

@Injectable({
	providedIn: 'root'
})
export class ArchivedListDataSource extends BaseDataSource<ArchivedItem, ArchiveFilter> {
	private filterBy$$ = new BehaviorSubject<ArchiveFilter>(ArchiveFilter.ALL);
	private sortBy$$ = new BehaviorSubject<SortOrder>(SortOrder.NEWEST);
	private add$$ = new Subject<ArchivedItem>();
	private remove$$ = new Subject<ArchivedItem>();

	constructor (private archiveService: ArchiveService) {
		super();
		this.params$ = combineLatest([
			this.filterBy$$.asObservable(),
			this.reload$$
		], (filter, _reload) => filter);
		this.results$ = this.getArchiveItems();
	}

	public get currentSort () {
		return this.sortBy$$.getValue();
	}

	public get currentFilter () {
		return this.filterBy$$.getValue();
	}

	public connect (): Observable<readonly ArchivedItem[]> {
		return this.results$;
	}

	public disconnect (): void {}

	public add (item) {
		this.add$$.next(item);
	}

	public remove (item: ArchivedItem) {
		this.remove$$.next(item);
	}

	public sortBy (sortBy: SortOrder) {
		this.sortBy$$.next(sortBy);
	}

	public filterBy (filter: ArchiveFilter) {
		this.filterBy$$.next(filter);
	}

	private sortResult (results, sort: SortOrder) {
		return results.sort((f1, f2) => {
			if (sort === SortOrder.NEWEST) {
				return new Date(f2.archived_at).getTime() - new Date(f1.archived_at).getTime();
			}

			if (sort === SortOrder.OLDEST) {
				return new Date(f1.archived_at).getTime() - new Date(f2.archived_at).getTime();
			}

			if (sort === SortOrder.ASC) {
				if (f1.name < f2.name) {
					return -1;
				}

				if (f1.name > f2.name) {
					return 1;
				}

				return 0;
			}

			if (f1.name > f2.name) {
				return -1;
			}

			if (f1.name < f2.name) {
				return 1;
			}

			return 0;
		});
	}

	private getArchiveItems () {
		return this.params$
			.pipe(
				debounceTime(DEBOUNCE_TIME),
				tap(() => this.setLoading(true)),
				switchMap((params: ArchiveFilter) => this.archiveService.getArchivedItems(params)),
				switchMap((archiveList: ArchivedItem[]) => {
					const added$ = this.add$$.pipe(accumulateArr());
					const removed$ = this.remove$$.pipe(accumulateArr());
					return combineLatest({added: added$, removed: removed$}).pipe(
						map(({added, removed}) =>
							archiveList.concat(added)
								.filter((item) => !removed.includes(item))
						)
					);
				}),
				tap(() => this.setLoading(false)),
				shareReplay({ bufferSize: 1, refCount: true }),
				switchMap((results) =>
					this.sortBy$$.asObservable()
						.pipe(map((sortOrder) => this.sortResult(results, sortOrder)))),
				catchError((err) => {
					this.onError$$.next(err);
					this.setLoading(false);
					return EMPTY;
				})
			);
	}
}
