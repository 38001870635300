import {
	ChangeDetectionStrategy,
	Component,
	OnInit
} from '@angular/core';

@Component({
	selector: 'auth-template',
	template: require('./auth-template.component.html'),
	styles: [require('./auth-template.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthTemplateComponent implements OnInit {

	constructor () {}

	public ngOnInit (): void {

	}

}
