import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'feedback-session-header'
})
export class FeedbackSessionHeaderDirective extends UpgradeComponent {
	constructor (
		elementRef: ElementRef,
		injector: Injector
	) {
		super('feedbackSessionHeader', elementRef, injector);
	}
}
