export enum SignupStepsState {
	EMAIL_AND_PASSWORD,
	EMAIL_VERIFICATION,
	LTI_CONFIRMATION,
	ORG_SEARCH,
	ORG_CREATE,
	ORG_INVITE,
	ACCOUNT_CREATE,
	STUDENT_SIGNUP_MESSAGE
}
