import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import { ResponsiveViewService, Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import type { TransitionService } from '@uirouter/angularjs';
import './zendesk.lib.js';
type ZendeskInit = (arg0: () => void) => void;

interface Zendesk {
	setHelpCenterSuggestions(arg0: object): void;
	identify(arg0: object): void;
}

/* @ngInject */
export class ZendeskService {
	public static readonly NG1_INJECTION_NAME = 'zendeskService' as const;
	public userIdTracker: string;
	private zendeskInit:  ZendeskInit;
	private zendesk: Zendesk;

	constructor (
		private responsiveView: ResponsiveViewService,
		private UADetect: UADetectClass,
		private $window: angular.IWindowService,
		private $transitions: TransitionService,
		private $rootScope: angular.IRootScopeService
	) {
		this.zendeskInit = $window.zE as ZendeskInit;
		const deregisterTransitionHook = this.$transitions.onFinish({}, this.transitionOnFinish.bind(this));
		this.$rootScope.$on('$destroy', () => {
			deregisterTransitionHook();
		});
	}

	public transitionOnFinish () {
		this.updateZendeskWidget();
	}

	public configure (user: any) {
		if (user.user_id === this.userIdTracker) return;
		this.userIdTracker = user.user_id;
		this.zendeskInit(() => {
			// zE on window is redefined after intialization of zendesk.
			this.zendesk = this.$window.zE as Zendesk;

			// Allows for custom links controlled by support
			this.zendesk.setHelpCenterSuggestions({labels: ['widget']});

			this.zendesk.identify({
				name: user.fullname || user.full_name,
				email: user.email
			});
		});
	}

	public reset () {
		this.userIdTracker = null;
	}

	public updateZendeskWidget () {
		// DEV-10517 Make the Zendesk Web Widget more friendly when zoomed in on desktop
		if (this.UADetect.isMobile()) return;

		const isSmall = this.responsiveView.isAtMost(Sizes.SMALL);

		let webWidget;
		if (!isSmall) {
			webWidget = this.getVerticalNavPositioning();
		} else {
			webWidget = this.getLegacyDashboardPositioning();
		}

		this.$window.zE('webWidget', 'updateSettings', { webWidget });

		// We have to add the class to the body because the Zendesk widget is not present on init
		const action = isSmall ? 'add' : 'remove';
		this.$window.document.body.classList[action]('zendesk-zoomed-in-override');
	};

	private getVerticalNavPositioning () {
		return {
			position: { horizontal: 'left', vertical: 'bottom' },
			offset: { horizontal: '225px', vertical: '10px' }
		};
	}

	private getLegacyDashboardPositioning () {
		const offset = '-22px';
		return {
			// Need to reset the position if resizing from large to small screens
			position: { horizontal: 'right', vertical: 'top' },
			offset: { horizontal: offset, vertical: offset }
		};
	}
}

export const zendeskToken = upgradeNg1Dependency(ZendeskService);
