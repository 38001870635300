import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxActivityCardComponent } from 'ngx/go-modules/src/components/folder-view/activity-card/activity-card.component';
import { CardContainerModule } from 'ngx/go-modules/src/components/card-container/card-container.module';
import { NgxActivityDueDateModule } from 'ngx/go-modules/src/components/folder-view/activity-due-date/activity-due-date.module';
import { NgxActivityListStatusModule } from 'ngx/go-modules/src/components/folder-view/activity-list-status/activity-list-status.module';
import { DotIndicatorModule } from 'ngx/go-modules/src/components/dot-indicator/dot-indicator.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { ActivityMenuModule } from 'ngx/go-modules/src/components/menus/activity-menu/activity-menu.module';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { CommentIndicatorModule } from 'ngx/go-modules/src/components/comment-indicator/comment-indicator.module';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		CardContainerModule,
		NgxActivityDueDateModule,
		NgxActivityListStatusModule,
		GoMaterialModule,
		DotIndicatorModule,
		ActivityMenuModule,
		CommentIndicatorModule,
		MenuModule
	],
	declarations: [
		NgxActivityCardComponent
	],
	entryComponents: [
		NgxActivityCardComponent
	],
	exports: [
		NgxActivityCardComponent
	],
	providers: [
		ActivityListDataSource
	]
})
export class NgxActivityCardModule {}
