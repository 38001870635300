import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { userServiceToken } from 'go-modules/models/user/user.service';
import type { UserService } from 'go-modules/models/user/user.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { snakeCase } from 'lodash';
import type { EmailFrequencyMatOption, NotificationSettings } from 'ngx/go-modules/src/interfaces/notification-settings';
import { NotificationFrequencies } from 'ngx/go-modules/src/interfaces/notification-settings';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';

export const DEBOUNCE_TIME = 500;

@Component({
	selector: 'email-notifications',
	template: require('./email-notifications.component.html'),
	styles: [require('./email-notifications.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailNotificationsComponent {
	public form: FormGroup;
	public user;
	public shouldShowSaveButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public frequencies: EmailFrequencyMatOption[];
	public frequenciesNotWeekly: EmailFrequencyMatOption[];
	public frequenciesNotImmediately: EmailFrequencyMatOption[];
	public frequenciesNotHourly: EmailFrequencyMatOption[];
	public assignmentSubmittedEmailFrequency: EmailFrequencyMatOption;
	public feedbackSubmittedEmailFrequency: EmailFrequencyMatOption;
	public newAssignmentEmailFrequency: EmailFrequencyMatOption;
	public assignmentDueEmailFrequency: EmailFrequencyMatOption;
	public assignmentGradedEmailFrequency: EmailFrequencyMatOption;
	public emailFrequencies: string[] = [
		'assignmentSubmittedEmailFrequency',
		'feedbackSubmittedEmailFrequency',
		'newAssignmentEmailFrequency',
		'assignmentDueEmailFrequency',
		'assignmentGradedEmailFrequency'
	];
	public notificationSettings: NotificationSettings;
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		private fb: FormBuilder,
		private ngxUserService: NgxUserService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(userServiceToken) private userService: UserService
	) {}

	public ngOnInit () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();

		this.user = this.userService.currentUser;
		this.frequencies = this.getFrequencies();
		this.frequenciesNotImmediately = this.frequencies.filter((frequency) =>
			frequency.name !== NotificationFrequencies.IMMEDIATELY
		);
		this.frequenciesNotWeekly = this.frequenciesNotImmediately.filter((frequency) =>
			frequency.name !== NotificationFrequencies.WEEKLY
		);
		this.frequenciesNotHourly = this.frequencies.filter((frequency) =>
			frequency.name !== NotificationFrequencies.HOURLY
		);
		this.initializeForm();

		this.ngxUserService.getNotificationSettings(this.user.user_id).subscribe((notificationSettings) => {
			this.notificationSettings = notificationSettings;
			this.emailFrequencies.forEach((emailFrequency) => {
				this[emailFrequency] = this.frequencies.find(({value}) =>
					value === notificationSettings[snakeCase(emailFrequency)]
				);

				this.form.get(emailFrequency).setValue(this[emailFrequency]);
			});
		});
	}

	public hasReviewerRoleOrHigher (): boolean  {
		return this.userService.currentUser.isReviewerPlus;
	}

	public hasPresenterRole (): boolean {
		return this.userService.currentUser.isPresenter;
	}

	public isLti () {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public getFrequencies () {
		return [
			{ name: NotificationFrequencies.IMMEDIATELY, value: NotificationFrequencies.IMMEDIATELY.toLowerCase() },
			{ name: NotificationFrequencies.HOURLY, value: NotificationFrequencies.HOURLY.toLowerCase() },
			{ name: NotificationFrequencies.DAILY, value: NotificationFrequencies.DAILY.toLowerCase() },
			{ name: NotificationFrequencies.WEEKLY, value: NotificationFrequencies.WEEKLY.toLowerCase() },
			{ name: NotificationFrequencies.NEVER, value: NotificationFrequencies.NEVER.toLowerCase() }
		];
	}

	private initializeForm () {
		const group = {};

		this.emailFrequencies.forEach((emailFrequency) =>
			group[emailFrequency] = new FormControl(this[emailFrequency], [Validators.required])
		);

		this.form = this.fb.group(group);

		this.form.valueChanges.pipe(
			debounceTime(DEBOUNCE_TIME),
			distinctUntilChanged()
		).subscribe((val) => {
			const hasChanges = this.emailFrequencies.some((emailFrequency) =>
				this[emailFrequency] !== val[emailFrequency]
			);

			this.shouldShowSaveButton$.next(hasChanges);
		});
	}

	public saveNotificationSettings (form) {
		if (!form.valid) {
			return;
		}

		this.emailFrequencies.forEach((emailFrequency) => {
			this.notificationSettings[snakeCase(emailFrequency)] = this.form.get(emailFrequency).value.value;
		});

		this.ngxUserService.saveNotificationSettings(this.user.user_id, this.notificationSettings).subscribe(() => {
			this.shouldShowSaveButton$.next(false);
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'common-save-success'
			});
		});
	}
}
