import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CourseContextMenuComponent } from './course-context-menu.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatMenuModule,
		GoMaterialModule
	],
	declarations: [
		CourseContextMenuComponent
	],
	entryComponents: [
		CourseContextMenuComponent
	],
	exports: [
		CourseContextMenuComponent
	],
	providers: [
		TranslatePipe
	]
})
export class CourseContextMenuModule {}
