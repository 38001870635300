import { BidiModule } from '@angular/cdk/bidi';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridVirtualScrollRowComponent } from './components/grid-virtual-scroll-row/grid-virtual-scroll-row.component';
import { GridVirtualScrollViewportComponent } from './components/grid-virtual-scroll-viewport/grid-virtual-scroll-viewport.component';
import { GridVirtualForOfDirective } from './grid-virtual-for-of.directive';
import { CdkVirtualForOfOverrrideDirective } from './override/cdk-virtual-for-of-override.directive';

@NgModule({
	imports: [
		CommonModule,
		BidiModule,
		ScrollingModule
	],
	declarations: [
		GridVirtualScrollViewportComponent,
		GridVirtualForOfDirective,
		CdkVirtualForOfOverrrideDirective,
		GridVirtualScrollRowComponent
	],
	exports: [
		GridVirtualScrollViewportComponent,
		GridVirtualForOfDirective,
		CdkVirtualForOfOverrrideDirective,
		GridVirtualScrollRowComponent
	]
})
export class GridVirtualScrollViewportModule {}
