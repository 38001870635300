import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LicenseRenewalSnackBarComponent } from './license-renewal-snack-bar.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LicenseRenewalSnackBarComponent
	],
	entryComponents: [
		LicenseRenewalSnackBarComponent
	],
	exports: [
		LicenseRenewalSnackBarComponent
	]
})
export class LicenseRenewalSnackBarModule {}
