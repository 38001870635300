import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { BehaviorSubject, catchError, forkJoin, of, retry, Subject } from 'rxjs';
import { WelcomeStepsState } from './welcome-dialog-steps-state';
import type { License } from 'go-modules/services/group/license';
import { OrgType } from 'ngx/go-modules/src/interfaces/groups/org-types';
import type { OrgSettings } from 'ngx/go-modules/src/interfaces/groups/org-settings';
import * as dayjs from 'dayjs';

enum requestStatus {
	FAILED = 'failed',
	LOADING = 'loading',
	LOADED = 'loaded'
}

@Component({
	selector: 'welcome-dialog',
	template: require('./welcome-dialog.component.html'),
	styles: [require('./welcome-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeDialogComponent implements OnInit {
	public showSelfPayStep: boolean = false;
	public orgSettings: OrgSettings;
	public requestStatus: string = requestStatus.LOADING;
	public showLoadingIndicator$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public loading$$: Subject<boolean> = new Subject();
	/* eslint-disable-next-line max-len */
	public step$: BehaviorSubject<WelcomeStepsState> = new BehaviorSubject<WelcomeStepsState>(WelcomeStepsState.WELCOME);
	public welcomeStepsState = WelcomeStepsState;
	public eligibleLicenses: License[] = [];
	public hasFreeTrialLicense: boolean;

	constructor (
		public dialogRef: GoDialogRef,
		private ngxGroupService: NgxGroupService,
		@Inject(GO_MODAL_DATA) public data: { user: any; account: any; numDays: any }
	) {}

	public ngOnInit () {
		// if no account then they must be a student
		this.showSelfPayStep = this.data.account;
		if (this.showSelfPayStep) {
			const billingRequest = this.ngxGroupService.getEligibleBillingObservable(this.data.account.group_id);
			const orgSettingsRequest = this.ngxGroupService.getOrgSettings(this.data.account.org_id);
			forkJoin([billingRequest, orgSettingsRequest])
				.pipe(retry(3))
				.pipe(catchError(() => {
					this.requestStatus = requestStatus.FAILED;
					this.loading$$.next(false);
					return of(null);
				}))
				.subscribe((res) => {
					if (res) {
						this.eligibleLicenses = res[0].licenses;
						this.orgSettings = res[1];
						this.requestStatus = requestStatus.LOADED;
						this.showLoadingIndicator$.next(false);
						this.loading$$.next(true);
						this.hasFreeTrialLicense = this.eligibleLicenses.some((license) => {
							return license.salesforce_license?.is_free_trial;
						});
						if (this.hasFreeTrialLicense) {
							const index = this.eligibleLicenses.findIndex((license) => {
								return license.salesforce_license.is_free_trial;
							});
							this.data.numDays = dayjs(this.eligibleLicenses[index].ends_at).diff(dayjs(), 'days');
						}
					}
				});
		} else {
			this.showLoadingIndicator$.next(false);
		}
	}

	public continue () {
		if (this.showSelfPayStep && this.requestStatus === requestStatus.LOADING) {
			this.showLoadingIndicator$.next(true);
			this.loading$$.subscribe((successful) => {
				if (successful) {
					if (this.eligibleLicenses.length === 0 && this.orgSettings.org_type !== OrgType.BIZ) {
						this.step$.next(WelcomeStepsState.CHOOSE_PLAN);
						return;
					} else {
						this.close({selfPay: false});
					}
				} else {
					this.close({selfPay: false});
				}
			});
			return;
		} else if (this.showSelfPayStep &&
			this.requestStatus === requestStatus.LOADED &&
			this.eligibleLicenses.length === 0 &&
			this.orgSettings.org_type !== OrgType.BIZ
		) {
			this.step$.next(WelcomeStepsState.CHOOSE_PLAN);
			return;
		}
		this.close({selfPay: false});
	}

	public back () {
		this.step$.next(WelcomeStepsState.WELCOME);
	}

	/**
	 * Close the dialog
	 */
	public close (result: {selfPay: boolean}) {
		this.dialogRef.close(result.selfPay);
	}
}
