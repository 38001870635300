import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SessionListSearchResultInfoComponent } from './session-list-search-result-info.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		SessionListSearchResultInfoComponent
	],
	entryComponents: [
		SessionListSearchResultInfoComponent
	],
	exports: [
		SessionListSearchResultInfoComponent
	]
})
export class SessionListSearchResultInfoModule {}
