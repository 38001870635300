import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LicenseUpgradeSnackBarComponent } from './license-upgrade-snack-bar.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LicenseUpgradeSnackBarComponent
	],
	entryComponents: [
		LicenseUpgradeSnackBarComponent
	],
	exports: [
		LicenseUpgradeSnackBarComponent
	]
})
export class LicenseUpgradeSnackBarModule {}
