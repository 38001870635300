import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { confirmModalModule } from '../confirm';
import { activityModel } from '../../models/activity';
import { mediaModel } from '../../models/media';
import { ActivityRecordingInstructionsEditorModal } from './modal.instructions-recording';
import { ActivityFeedbackInstructionsEditorModal } from './modal.instructions-feedback';
import { ActivityInstructionsEditorModal } from './modal';
import type { ModalOptions as ExportModalOptions } from './options';

import './modal.less';
import { featureFlagModule } from 'go-modules/feature-flag';
import { instructionsPreviewModalModule } from 'go-modules/modals/instructions-preview';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.

// tslint:disable-next-line:import-spacing
import /*{ goModal } from*/ 'go-modules/modals';
// tslint:disable-next-line:import-spacing
import /*{ mediaThumbnailModule } from*/ '../../media-thumbnail';
import { ngxRichTextEditorModule } from 'ngx/go-modules/src/components/rich-text-editor';
import { ngxFeatureFlagServiceModule } from 'ngx/go-modules/src/services/feature-flag';

export {
	ActivityRecordingInstructionsEditorModal,
	ActivityFeedbackInstructionsEditorModal
};

export type ModalOptions = ExportModalOptions;

export const activityInstructionsEditorModalModule = angular.module('modal.activityInstructionsEditor', [
	goModalBootstrapModule,
	angularTranslate,
	confirmModalModule,
	activityModel,
	mediaModel,
	featureFlagModule,
	instructionsPreviewModalModule,
	ngxRichTextEditorModule,
	ngxFeatureFlagServiceModule,
	// TODO: use module strings directly until circular dependency is fixed
	'go.modal',
	'go.mediaThumbnail'
])
	.service('activityInstructionsEditorModal', ActivityInstructionsEditorModal)
	.service('activityRecordingInstructionsEditorModal', ActivityRecordingInstructionsEditorModal)
	.service('activityFeedbackInstructionsEditorModal', ActivityFeedbackInstructionsEditorModal)
	.name;
