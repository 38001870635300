import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output
} from '@angular/core';
import { SignupStepsState } from '../sign-up-steps-state';

@Component({
	selector: 'lti-confirmation',
	template: require('./lti-confirmation.component.html'),
	styles: [require('./lti-confirmation.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LTIConfirmationComponent {
	@Output()
	public onBack = new EventEmitter<SignupStepsState>();
	@Output()
	public onProceed = new EventEmitter<SignupStepsState>();

	constructor () {}

	public back () {
		this.onBack.emit();
	}

	public proceed () {
		this.onProceed.emit();
	}
}
