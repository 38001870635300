import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';

@Component({
	selector: 'folder-zero-state',
	template: require('./folder-zero-state.component.html'),
	styles: [require('./folder-zero-state.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderZeroStateComponent implements OnInit, OnDestroy {
	@Output() public openAssignmentSourceModal = new EventEmitter();
	@Output() public openInvite = new EventEmitter();
	@Input() public hasInstructorRoleOrAbove = false;
	public isFiltering$ = new BehaviorSubject<boolean>(false);

	public componentDestroyed$$ = new Subject();
	public canCreateActivity$$ = new BehaviorSubject<boolean>(false);
	public getActivityCreationDisabledMessage$$ = new BehaviorSubject<string>('');
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		private ngxActivityService: NgxActivityService,
		private translateService: TranslateService,
		public activityListDataSource: ActivityListDataSource
	) {
		this.activityListDataSource.isFiltering$.subscribe((isFiltering) => this.isFiltering$.next(isFiltering));
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		this.getLicenseAndValidate();
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.canCreateActivity$$.complete();
		this.getActivityCreationDisabledMessage$$.complete();
	}

	public isLti () {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public getTitle (): Observable<string> {
		if (this.selectedService.getGroup().hasInstructorRole(true)) {
			return this.isFiltering$.getValue() ?
				this.translateService.get('group-view_zero-state-data-source-empty-title-instructor-with-filter') :
				this.translateService.get('group-view_zero-state-data-source-empty-title-instructor-without-filter');
		}

		return this.translateService.get('group-view_zero-state-data-source-empty-title-presenter');
	}

	public getSubText (): Observable<string> {
		if (this.selectedService.getGroup().hasInstructorRole(true)) {
			return this.isFiltering$.getValue() ?
				this.translateService.get('group-view_zero-state-data-source-empty-subtitle-instructor-with-filter') :
				this.translateService.get('group-view_zero-state-data-source-empty-subtitle-instructor-without-filter');
		}

		return this.translateService.get('group-view_zero-state-data-source-empty-subtitle-presenter');
	}

	private validateCanCreateActivity () {
		this.canCreateActivity$$.next(this.ngxActivityService.canCreateActivity());
		this.getActivityCreationDisabledMessage$$.next(this.ngxActivityService.getActivityCreationDisabledMessage());
	}

	private getLicenseAndValidate () {
		this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.componentDestroyed$$),
				filter((selected: any) => selected.group)
			)
			.subscribe(() => {
				this.validateCanCreateActivity();
			});
	}
}
