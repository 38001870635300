/* @ngInject */
export const fullnameFilter = () => {
	return (user) => {
		if (!user) {
			return;
		}

		const firstName = user.first_name || '';
		const lastName = user.last_name || '';
		const space = firstName.length && lastName.length ? ' ' : '';

		return firstName + space + lastName;
	};
};
