import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import {
	GroupSettingsPanelService,
	groupSettingsPanelServiceToken
} from 'go-modules/group-settings-panel/group-settings-panel.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';

@Component({
	selector: 'course-context-menu',
	template: require('./course-context-menu.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class CourseContextMenuComponent {
	@Input() public canCreateActivity;
	@Input() public canReorderActivities: boolean;
	@Output() public onInvite = new EventEmitter();
	@Output() public onCreateActivity = new EventEmitter();
	@Output() public onSortActivities = new EventEmitter();
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		private folderListDataSource: FolderListDataSource,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public manageUsers () {
		this.openSettings('users');
	}

	public editFolder () {
		this.openSettings('info');
	}

	public isLti () {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public openSettings (defaultTab: 'info' | 'users') {
		const folder = this.selectedService.getGroup();
		this.groupSettingsPanel.open(folder, this.userService.currentUser, defaultTab, {}).result.then(
			(group?) => {
				// group is not-null if changed
				if (group != null) {
					this.folderListDataSource.editFolder(group);
				}
			}
		);
	}

	public hasMenuAccess (): boolean {
		return this.selectedService.getGroup().hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}
}
