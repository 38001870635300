import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'loading',
	template: require('./loading.component.html'),
	styles: [require('./loading.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
	@Input() public height: string = '75px';
	@Input() public width: string = '75px';

	constructor () {}
}
