import { Component, Inject, Input} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import type { ActivityFactory } from 'go-modules/models/activity/activity.factory';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { MediaStatus } from 'ngx/go-modules/src/services/media/media-status';

@Component({
	selector: 'dot-indicator',
	template: require('./dot-indicator.component.html'),
	styles: [require('./dot-indicator.component.scss')],
	providers: [TranslatePipe]
})
export class DotIndicatorComponent {
	@Input() public color: 'green' | 'red' = 'green';
	@Input() public size = 6;
	@Input() public hasBorder = false;
	@Input() public borderColor: 'white' | 'black' = 'white';

	@Input() public activity?;
	@Input() public folder?;
	@Input() public session?;

	constructor (
		private translatePipe: TranslatePipe,
		@Inject(activityToken) private Activity: ActivityFactory,
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public getBorderColor () {
		return 'border-' + this.borderColor;
	}

	public getAnimation () {
		return (this.session && this.session.isLive()) ? 'is-live': '';
	}

	public shouldShowAttentionNeeded (): boolean {
		if (this.activity) {
			return this.shouldShowAttentionNeededForActivity();
		} else if (this.folder) {
			return this.folder.stats?.attention_needed;
		} else if (this.session) {
			return this.shouldShowAttentionForSession();
		}
		return false;
	}

	public getTooltip () {
		if (this.activity) {
			return this.getActivityTooltip();
		} else if (this.folder) {
			return this.getFolderTooltip();
		} else if (this.session) {
			return this.getSessionTooltip();
		}
	}

	private shouldShowAttentionNeededForActivity (): boolean {
		const activity = this.Activity.model(this.activity);
		const group = this.selectedService.getGroup();

		return (group.hasPresenterRole() &&
			(activity.viewed_by_me === false || this.shouldShowWarningForDueAssignment(activity))) ||
			(group.hasReviewerRole(true) && activity.unviewed_sessions > 0);
	}

	private shouldShowWarningForDueAssignment (activity): boolean {
		return activity.have_i_submitted === false &&
			(activity.isAlmostDue() || activity.isPastDue());
	}

	private shouldShowAttentionForSession (): boolean {
		return this.session.isLive() ||
			(!this.session.isLive() &&
				!this.session.viewed &&
				this.session.media?.media_status === MediaStatus.READY) ||
			this.session.num_direct_unviewed_comments > 0;
	}

	private getActivityTooltip () {
		const activity = this.Activity.model(this.activity);
		const group = this.selectedService.getGroup();

		if (group.hasPresenterRole()) {
			if (activity.viewed_by_me === false) {
				return 'activity-list_activity-card_new_assignment';
			} else {
				if (activity.isAlmostDue() && !activity.isPastDue()) {
					return 'activity-list_activity-card_coming_due';
				} else if (activity.isPastDue()) {
					return 'activity-list_activity-card_past_due';
				}
			}
		} else if (group.hasReviewerRole(true) && activity.unviewed_sessions > 0) {
			return 'activity-list_activity-card_unviewed_submissions';
		}
	}

	private getFolderTooltip () {
		return this.folder.hasPresenterRole() ? 'course-menu_unviewed-assignments' : 'course-menu_unviewed-sessions';
	}

	private getSessionTooltip () {
		if (this.session.isLive()) {
			return 'session-list-item_live';
		} else {
			if (!this.session.viewed) {
				return 'session-list-item_unviewed';
			} else {
				const key = this.session.num_direct_unviewed_comments === 1 ?
					this.translatePipe.transform('comment-notifications-unviewed-single') :
					this.translatePipe.transform('comment-notifications-unviewed-plural', {number: this.session.num_direct_unviewed_comments});
				return key;
			}
		}
	}
}
