import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';

@Component({
	selector: 'folder-card',
	template: require('./folder-card.component.html'),
	styles: [require('./folder-card.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderCardComponent {
	@Input() public folder: any;
	@Output() public goToFolder: EventEmitter<any>  = new EventEmitter();
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('cardContainer', {read: ElementRef}) public cardContainer: ElementRef;
	public selectedFolder: any;

	constructor (
		@Inject(userServiceToken) private userService: UserService
	) {}

	public openMenu (event: MouseEvent) {
		event.stopPropagation();
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.canOpenMenu()) {
			return;
		}

		event.preventDefault();
		this.selectedFolder = this.folder;
		this.menuComponent.openMenu(event, this.cardContainer.nativeElement);
	}

	public canOpenMenu (): boolean {
		return this.folder.hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public unviewedState (folder: CourseFolder): boolean {
		return folder.hasReviewerRole(true) && folder.stats?.attention_needed;
	}
}
