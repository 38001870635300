import template from './panel.component.html';
import { MarkerSetPanelController as controller } from './panel.controller';
import './style.less';

export default {
	bindings: {
		markerSet: '<',
		activity: '<',
		onEdited: '&',
		onRemoved: '&'
	},
	controller,
	require: {
		activityEditorController: '?^activityEditor'
	},
	template
} as ng.IComponentOptions;
