import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'alphabeticalByKey'
})
export class AlphabeticalByKeyPipe implements PipeTransform {
	public transform (value: any[], key: string): any[] {
		if (!value) return;
		return value.sort((a, b) => {
			return a[key].localeCompare(b[key]);
		});
	}
}
