import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import type { CountryData } from 'ngx/go-modules/src/interfaces/country-data';

@Injectable({
	providedIn: 'root'
})
export class NgxCountryService {
	constructor (
		private http: HttpClient
	) {}

	public getCountries (): Observable<CountryData[]> {
		return this.http.get<CountryData[]>(`${clientSettings.GoReactV2API}/countries`);
	}
}
