import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Output, ViewChild, OnInit } from '@angular/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';

@Component({
	selector: 'activity-table-view',
	template: require('./activity-table-view.component.html'),
	styles: [require('./activity-table-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityTableViewComponent implements OnInit {
	@Output() public goToActivity: EventEmitter<any> = new EventEmitter();
	@ViewChild(MenuComponent)
	public menuComponent: MenuComponent;

	@ViewChild('tableContainer', {read: ElementRef})
	public tableContainer: ElementRef;

	public displayColumns: string[];
	public selectedActivity: any;
	public group: any;

	constructor (
		public activityListDataSource: ActivityListDataSource,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService
	) {
		this.displayColumns = ['attention_needed', 'name', 'due_date', 'status', 'actions'];
	}

	public ngOnInit () {
		this.group = this.selectedService.getGroup();
	}

	public openMenu (event: MouseEvent, activity) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.canOpenMenu()) {
			return;
		}

		event.preventDefault();
		this.selectedActivity = activity;
		this.menuComponent.openMenu(event, this.tableContainer.nativeElement);
	}

	public canOpenMenu (): boolean {
		return this.group.hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public unviewedState (activity): boolean {
		return (this.group.hasReviewerRole(true) && activity.unviewed_sessions > 0) ||
			(this.group.hasPresenterRole() && activity.viewed_by_me === false);
	}
}
