import { NgModule } from '@angular/core';
import { TimeDisplayComponent } from 'ngx/go-modules/src/components/time-display/time-display.component';
import { FormatTimeModule } from 'ngx/go-modules/src/pipes/format-time/format-time.module';

@NgModule({
	entryComponents: [
		TimeDisplayComponent
	],
	declarations: [
		TimeDisplayComponent
	],
	exports: [
		TimeDisplayComponent
	],
	imports: [
		FormatTimeModule
	]
})
export class TimeDisplayModule {}
