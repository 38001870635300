import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import type { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';

@Component({
	selector: 'ngx-activity-list-status',
	template: require('./activity-list-status.component.html'),
	styles: [require('./activity-list-status.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityListStatusComponent {
	@Input() public activity: Activity;

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public hasReviewerOrHigherRole (): boolean {
		return this.selectedService.getGroup().hasReviewerRole(true);
	}

	public hasPresenterRole (): boolean {
		return this.selectedService.getGroup().hasPresenterRole();
	}

	public isNotGraded (): boolean {
		return !!!this.activity.total_score;
	}

	public hasScores (): boolean {
		return this.activity.total_score > 0 && !!this.activity.my_score;
	}

	public hasNoScores (): boolean {
		return this.activity.total_score > 0 && !this.activity.my_score;
	}

	public hasNoSubmissions (): boolean {
		return this.hasReviewerOrHigherRole() &&
			this.activity.total_score > 0 &&
			this.activity.usage === 0;
	}

	public hasSomeGraded (): boolean {
		return this.hasReviewerOrHigherRole() &&
			this.activity.total_score > 0 &&
			this.activity.num_graded !== this.activity.usage &&
			this.activity.usage > 0;
	}

	public isAllGraded (): boolean {
		return this.hasReviewerOrHigherRole() &&
			this.activity.total_score > 0 &&
			this.activity.num_graded === this.activity.usage &&
			this.activity.usage > 0;
	}
}
