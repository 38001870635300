import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { OutsideClickModule } from 'ngx/go-modules/src/directives/outside-click';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		GoMaterialModule,
		CommonModule,
		OutsideClickModule,
		A11yModule
	],
	declarations: [
		MenuComponent
	],
	entryComponents: [
		MenuComponent
	],
	exports: [
		MenuComponent
	]
})
export class MenuModule {}
