import * as angular from 'angular';

export enum LoadingState {
	LOADING,
	LOADED,
	ERROR
}

export class ActivityReportController {

	public LoadingState = LoadingState;
	public loaded: LoadingState;
	public instructorSupport: string;
	public summarizedReport: any;
	public userReports: {[key: string]: any} = {};

	// bindings
	public activityId: string | number;
	public groupId: string | number;

	/* @ngInject */
	constructor (
		private ActivityModel,
		private Session,
		private helpUrls
	) {}

	public $onInit () {
		this.instructorSupport = this.helpUrls.instructorSupport;
		this.loaded = LoadingState.LOADING;
		this.getSummary();
	}

	public getSummary () {
		// load activity summary report
		this.ActivityModel.getSummaryReport({
			activity_id: this.activityId,
			group_id: this.groupId
		}).$promise.then((summary) => {
			angular.forEach(summary, (user) => {
				user.fullname = user.first_name + ' ' + user.last_name;
				user.expanded = false;
			});

			// Alphabetize
			const summarizedReport = summary.sort((userA, userB) => {
				if (userA.last_name.toLowerCase() < userB.last_name.toLowerCase()) {
					return -1;
				} else if (userA.last_name.toLowerCase() > userB.last_name.toLowerCase()) {
					return 1;
				}

				return 0;
			});

			this.summarizedReport = summarizedReport;
			this.loaded = LoadingState.LOADED;
		}).catch(() => {
			this.loaded = LoadingState.ERROR;
		});
	}

	public getUserReport (userId: string) {
		if (!this.userReports[userId]) {
			// get sub report summary
			const params = {
				group_id: this.groupId,
				user_id: userId
			} as { group_id: string|number; user_id: string|number; activity_id?: string|number};

			if (this.activityId !== null) {
				params.activity_id = this.activityId;
			}

			this.Session.getSummaryReport(params).$promise.then((data) => {
				data.forEach((session) => {
					session.activity = this.ActivityModel.model(session.activity);
				});
				this.userReports[userId] = data.length ? data : null;
			});
		}
	}

	public toggleUser (user) {
		user.expanded = !user.expanded;
		this.getUserReport(user.user_id);
	}
}
