import * as angular from 'angular';
import 'angulartics';
import { AnalyticsService } from './analytics.service';
import { userModel } from 'go-modules/models/user';

export const analyticsModule = angular.module('AnalyticsModule', [
	'angulartics',
	userModel
])
	.service('analyticsService', AnalyticsService)
	.name;
