import template from './ui-view-inner.component.html';
import './style.less';

// This component exists because angular and angular.js animation
// compete with each other. Having another angular component
// that ultimately has the ui-view directive inside of it
// prevents this race by delaying the compiling of ui-view.
// We won't remove this ultimately until we completely redo the
// routing on dashboard to use angular and not angular.js
export const uiViewInnerComponent = {
	template
} as ng.IComponentOptions;
