import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GroupSettingsPanelService, groupSettingsPanelServiceToken } from 'go-modules/group-settings-panel/group-settings-panel.service';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { filter, switchMap } from 'rxjs';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import { AdminPanelComponent } from 'ngx/go-modules/src/components/sidepanels/admin-panel/admin-panel.component';

@Component({
	selector: 'account-menu',
	template: require('./account-menu.component.html'),
	styles: [require('./account-menu.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountMenuComponent {
	@Input() public account: any;

	constructor (
		private ngxGoToastService: NgxGoToastService,
		private dialog: MatDialog,
		private groupService: NgxGroupService,
		private translate: TranslateService,
		private ngxGoSidepanelService: GoSidepanelService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService
	) {
	}

	public inviteUsers () {
		this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.account
			}
		});
	}

	public manageUsers () {
		this.groupSettingsPanel.open(this.account, this.userService.currentUser, 'users', {});
	}

	public openAccountAdminSettings () {
		this.ngxGoSidepanelService.open(AdminPanelComponent, {
			title: this.translate.instant('group-menu-dropdown-platform-admin-account-settings')
		});
	}

	public deleteAccount () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('course-menu_delete-account'),
				message: this.translate.instant('modal-remove-group_sure-delete', {type: this.translate.instant('common_account'), name: this.account.name}),
				confirmText: this.translate.instant('common_delete')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm),
			switchMap(() => this.groupService.deleteGroup(this.account.group_id))
		).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: this.translate.instant('group-menu_deleted', {type: this.translate.instant('common_account')})
				});
			},
			error: () => this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: this.translate.instant('group-menu_delete_error', {type: this.translate.instant('common_account')})
			})
		});
	}
}
