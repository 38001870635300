export class ModelRouterService {
	/* @ngInject */
	constructor (
		private $location: angular.ILocationService,
		private GroupSettingsPanel,
		private selectedService
	) {}

	public navigateToGroup (group, user) {
		// Can't use `viewGroup` here because some groups aren't cached that depend on this function.
		if (group.isCourse() || group.isFolder()) {
			this.$location.path('/folder/' + group.getId());
		}

		if (group.isAccount()) {
			this.selectedService.setAccount(group);
		}

		// Open the group settings panel in the case of an account or org group.
		if (!group.isCourse() && !group.isFolder()) {
			const section = group.isAccount() ? 'defaults' : '';
			this.GroupSettingsPanel.open(group, user, section);
		}
	}
}
