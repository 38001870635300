import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { GRID_CONSTANTS } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid.constants';

@Component({
	selector: 'session-grid',
	template: require('./session-grid.component.html'),
	styles: [require('./session-grid.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionGridComponent {
	@Output() public openSession = new EventEmitter();
	public minWidth: number = GRID_CONSTANTS.minWidth;
	public maxWidth: number = GRID_CONSTANTS.maxWidth;
	public minGap: number = GRID_CONSTANTS.minGap;

	constructor (
		public sessionListDataSource: SessionListDataSource
	) {}

	public goToSession (session) {
		this.openSession.emit(session);
	}
}
