import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { UserSettingsComponent } from './user-settings.component';

export { UserSettingsModule } from './user-settings.module';
export { UserSettingsComponent } from './user-settings.component';

export const ngxUserSettingsModule = angular.module('ngxUserSettingsModule', [])
	.directive('ngxUserSettings', downgradeComponent({component: UserSettingsComponent}))
	.name;
