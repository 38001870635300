import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, HostListener, Input, QueryList, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { GoDropdownOptionComponent } from './go-dropdown-option.component';
import { GoDropdownService } from './go-dropdown.service';

@Component({
	selector: 'go-dropdown-dropdown',
	template: `
		<ng-template #templatePortalContent>
			<ng-content></ng-content>
		</ng-template>`,
	styles: [require('./go-dropdown.component.scss')]
})
export class GoDropdownDropdownComponent implements AfterViewInit {

	@Input() public dropdownComp: HTMLElement;
	@ViewChild('templatePortalContent') public templatePortalContent: TemplateRef<unknown>;
	@ContentChildren(GoDropdownOptionComponent) public options: QueryList<GoDropdownOptionComponent>;
	public showing = false;
	protected overlayRef: OverlayRef;

	constructor (
		protected overlay: Overlay,
		private cdr: ChangeDetectorRef,
		private viewContainerRef: ViewContainerRef,
		private dropdownService: GoDropdownService
	) {}

	@HostListener('window:resize')
	public onWinResize () {
		this.syncWidth();
	}

	public ngAfterViewInit () {
		this.dropdownService.options = this.options;
	}

	public show () {
		const positionStrategy = this.overlay.position()
			.flexibleConnectedTo(this.dropdownComp)
			.withPush(true)
			.withPositions([{
				originX: 'center',
				originY: 'bottom',
				overlayX: 'center',
				overlayY: 'top',
				offsetY: 4
			}, {
				originX: 'center',
				originY: 'top',
				overlayX: 'center',
				overlayY: 'bottom'
			}]);
		const overlayConfig = new OverlayConfig({
			positionStrategy,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
			width: this.dropdownComp.offsetWidth
		});

		this.overlayRef = this.overlay.create(overlayConfig);
		const templatePortal = new TemplatePortal(
		  this.templatePortalContent,
		  this.viewContainerRef
		);
		this.overlayRef.attach(templatePortal);
		this.overlayRef.updatePosition();
		this.overlayRef.backdropClick().subscribe(() => this.hide());
		this.showing = true;
		this.cdr.detectChanges();
	}

	public hide () {
		this.overlayRef.dispose();
		this.showing = false;
		this.cdr.detectChanges();
	}

	private syncWidth () {
		if (!this.overlayRef) {
			return;
		}

		this.overlayRef.updateSize({ width: this.dropdownComp.offsetWidth });
		this.cdr.detectChanges();
	}
}
