import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionItemViewerModule } from '../collection-item-viewer/collection-item-viewer.module';
import { SearchPipeModule } from 'ngx/go-modules/src/pipes/search/search.pipe.module';
import { SearchPipe } from 'ngx/go-modules/src/pipes/search/search.pipe';
import { SortPipeModule } from 'ngx/go-modules/src/pipes/sort/sort.pipe.module';
import { LibraryCollectionViewerComponent } from './library-collection-viewer.component';
import { SortPipe } from 'ngx/go-modules/src/pipes/sort/sort.pipe';
import { ManageCollectionsPanelModule } from '../manage-collections-panel/manage-collections-panel.module';
import { CollectionFolderViewerModule } from '../collection-folder-viewer/collection-folder-viewer.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { NgxZeroStateModule } from '../../zero-state/zero-state.module';
import { A11yModule } from '@angular/cdk/a11y';
import { CollectionDestinationModule } from '../collection-destination/collection-destination.module';
import { ManageLibraryCollectionFolderModule } from '../manage-library-collection-folder/manage-library-collection-folder.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		CollectionFolderViewerModule,
		CollectionItemViewerModule,
		ManageCollectionsPanelModule,
		SortPipeModule,
		SearchPipeModule,
		GoTooltipModule,
		NgxZeroStateModule,
		A11yModule,
		CollectionDestinationModule,
		ManageLibraryCollectionFolderModule
	],
	declarations: [
		LibraryCollectionViewerComponent
	],
	entryComponents: [
		LibraryCollectionViewerComponent
	],
	exports: [
		LibraryCollectionViewerComponent
	],
	providers: [
		SearchPipe,
		SortPipe
	]
})
export class LibraryCollectionViewerModule {}
