import * as angular from 'angular';
import * as angulartics from 'angulartics';
import * as angularTranslate from 'angular-translate';
import * as uiTabs from 'angular-ui-bootstrap/src/tabs';
import './style.less';
import { templatesRun } from './templates.run';
import { GroupSettingsPanelService } from './group-settings-panel.service';
import { SidePanelModule } from 'go-modules/side-panel';
import { groupDepModel } from 'go-modules/models/group-dep';
import { userModel } from 'go-modules/models/user';
import { masqueradeModule } from 'go-modules/masquerade';
import { helpUrlsModule } from 'go-modules/help-urls';
import { groupsIndexModule } from 'go-modules/groups-index';
import { OrgSettingsComponent } from './org-settings/org-settings.component';
import { messageModalModule } from 'go-modules/modals/message';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';
import { commonFiltersModule } from 'go-modules/common-filters';

export const groupSettingsPanelModule = angular.module('group-settings-panel', [
	uiTabs,
	angularTranslate,
	SidePanelModule,
	groupDepModel,
	angulartics,
	userModel,
	masqueradeModule,
	helpUrlsModule,
	groupsIndexModule,
	messageModalModule,
	ngxGoToastModule,
	commonFiltersModule
])
	.component('orgSettings', OrgSettingsComponent)
	.service(GroupSettingsPanelService.NG1_INJECTION_NAME, GroupSettingsPanelService)
	.run(templatesRun)
	.name;
