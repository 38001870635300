import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { NgxLicenseService } from 'ngx/go-modules/src/services/license';
import { TranslateService } from '@ngx-translate/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatSort, Sort } from '@angular/material/sort';
import { Roles, SortOrder } from 'ngx/go-modules/src/enums';
import type { LicenseSeatsdataSourceElement, LicenseSeatsRoleDataSourceElement } from './license-seats.datasource';
import { LicenseSeatsDataSource } from './license-seats.datasource';
import {
	RemoveUserAction,
	RemoveUserConfirmDialogComponent,
	RemoveUserConfirmDialogData
} from './remove-user-confirm-dialog/remove-user-confirm-dialog.component';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { BehaviorSubject, combineLatest, Subject, throwError } from 'rxjs';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SeatOveragePolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';

export const NETWORK_LOST_TRANSLATION_KEY = 'license-management_error-network-lost';
export const PERMISSION_ERROR_TRANSLATION_KEY = 'license-management_error-permission-error';
export const ACTIVATE_PERMISSION_ERROR_TRANSLATION_KEY = 'license-management_error-activate-permission-error';
export const UNKNOWN_ERROR_TRANSLATION_KEY = 'license-management_error-unknown-error';

@Component({
	selector: 'license-seats-management',
	template: require('./license-seats-management.component.html'),
	styles: [require('./license-seats-management.component.scss')],
	providers: [{
		provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
		useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
	}]
})
export class LicenseSeatsManagementComponent implements OnChanges, OnDestroy {
	@Input()
	public license;

	@Input()
	public set viewByUser (value) {this.viewByUser$$.next(value);}

	@ViewChild(MatSort) public sort!: MatSort;

	public readonly SeatOveragePolicies = SeatOveragePolicies;
	public dataSource: LicenseSeatsDataSource;
	public roles = [
		'presenter',
		'reviewer',
		'instructor',
		'owner',
		'admin'
	];
	public datePickerOpen: boolean = false;
	public componentDestroy$$ = new Subject();
	public viewByUser$$ = new BehaviorSubject(null);
	public isScrolled$ = new BehaviorSubject(false);
	private dataSourceReady = new BehaviorSubject(null);

	constructor (
		public dialog: MatDialog,
		private licenseService: NgxLicenseService,
		private ngxUserService: NgxUserService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private elementRef: ElementRef,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(uaDetectToken) public UADetect: UADetectClass
	) {
		combineLatest([this.viewByUser$$, this.dataSourceReady])
			.pipe(takeUntil(this.componentDestroy$$))
			.subscribe(([viewByUser, dataSourceReady]) => {
				if (viewByUser && dataSourceReady) {
					this.dataSource.setSearchTerm(this.viewByUser);
				}
			});
	}

	public ngOnDestroy (): void {
		this.dataSource?.destroy();
		this.componentDestroy$$.next(true);
		this.componentDestroy$$.complete();
		this.viewByUser$$.complete();
		this.dataSourceReady.complete();
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if (changes.license?.currentValue && changes.license.currentValue.id !== changes.license.previousValue?.id) {
			this.selectedService.setLicense(changes.license.currentValue);
			this.dataSource = new LicenseSeatsDataSource(
				this.userService,
				(id, query) => this.licenseService.getLicenseSeats(id, query),
				changes.license.currentValue.id
			);

			this.dataSource.onError.subscribe((error: HttpErrorResponse) => {
				let errorString = '';
				switch(error.status) {
					case 0: 	errorString = NETWORK_LOST_TRANSLATION_KEY; break;
					case 403: 	errorString = PERMISSION_ERROR_TRANSLATION_KEY; break;
					default: 	errorString = UNKNOWN_ERROR_TRANSLATION_KEY; break;
				}

				let errorMessage = '';
				this.translate.get(errorString)
					.pipe(take(1))
					.subscribe((value) => {
						errorMessage = value;
					});

				this.snackBar.open(errorMessage, null, { duration: 10000 });
			});

			this.dataSourceReady.next(true);
		}
	}

	public doSearch (searchTerm) {
		this.dataSource.setPage(1);
		this.dataSource.setSearchTerm(searchTerm);
	}

	// This may come back if the rules change on who takes a seat
	// public filterRoles (role) {
	// 	this.dataSource.setRole(role);
	// }

	public filterLogin (start, end) {
		this.toggleDatePicker();
		this.dataSource.setLogin({lower: start.value, upper: end.value});
	}

	// This may come back if the rules change on who takes a seat
	// public removeRole () {
	// 	this.dataSource.setRole(null);
	// }

	public removeLogin () {
		this.dataSource.setLogin(null);
	}

	public goToPreviousPage () {
		this.dataSource.setPage(this.dataSource.currentPage - 1);
	}

	public goToNextPage () {
		this.dataSource.setPage(this.dataSource.currentPage + 1);
	}

	public goToFirstPage () {
		this.dataSource.setPage(1);
	}

	public goToLastPage () {
		this.dataSource.setPage(this.dataSource.lastPage);
	}

	public toggleDatePicker () {
		this.datePickerOpen = !this.datePickerOpen;
		if (this.datePickerOpen) {
			setTimeout(() => {
				const startInput = document.querySelector('input.mat-start-date') as HTMLElement;
				startInput.focus();
			});
		} else {
			this.elementRef.nativeElement.querySelector('.mat-column-last_login_at .filter-btn').focus();
		}
	}

	public sortData (sort: Sort) {
		const { active, direction } = sort;
		this.dataSource.setPage(1);
		this.dataSource.setSortBy(active);
		this.dataSource.setSortOrder(direction as SortOrder);
	}

	public canBeRemoved (element, roleElement) {
		if (this.userService.currentUser.is_root_user) {
			return true;
		}
		return roleElement.can_be_removed &&
			roleElement.role !== Roles.OWNER &&
			element.user_id !== this.userService.currentUser.user_id;
	}

	public removeFromCourse (user: LicenseSeatsdataSourceElement, role: LicenseSeatsRoleDataSourceElement) {
		const data: RemoveUserConfirmDialogData = {
			user, role, action: RemoveUserAction.REMOVE_FROM_COURSE
		};

		this.removeUserFromCourseRequest(data).subscribe(() => {
			if (user.roles.length === 1) {
				this.removeUserFromDataSource();
			} else {
				user.roles = user.roles.filter((userRole) => (userRole as LicenseSeatsRoleDataSourceElement) !== role);
			}

			this.reloadLicense();
			this.snackBar.open(this.translate.instant('common_successfully-removed'), null, { duration: 1000 });
		});
	}

	public removeFromAllCourses (user: LicenseSeatsdataSourceElement) {
		const data: RemoveUserConfirmDialogData = {
			user, action: RemoveUserAction.REMOVE_FROM_ALL_COURSES
		};

		this.removeUserFromCourseRequest(data).subscribe(() => {
			this.removeUserFromDataSource();
			this.reloadLicense();
			this.snackBar.open(this.translate.instant('common_successfully-removed'), null, { duration: 1000 });
		});
	}

	public onScroll ($event) {
		if ($event.target.scrollLeft > 0) {
			this.isScrolled$.next(true);
		} else {
			this.isScrolled$.next(false);
		}
	}

	private removeUserFromCourseRequest (data: RemoveUserConfirmDialogData) {
		return this.dialog.open(RemoveUserConfirmDialogComponent, {data}).afterClosed()
			.pipe(
				filter((isTrue) => isTrue),
				tap(() => {
					if(data.action === RemoveUserAction.REMOVE_FROM_COURSE) {
						data.role.removing = true;
					} else {
						data.user.removing = true;
					}
				}),
				switchMap(() => {
					if(data.action === RemoveUserAction.REMOVE_FROM_COURSE) {
						return this.ngxUserService
							.removeFromGroup(data.user.user_id, data.role.raw_data.course.group_id);
					} else {
						return this.licenseService.removeUserFromAllCourses(this.license.id, data.user.user_id);
					}
				}),
				finalize(() => data.role.removing = false),
				catchError((error) => {
					if (error.status === 403  || error.status === 0) {
						const errorMessage = error.status === 403 ?
							'license-seats-management_failed-to-remove-user-from-course-403-response' :
							NETWORK_LOST_TRANSLATION_KEY;

						const snackBar = this.snackBar.open(this.translate.instant(errorMessage),  this.translate.instant('common_reload'));

						snackBar.onAction().subscribe(() => {
							this.dataSource.reload();
							this.reloadLicense();
						});
					} else {
						this.snackBar.open(this.translate.instant('common_unknown-error'), null, { duration: 10000 });
					}

					return throwError(null);
				})
			);
	}

	private removeUserFromDataSource () {
		if (this.dataSource.dataLength === 1 && this.dataSource.currentPage !== 1) {
			this.goToPreviousPage();
		} else {
			this.dataSource.reload();
		}
	}

	private reloadLicense () {
		this.licenseService.getLicense(this.license.id).subscribe((license) => this.license = license);
	}
}
