/* @ngInject */
export function compileProviderConfig (ENVIRONMENT_CONSTANTS, $compileProvider) {

	const shouldBeEnabled = () => {
		return ENVIRONMENT_CONSTANTS.APP_ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD;
	};

	$compileProvider.debugInfoEnabled(shouldBeEnabled());
	$compileProvider.commentDirectivesEnabled(shouldBeEnabled());
	$compileProvider.cssClassDirectivesEnabled(shouldBeEnabled());
}
