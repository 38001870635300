import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArchiveFilter } from 'ngx/go-modules/src/enums/archive-filter';
import { Observable } from 'rxjs';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { ArchivedItem } from 'ngx/go-modules/src/interfaces/archived/archived.interface';

@Injectable({
	providedIn: 'root'
})
export class ArchiveService {
	constructor (private http: HttpClient) {}

	public getArchivedItems (param: ArchiveFilter): Observable<ArchivedItem[]> {
		let filter = '';

		if(param && param !== ArchiveFilter.ALL) {
			filter = `filter=${param}`;
		}

		return this.http.get<ArchivedItem[]>(`${clientSettings.GoReactV2API}/archived?${filter}`);
	}
}
