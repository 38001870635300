import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, Validators } from '@angular/forms';
import { interval, of, Subject } from 'rxjs';
import { debounceTime, delay, take, takeUntil} from 'rxjs/operators';

@Component({
	selector: 'code-input',
	template: require('./code-input.component.html'),
	styles: [require('./code-input.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeInputComponent implements OnDestroy, AfterViewInit  {
	@Input()
	public length: number = 4;

	@Input()
	public disabled: boolean;

	@Input()
	public autoFocus = false;

	@Output()
	public onChange = new EventEmitter<string>();

	public inputControls: FormArray;

	private notifier = new Subject<any>();
	private inputsCurrentValue = [];
	private currentCode = null;

	constructor (private elementRef: ElementRef) {
		this.createInputs();

		this.inputControls.valueChanges
			.pipe(
				takeUntil(this.notifier),
				debounceTime(100)
			).subscribe((values: string[]) => {
				const value = values.join('');

				if(this.inputControls.valid && (this.currentCode !== value)) {
					this.currentCode = value;
					this.inputElements[this.inputElements.length - 1].blur();
					this.onChange.emit(this.currentCode);
				}
			});
	}

	public ngAfterViewInit () {
		if (this.autoFocus) {
			interval(1).pipe(take(1)).subscribe(() => this.elementRef.nativeElement.querySelector('input').focus());
		}
	}

	public ngOnDestroy () {
		// Clean up all subscriptions
		this.notifier.next(null);
		this.notifier.complete();
	}

	public get inputElements (): HTMLInputElement[] {
		return this.elementRef.nativeElement.querySelectorAll('input') as HTMLInputElement[];
	}

	public clear () {
		this.inputControls.controls.forEach((formControl: FormControl) => {
			formControl.setValue(null);
			formControl.markAsPristine();
		});

		this.currentCode = null;
		this.inputsCurrentValue = [];
		this.inputElements[0].focus();
	}

	public asFormControl (control: AbstractControl): FormControl {
		return control as FormControl;
	}

	public valueChange ($event, control) {
		const value = $event.data?.toString();
		const index = this.inputControls.controls.indexOf(control);
		const reg = new RegExp('^[0-9]+$');

		if (value !== undefined) {
			if( reg.test(value)) {
				const newValue = value[value.length - 1];
				control.setValue(newValue);
				this.inputElements[index + 1]?.focus();
				this.inputsCurrentValue[index - 1] = newValue;
			} else {
				control.setValue(this.inputsCurrentValue[index - 1]);
			}
		}
	}

	public onClick () {
		const totalEmpty = this.inputControls.controls.filter((control) => {
			return control.invalid;
		});

		if (totalEmpty.length === this.inputControls.length) {
			this.inputElements[0].focus();
		}
	}

	public onPaste ($event: ClipboardEvent) {
		const data: string = $event.clipboardData.getData('text/plain');

		// should run on next tick to avoid race condition with input event
		of(true).pipe(delay(1), take(1)).subscribe(() => {
			this.inputControls.controls.forEach((control_, index_) => {
				control_.setValue(data[index_] ?? null);
			});

			if (data.length >= this.inputControls.length) {
				this.inputElements[this.inputControls.length - 1].focus();
			} else {
				this.inputElements[data.length].focus();
			}
		});
	}

	public onKeyDown ($event, control) {
		if ($event.key === 'Backspace' && $event.target.value.length === 0) {
			const index = this.inputControls.controls.indexOf(control);

			if (index > 0) {
				this.inputElements[index - 1].focus();
			}

		}
	}

	protected createInputs () {
		const arr = (new Array(this.length)).fill(null);
		this.inputControls = new FormArray(arr.map(() => {
			this.inputsCurrentValue.push(null);
			return new FormControl(null, [Validators.required]);
		}));
	}
}
