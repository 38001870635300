import { Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';
import { LtiRefreshTourService } from 'ngx/go-modules/src/components/tours/lti-refresh-tour/lti-refresh-tour.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { TermsOfUseDialogComponent } from 'ngx/go-modules/src/components/dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
import { TermsService } from 'ngx/go-modules/src/services/terms/terms.service';

@Injectable({
	providedIn: 'root'
})
export class PromptsService {
	public environmentVarsService: EnvironmentVarsService;
	public isLTI: boolean = false;

	constructor (
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		private termsService: TermsService,
		private tourService: LtiRefreshTourService,
		private dialog: MatDialog,
		private ngZone: NgZone
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.isLTI = this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public showTermsOrTourWhenRequired () {
		if (this.isLTI) {
			const promise = new Promise<void>((resolve) => {
				this.termsService.requiresUpdate().subscribe({
					next: () => {
						this.ngZone.run(() => {
							const dialogRef = this.dialog.open(TermsOfUseDialogComponent);
							dialogRef.afterClosed().subscribe(() => {
								resolve();
							});
						});
					},
					error: () => {
						// Nothing to do if no terms to accept
						resolve();
					}
				});
			});

			promise.then(() => {
				if (this.selectedService.getGroup().hasInstructorRole(true)) {
					const environment = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT);
					const hasCourseLevelLTI = environment.mode === LTI_ENVIRONMENT_MODES.COURSE_LEVEL;
					this.tourService.startTour(hasCourseLevelLTI);
				}
			});
		}
	}
}
