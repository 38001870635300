/* @ngInject */
export const requestMonitorFactory = ($timeout, $filter) => {
	let _requestMessage = {} as any, timeout;
	const delay = 4000,
		successMessage = 'request-monitor_controller-updates-saved',
		errorMessage = 'request-monitor_controller-something-wrong';
	const statuses = {
		SUCCESS: 'success',
		FAIL: 'error'
	};
	return {
		// expose constant data
		get staticData () {
			return {
				statuses,
				successMessage,
				errorMessage,
				delay: 4000
			};
		},

		// the active request object info
		get activeRequest () {
			return _requestMessage;
		},

		// Tell service request was successful, status and message on activeRequest
		// will be defined for duration of delay
		setSuccess (success) {
			_requestMessage.message = success && $filter('translate')(success) ? $filter('translate')(successMessage) : undefined;
			_requestMessage.status = success ? statuses.SUCCESS : undefined;
			$timeout.cancel(timeout);
			timeout = $timeout(() => {
				_requestMessage = {};
			}, delay);
		}
	};
};
