import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'feedback-session'
})
export class FeedbackSessionDirective extends UpgradeComponent {
	@Input() public user;
	@Input() public userGroup;
	@Input() public license;
	@Input() public activity;
	@Input() public options;
	@Input() public session;
	@Input() public feedbackMode;
	@Input() public viewMode;
	@Input() public orgSettings;

	constructor (
		elementRef: ElementRef,
		injector: Injector
	) {
		super('feedbackSession', elementRef, injector);
	}
}
