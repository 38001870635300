import { ResponsiveViewService, Sizes } from 'go-modules/responsive-view/responsive-view.service';

export class HeaderViewController {

	/* @ngInject */
	constructor (
		public $scope,
		public responsiveView: ResponsiveViewService
	) {}

	public $onInit (): void {
		this.$scope.Sizes = Sizes;
	}
}
