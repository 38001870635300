export interface AnalyticsConfigInterface {
	analytics: AnalyticsInterface;
	user: AnalyticsUserInterface;
}
export interface AnalyticsInterface {
	domain: string;
	user: string;
};

export interface AnalyticsUserInterface {
	user_id: string;
	email: string;
	first_name: string;
	fullname: string;
	last_name: string;
	username: string;
};

export enum AnalyticsUserEvent {
	UserAuthenticated = 'user_authenticated'
}
