export interface SkipLinkComponentBindings {
	options: SkipLinkOption[];
}

export interface SkipLinkOption {
	id: string;
	linkText: string;
	domElement?: HTMLElement;
}

export class SkipLinksController implements ng.IOnDestroy, ng.IOnInit, SkipLinkComponentBindings {

	public options: SkipLinkOption[];
	private domObserver: MutationObserver;

	/** @ngInject */
	constructor (private $document: ng.IDocumentService) {}

	public $onInit () {
		this.domObserver = new MutationObserver(this.onDomChange);
		const body = this.$document[0].body;
		this.domObserver.observe(body, {
			subtree: true,
			childList: true
		});
	}

	public $onDestroy () {
		this.domObserver.disconnect();
	}

	private onDomChange = () => {
		this.options.forEach((option) => {
			option.domElement = this.$document[0].getElementById(option.id);
		});
	};
}
