import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';

@Component({
	selector: 'activity-instructions-dialog',
	template: require('./activity-instructions-dialog.component.html'),
	styles: [require('./activity-instructions-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityInstructionsDialogComponent {

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public close () {
		this.dialogRef.close();
	}

	public shouldHideRecordingInstructions () {
		// TODO DEV-15860
		return this.selectedService.getGroup().hasPresenterRole() &&
			this.selectedService.getActivity().has_single_recording_attempt &&
			!this.selectedService.getActivity().have_i_submitted;
	}
}
