import * as angular from 'angular';
import { Masquerade } from 'go-modules/masquerade/masquerade.service';
import { UserService } from 'go-modules/models/user/user.service';
import type { DashboardUtilsServiceInterface } from '../services/dashboard-utils.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import type { SkipLinkOption } from 'go-modules/acessibility-skip-links/skip-links.controller';
import { UploadManagerService } from 'go-modules/services/upload-manager/upload-manager.service';
import { Status, UploadStatus } from 'go-modules/services/upload-manager/db.interface';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { WelcomeDialogComponent } from 'ngx/go-modules/src/components/dialogs/welcome-dialog/welcome-dialog.component';
import { ResponsiveViewService } from 'go-modules/responsive-view/responsive-view.service';
import type { StateParams } from '@uirouter/angularjs';
import { LICENSE_TRANSACTION_TYPE, PAYMENT_TYPES, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { LazyPaymentLoader } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { GoTourService } from 'go-modules/go-tour/go-tour.service';
import { TermsService } from 'ngx/go-modules/src/services/terms/terms.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import dayjs from 'dayjs';

interface RootScope extends ng.IRootScopeService {
	currentUser: any;
}

interface DashboardRootViewScope extends ng.IScope {
	[key: string]: any;
}

export class DashboardRootViewController {
	public appPrefStorage;
	public skipLinkOptions: SkipLinkOption[];
	private key = 'welcome-modal-tour';
	private constraints = {
		isOnboarding: true,
		tourStartDate: dayjs('2023-05-23 15:00:00 MDT').toDate()
	};

	/* @ngInject */
	constructor (
		public goModal,
		public $window,
		public $scope: DashboardRootViewScope,
		public $rootScope: RootScope,
		public responsiveView: ResponsiveViewService,
		private selectedService: SelectedService,
		private dashboardUtilsService: DashboardUtilsServiceInterface,
		private groupsIndex,
		private $stateParams: StateParams,
		private $log,
		public ModelRouter,
		private $analytics: angulartics.IAnalyticsService,
		private Group,
		public masquerade: Masquerade,
		private User,
		private $element: ng.IRootElementService,
		private userService: UserService,
		private WebStorage,
		private translateFilter: angular.translate.ITranslateFilter,
		private uploadManager: UploadManagerService,
		private ngxGoToastService: NgxGoToastService,
		private ngxGoModalService: GoModalService,
		private PaymentLoader: LazyPaymentLoader,
		private pubnub: PubnubService,
		public goTour: GoTourService,
		private ngxTermsService: TermsService,
		private ngxDowngradeModalService: DowngradeModalService
	) {
		this.appPrefStorage = new this.WebStorage('appPrefs');

		// track dashboard view
		this.$analytics.pageTrack('/dashboard');

		// set initial data on scopes
		this.$scope.currentUser = this.userService.currentUser;
		this.$scope.selected = this.selectedService.getSelected();
		this.$scope.disjointGroups = this.dashboardUtilsService.disjointGroups;
		this.$scope.groups = this.groupsIndex;
		this.$scope.myAccount  = this.dashboardUtilsService.myAccount;
		this.$scope.stateParams = $stateParams;
		this.$scope.siteNotification = this.dashboardUtilsService.siteNotification;

		// @TODO: verify if this is being used.
		this.$scope.routeParams = $stateParams;

		this.selectedService.selectedSubject.asObservable().subscribe((selected) => {
			this.$scope.selected = selected;
		});

		this.pubnub.scopePhase.subscribe(() => {
			// TODO: STAB-997 is this needed anymore?
			// eslint-disable-next-line angular/no-private-call
			if (!this.$scope.$$phase) {
				this.$scope.$apply();
			}
		});


		// Enable Upload Manager
		this.uploadManager.init().then(() => this.uploadManager.checkAndRetryUploads());
		this.uploadManager.$.subscribe((uploadStatus: UploadStatus) => this.handleUploadEvents(uploadStatus));

		// bind scope functions
		// TODO: verify these functions if used somewhere and verify if it should use service or still depend
		// on a scope. Remove if its not being used.
		this.$scope.removeAllGroups = this.removeAllGroups.bind(this);
		this.$scope.moveGroup = this.moveGroup.bind(this);
		this.$scope.getAppPreference = this.getAppPreference.bind(this);
		this.$scope.selectGroup = this.dashboardUtilsService.selectGroup.bind(this);
		this.$scope.selectActivity = this.dashboardUtilsService.selectActivity.bind(this);
		this.$scope.findFallbackActivity = this.dashboardUtilsService.findFallbackActivity.bind(this);
		this.$scope.handleGroupRouteParamChange = this.dashboardUtilsService.handleGroupRouteParamChange.bind(this);
	}

	/**
	 * On Init Handler.
	 *
	 * @returns {void}
	 */
	public $onInit (): void {

		// Modelize scope.disjointGroups
		this.$scope.disjointGroups = this.$scope.disjointGroups.map((group) => {
			return this.Group.model(group);
		});

		// Define accessibilty skip link options
		this.skipLinkOptions = [{
			id: 'activity-sidebar',
			linkText: this.translateFilter('skip-link_activity-list')
		}, {
			id: 'session-list',
			linkText: this.translateFilter('skip-link_session-list')
		}];

		if (this.userService.currentUser.is_root_user) {
			this.$element.addClass('root-user');
		}

		// determine student course-credits
		this.User.getBillingBalances({
			userId: this.userService.currentUser.user_id
		}).$promise.then((response) => {
			this.userService.currentUser.plan = response.plan;
			this.userService.currentUser.totalStudentCredits = response.credits.reduce((total, credit) => {
				return total + parseInt(credit.units_available, 10);
			}, 0);
		}).catch(this.$log.error);

		this.goTour.canTourBeViewed(this.key, this.constraints).then(()=> {
			this.openWelcomeModal();
		}).catch(()=> {
			if (!this.masquerade.isMasked()) {
				// Opens terms of service modal
				this.ngxTermsService.requiresUpdate().subscribe({
					next: () => { this.ngxDowngradeModalService.openTermsOfUseDialog(); },
					error: angular.noop // Nothing to do if no terms to accept
				});
			}
		});
	}

	public openWelcomeModal () {
		const dialogRef = this.ngxGoModalService.open(
			WelcomeDialogComponent,
			false,
			{data: {
				user: this.userService.currentUser,
				account: this.dashboardUtilsService.myAccount
			}}
		);
		dialogRef.afterClosed().subscribe((selfPay: boolean) => {
			this.goTour.markTourViewed(this.key);
			if (selfPay) {
				this.PaymentLoader.openPayForm(
					this.userService.currentUser,
					this.dashboardUtilsService.myAccount,
					() => {
						this.$scope.$apply();
					},
					null,
					PAYMENT_TYPES.CARD,
					PURCHASE_TYPES.LICENSE,
					LICENSE_TRANSACTION_TYPE.INITIAL
				);
			} else {
				this.$scope.$apply();
			}
		});
	}

	/**
	 * Clear all groups.
	 *
	 * @returns {void}
	 */
	public removeAllGroups (): void {
		this.groupsIndex.clear();
	};

	/**
	 * Move a group within the groups index.
	 *
	 *
	 * @returns {void}
	 */
	public moveGroup (group, newParentId): void {
		this.groupsIndex.move(group, newParentId);
	};

	/**
	 * Get App Preference via key.
	 *
	 * @param {*} key
	 *
	 * @return {*}
	 */
	public getAppPreference (key) {
		return this.appPrefStorage.get(key);
	};

	/**
	 * Sets App Preference via key and value.
	 *
	 * @param {*} key
	 * @param {*} value
	 *
	 * @returns {void}
	 */
	public setAppPreference (key, value): void {
		this.appPrefStorage.put(key, value);
	};

	/**
	 * Gets group template.
	 *
	 * @return {*}
	 */
	public getGroupTemplate () {
		return this.Group.getTemplate();
	};

	/**
	 * Checks if should show account settings panel.
	 *
	 * @returns {boolean}
	 */
	public shouldShowAccountSettingsPanel () {
		const { showAccountSettingsPanel } = this.$stateParams;
		return showAccountSettingsPanel === 'true';
	}

	/**
	 * Display upload status.
	 */
	 public handleUploadEvents (uploadStatus: UploadStatus) {
		// Don't start emitting untill all parts of the file have been added to the local DB
		if (uploadStatus.allPartsAdded) {
			const toastSettings = uploadStatus.status === Status.UPLOADING
				? {type: GoToastStatusType.WARNING, message: 'upload-manager-processing'}
				: {type: GoToastStatusType.SUCCESS, message: 'upload-manager-complete'};
			this.ngxGoToastService.createToast(toastSettings);
		}
	}
}
