import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { MatTable } from '@angular/material/table';
import { map, Observable, of } from 'rxjs';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';

@Component({
	selector: 'folders-table-view',
	template: require('./folders-table-view.component.html'),
	styles: [require('./folders-table-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxFolderTableViewComponent implements OnInit {
	@ViewChild(MatTable) public table: MatTable<any>;
	@ViewChild('tableElementRef', {read: ElementRef}) public tableElementRef: ElementRef<HTMLTableElement>;
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@Output() public goToFolder: EventEmitter<any>  = new EventEmitter();
	public displayColumns: string[];
	public selectedFolder: any;
	public hasAtLeastOneEndDate$: Observable<boolean> = of(false);

	constructor (
		public folderListDataSource: FolderListDataSource,
		@Inject(userServiceToken) public userService: UserService
	) {
		this.displayColumns = ['attention_needed', 'name', 'end_date', 'updated_at', 'actions'];
	}

	public ngOnInit (): void {
		this.hasAtLeastOneEndDate$ = this.folderListDataSource.connect()
			.pipe(
				map((folders) => folders.some((folder) => !!folder.end_date))
			);
	}

	public openMenu (event: MouseEvent, folder) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}

		if (!folder.hasInstructorRole(true) && !this.userService.currentUser.is_root_user) {
			return;
		}

		event.preventDefault();
		this.selectedFolder = folder;
		this.menuComponent.openMenu(event, this.tableElementRef.nativeElement);
	}

	public unviewedState (folder: CourseFolder): boolean {
		return folder.hasReviewerRole(true) && folder.stats?.attention_needed;
	}
}
