import {
	Component,
	Inject, NgZone, OnDestroy,
	OnInit
} from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { BehaviorSubject, EMPTY, noop, Subject } from 'rxjs';
import {
	LicenseExpirationHandler
} from 'ngx/go-modules/src/services/license-expiration-handler/license-expiration-handler.service';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { GoTourService, goTourServiceToken } from 'go-modules/go-tour/go-tour.service';
import dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LicenseUpgradeSnackBarComponent, LicenseUpgradeSnackbarType } from '../../../snack-bars/license-upgrade/license-upgrade-snack-bar.component';

export const LICENSE_UPGRADE_PURCHASE_TOUR_KEY = 'license-upgrade-purchase-tour';
export const LICENSE_UPGRADE_PURCHASE_TOUR_CONSTRAINTS = {
	isOnboarding: false,
	tourStartDate: dayjs('2024-07-01 09:00:00').toDate()
};

import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';

@Component({
	selector: 'license-plan-breadcrumb',
	template: require('./license-plan-breadcrumb.component.html'),
	styles: [require('./license-plan-breadcrumb.component.scss')]
})
export class LicensePlanBreadcrumbComponent implements OnInit, OnDestroy {
	public license$ = new BehaviorSubject<License>(null);
	public loading$ = new BehaviorSubject(false);
	public readonly ProductTier = ProductTier;
	public licenseUpgradePurchaseFlagOn: boolean = false;
	public hasCheckedUpgradePurchaseTour: boolean = false;

	private destroyed$ = new Subject<void>();

	constructor (
		public licenseExpirationHandler: LicenseExpirationHandler,
		public featureFlag: NgxFeatureFlagService,
		private ngZone: NgZone,
		private snackbar: MatSnackBar,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService,
		@Inject(goTourServiceToken) private goTour: GoTourService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public ngOnInit () {
		this.licenseUpgradePurchaseFlagOn = this.featureFlag.isAvailable('LICENSE_UPGRADE_PURCHASE');

		this.selectedService.selectedSubject
			.pipe(takeUntil(this.destroyed$))
			.subscribe((selected) => {
				// Students should not see the license plan breadcrumb
				if (!selected.group || !selected.group.hasInstructorRole(true)) {
					return this.setLicense(null);
				}

				if (selected.license) {
					this.setLicense(selected.license as License);
				} else if (selected.group.hasOwnProperty('license')) {
					this.setLicense(selected.group.license);
				}
			});
	}

	public ngOnDestroy () {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	public setLicense (license: License) {
		if (license && Object.keys(license).length) {
			// license is only set if has instructor role on group
			this.license$.next(license);
			if (!this.licenseExpirationHandler.isExpiringLicenseRequiringWarning(license) &&
				license.salesforce_license?.may_tease) {
				this.showUpgradePurchaseTour();
			}
		} else {
			this.license$.next(null);
		}
	}

	public showUpgradePurchaseTour () {
		if (
			this.licenseUpgradePurchaseFlagOn &&
			!this.hasCheckedUpgradePurchaseTour &&
			// Only show the tour if license isn't already upgraded
			this.license$.value.salesforce_license?.tier_name !== ProductTier.Plus
		) {
			this.hasCheckedUpgradePurchaseTour = true;
			this.goTour.canTourBeViewed(
				LICENSE_UPGRADE_PURCHASE_TOUR_KEY,
				LICENSE_UPGRADE_PURCHASE_TOUR_CONSTRAINTS
			).then(()=> {
				this.ngZone.run(() => {
					LicenseUpgradeSnackBarComponent.open(this.snackbar, {
						mode: LicenseUpgradeSnackbarType.UPGRADE
					}).afterDismissed().subscribe(() => {
						this.goTour.markTourViewed(LICENSE_UPGRADE_PURCHASE_TOUR_KEY);
					});
				});
			}).catch(noop);
		}
	}

	public licensePlanBadgeClicked (license: License) {
		this.loading$.next(true);
		const result = this.ngxLicenseUpgradeService.upgradeOrPurchase(license);

		result.pipe(
			switchMap(({requestUpgrade}) => {
				if (requestUpgrade) {
					// Non license-admin requested an upgrade
					return this.ngxLicenseUpgradeService.handleLicenseUpgradeRequest(license.id);
				} else {
					// License admin successfully upgraded
					return EMPTY;
				}
			}),
			takeUntil(this.destroyed$),
			finalize(() => this.loading$.next(false))
		).subscribe();
	}
}
