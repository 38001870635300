import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
	StatusOverlayComponent
} from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	exports: [
		StatusOverlayComponent
	],
	declarations: [
		StatusOverlayComponent
	],
	entryComponents: [
		StatusOverlayComponent
	]
})
export class VideoSceneModule {}
