import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license/expiration-policies';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { LicenseRenewalSnackBarComponent } from 'ngx/go-modules/src/components/snack-bars/license-renewal';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

export const EXPIRING_LICENSE_LIST = 'expiring-licenses';

@Injectable({
	providedIn: 'root'
})
export class LicenseExpirationHandler {

	public environmentVarsService: EnvironmentVarsService;

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		@Inject('Window') private window: Window,
		private snackBar: MatSnackBar
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public isExpiredLicense (license: License): boolean {
		return license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
			(!license.is_active || dayjs().isAfter(dayjs.utc(license.ends_at)));
	}

	public isExpiringLicense (license: License): boolean {
		const dateToShowWarning = dayjs.utc(license.ends_at).subtract(license.salesforce_license.expiring_warning_days, 'day');
		return !license.is_active ||
			dayjs().isSame(dateToShowWarning) || dayjs().isAfter(dateToShowWarning);
	}

	public isExpiringLicenseRequiringWarning (license: License): boolean {
		return license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED &&
			this.isExpiringLicense(license);
	}

	public isExpiringLicenseRequiringRenewal (license: License): boolean {
		return !license.salesforce_license.has_renewal &&
			this.isExpiringLicenseRequiringWarning(license) &&
			this.mayAdministerLicense(license);
	}

	public mayAdministerLicense (license: License): boolean {
		return this.userService.currentUser.is_root_user ||
			license.salesforce_license.is_license_admin ||
			license.salesforce_license.is_org_admin;
	}

	public displayLicenseExpirationWarningToastIfNeeded (license: License) {
		if (!this.isWhiteLabel() &&
			!this.hasAlreadyShownWarning(license) &&
			this.isExpiringLicenseRequiringRenewal(license)) {
			LicenseRenewalSnackBarComponent.open(this.snackBar, { license }).afterDismissed().subscribe(() => {
				this.addLicenseToListOfAlreadyWarnedExpiringLicenses(license);
			});
		}
	}

	private isWhiteLabel (): boolean {
		return !!this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL);
	}

	private hasAlreadyShownWarning (license: License): boolean {
		const licenseList = this.getListOfAlreadyWarnedExpiringLicenses();
		return licenseList?.includes(license.id);
	}

	private getListOfAlreadyWarnedExpiringLicenses () {
		const licenseStorage = this.window.sessionStorage.getItem(EXPIRING_LICENSE_LIST);
		return licenseStorage && JSON.parse(licenseStorage);
	}

	private addLicenseToListOfAlreadyWarnedExpiringLicenses (license: License) {
		const licenseList = this.getListOfAlreadyWarnedExpiringLicenses() ?? [];
		licenseList.push(license.id);
		this.window.sessionStorage.setItem(EXPIRING_LICENSE_LIST, JSON.stringify(licenseList));
	}
}
