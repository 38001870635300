import multipleCameraHelp from './popover-templates/multiple-camera-help.html';
import liveReviewHelp from './popover-templates/live-review-help.html';
import assignmentTypeHelp from './popover-templates/assignment-type-help.html';
import reviewersSeeRubricsHelp from './popover-templates/reviewers-rubrics-access-help.html';
import videoShareHelp from './popover-templates/video-share-help.html';
import shareVideoWithGuestReviewersHelp from './popover-templates/share-video-with-guest-reviewers-help.html';

/* @ngInject */
export const templates = ($templateCache: ng.ITemplateCacheService) => {
	$templateCache.put('multiple-camera-help.html', multipleCameraHelp);
	$templateCache.put('live-review-help.html', liveReviewHelp);
	$templateCache.put('assignment-type-help.html', assignmentTypeHelp);
	$templateCache.put('reviewers-rubrics-access-help.html', reviewersSeeRubricsHelp);
	$templateCache.put('video-share-help.html', videoShareHelp);
	$templateCache.put('share-video-with-guest-reviewers-help.html', shareVideoWithGuestReviewersHelp);
};
