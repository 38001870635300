import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, fromEvent, map, shareReplay, startWith, switchMap } from 'rxjs';
import { VIEWS } from 'ngx/go-modules/src/enums/views';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { BREAKPOINTS_NUM } from 'ngx/go-modules/src/common/interfaces/breakpoints';

@Injectable({
	providedIn: 'root'
})
export class ViewStateDataSource {

	private viewState$$: BehaviorSubject<VIEWS>;
	public viewState$: Observable<VIEWS>;

	constructor (@Inject(uaDetectToken) public UADetect: UADetectClass) {
		let currentView = localStorage.getItem('view-layout') as VIEWS ?? VIEWS.GRID;
		if(this.UADetect.isMobile()) {
			currentView = VIEWS.GRID;
		}
		localStorage.setItem('view-layout', currentView);
		this.viewState$$ = new BehaviorSubject<VIEWS>(currentView);
		this.viewState$ = this.viewState$$.pipe(
			switchMap((view) => {
				return fromEvent(window, 'resize').pipe(
					debounceTime(150),
					filter((event: UIEvent) => (event.target as Window).innerWidth < BREAKPOINTS_NUM.SCREEN_SM),
					map(() => VIEWS.GRID),
					startWith(view),
					distinctUntilChanged()
				);
			}),
			shareReplay({refCount: true, bufferSize: 1})
		);
	}

	public toggleCurrentView () {
		const newView = this.viewState$$.value === VIEWS.LIST ? VIEWS.GRID : VIEWS.LIST;
		localStorage.setItem('view-layout', newView);
		this.viewState$$.next(newView);
	}
}
