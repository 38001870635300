import * as angular from 'angular';
import { goTourModule } from 'go-modules/go-tour';
import { common } from 'go-modules/models/common';
import { ActivityEditorTour } from './activity-editor-tour.service';

export const ActivityEditorTourModule = angular.module('activity-editor-tour', [
	goTourModule,
	common
])
	.service('activityEditorTour', ActivityEditorTour)
	.name;
