const ORG_NAME_LABELS = {
	INSTITUTION_NAME: 'Institution Name',
	ORGANIZATION_NAME: 'Organization Name'
};

const USE_TYPE_LABELS = {
	COURSE_TYPE: 'Course Type',
	TRAINING_TYPE: 'Training Type'
};

const COURSE_FORMAT_LABELS = {
	COURSE_FORMAT: 'Course Format',
	TRAINING_FORMAT: 'Training Format'
};

export const ORG_UNAFFILIATED = 'Unaffiliated';

export enum ORG_TYPE_LABELS {
	UNSELECTED = 'unselected',
	EDU = 'edu',
	K12 = 'k12',
	K12_PRO = 'k12pro',
	BIZ = 'biz',
	OTHER = 'other'
};

export const COURSE_FORMATS = [
	{ name: 'Online' },
	{ name: 'In-person' },
	{ name: 'Both online and in-person' },
	{ name: 'Other' }
];

export const ORG_TYPES = {
	HIGHER_ED: 'org-constants_org-type-higher',
	K12: 'org-constants_org-type-k12',
	PROFESSIONAL_ORG: 'org-constants_org-type-pro',
	PERSONAL_USE: 'org-constants_org-type-personal'
};

export const ORG_TYPE_DESCRIPTIONS = {
	HIGHER_ED: 'org-constants_org-type-desc-higher',
	K12: 'org-constants_org-type-desc-k12',
	PROFESSIONAL_ORG: 'org-constants_org-type-desc-pro',
	PERSONAL_USE: 'org-constants_org-type-desc-personal'
};

export interface OrgTypeOption {
	optionLabel: typeof ORG_TYPES[keyof typeof ORG_TYPES];
	description: typeof ORG_TYPE_DESCRIPTIONS[keyof typeof ORG_TYPE_DESCRIPTIONS];
	orgNameLabel?: typeof ORG_NAME_LABELS[keyof typeof ORG_NAME_LABELS];
	useTypeLabel?: typeof USE_TYPE_LABELS[keyof typeof USE_TYPE_LABELS];
	courseFormatLabel: typeof COURSE_FORMAT_LABELS[keyof typeof COURSE_FORMAT_LABELS];
	type: ORG_TYPE_LABELS;
	roles?: {name: string}[];
}

export const ORG_TYPE_OPTIONS: OrgTypeOption[] = [
	{
		optionLabel: ORG_TYPES.HIGHER_ED,
		description: ORG_TYPE_DESCRIPTIONS.HIGHER_ED,
		orgNameLabel: ORG_NAME_LABELS.INSTITUTION_NAME,
		useTypeLabel: USE_TYPE_LABELS.COURSE_TYPE,
		courseFormatLabel: COURSE_FORMAT_LABELS.COURSE_FORMAT,
		type: ORG_TYPE_LABELS.EDU,
		roles: [
			{ name: 'Coordinator' },
			{ name: 'Dean' },
			{ name: 'Department Chair' },
			{ name: 'Department Head' },
			{ name: 'Director' },
			{ name: 'Instructor' },
			{ name: 'Instructional Designer' },
			{ name: 'Professor' },
			{ name: 'Instructional Technology' },
			{ name: 'Administrator' },
			{ name: 'Other' }
		]
	},
	{
		optionLabel: ORG_TYPES.K12,
		description: ORG_TYPE_DESCRIPTIONS.K12,
		orgNameLabel: ORG_NAME_LABELS.INSTITUTION_NAME,
		useTypeLabel: USE_TYPE_LABELS.TRAINING_TYPE,
		courseFormatLabel: COURSE_FORMAT_LABELS.COURSE_FORMAT,
		type: ORG_TYPE_LABELS.K12,
		roles: [
			{ name: 'Educator'},
			{ name: 'Principal/Administrator'},
			{ name: 'Superintendent'},
			{ name: 'Other' }
		]
	},
	{
		optionLabel: ORG_TYPES.PROFESSIONAL_ORG,
		description: ORG_TYPE_DESCRIPTIONS.PROFESSIONAL_ORG,
		orgNameLabel: ORG_NAME_LABELS.ORGANIZATION_NAME,
		useTypeLabel: USE_TYPE_LABELS.TRAINING_TYPE,
		courseFormatLabel: COURSE_FORMAT_LABELS.TRAINING_FORMAT,
		type: ORG_TYPE_LABELS.BIZ,
		roles: [
			{ name: 'Sales' },
			{ name: 'Training' },
			{ name: 'Human Resources' },
			{ name: 'Agency' },
			{ name: 'Other' }
		]
	},
	{
		optionLabel: ORG_TYPES.PERSONAL_USE,
		description: ORG_TYPE_DESCRIPTIONS.PERSONAL_USE,
		useTypeLabel: USE_TYPE_LABELS.TRAINING_TYPE,
		courseFormatLabel: COURSE_FORMAT_LABELS.TRAINING_FORMAT,
		type: ORG_TYPE_LABELS.OTHER
	}
];
