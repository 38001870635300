import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export const ScopeProvider = {
	deps: ['$injector'],
	provide: '$scope',
	useFactory: (injector: Injector) => injector.get('$rootScope').$new()
};

@Directive({
	providers: [ScopeProvider],
	selector: 'activity-info'
})
export class ActivityInfoDirective extends UpgradeComponent {
	@Input() public activity;
	@Input() public group;
	@Input() public hideName;
	@Input() public hideSourceMedia;
	@Input() public hideRecordingInstructions;
	@Input() public hideFeedbackInstructions;
	@Input() public isOnDashboard;
	@Input() public session;

	constructor (elementRef: ElementRef, injector: Injector) {
		super('activityInfo', elementRef, injector);
		const $scope = injector.get('$scope');
		/**
		 * We have to fire the evalAsync otherwise it won't fire the ng1 digest cycle
		 * https://stackoverflow.com/q/58986644/1248889
		 */
		$scope.$evalAsync();
	}
}
