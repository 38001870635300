import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { CommentFilterDropdownComponent } from './comment-filter-dropdown.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		GoSelectModule
	],
	declarations: [
		CommentFilterDropdownComponent
	],
	entryComponents: [
		CommentFilterDropdownComponent
	],
	exports: [
		CommentFilterDropdownComponent
	]
})
export class CommentFilterDropdownModule {}
