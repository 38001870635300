import * as angular from 'angular';
import * as ngTranslate from 'angular-translate';
import * as dropdown from 'angular-ui-bootstrap/src/dropdown';
import { groupServiceModule } from 'go-modules/services/group';
import { courseEditorModule } from 'go-modules/course-editor';
import { analyticsModule } from 'go-modules/services/analytics';
import { courseTemplate } from './course-template.component';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { featureFlagModule } from 'go-modules/feature-flag';
import { SelectedModule } from 'go-modules/services/selected';

export const courseTemplateModule = angular.module('go.course-template', [
	groupServiceModule,
	courseEditorModule,
	ngTranslate,
	dropdown,
	analyticsModule,
	featureFlagModule,
	ngxGroupServiceModule,
	SelectedModule
])
	.component('courseTemplate', courseTemplate)
	.name;
