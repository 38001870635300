import { ILogService } from 'angular';
import { SessionCreationIntent } from './intent';
import type { SessionCreationWizardOptions } from './options';
import type { SessionCreationWizardStep } from './steps';
import { MODE as SESSION_EDITOR_MODE } from '../session-editor/modes';
import { UMC_ERROR_REASONS } from 'go-modules/universal-media-chooser/error';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/**
 * A service that presents the user with a set of UI/UX flows
 * to help guide them through the session setup process.
 */
export class SessionCreationWizardService {
	public static readonly NG1_INJECTION_NAME = 'sessionCreationWizardService' as const;

	public environmentVarsService: EnvironmentVarsService;
	private steps: SessionCreationWizardStep[] = [];
	private recordIntentSteps: SessionCreationWizardStep[];
	private uploadIntentSteps: SessionCreationWizardStep[];
	private saveForLaterIntentSteps: SessionCreationWizardStep[];
	private continueIntentSteps: SessionCreationWizardStep[];
	private ignoreStepsIfUpload: SessionCreationWizardStep[];
	private initialIntent: SessionCreationIntent;

	/* @ngInject */
	constructor (
		private Session: any,
		private sessionCreationWizardInitiateStep: SessionCreationWizardStep,
		private sessionCreationWizardEquipmentCheckStep: SessionCreationWizardStep,
		private sessionCreationWizardStartTestStep: SessionCreationWizardStep,
		private sessionCreationWizardInstructionsStep: SessionCreationWizardStep,
		private sessionCreationWizardUploadStep: SessionCreationWizardStep,
		private sessionCreationWizardRecordStep: SessionCreationWizardStep,
		private sessionCreationWizardFinalizeStep: SessionCreationWizardStep,
		private sessionEditorModal: any,
		private $log: ILogService,
		private $q: ng.IQService,
		private featureFlag: FeatureFlag
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.recordIntentSteps = [
			this.sessionCreationWizardInitiateStep,
			this.sessionCreationWizardEquipmentCheckStep,
			this.sessionCreationWizardStartTestStep,
			this.sessionCreationWizardRecordStep,
			this.sessionCreationWizardInstructionsStep,
			this.sessionCreationWizardFinalizeStep
		];

		this.uploadIntentSteps = [
			this.sessionCreationWizardInitiateStep,
			this.sessionCreationWizardStartTestStep,
			this.sessionCreationWizardInstructionsStep,
			this.sessionCreationWizardUploadStep,
			this.sessionCreationWizardFinalizeStep
		];

		this.saveForLaterIntentSteps = [
			this.sessionCreationWizardInitiateStep,
			this.sessionCreationWizardFinalizeStep
		];

		this.continueIntentSteps = [
			this.sessionCreationWizardInitiateStep,
			this.sessionCreationWizardInstructionsStep,
			this.sessionCreationWizardUploadStep,
			this.sessionCreationWizardEquipmentCheckStep,
			this.sessionCreationWizardRecordStep,
			this.sessionCreationWizardFinalizeStep
		];

		this.ignoreStepsIfUpload = [
			this.sessionCreationWizardEquipmentCheckStep,
			this.sessionCreationWizardRecordStep
		];
	}

	/**
	 * Starts the session creation setup flow
	 */
	public start (options: SessionCreationWizardOptions): ng.IPromise<any> {
		// Create a session that will be used through this flow
		if (!options.session) {
			options.session = this.createNewSession(options);
			options.session.presenters.push(options.user);
		}

		return this.$q((resolve, reject) => {

			let intentPrompt = this.$q.resolve();

			if (!options.intent && !options.session.isRecorded()) {

				intentPrompt = this.promptForInitialIntent(options).then((intent: SessionCreationIntent) => {

					options.intent = intent;

					// Modify the intent if the assignment type is a test
					if (options.activity.has_single_recording_attempt) {
						options.intent = SessionCreationIntent.RECORD;
					}

					this.initialIntent = options.intent;
				});
			}

			intentPrompt
				.then(() => this.walkThroughSetupSteps(options))
				.then(() => {
					resolve(options.session);
				})
				.catch((err) => {
					// If this was a cancelled UMC action and it was a test
					// act as if everything was fine
					if (err === UMC_ERROR_REASONS.ACTION_CANCELLED && options.activity.has_single_recording_attempt) {
						return resolve(options.session);
					} else {
						this.archiveSession(options);
					}

					if (err instanceof Error) {
						this.$log.error(err);
					}

					reject(err);
				});
		});
	}

	/**
	 * Compile a list of sequential wizard steps
	 */
	public compileSteps (options: SessionCreationWizardOptions): SessionCreationWizardStep[] {
		let steps: SessionCreationWizardStep[] = [];

		if (!options.session) {
			throw new Error('Cannot compile steps until session has been created');
		}

		switch (options.intent) {
			case SessionCreationIntent.RECORD:
				steps = steps.concat(this.recordIntentSteps);
				break;
			case SessionCreationIntent.CONTINUE:
				steps = steps.concat(this.continueIntentSteps);
				break;
			case SessionCreationIntent.UPLOAD:
				steps = steps.concat(this.uploadIntentSteps);
				break;
			default:
				steps = steps.concat(this.saveForLaterIntentSteps);
				break;
		}

		return steps.filter((step: SessionCreationWizardStep) => {
			return step.isRequired(options);
		});
	}

	/**
	 * Determine what the user's initial is intent is when creating the session
	 */
	private promptForInitialIntent (options: SessionCreationWizardOptions): ng.IPromise<SessionCreationIntent> {
		return this.sessionEditorModal.open({
			modalData: {
				user: options.user,
				group: options.group,
				activity: options.activity,
				session: options.session,
				mode: SESSION_EDITOR_MODE.CREATE
			}
		}).result;
	}

	/**
	 * Walk through a series of setup steps
	 */
	private walkThroughSetupSteps (options: SessionCreationWizardOptions): ng.IPromise<void> {
		// Determine which steps should be included
		this.steps = this.compileSteps(options);

		// Go through each step, chaning onto each result
		return this.steps.map((step) => {
			return () => {
				return this.checkIfUploadModifySteps(options, step) ?
					this.$q.resolve() :
					step.run(options);
			};
		}).reduce((finalPromise, nextPromiseFn) => {
			return finalPromise.then(nextPromiseFn);
		}, this.$q.resolve());
	}

	private checkIfUploadModifySteps (options: SessionCreationWizardOptions, step: SessionCreationWizardStep) {
		return options.intent !== this.initialIntent &&
			options.intent === SessionCreationIntent.UPLOAD &&
			this.initialIntent === SessionCreationIntent.CONTINUE &&
			this.ignoreStepsIfUpload.includes(step);
	}

	/**
	 * Creates a new session instance
	 */
	private createNewSession (options: SessionCreationWizardOptions): any {
		// We must first determine the initial status of the session.
		// When the activity settings dictate that this session must
		// not have response media (e.g., comment only activity type),
		// we must start the session out with a "recorded" status.
		// Otherwise, if the session should have response media, we will
		// start out with a "cancelled" status. This helps us later identify
		// whether the session was abandoned before finishing the entire flow.
		let status: number = !options.activity.has_response_media ?
			this.Session.RECORDED : this.Session.CANCELLED;

		// When the activity supports live sessions or conversations
		// change the session's status to awaiting start.
		if (options.activity.has_response_media
			&& (options.activity.isLiveSessionEnabled() || options.activity.is_conversation)
			&& !options.activity.has_single_recording_attempt) {
			status = this.Session.WAITING;
		}

		// Create a session instance
		const session: any = this.Session.model({
			group_id: options.group.group_id,
			activity_id: options.activity.activity_id,
			created_by: options.user.user_id,
			status
		});

		// If there is any extra session data in an environment variable, include that as well.
		const extraSessionData = this.environmentVarsService.get(EnvironmentVarsService.VAR.EXTRA_SESSION_DATA);
		if (extraSessionData) {
			session.extraData = extraSessionData;
		}

		session.setHaveISubmitted(options.group, options.activity, true);

		return session;
	}

	/**
	 * Archive a given session
	 */
	private archiveSession (options: SessionCreationWizardOptions): ng.IHttpPromise<void> {
		options.session.setHaveISubmitted(options.group, options.activity, false);
		if (options.session.session_id && !options.session.archived_at) {
			return options.session.archive();
		}
	}
}

export const sessionCreationWizardServiceToken = upgradeNg1Dependency(SessionCreationWizardService);
