import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxActivityViewComponent } from 'ngx/go-modules/src/components/activity-view/activity-view.component';
import { FilterViewLayoutModule } from 'ngx/go-modules/src/components/filter-view-layout/filter-view-layout.module';
import { SessionSearchModule } from 'ngx/go-modules/src/components/activity-view/session-search/session-search.module';
import { SessionTableModule } from 'ngx/go-modules/src/components/activity-view/session-table/session-table.module';
import { SessionGridModule } from 'ngx/go-modules/src/components/activity-view/session-grid/session-grid.module';
import { SessionListSearchResultInfoModule } from 'ngx/go-modules/src/components/activity-view/session-list-search-result-info/session-list-search-result-info.module';
import { SessionZeroStateModule } from 'ngx/go-modules/src/components/activity-view/session-zero-state/session-zero-state.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { FeedbackHoldModule } from '../feedback-hold/feedback-hold.module';
import { DataSourceZeroStateModule } from 'ngx/go-modules/src/components/data-source-zero-state/data-source-zero-state.module';
import { ActivityContextMenuModule } from 'ngx/go-modules/src/components/activity-view/activity-context-menu/activity-context-menu.module';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { ActivityInstructionsDialogModule } from '../dialogs/activity-Instructions-dialog/activity-instructions-dialog.module';
import { LoadingModule } from 'ngx/go-modules/src/components/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FilterViewLayoutModule,
		SessionSearchModule,
		SessionTableModule,
		TranslateModule,
		SessionGridModule,
		GoMaterialModule,
		GoSelectModule,
		SessionListSearchResultInfoModule,
		SessionZeroStateModule,
		GoTooltipModule,
		ReactiveFormsModule,
		NgxZeroStateModule,
		FeedbackHoldModule,
		DataSourceZeroStateModule,
		MenuModule,
		ActivityContextMenuModule,
		ActivityInstructionsDialogModule,
		LoadingModule
	],
	declarations: [
		NgxActivityViewComponent
	],
	entryComponents: [
		NgxActivityViewComponent
	],
	exports: [
		NgxActivityViewComponent
	]
})
export class NgxActivityViewModule {}
