import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	QueryList
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { GoStepComponent } from './go-step/go-step.component';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'go-stepper',
	template: require('./go-stepper.component.html'),
	styles: [require('./go-stepper.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: CdkStepper, useExisting: GoStepperComponent}]
})
export class GoStepperComponent extends CdkStepper  {
	@Input()
	public goStepperClass = '';
	public hideWrapper: boolean;
	public readonly steps: QueryList<GoStepComponent>;

	constructor (
		_dir: Directionality,
		_changeDetectorRef: ChangeDetectorRef,
		element: ElementRef,
		@Inject(DOCUMENT) document
	) {
		super(_dir, _changeDetectorRef, element, document);
	}

	public get hideStepsIndicator (): boolean {
		return (this.selected as GoStepComponent)?.hideIndicator;
	}

	public isSelected (stepIndex: number) {
		return this.selectedIndex >= stepIndex;
	}
}
