import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class GoLocationService {
	public static readonly NG1_INJECTION_NAME = 'goLocationService' as const;

	/* @ngInject */
	constructor (
		public $location: angular.ILocationService
	) {
	}

	public path (url: string, query: any) {
		const location = this.$location;
		location.search(query);
		location.path(url);
		return location;
	}

	public search (search: any = null) {
		return search ? this.$location.search(search) : this.$location.search();
	}
}

export const goLocationServiceToken = upgradeNg1Dependency(GoLocationService);
