import * as angular from 'angular';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { Masquerade } from 'go-modules/masquerade/masquerade.service';
import { MessageModal } from 'go-modules/modals/message/modal.factory';
import { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';

export class GroupSettingsPanelController implements ng.IOnInit {
	public group: any;
	public user: any;
	public section: any;

	private groups: any;
	private saving: boolean = false;
	private orgSettings: any;
	private defaultActiveTab: any;

	/** @ngInject */
	constructor (
		public $scope,
		private $analytics: angulartics.IAnalyticsService,
		private $translate: ng.translate.ITranslateService,
		private messageModal: MessageModal,
		private Group,
		private ngxGoToastService: NgxGoToastService,
		private groupsIndex: GroupsIndexService,
		private helpUrls,
		private masquerade: Masquerade,
		private updatedGroup,
		private $document,
		private selectedService: SelectedService
	) {}

	public $onInit () {
		this.group = {...this.updatedGroup};
		this.groups = this.groupsIndex;
		this.Group.getOrgSettings({group_id: this.group.group_id})
			.then((orgSettings) => {
				this.orgSettings = orgSettings;
			});

		this.$scope.group = this.group;
		this.$scope.currentGroup = this.group;
		this.$scope.currentGroupId = this.group.getId();

		const tabs = ['info', 'users'];

		this.section = this.section || 'info';

		this.defaultActiveTab = tabs.indexOf(this.section);

		// Send Google Analytics event when the panel opens
		this.$analytics.eventTrack(this.section === 'info' ? 'settings' : this.section, {
			category: this.group.type,
			label: null
		});
	};

	public saveGroup (form) {
		if (form.$invalid) {
			const invalid = this.$document[0].querySelector(':not(form):not(ng-form).ng-invalid') as HTMLElement;
			invalid.focus();
			return;
		}

		this.saving = true;
		this.group.copyFrom = null;
		this.group.save().$promise.then((group) => {
			this.selectedService.setGroup(group);
			this.saving = false;
			this.$scope.$close(this.group);
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'group-settings_panel-success'
			});
		}).catch(() => {
			this.saving = false;
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: 'group-settings_panel-error'
			});
		});
	}

	/**
	 * Whether or not a user may mask as another user
	 *
	 * @param group
	 * @param user
	 * @param role
	 * @returns boolean
	 */
	public mayMask (group, user, role) {
		let result = this.user.is_root_user;
		if (!result && group && user && role) {
			// org admins may mask as anyone
			const roleOrigin = group.role_origin;
			if (angular.isObject(roleOrigin) &&
			roleOrigin.group_type === this.Group.ORG &&
			(roleOrigin.role === this.Group.role.ADMIN ||
			roleOrigin.role === this.Group.role.OWNER)) {
				result = true;
			} else if (group.hasInstructorRole(true)) {
				result = role === this.Group.role.PRESENTER;
			}
		}
		return result;
	}

	/**
	 * Mask as another user
	 *
	 * @param user
	 */
	public loginAs (user) {
		return this.masquerade
			.as(user)
			.catch(() => {
				this.messageModal.open({
					modalData: {
						title: this.$translate.instant('mask-user-error_title'),
						message: this.$translate.instant('mask-user-error_message')
					}
				});
			});
	}

	public onActivityCopy (promise) {
		this.$scope.$close(promise);
	}


	public getHeaderTranslation () {
		if (this.defaultActiveTab === 0) {
			switch (this.group.type) {
				case 'org':
					return 'group-settings_organization-information';
				case 'folder':
					return 'group-settings_folder-information';
				default:
					return 'group-settings_course-information';
			}
		} else {
			return 'course-menu_manage-users';
		}
	}
};
