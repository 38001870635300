import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	ChangeDetectorRef,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges, ElementRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as fuzzysort from 'fuzzysort';
import { ORG_TYPE_LABELS } from 'go-modules/org-settings/org-settings.constants';
import type { AllowedOrg, EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import { UserType, UserTypeSelectionDialog } from 'ngx/go-modules/src/components/dialogs/user-type-selection-dialog/user-type-selection-dialog.component';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'org-search',
	template: require('./org-search.component.html'),
	styles: [
		require('./org-search.component.scss'),
		require('./org-search-sm.component.scss'),
		require('./org-search-xs.component.scss')
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgSearchComponent implements OnChanges, OnDestroy {
	@Input()
	public emailVerificationData: EmailVerificationResponse;
	@Input()
	public allOrgs: SignupOrg[];
	@Input()
	public allowedOrgs: AllowedOrg[];
	@Output()
	public onBack = new EventEmitter<void>();
	@Output()
	public onCreateOrg = new EventEmitter<{searchTerm: string}>();
	@Output()
	public onSelectOrg = new EventEmitter<SignupOrg>();
	@Output()
	public reloadOrgs = new EventEmitter<void>();
	@Output()
	public navigateToStudentStep = new EventEmitter<void>();

	public searchResults;
	public defaultInvites = [];
	public searchTerm = '';
	public searchCountTranslation = '';
	public totalSearchResults = 0;
	public searchResultLimit = 100;

	private componentDestroyed: Subject<boolean> = new Subject();

	constructor (
		private cd: ChangeDetectorRef,
		private ngxTranslate: TranslateService,
		private elementRef: ElementRef,
		public dialog: MatDialog
	) {}

	public ngOnChanges (_changes: SimpleChanges): void {
		const inviteOrgs = this.emailVerificationData?.invited_orgs ?? [] as AllowedOrg[];
		let allowedOrgs = this.allowedOrgs ?? [];

		allowedOrgs = allowedOrgs.filter((allowedOrg) => {
			return !inviteOrgs.find((inviteOrg) => inviteOrg.group_id === allowedOrg.group_id);
		});

		this.defaultInvites = inviteOrgs.concat(allowedOrgs);

		if (!!this.allOrgs && this.defaultInvites.length > 0) {
			this.defaultInvites.forEach((di) => {
				if (!this.allOrgs.find((o) => o.group_id === di.group_id)) {
					const newOrg: SignupOrg = di;
					di.open_access = true;
					this.allOrgs.push(newOrg);
				}
			});
			this.allOrgs.forEach((o) => {
				// allow joining any orgs that permit based on your email domain or your invites.
				o.open_access = o.open_access || !!this.defaultInvites.find((di) => di.group_id === o.group_id);
			});
		}
		// If there was an update, re-search
		this.search();
		this.cd.detectChanges();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed.next(true);
		this.componentDestroyed.complete();
	}

	public search (): void {
		if (this.searchTerm === '') {
			this.searchResults = null;
		} else {
			const options = { allowTypo: true, keys: ['name'] };
			this.searchResults = fuzzysort
				.go(this.searchTerm, this.allOrgs, options)
				.map((result) => result.obj);
			this.totalSearchResults = this.searchResults.length;
			// Truncate for performance reasons.
			this.searchResults = this.searchResults.slice(0, this.searchResultLimit);
		}
		const translation = !this.searchResults || this.totalSearchResults === 1 ?
			this.ngxTranslate.get('sign-up-org-search_search-result-one-count') :
			this.ngxTranslate.get('sign-up-org-search_search-result-plural-count', {count: this.totalSearchResults});
		translation
			.pipe(take(1))
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe((val) => {
				this.searchCountTranslation = val;
				this.cd.detectChanges();
			});
	}

	public startSearch () {
		this.defaultInvites = [];
		this.cd.detectChanges();
		this.elementRef.nativeElement.querySelector('input').focus();
	}

	public clearSearch () {
		this.searchTerm = '';
		this.searchResults = null;
		this.elementRef.nativeElement.querySelector('input').focus();
		this.cd.detectChanges();
	}

	public selectOrg (selectedOrg: SignupOrg) {
		if (selectedOrg.org_type === ORG_TYPE_LABELS.BIZ) {
			this.onSelectOrg.emit(selectedOrg);
			return;
		}

		this.dialog.open(UserTypeSelectionDialog)
			.afterClosed().subscribe((userType: UserType) => {
				if(userType === UserType.Instructor) {
					this.onSelectOrg.emit(selectedOrg);
				} else if(userType === UserType.Presenter) {
					this.navigateToStudentStep.emit();
				}
			});
	}

	public createOrg () {
		this.onCreateOrg.emit({searchTerm: this.searchTerm});
	}

	public back () {
		this.onBack.emit();
	}
}
