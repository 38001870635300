import * as angular from 'angular';
import { Masquerade } from 'go-modules/masquerade/masquerade.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { AppDataService } from 'dashboard/services/app-data.service';
import { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import { UserService } from 'go-modules/models/user/user.service';
import { GroupService as GroupsService } from 'go-modules/services/group/group.service';

/* @ngInject */
export class DashboardUtilsService implements DashboardUtilsServiceInterface {
	public disjointGroups;
	public gTimeoutId;
	public myAccount;
	public siteNotification;

	constructor (
		public $q,
		private groupsIndex: GroupsIndexService,
		private $log,
		private $filter: angular.IFilterService,
		public masquerade: Masquerade,
		private selectedService: SelectedService,
		private Group,
		private pubnub: PubnubService,
		private fullstoryService: FullstoryService,
		private AppData: AppDataService,
		private userService: UserService,
		private $injector,
		private ActivityModel,
		private GroupService: GroupsService
	) {
		this.disjointGroups = this.Group.setModels(this.AppData.disjoint_groups);
		this.selectedService.setGroups(this.disjointGroups);
		this.myAccount = this.AppData.my_account ? Group.model(this.AppData.my_account) : this.AppData.my_account;
		this.siteNotification = this.AppData.site_notification;

		// Added a listener for AppData values when being updated.
		this.AppData.appDataSubject.subscribe(() => {
			this.disjointGroups = this.Group.setModels(this.AppData.disjoint_groups);
			this.selectedService.setGroups(this.disjointGroups);
			this.myAccount = this.AppData.my_account ? Group.model(this.AppData.my_account) : this.AppData.my_account;
			this.siteNotification = this.AppData.site_notification;
		});
	}

	/**
	 * Choose the first activity from the sorted list,
	 * if there are no activities, choose the default.
	 *
	 * @param group
	 *
	 * @returns {Object}
	 */
	public findFallbackActivity (group) {
		let activity;
		const activities = this.$filter('orderBy')(
			this.$filter('activityFilter')(group.activities, group),
			'sort_index'
		);
		if (activities.length) {
			activity = activities[0];
		}

		return activity;
	};

	/**
	 * Select an activity
	 *
	 * @param activity
	 */
	public selectActivity (activity): void {
		if (this.selectedService.getActivity() !== activity) {
			this.selectedService.setActivity(activity);
		}
	};

	public handleFoldersRouteParamChange (orgId): ng.IPromise<void> {
		const lastOrg = this.selectedService.getOrg();
		if (lastOrg == null || lastOrg.group_id !== orgId) {
			const myOrg = this.selectedService.getMyOrgs().find((org) => {
				return parseInt(org.group_id, 10) === parseInt(orgId, 10);
			});

			let resolveOrg = myOrg != null ? this.$q.resolve(myOrg) : this.$q.reject();

			if (this.userService.currentUser.is_root_user) {
				// If the user is root, then they are allowed to navigate to orgs they don't have in myOrgs
				// We'll just need to fetch the group information for them to use
				resolveOrg = resolveOrg.catch(() => this.GroupService.getGroup(orgId));
			}

			return resolveOrg.then((org) => this.selectOrg(org));
		}
		return this.$q.resolve();
	};

	public handleGroupRouteParamChange (groupId: number): ng.IPromise<void> {
		const lastGroup = this.selectedService.getGroup();
		let resolveGroup;
		if (lastGroup == null || lastGroup.group_id !== groupId) {
			resolveGroup = this.groupsIndex.resolve(groupId, true).then((group) => {
				return this.selectGroup(group);
			});
		} else {
			resolveGroup = this.$q.when(lastGroup);
		}

		return resolveGroup.then((group) => this.handleFoldersRouteParamChange(group.org_id));
	};

	public handleActivityRouteParamChange (activityId: number): ng.IPromise<void> {
		const lastActivity = this.selectedService.getActivity();
		let resolveActivity;
		if (lastActivity == null || lastActivity.activity_id !== activityId) {
			resolveActivity = this.ActivityModel.get({
				activity_id: activityId
			}).$promise;
		} else {
			resolveActivity = this.$q.when(lastActivity);
		}

		resolveActivity = resolveActivity.then((activity) => {
			this.selectedService.setActivity(activity);

			// TODO DEV-15860
			if (!('unviewed_comments' in activity)) {
				// This evil getter uses an interceptor & a cache to globally update the activity stats
				// Wait for it to be done, but we still want the activity afterwards
				return this.ActivityModel.getAllActivityStats({ group_id: activity.group_id }).$promise
					.then(() => activity)
					.catch(()=> activity);
			}
			return activity;
		});

		return resolveActivity.then((activity) => this.handleGroupRouteParamChange(activity.group_id));

	};

	public handleSessionRouteParamChange (sessionId: number): ng.IPromise<void> {
		const lastSession = this.selectedService.getSession();
		let resolveSession;
		if (lastSession == null || lastSession.session_id !== sessionId || !lastSession.is_fully_loaded) {
			resolveSession = this.$injector.get('Session').get({
				'session_id': sessionId,
				'with[]': [
					'activity',
					'activity.attachments',
					'activity.aiPrompts',
					'activity.recording_instructions',
					'activity.feedback_instructions',
					'player_time_sync_events',
					'activity.source_media'
				]
			}).$promise.then((session) => {
				session.is_fully_loaded = true;
				this.selectedService.setSession(session);
				this.selectedService.setActivity(this.ActivityModel.model(session.activity));
				return session;
			});
		} else {
			resolveSession = this.$q.when(lastSession);
		}

		return resolveSession.then((session) => this.handleActivityRouteParamChange(session.activity_id));
	};

	public ensureOrgIsSelected (): ng.IPromise<void> {
		if (!this.selectedService.getOrg()) {
			return this.selectOrg(this.selectedService.getMyOrgs()[0]);
		}

		return this.$q.resolve();
	};

	/**
	 * Select a group
	 *
	 * @param group
	 * @returns {*}
	 */
	public selectGroup (group) {
		// set last selected group
		this.selectedService.setLastGroup(this.selectedService.getGroup());

		// set selected group
		this.selectedService.setGroup(group);

		if (group) {
			// get group data
			const promise = group.getData();

			promise.then(() => {
				this.fullstoryService.createEvent(FULLSTORY_EVENTS.USER_CONTEXT_IDENTIFIED, {
					userRole_str: group.role,
					groupId_int: parseInt(group.group_id, 10),
					orgId_int: parseInt(group.org_id, 10)
				});

				if (group.hasInstructorRole(true)) {
					this.fullstoryService.createEvent(FULLSTORY_EVENTS.USE_TYPE_IDENTIFIED, {
						useTypeId_int: parseInt(this.myAccount.use_type_id, 10)
					});
				}

				this.pubnub.updateChannels();

				return group;
			}).catch(this.$log.error);

			return promise;
		}

		return null;
	}

	/**
	 * Get disjoint groups modelized
	 */
	public getModelizedDisjointGroups () {
		return this.disjointGroups.map((group) => {
			return this.Group.model(group);
		});
	}

	private selectOrg (org): ng.IPromise<void> {
		const group = this.Group.model(org);
		return this.selectedService.setOrg(group);
	}
}

export interface DashboardUtilsServiceInterface {
	disjointGroups: any;
	myAccount: any;
	siteNotification: any;
	gTimeoutId: any;
	findFallbackActivity(group);
	selectActivity(activity): void;
	handleFoldersRouteParamChange(orgId): ng.IPromise<void>;
	handleActivityRouteParamChange(activityId): ng.IPromise<void>;
	handleSessionRouteParamChange(sessionId): ng.IPromise<void>;
	handleGroupRouteParamChange(groupId): ng.IPromise<void>;
	ensureOrgIsSelected():  ng.IPromise<void>;
	selectGroup(group);
	getModelizedDisjointGroups();
};
