import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceBootstrapComponent } from './components/service-bootstrap/service-bootstrap.component';
import { GoMaterialModule } from './go-material.module';
import { EmailVerificationModule } from './components/email-verification/email-verification.module';
import { LicenseManagementModule } from 'ngx/go-modules/src/components/license-management';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { GoDropdownModule } from './directives/go-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoMonitorTabEventModule } from './directives/go-monitor-tab-event';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { FeedbackCreatorModule } from './components/feedback-session/feedback-creator';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { NgxRichTextEditorModule } from './components/rich-text-editor/rich-text-editor.module';
import { NgxAccessDeniedModule } from './components/access-denied/access-denied.module';
import { LicenseSeatsManagementModule } from './components/license-seats-management';
import { SearchInputModule } from './components/search-input/search-input.module';
import { WelcomeDialogModule } from './components/dialogs/welcome-dialog/welcome-dialog.module';
import { NgxActivityViewModule } from 'ngx/go-modules/src/components/activity-view/activity-view.module';
import { GoSelectModule } from './directives/go-select/go-select.module';
import { SessionCardModule } from './components/activity-view/session-card';
import { DataSourceZeroStateModule } from './components/data-source-zero-state/data-source-zero-state.module';
import { OrgPickerModule } from 'ngx/go-modules/src/components/org-picker/org-picker.module';
import { OutsideClickModule } from './directives/outside-click';
import { LicenseRenewalSnackBarModule } from 'ngx/go-modules/src/components/snack-bars/license-renewal';
import { ExpiringLicenseModule } from './components/breadcrumbs/folder-breadcrumbs/expiring-license';
import { FeatureFlagDialogComponentModule } from './components/dialogs/feature-flag-dialog/feature-flag-dialog.module';
import { LoginModule } from './components/auth/login';
import { UserSettingsModule } from 'ngx/go-modules/src/components/user-settings';
import { ResetPasswordModule } from 'ngx/go-modules/src/components/auth/reset-password';
import { CommentReactionsModule } from './components/feedback-session/comment-reactions';
import { TranscriptViewerModule } from './components/feedback-session/transcript-viewer';
import { SessionAnalyticsModule } from 'ngx/go-modules/src/components/feedback-session/session-analytics';
import { SpeedGraderModule } from 'ngx/go-modules/src/components/feedback-session/speed-grader/speed-grader.module';
import { CopyToClipboardDirectiveModule } from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard.module';
import { DropUserDialogModule } from './components/dialogs/drop-user-dialog';
import { GoSidepanelModule } from './services/go-sidepanel/go-sidepanel.module';
import { MessageDialogModule } from './components/dialogs/message-dialog/message-dialog.module';
import { ConfirmDialogModule } from './components/dialogs/confirm-dialog/confirm-dialog.module';
import { EditUserDetailsDialogModule } from './components/dialogs/edit-user-details-dialog/edit-user-details-dialog.module';
import { ForgotPasswordModule } from 'ngx/go-modules/src/components/auth/forgot-password/forgot-password.module';
import { NgxArchiveViewModule } from 'ngx/go-modules/src/components/archive/archive-view/archive-view.module';
import { FolderMenuModule } from 'ngx/go-modules/src/components/menus/folder-menu/folder-menu.module';
import { DashboardModule } from 'ngx/go-modules/src/components/dashboard/dashboard.module';
import { NgxFolderViewModule } from 'ngx/go-modules/src/components/folder-view/folder-view.module';
import { MenuButtonModule } from 'ngx/go-modules/src/components/menus/menu-button/menu-button.module';
import { AccountMenuModule } from 'ngx/go-modules/src/components/menus/account-menu/account-menu.module';
import { AIMarkersDialogModule } from './components/dialogs/ai-markers-dialog/ai-markers-dialog.module';
import { WelcomeTourDialogModule } from 'ngx/go-modules/src/components/dialogs/welcome-tour-dialog/welcome-tour-dialog.module';
import { NgxSessionViewModule } from './components/feedback-session/session-view/session-view.module';
import { TimeDisplayModule } from 'ngx/go-modules/src/components/time-display/time-display.module';
import { RateLimitInterceptor } from 'ngx/go-modules/src/services/interceptors/rate-limit-interceptor/rate-limit-interceptor.service';
import { RateLimitDialogModule } from 'ngx/go-modules/src/components/dialogs/rate-limit-dialog/rate-limit-dialog.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordDialogModule } from 'ngx/go-modules/src/components/dialogs/change-password-dialog/change-password-dialog.module';
import { TermsOfUseDialogModule } from 'ngx/go-modules/src/components/dialogs/terms-of-use-dialog/terms-of-use-dialog.module';
import { ActivitySourceDialogModule } from 'ngx/go-modules/src/components/dialogs/activity-source-dialog/activity-source-dialog.module';
import { VideoSceneModule } from 'ngx/go-modules/src/components/video-scene/video-scene.module';
import { MarkerFilterDropdownModule } from './components/feedback-session/feedback-filters/marker-filter/marker-filter-dropdown.module';
import { CommentFilterDropdownModule } from './components/feedback-session/feedback-filters/comment-filter/comment-filter-dropdown.module';
import { VideoDetailsDialogModule } from 'ngx/go-modules/src/components/dialogs/video-details-dialog/video-details-dialog.module';
import { CompareProductsDialogModule } from './components/dialogs/compare-products-dialog/compare-products-dialog.module';
import {
	LicenseUpgradeSnackBarModule
} from 'ngx/go-modules/src/components/snack-bars/license-upgrade/license-upgrade-snack-bar.module';
import { ConfirmPasswordDialogModule } from 'ngx/go-modules/src/components/dialogs/confirm-password-dialog/confirm-password-dialog.module';
import { RubricFilterDropdownModule } from './components/feedback-session/feedback-filters/rubric-filter/rubric-filter-dropdown.module';
import { PaymentPanelModule } from 'ngx/go-modules/src/components/payment-panel/payment-panel.module';
import { DraftTourSnackBarModule } from 'ngx/go-modules/src/components/snack-bars/draft-tour/draft-tour-snack-bar.module';
import { LibraryCollectionViewerModule } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.module';
import { ColorMenuModule } from 'ngx/go-modules/src/components/color-menu/color-menu.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		GoMaterialModule,
		EmailVerificationModule,
		LicenseManagementModule,
		LicenseSeatsManagementModule,
		FeedbackCreatorModule,
		A11yModule,
		GoDropdownModule,
		CopyToClipboardDirectiveModule,
		GoMonitorTabEventModule,
		NgxZeroStateModule,
		GoToastModule,
		NgxRichTextEditorModule,
		NgxAccessDeniedModule,
		SearchInputModule,
		GoSelectModule,
		WelcomeDialogModule,
		NgxActivityViewModule,
		SessionCardModule,
		DataSourceZeroStateModule,
		OrgPickerModule,
		OutsideClickModule,
		LicenseRenewalSnackBarModule,
		LicenseUpgradeSnackBarModule,
		ExpiringLicenseModule,
		FeatureFlagDialogComponentModule,
		LoginModule,
		UserSettingsModule,
		ResetPasswordModule,
		CommentReactionsModule,
		TranscriptViewerModule,
		SessionAnalyticsModule,
		SpeedGraderModule,
		DropUserDialogModule,
		GoSidepanelModule,
		MessageDialogModule,
		ConfirmDialogModule,
		EditUserDetailsDialogModule,
		ForgotPasswordModule,
		NgxArchiveViewModule,
		DashboardModule,
		FolderMenuModule,
		NgxFolderViewModule,
		NgxSessionViewModule,
		MenuButtonModule,
		AccountMenuModule,
		AIMarkersDialogModule,
		WelcomeTourDialogModule,
		RateLimitDialogModule,
		ChangePasswordDialogModule,
		TermsOfUseDialogModule,
		ActivitySourceDialogModule,
		TimeDisplayModule,
		VideoSceneModule,
		CommentFilterDropdownModule,
		MarkerFilterDropdownModule,
		RubricFilterDropdownModule,
		VideoDetailsDialogModule,
		CompareProductsDialogModule,
		ConfirmPasswordDialogModule,
		PaymentPanelModule,
		DraftTourSnackBarModule,
		LibraryCollectionViewerModule,
		ColorMenuModule
	],
	declarations: [
		ServiceBootstrapComponent
	],
	exports: [
		ServiceBootstrapComponent
	],
	entryComponents: [
		ServiceBootstrapComponent
	],
	providers: [
		{ provide: 'Window', useValue: window },
		{ provide: HTTP_INTERCEPTORS, useClass: RateLimitInterceptor, multi: true }
	]
})
export class GoModulesModule {}
