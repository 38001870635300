import * as angular from 'angular';
import { UserService } from 'go-modules/models/user/user.service';

export class GroupSettingsUserListItemController {

	/* @ngInject */
	constructor (
		private $scope,
		private $log: angular.ILogService,
		private Group,
		private User,
		private userService: UserService
	) {}

	/**
	 * Whether has paid icon should be shown
	 *
	 * @returns {boolean}
	 */
	public showHasPaid = () => {
		return this.$scope.group.requiresPayment() &&
			this.hasUserPaid() &&
			this.$scope.user.role === this.Group.role.PRESENTER;
	};

	/**
	 * Whether has not paid icon should be shown
	 *
	 * @returns {boolean}
	 */
	public showHasNotPaid = () => {
		return this.$scope.group.requiresPayment() &&
			!this.hasUserPaid() &&
			this.$scope.user.role === this.Group.role.PRESENTER;
	};

	public restoreUser = (user) => {
		this.User.restoreRole({
			userId: user.user_id, groupId: user.group_id
		}).$promise
			.then((updatedRole) => {

				const updateRole = (role) => {
					angular.extend(user, role);
				};

				updateRole(updatedRole);

			}).catch(this.$log.error);
	};

	/**
	 * Whether user info may be edited
	 *
	 * @param user
	 * @param group
	 * @param role
	 * @returns {boolean}
	 */
	public mayEdit = (user, group, role) => {

		if (!user || !group) {
			return false;
		}

		if (this.userService.currentUser.is_root_user) {
			return true;
		}

		return group.hasHigherRole(role, true) ||
			user.user_id === this.userService.currentUser.user_id;
	};

	/**
	 * Whether user info may be blocked from further participation from the group
	 *
	 * @param user
	 * @param group
	 * @param role
	 * @returns {boolean}
	 */
	public mayDeactivate = (user, userGroup) => {
		// Can deactivate presenters as long as you have a higher role, and they are paid
		return user.role === this.Group.role.PRESENTER &&
			user.billing_entity_id != null &&
			userGroup.hasHigherRole(user.role, /*orEqualTo*/ false);
	};

	/**
	 * Whether user info may be removed from group
	 *
	 * @param user
	 * @param group
	 * @param role
	 * @returns {boolean}
	 */
	public mayRemove = (user, group, role) => {

		if (!user || !group) {
			return false;
		}

		if (this.userService.currentUser.is_root_owner) {
			return true;
		}

		// Can remove people with a lesser role than yourself
		// so long as they are not yourself and they are either
		// unpaid or the course is group pay
		return group.hasHigherRole(user.role, false) &&
			role !== this.Group.role.OWNER &&
			user.user_id !== this.userService.currentUser.user_id &&
			(user.billing_entity_id === null ||
			group.billing_party === 'group' ||
			user.role !== this.Group.role.PRESENTER);
	};

	/**
	 * Whether user has paid
	 *
	 * @returns {boolean}
	 */
	private hasUserPaid = () => {
		return this.$scope.user.billing_entity_id > 0;
	};
}
