import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { ORG_TYPE_OPTIONS } from 'go-modules/org-settings/org-settings.constants';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import { EMPTY, from, Observable, of, Subject } from 'rxjs';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import type { EmailAndPasswordData } from 'ngx/dashboard/src/interfaces/sign-up/email-and-password-data';
import { catchError, filter, first, ignoreElements, map, share, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import type { RequestValidationError } from 'ngx/go-modules/src/interfaces/request-validation-error';
import type { OrgCreateData } from 'ngx/dashboard/src/interfaces/sign-up/org-create-data';
import { States } from 'go-modules/enums/states.enum';
import type { SignupRequest } from 'ngx/dashboard/src/interfaces/sign-up/signup-request';
import type { EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { SignupUtil } from '../signup-util';
import type { CourseInvite, SsoData } from 'ngx/dashboard/src/components/signup/course-invite';
import type { AuthResponseSuccessDataInterface } from 'ngx/go-modules/src/services/auth/interfaces/auth-response-success.interface';
import type { StudentRegisterRequest } from 'ngx/go-modules/src/interfaces';
import type { StateService, StateOrName } from '@uirouter/angularjs';
import { SignupMode } from 'ngx/dashboard/src/components/signup/signup-mode';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';

export const NETWORK_LOST_TRANSLATE_KEY = 'sign-up-form-validation-connection-lost';
export const DUPLICATE_EMAIL_TRANSLATE_KEY = 'sign-up-form-validation-duplicate-user-email';
export const DUPLICATE_ORG_NAME_TRANSLATE_KEY = 'sign-up-form-validation-duplicate-org-name';
export const UNKNOWN_ERROR_TRANSLATE_KEY = 'sign-up-form-validation-unknown-issue';

export interface StateGoNamedParameters {
	to: Parameters<StateService['go']>[0];
	params?: Parameters<StateService['go']>[1];
	options?: Parameters<StateService['go']>[2];
}

@Component({
	selector: 'account-create',
	template: require('./account-create.component.html'),
	styles: [require('./account-create.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCreateComponent implements OnChanges, OnInit {
	@Input()
	public emailAndPasswordData: EmailAndPasswordData;
	@Input()
	public org: SignupOrg | OrgCreateData;
	@Input()
	public emailVerificationData: EmailVerificationResponse;
	@Input()
	public courseInvite: CourseInvite;
	@Input()
	public ssoData: SsoData;
	@Input()
	public infoKey: string|null;
	@Input()
	public mode: SignupMode;
	@Output()
	public onBack$ = new EventEmitter<States>();
	@Output()
	public onNavigate$ = new EventEmitter<StateGoNamedParameters | StateOrName>();
	@Output()
	public reloadOrgs$ = new EventEmitter<StateGoNamedParameters | StateOrName>();

	public form = new FormGroup({
		phoneNumber: new FormControl(null, []),
		useType: new FormControl(null),
		jobRole: new FormControl(null)
	});

	public SIGNUPMODES = SignupMode;
	public accountCreate$ = new Subject<void>();
	public useTypes$: Observable<UseType[]>;
	public submitting$: Observable<boolean> = of(false);
	public errorMessage$: Observable<string> = of(null);
	public jobRoles = [];

	constructor (
		private signUpService: SignUpService,
		private authService: NgxAuthService,
		private ngxGoToastService: NgxGoToastService
	) {}

	public get requiresAccountInfo (): boolean {
		if (this.mode === SignupMode.GUEST_REVIEW) return false;
		return SignupUtil.requiresAccountInfo(this.courseInvite);
	}

	public get firstName (): string {
		return this.infoKey ? this.form.value.firstName : this.emailAndPasswordData.firstName;
	}

	public get lastName (): string {
		return this.infoKey ? this.form.value.lastName : this.emailAndPasswordData.lastName;
	}

	private static errorHandler (err: HttpErrorResponse): string {
		if (err.status === 0) {
			return NETWORK_LOST_TRANSLATE_KEY;
		}
		if (err.status === 422) {
			const errors: RequestValidationError = err.error;
			if (errors.failed['user.email']?.includes('Unique')) {
				return DUPLICATE_EMAIL_TRANSLATE_KEY;
			}
			if (errors.failed['org.name']?.includes('Unique')) {
				return DUPLICATE_ORG_NAME_TRANSLATE_KEY;
			}
		}

		return UNKNOWN_ERROR_TRANSLATE_KEY;
	}

	private static isExistingOrg (org: SignupOrg | OrgCreateData): org is SignupOrg {
		return 'group_id' in org;
	}

	public ngOnInit (): void {
		// If using SSO provider
		if (this.infoKey) {
			this.form.addControl('firstName', new FormControl(this.ssoData.first_name, [Validators.required]));
			this.form.addControl('lastName', new FormControl(this.ssoData.last_name, [Validators.required]));
		}

		if (this.requiresAccountInfo) {
			this.useTypes$ = this.signUpService.getUseTypes();
			this.form.controls.useType.addValidators(Validators.required);
			this.form.controls.jobRole.addValidators(Validators.required);
		}

		if (this.mode === SignupMode.GUEST_REVIEW) {
			this.form.removeControl('phoneNumber');
			this.form.removeControl('useType');
			this.form.removeControl('jobRole');
		}

		// Create a pipe from the starting click to the ending redirect to dashboard
		this.accountCreate$.pipe(
			filter(() => this.form.valid),
			switchMap(() => {
				const createAccount$ = this.requiresAccountInfo ?
					this.signUpService.createAccount(this.getSignupRequestData()).pipe(share()) :
					this.createStudentAccount().pipe(share());
				// Do the http call and share the result (make the observer hot)

				// Create a pipe that only pays attention to errors and displays them
				this.errorMessage$ = createAccount$.pipe(
					ignoreElements(),
					catchError((err: HttpErrorResponse) => of(err)),
					map(AccountCreateComponent.errorHandler)
				);

				// In the case that the errorMessage is duplicate org, we need to reload the orgs
				this.errorMessage$.pipe(
					filter((msg) => msg === DUPLICATE_ORG_NAME_TRANSLATE_KEY)
				).subscribe(this.reloadOrgs$);

				// Create a pipe that handles while we are submitting.
				// Normally we'd set it to false once the request is finished, but we don't want
				// the user to be able to push the button again while the dashboard page is loading,
				// so we'll only set it back to false if there is an error.
				this.submitting$ = createAccount$.pipe(
					ignoreElements(),
					startWith(true),
					catchError(() => of(false))
				);

				// And return the pipe that ignores errors
				return createAccount$.pipe(
					catchError(() => EMPTY)
				);
			}),
			first(),
			map((): StateGoNamedParameters => {
				return this.mode === SignupMode.GUEST_REVIEW ? { to: 'SSOCOMPLETE' } : { to: States.DASHBOARD_ROOT };
			})
		).subscribe(this.onNavigate$);

		// If they go back clear any error messages
		this.onBack$.subscribe(() => {
			this.errorMessage$ = of(null);
		});
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if ('org' in changes && changes.org.currentValue != null) {
			const org: AccountCreateComponent['org'] = changes.org.currentValue;
			const type = AccountCreateComponent.isExistingOrg(org) ?
				org.org_type :
				org.orgSettings.org_type;

			this.jobRoles = ORG_TYPE_OPTIONS.find((option) => option.type === type).roles;
		}
	}

	public resolveError (errorKey) {
		switch (errorKey) {
			case NETWORK_LOST_TRANSLATE_KEY:
				// Do nothing
				return;
			case DUPLICATE_EMAIL_TRANSLATE_KEY:
				// They already exist, login
				this.onNavigate$.next(States.AUTH_LOGIN);
				return;
			case DUPLICATE_ORG_NAME_TRANSLATE_KEY:
				// Their org already exists, send them back to select it.
				this.onBack$.next(null);
				return;
			default:
				// We don't know what went wrong. Start over
				this.onNavigate$.next(States.AUTH_SIGNUP);
		}
	}

	public createStudentAccount (): Observable<AuthResponseSuccessDataInterface> {
		const data: StudentRegisterRequest = {
			first_name: this.firstName,
			last_name: this.lastName,
			course_code: this.courseInvite.course?.uuid,
			invite_uuid: this.courseInvite.invite?.uuid,
			accepted_terms_at: new Date()
		};

		data['info-key'] = this.infoKey;

		if(data['info-key']) {
			// close sso warning toast
			this.ngxGoToastService.closeToast();
		}

		return from(this.authService.registerStudent(data));
	}

	private getSignupRequestData (): SignupRequest {
		const commonUserData = {
			first_name: this.firstName,
			last_name: this.lastName,
			phone_number: this.form.controls.phoneNumber.value || null,
			accepted_terms_at: new Date()
		};

		const commonData = {
			org: AccountCreateComponent.isExistingOrg(this.org) ?
				{org_id: this.org.group_id} :
				this.org,
			account: {
				use_type_id: this.form.controls.useType.value.use_type_id
			},
			role: this.form.controls.jobRole.value
		};

		if (this.infoKey) {
			// close sso warning toast
			this.ngxGoToastService.closeToast();
			return {
				'info-key': this.infoKey,
				'user': commonUserData,
				...commonData
			};
		}

		return {
			user: {
				email: this.emailAndPasswordData.email,
				password: this.emailAndPasswordData.password,
				...commonUserData
			},
			email_signature: this.emailVerificationData.email_signature,
			...commonData
		};
	}
}
