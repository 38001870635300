export enum SettingsOptions {
	USER_DETAILS = 'details',
	EMAIL_NOTIFICATIONS = 'email',
	ACTIVITY_DEFAULTS = 'activity',
	BILLING_HISTORY = 'billing'
}

export const SettingsOptionsTranslations: Record<SettingsOptions, string> = {
	details: 'modal-edit-user_user-details',
	email: 'account-settings-panel_email-notifications',
	activity: 'account-settings-panel_assignment-defaults',
	billing: 'header_billing-history'
};
