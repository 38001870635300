import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardContainerModule } from 'ngx/go-modules/src/components/card-container/card-container.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { MatMenuModule } from '@angular/material/menu';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { NgxArchiveCardComponent } from './archive-card.component';
import { ThumbnailModule } from 'ngx/go-modules/src/components/thumbnail/thumbnail.module';
import { ArchiveMenuModule } from 'ngx/go-modules/src/components/archive/archive-menu/archive-menu.module';
import { EllipsisWithCountModule } from 'ngx/go-modules/src/components/ellipsis-with-count/ellipsis-with-count.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		CardContainerModule,
		GoMaterialModule,
		MatMenuModule,
		MenuModule,
		ThumbnailModule,
		ArchiveMenuModule,
		EllipsisWithCountModule
	],
	declarations: [
		NgxArchiveCardComponent
	],
	entryComponents: [
		NgxArchiveCardComponent
	],
	exports: [
		NgxArchiveCardComponent
	]
})
export class NgxArchiveCardModule {}
