import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { EditUserDetailsDialogComponent } from './edit-user-details-dialog.component';
import { UserSettingsModule } from 'ngx/go-modules/src/components/user-settings';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		UserSettingsModule,
		A11yModule
	],
	declarations: [
		EditUserDetailsDialogComponent
	],
	exports: [
		EditUserDetailsDialogComponent
	],
	entryComponents: [
		EditUserDetailsDialogComponent
	]
})
export class EditUserDetailsDialogModule {}
