import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PWAInstallPromptComponent } from './pwa-install-prompt.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PWAInstallPromptComponent
	],
	exports: [
		PWAInstallPromptComponent
	],
	entryComponents: [
		PWAInstallPromptComponent
	]
})
export class PWAInstallPromptModule {}
