import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * NGX directive that copies to the clipboard and displays the appropriate tooltips
 */
@Directive({
	selector: '[goCopyToClipboard]',
	providers: [MatTooltip]
})
export class CopyToClipboardDirective implements OnInit, OnDestroy {
	@Input('goCopyToClipboard') public copyText?: string | null;
	public destroyed$ = new Subject<void>();
	public hideTooltip$ = new Subject<void>();

	constructor (
		private clipboard: Clipboard,
		private tooltip: MatTooltip,
		private translate: TranslateService
	) {}

	public ngOnInit () {
		this.tooltip.position = 'above';
		this.tooltip.tooltipClass = 'clipboard-directive-tooltip';
	}

	public ngOnDestroy () {
		this.destroyed$.next();
		this.destroyed$.complete();
		this.hideTooltip$.complete();
		this.tooltip.hide();
	}

	@HostListener('click')
	public copyTextAndShowTooltip (): void {
		if (this.copyText == null) return;

		this.clipboard.copy(this.copyText);
		this.tooltip.message = this.translate.instant('copied-to-clipboard');
		this.tooltip.show();

		// Hide the tooltip after specific delay for mobile users
		// Also stop the timer if the tooltip was already hidden on mouse leave
		timer(3000)
			.pipe(takeUntil(this.destroyed$), takeUntil(this.hideTooltip$))
			.subscribe(() => {
				this.tooltip.hide();
			});
	}

	@HostListener('mouseleave')
	public hideTooltip (): void {
		this.hideTooltip$.next();
		this.tooltip.hide();
	}

	@HostListener('mouseenter')
	public showHoverTooltip (): void {
		this.tooltip.message = this.translate.instant('copy-to-clipboard');
		this.tooltip.show();
	}
}
