import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { CsvReportService, ReportConfig, ReportResponse } from '../csv-report.service';

export class ActivityRubricReportService extends CsvReportService {
	public static readonly NG1_INJECTION_NAME = 'ActivityRubricReportService' as const;
	/* @ngInject */
	constructor ($window: ng.IWindowService, private ActivityModel: any) {
	  super($window);
	}

	public async requestCsvData (config: ReportConfig): Promise<ReportResponse> {
	  return await this.ActivityModel.getRubricReport(config).$promise;
	}

	public async execute (activity: any, reportType: string): Promise<void> {
		const timezone = this.getUserTimezone();

		const { filename, rows } = await this.requestCsvData({
			activity_id: activity.activity_id,
			report_type: reportType,
			timezone
		});

		const output = this.processReport(rows);
		const data = this.generateBlob(output);
		this.downloadCsv(data, filename);
	}
}

export const activityRubricReportServiceToken = upgradeNg1Dependency(ActivityRubricReportService);
