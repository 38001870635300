import * as angular from 'angular';
import { detectModule } from 'go-modules/detect';
import { responsiveViewModule } from 'go-modules/responsive-view';
import { featureFlagModule } from 'go-modules/feature-flag';
import { ZendeskService } from './zendesk.service';

export const zendeskModule = angular.module('ZendeskModule', [
	detectModule,
	responsiveViewModule,
	featureFlagModule
])
	.service(ZendeskService.NG1_INJECTION_NAME, ZendeskService)
	.name;
