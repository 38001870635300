import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { States } from 'go-modules/enums/states.enum';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import type { ActivityFactory } from 'go-modules/models/activity/activity.factory';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import {
	LibraryCollectionViewerModal,
	libraryCollectionViewerModalToken
} from 'go-modules/modals/library-collection-viewer/modal.service';
import { TranslatePipe } from '@ngx-translate/core';
import {
	ActivityEditorPanel,
	activityEditorPanelToken
} from 'go-modules/activity-editor-panel/activity-editor-panel.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { VIEWS } from 'ngx/go-modules/src/enums/views';
import { BehaviorSubject, Observable, Subject, combineLatest, take, tap, delay, merge } from 'rxjs';
import { distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';
import { ViewStateDataSource } from 'ngx/go-modules/src/services/view-state-datasource/view-state.datasource';
import { SortOrder, SortOrderTranslations } from 'ngx/go-modules/src/enums/sort-order-main';
import { AvailabilityFilter, AvailabilityFilterTranslations } from 'ngx/go-modules/src/enums/availability-filter';
import { FormControl, FormGroup } from '@angular/forms';
import {
	ZeroStateChangedEvent
} from 'ngx/go-modules/src/components/data-source-zero-state/data-source-zero-state.component';
import {
	LicenseExpirationHandler
} from 'ngx/go-modules/src/services/license-expiration-handler/license-expiration-handler.service';
import type { License } from 'ngx/go-modules/src/interfaces/licenses';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { targetElems } from 'ngx/go-modules/src/enums/context-menu-target-elems';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import {
	ActivityReorderComponent
} from 'ngx/go-modules/src/components/sidepanels/activity-reorder/activity-reorder.component';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { CustomSortTourService } from 'ngx/go-modules/src/tours/sort-activities-tour/custom-sort-tour.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { CopyActivitiesDialogComponent } from 'ngx/go-modules/src/components/dialogs/copy-activities-dialog/copy-activities-dialog.component';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { PromptsService } from 'ngx/go-modules/src/services/prompts/prompts.service';
import { ACTIVITY_SOURCES, ActivitySourceDialogComponent } from '../dialogs/activity-source-dialog/activity-source-dialog.component';

@Component({
	selector: 'ngx-folder-view',
	template: require('./folder-view.component.html'),
	styles: [require('./folder-view.component.scss')],
	providers: [TranslatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxFolderViewComponent implements OnInit, OnDestroy {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('folderContainer', {read: ElementRef}) public folderContainer: ElementRef;
	public readonly views = VIEWS;
	public currentView$: Observable<VIEWS>;
	public dataSourceZeroState: ZeroStateChangedEvent = {isLoading: true, hasZeroState: false, errorCode: null };
	public isCreating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public filterOptions: AvailabilityFilter[] = Object.values(AvailabilityFilter);
	public filterTranslations = AvailabilityFilterTranslations;
	public sortByOptions: SortOrder[] = Object.values(SortOrder);
	public sortOrder = SortOrder;
	public sortOrderTranslations = SortOrderTranslations;
	public hasActivities$: Observable<boolean>;
	public shouldShowFooterButtons$: Observable<boolean>;
	public form: FormGroup;
	public componentDestroyed$$ = new Subject();
	public canCreateActivity$$ = new BehaviorSubject<boolean>(false);
	public getActivityCreationDisabledMessage$$ = new BehaviorSubject<string>('');
	public canReorderActivities$: Observable<boolean>;
	public showReorderActivities$: Observable<boolean>;
	public environmentVarsService: EnvironmentVarsService;

	private tourDismissed$ = new Subject<void>();
	private hasFinishedSetup$ = new BehaviorSubject<boolean>(false);

	constructor (
		private ngxActivityService: NgxActivityService,
		private translatePipe: TranslatePipe,
		private licenseExpirationHandler: LicenseExpirationHandler,
		public activityListDataSource: ActivityListDataSource,
		public viewStateDataSource: ViewStateDataSource,
		public ngxGoToastService: NgxGoToastService,
		public cdr: ChangeDetectorRef,
		private ngxGoSidepanelService: GoSidepanelService,
		private customSortTourService: CustomSortTourService,
		private ngxUserService: NgxUserService,
		private modal: GoModalService,
		private ngxGroupService: NgxGroupService,
		private promptsService: PromptsService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject($stateToken) private $state,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService,
		@Inject(activityToken) private Activity: ActivityFactory,
		@Inject(libraryCollectionViewerModalToken) private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		@Inject(activityEditorPanelToken) private activityEditorPanel: ActivityEditorPanel
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		this.getLicenseAndValidate();

		this.currentView$ = this.viewStateDataSource.viewState$;
		this.hasActivities$ = this.activityListDataSource.connect()
			.pipe(map((activities) => activities.length > 0));
		this.shouldShowFooterButtons$ = combineLatest({
			hasActivities: this.hasActivities$,
			isFiltering: this.activityListDataSource.isFiltering$
		}).pipe(
			map(({hasActivities, isFiltering}) => {
				return (hasActivities || isFiltering) && this.hasInstructorRoleOrAbove();
			})
		);
		this.canReorderActivities$ = this.activityListDataSource.connect()
			.pipe(map((activities) => this.hasInstructorRoleOrAbove() && activities.length >= 2));
		this.showReorderActivities$ = this.canReorderActivities$.pipe(
			map((canReorderActivities) =>
				canReorderActivities && this.activityListDataSource.getCurrentSort() === this.sortOrder.DEFAULT
			)
		);

		// only COURSE_LEVEL lti can see folder-view
		// so if LTI then prompt to copy activities
		// if instructor and no current activities
		if (this.isLti() && this.hasInstructorRoleOrAbove()) {
			combineLatest({
				hasActivities: this.hasActivities$,
				isFiltering: this.activityListDataSource.isFiltering$
			})
				.pipe(
					first(),
					filter(({hasActivities, isFiltering}) => {
						const doesRequireSetup = !hasActivities && !isFiltering;

						if (!doesRequireSetup) {
							this.hasFinishedSetup$.next(true);
						}
						return doesRequireSetup;
					})
				)
				.subscribe(() => {
					this.promptToCopyActivities();
				});
		} else {
			this.hasFinishedSetup$.next(true);
		}
		this.setupSortTourListener();
		this.setupForm();


		this.hasFinishedSetup$.pipe(
			filter((hasFinishedSetup) => hasFinishedSetup),
			take(1),
			takeUntil(this.componentDestroyed$$)
		).subscribe(() => {
			this.promptsService.showTermsOrTourWhenRequired();
		});
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.canCreateActivity$$.complete();
		this.getActivityCreationDisabledMessage$$.complete();
	}

	public isLti (): boolean {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public setupForm () {
		this.form = new FormGroup({
			sortBy: new FormControl(this.activityListDataSource.getCurrentSort()),
			filterBy: new FormControl(this.activityListDataSource.getCurrentFilter())
		});

		// Observe datasource filter changed
		this.activityListDataSource.availabilityFilter$$.asObservable()
			.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$$))
			.subscribe((_filter) => {
				this.form.patchValue({filterBy: _filter});
				this.cdr.detectChanges();
			});
		// Observe datasource sort changed
		this.activityListDataSource.sortBy$$.asObservable()
			.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$$))
			.subscribe((_sortBy) => {
				this.form.patchValue({sortBy: _sortBy});
				this.cdr.detectChanges();
			});

		// Observe Changes
		this.form.get('sortBy').valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.activityListDataSource.sortBy(selected));
		this.form.get('filterBy').valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$$))
			.subscribe((selected) => this.activityListDataSource.setAvailabilityFilter(selected));
	}

	public goToActivity (activity) {
		this.selectedService.setActivity(this.Activity.model(activity));
		this.$state.go(States.DASHBOARD_ACTIVITY_VIEW, {activity_id: activity.activity_id});
	}

	private validateCanCreateActivity () {
		this.canCreateActivity$$.next(this.ngxActivityService.canCreateActivity());
		this.getActivityCreationDisabledMessage$$.next(this.ngxActivityService.getActivityCreationDisabledMessage());

		const license = this.selectedService.getLicense();
		if (license) {
			// TODO STAB-489 Consolidate ngx/ng1 License interfaces since we're already using them interchangeably
			this.licenseExpirationHandler.displayLicenseExpirationWarningToastIfNeeded(license as License);
		}
	}

	private setupSortTourListener () {
		this.showReorderActivities$
			.pipe(
				filter((show) => show === true),
				take(1),
				tap(() => this.customSortTourService.getTour().start(()=> {
					this.tourDismissed$.next();
				})),
				delay(10000),
				takeUntil(merge(this.tourDismissed$, this.componentDestroyed$$)),
				tap(() => {
					this.customSortTourService.getTour().end();
				})
			)
			.subscribe();
	}

	private getLicenseAndValidate () {
		this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.componentDestroyed$$),
				filter((selected: any) => selected.group)
			)
			.subscribe(() => {
				this.validateCanCreateActivity();
			});
	}

	public hasInstructorRoleOrAbove (): boolean {
		return this.selectedService.getGroup().hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public openInviteModal () {
		return this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.selectedService.getGroup()
			}
		});
	}

	public openAssignmentSourceModal () {
		this.isCreating$.next(true);
		this.modal.open(ActivitySourceDialogComponent, true)
			.afterClosed().subscribe((source: ACTIVITY_SOURCES | undefined) => {
				if (source === ACTIVITY_SOURCES.NEW) {
					this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ASSIGNMENT_SOURCE_NEW, {});
					this.ngxActivityService.getDefault(this.selectedService.getGroup().group_id)
						.subscribe((defaultActivity) => {
							const activity = this.Activity.useAsTemplate(this.Activity.model(defaultActivity));
							activity.populateEmptyActivityOnGroup(this.selectedService.getGroup());
							activity.is_default = true;
							this.openActivityEditor(activity);
						});
				} else if (source === ACTIVITY_SOURCES.LIBRARY) {
					this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_ASSIGNMENT_SOURCE_LIBRARY, {});
					this.libraryCollectionViewerModal.open({
						modalData:{
							filterType: [CONTENT_TYPES.ACTIVITIES],
							mode: MODES.SELECT,
							collectionManagement: true
						}
					}).result.then((item) => {
						this.ngxActivityService.get(item.id)
							.subscribe((activity) => {
								activity.group_id = this.selectedService.getGroup().group_id;
								const newActivity = this.Activity.model({...activity, activity_id: null});
								newActivity.parent_id = activity.activity_id;
								newActivity.name = this.translatePipe.transform('group-view_copy-in-parenthesis', {activity_name: activity.name});
								newActivity.available_at = null;
								newActivity.due_at = null;
								newActivity.is_duplicate = true;
								newActivity.usage = 0;
								this.openActivityEditor(newActivity);
							});
					}).catch(() => this.isCreating$.next(false));
				} else {
					this.isCreating$.next(false);
				}
			});
	}

	public sortActivities () {
		this.ngxGoSidepanelService.open(ActivityReorderComponent, {
			title: this.translatePipe.transform('activity-reorder-panel_title'),
			noFooter: true
		});
	}

	public openActivityEditor (activity: any) {
		this.activityEditorPanel.open({
			user: this.userService.currentUser,
			group: this.selectedService.getGroup(),
			activity,
			orgSettings: this.selectedService.getOrg().org_settings,
			firstFocusSelector: '#activity-editor-name-input',
			headerOptions: {
				editMode: false
			}
		}).result.then(() => {
			this.isCreating$.next(false);
			activity.owner_name = this.userService.currentUser.full_name;
			this.activityListDataSource.addActivity(activity);
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'folder-view_activity_added'
			});
		}).catch(() => this.isCreating$.next(false));
	}

	public zeroStateChanged (dataSourceZeroState: ZeroStateChangedEvent) {
		this.dataSourceZeroState = dataSourceZeroState;
	}

	public openMenu (event: MouseEvent) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (this.hasInstructorRoleOrAbove() && this.canCreateActivity$$) {
			const target = event.target as any;
			if (targetElems.includes(target.className)) {
				this.menuComponent.openMenu(event, this.folderContainer.nativeElement);
			}
		}
	}

	private promptToCopyActivities () {
		this.ngxUserService.getUserGroups(this.userService.currentUser.user_id).subscribe((groups) => {
			const excludingCurrentGroup = groups.filter((group) => {
				return group.group_id !== this.selectedService.getGroup().group_id;
			});
			if (excludingCurrentGroup.length) {
				this.openCopyActivityModal(excludingCurrentGroup);
			} else {
				this.hasFinishedSetup$.next(true);
			}
		});
	}

	private openCopyActivityModal (availableFolders) {
		this.modal.open(CopyActivitiesDialogComponent, true, {
			data: {availableFolders}
		}).afterClosed().subscribe((copyFrom: number | null) => {
			if (copyFrom) {
				this.ngxGroupService.copyActivities(
					copyFrom,
					this.selectedService.getGroup().group_id
				).subscribe({
					next: () => {
						this.activityListDataSource.reload();
						this.hasFinishedSetup$.next(true);
					},
					error: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'folder-view_failed-to-copy'
						});
					}
				});
			} else {
				this.hasFinishedSetup$.next(true);
			}
		});
	}
}
