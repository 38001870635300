import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ActivityInstructionsEditorModal } from './modal';
import type { ModalOptions } from './options';
import { TranslationKeys } from './translation-keys';
import { ActivityInstructionModel } from '../../models/activity';

export class ActivityFeedbackInstructionsEditorModal extends ActivityInstructionsEditorModal {

	public open (options: ModalOptions): IModalInstanceService {
		return super.open({
			windowClass: 'feedback-instructions-modal',
			modalData: {
				instructionsType: ActivityInstructionModel.TYPE.FEEDBACK,
				modalOptions: options,
				translationKeys: {
					modalTitle: 'activity-instructions-editor-modal_feedback-instructions-title',
					textInstructionsHeading: 'activity-instructions-editor-modal_text-instructions-heading',
					mediaInstructionsHeading: 'activity-editor-instructions_video-instructions'
				} as TranslationKeys
			}
		});
	}
}
