import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { HttpClientModule } from '@angular/common/http';
import { GoModulesModule } from 'ngx/go-modules/src/go-modules.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { PWAInstallPromptModule } from './components/pwa-install-prompt/pwa-install-prompt.module';
import { NgxSessionViewModule } from 'ngx/go-modules/src/components/feedback-session/session-view/session-view.module';
import { SignupModule } from 'ngx/dashboard/src/components/signup/signup.module';
import { DashboardModule as goDashboard } from 'ngx/go-modules/src/components/dashboard/dashboard.module';
import { NgxFoldersViewModule } from 'ngx/dashboard/src/components/folders-view/folders-view.module';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		TranslateModule,
		GoMaterialModule,
		GoModulesModule,
		GoTooltipModule,
		NgxSessionViewModule,
		PWAInstallPromptModule,
		SignupModule,
		goDashboard,
		NgxFoldersViewModule
	]
})
export class DashboardModule {}
