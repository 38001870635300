import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { AccountMenuComponent } from './account-menu.component';
import { AdminPanelModule } from 'ngx/go-modules/src/components/sidepanels/admin-panel/admin-panel.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		GoToastModule,
		AdminPanelModule
	],
	declarations: [
		AccountMenuComponent
	],
	entryComponents: [
		AccountMenuComponent
	],
	exports: [
		AccountMenuComponent
	]
})
export class AccountMenuModule {}
