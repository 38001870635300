import { Component, DoCheck, ViewChild, ViewContainerRef } from '@angular/core';
import { CdkVirtualForContextOverride } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/override/cdk-virtual-for-of-context-override';


@Component({
	selector: 'grid-virtual-scroll-row',
	template: require('./grid-virtual-scroll-row.component.html'),
	styles: [require('./grid-virtual-scroll-row.component.scss')]
})
export class GridVirtualScrollRowComponent implements DoCheck {
	@ViewChild('vc', {read: ViewContainerRef}) public viewContrainerRef: ViewContainerRef;
	public columnCount = 0;
	public columnMinWidth = 0;
	public columnMaxWidth = 0;
	public gap = 0;

	constructor () {
	}

	public ngDoCheck (): void {
		if(!this.viewContrainerRef) {
			return;
		}

		this.columnCount = this.context.columnCount;
		this.columnMinWidth = this.context.columnMinWidth;
		this.columnMaxWidth = this.context.columnMaxWidth;
		this.gap = this.context.gap;
	}

	/**There is an issue when injecting a viewContainerRef
	 * when you are using a forOf
	 * there is a possibly rendering this component will be delayed
	 * so we need to access the viewContainerRef from the child viewContainerRef
	 */
	public get context () {
		return (this.viewContrainerRef as any)._view.parent.context as CdkVirtualForContextOverride;
	}
}
