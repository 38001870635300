import template from './panel.component.html';
import './style.less';

export default {
	bindings: {
		attachments: '<',
		onChange: '&'
	},
	require: {
		activityEditorController: '^activityEditor'
	},
	template
} as ng.IComponentOptions;
