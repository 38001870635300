import { NgModule } from '@angular/core';
import { TrackByPropPipe } from './track-by-prop.pipe';

@NgModule({
	declarations: [
		TrackByPropPipe
	],
	exports: [
		TrackByPropPipe
	]
})
export class TrackByPropPipeModule {}
