import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SignupComponent } from 'ngx/dashboard/src/components/signup/signup.component';
import { EmailAndPasswordFormComponent } from 'ngx/dashboard/src/components/signup/email-and-password-form/email-and-password-form.component';
import { LTIConfirmationComponent } from 'ngx/dashboard/src/components/signup/lti-confirmation/lti-confirmation.component';
import { OrgSearchComponent } from 'ngx/dashboard/src/components/signup/org-search/org-search.component';
import { OrgCreateComponent } from 'ngx/dashboard/src/components/signup/org-create/org-create.component';
import { AccountCreateComponent } from 'ngx/dashboard/src/components/signup/account-create/account-create.component';
import { OrgInviteComponent } from 'ngx/dashboard/src/components/signup/org-invite/org-invite.component';
import { StudentSignupMessageComponent } from 'ngx/dashboard/src/components/signup/student-signup-message/student-signup-message.component';
import { GoStepperModule } from 'ngx/dashboard/src/components/go-stepper/go-stepper.module';
import { EmailVerificationModule } from 'ngx/go-modules/src/components/email-verification/email-verification.module';
import { AuthTemplateModule } from 'ngx/go-modules/src/components/auth-template/auth-template.module';
import { GoLogoModule } from 'ngx/dashboard/src/components/go-logo/go-logo.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { TrackByPropPipeModule } from 'ngx/go-modules/src/pipes/track-by-prop/track-by-prop-pipe.module';
import { UserTypeSelectionDialogModule } from 'ngx/go-modules/src/components/dialogs/user-type-selection-dialog/user-type-selection-dialog.module';
import { SsoProviderButtonModule } from 'ngx/go-modules/src/components/sso-provider-button/sso-provider-button.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		GoMaterialModule,
		GoStepperModule,
		GoTooltipModule,
		FocusOnFirstInvalidFieldModule,
		EmailVerificationModule,
		AuthTemplateModule,
		TrackByPropPipeModule,
		GoLogoModule,
		SsoProviderButtonModule,
		UserTypeSelectionDialogModule
	],
	declarations: [
		SignupComponent,
		EmailAndPasswordFormComponent,
		LTIConfirmationComponent,
		OrgSearchComponent,
		OrgCreateComponent,
		OrgInviteComponent,
		AccountCreateComponent,
		StudentSignupMessageComponent
	],
	entryComponents: [
		SignupComponent,
		EmailAndPasswordFormComponent,
		LTIConfirmationComponent,
		OrgSearchComponent,
		OrgCreateComponent,
		OrgInviteComponent,
		AccountCreateComponent,
		StudentSignupMessageComponent
	],
	exports: [
		SignupComponent,
		EmailAndPasswordFormComponent,
		LTIConfirmationComponent,
		OrgSearchComponent,
		OrgCreateComponent,
		OrgInviteComponent,
		AccountCreateComponent,
		StudentSignupMessageComponent
	]
})
export class SignupModule {}
