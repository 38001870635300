import { States } from 'go-modules/enums/states.enum';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';
import type { CourseIdValidationDataInterface } from 'ngx/dashboard/src/interfaces/auth-response-course-id.interface';
import type { InviteValidation } from 'ngx/dashboard/src/interfaces/invite-validation.interface';
import type { StateService } from '@uirouter/angularjs';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { GroupType } from 'ngx/go-modules/src/enums/group-type';

export class GroupConfirmController {
	public group: CourseIdValidationDataInterface;
	public invite: InviteValidation;
	public loading = false;
	public error = false;
	public showConfirmation = false;

	/* @ngInject */
	constructor (
		private authService: AuthService,
		private $stateParams,
		private sessionManager: SessionManagerService,
		private $state: StateService,
		private $log
	) {
	}

	public get headerTranslationKey () {
		const keys = {
			course: 'sign-up-heading-course-confirmation',
			org: 'sign-up-heading-org-confirmation',
			account: 'sign-up-heading-account-confirmation'
		};

		return keys[this.group.group_type];
	}

	public get subHeaderTranslationKey () {
		const keys = {
			course: 'sign-up-sub-heading-course-confirmation',
			org: 'sign-up-sub-heading-org-confirmation',
			account: 'sign-up-sub-heading-account-confirmation'
		};

		return keys[this.group.group_type];
	}

	public get getGroupTypeNameTranslation () {
		const keys = {
			course: 'sign-up-confirmation-course-label',
			org: 'sign-up-confirmation-org-label',
			account: 'sign-up-confirmation-account-label'
		};

		return keys[this.group.group_type];
	}

	public get isCourseInvite () {
		return this.group.group_type === GroupType.COURSE;
	}

	public $onInit () {
		if (this.$stateParams.invite || this.$stateParams.course) {
			this.handleInvite();
		}
	}

	public handleInvite () {
		this.loading = true;
		if (this.$stateParams.invite) {
			this.authService.validateInvite(this.$stateParams.invite)
				.then((response) => {
					this.invite = response.data;
					this.validateGroup(this.invite.group_uuid);
				}).catch((error) => {
					this.loading = false;
					this.error = true;
					this.$log.error(error);
				});
		} else if (this.$stateParams.course) {
			this.validateGroup(this.$stateParams.course);
		}
	}

	public validateGroup (uuid: string) {
		this.authService.validateGroupCode(uuid)
			.then((response) => {
				this.group = response.data;
				this.showConfirmation = true;
				this.loading = false;
			}).catch((error) => {
				this.error = true;
				this.loading = false;
				this.$log.error(error);
			});
	}

	/**
	 * Joins user to a course when user is already logged in.
	 *
	 * @returns {void}
	 */
	public confirmGroup () {
		if (this.sessionManager.isActive()) {
			this.authService.getCurrentUser().then((result) => {
				// checks if user and invite match before adding logged in user to course
				if (this.$stateParams.invite && (result.data.email !== this.invite.email)) {
					this.error = true;
					this.showConfirmation = false;
					return;
				}

				const options = {
					inviteId: this.$stateParams.invite || this.$stateParams.course,
					redirect: this.$stateParams.redirect,
					groupId: this.group.group_id
				};

				this.authService.setUpUser(result.data, options)
					.then((state) => {
						if (state) {
							this.$state.go(state.name, state.params, state.options);
						}
					}).catch(() => {
						this.error = true;
						this.showConfirmation = false;
					});
			});
		} else {
			const params = {
				courseInfo: this.group,
				inviteInfo: this.invite
			};
			this.$state.go(States.AUTH_SIGNUP, params, { location: false });
		}
	}

	/**
	 * Navigates to login page.
	 *
	 * @returns {void}
	 */
	public toLogin (): void {
		if(this.sessionManager.isActive()) {
			this.$state.go(States.DASHBOARD_ROOT);
		} else {
			this.$state.go(States.AUTH_LOGIN, null, { inherit: false });
		}
	}
}
