import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	Output,
	Input,
	ViewChild,
	OnInit
} from '@angular/core';
import { GoStepperComponent } from 'ngx/dashboard/src/components/go-stepper/go-stepper.component';
import type { EmailAndPasswordData } from 'ngx/dashboard/src/interfaces/sign-up/email-and-password-data';
import { SignupStepsState } from './sign-up-steps-state';
import type { AllowedOrg, EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import type { OrgCreateData } from 'ngx/dashboard/src/interfaces/sign-up/org-create-data';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import { SignupUtil } from './signup-util';
import type { CourseInvite, SsoData } from 'ngx/dashboard/src/components/signup/course-invite';
import type { StateGoNamedParameters } from './account-create/account-create.component';
import { SignupMode } from 'ngx/dashboard/src/components/signup/signup-mode';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import type { StateOrName } from '@uirouter/angularjs';
import angular from 'angular';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { States } from 'go-modules/enums/states.enum';
import { StudentRegisterRequest } from 'ngx/go-modules/src/interfaces';

export const BACKEND_MESSAGE_EMAIL_IN_USE = 'The email has already been taken.';

@Component({
	selector: 'signup-view-instructor',
	template: require('./signup.component.html'),
	styles: [require('./signup.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent implements OnInit, OnDestroy {
	@ViewChild('goStepper', {static: true})
	public stepper: GoStepperComponent;

	@Output()
	public navigated = new EventEmitter<StateGoNamedParameters | StateOrName>();

	@Input()
	public courseInvite: CourseInvite;

	@Input()
	public email: string|null;

	@Input()
	public infoKey: string|null;

	@Input()
	public mode: SignupMode;

	public ssoData: SsoData;
	public emailAndPasswordData?: EmailAndPasswordData;
	public emailVerificationData?: EmailVerificationResponse;
	public org: SignupOrg | OrgCreateData;
	public signupStepsState = SignupStepsState;
	public searchTerm: string = '';
	public allOrgs: SignupOrg[] = [];
	public stepTrace = [];
	public allowedOrgs: AllowedOrg[] = [];
	public signUpProFlag: boolean = false;

	public SIGNUPMODES = SignupMode;

	private componentDestroyed: Subject<boolean> = new Subject();

	constructor (
		private cdr: ChangeDetectorRef,
		private signUpService: SignUpService,
		private authService: NgxAuthService,
		private ngxGoToastService: NgxGoToastService
	) {}

	public ngOnInit () {
		if (this.infoKey) {
			this.signUpService.getSignupInfo(this.infoKey).subscribe({
				next: (signupInfo) => {
					this.ssoData = signupInfo;
					this.courseInvite = signupInfo.courseInvite;
					this.allowedOrgs = signupInfo.allowed_orgs;
					if (this.requiresAccountInfo && this.mode !== SignupMode.GUEST_REVIEW) {
						this.loadOrgs();
						this.changeStepperStep(SignupStepsState.ORG_SEARCH);
					} else {
						this.changeStepperStep(SignupStepsState.ACCOUNT_CREATE);
					}

					this.ngxGoToastService.createToast({
						type: GoToastStatusType.WARNING,
						message: 'sso_link-account-warning',
						hideToastTime: 0
					});
				},
				// TODO: add error handling
				error: () => angular.noop
			});
		}
	}

	public get isExistingOrg () {
		return this.org && 'group_id' in this.org;
	}

	public get currentStep () {
		return this.stepper.selectedIndex;
	}

	public get requiresAccountInfo (): boolean {
		return SignupUtil.requiresAccountInfo(this.courseInvite);
	}

	public get skipEmailVerification (): boolean {
		return !this.requiresAccountInfo && !!this.courseInvite.invite?.email_signature;
	}

	public get isStandardOrGuestReview () {
		return this.mode === SignupMode.STANDARD || this.mode === SignupMode.GUEST_REVIEW;
	}

	public next () {
		this.stepper.selectedIndex = SignupStepsState.EMAIL_VERIFICATION;
		this.cdr.detectChanges();
	}

	public navigate (location: StateGoNamedParameters | StateOrName): void {
		this.navigated.emit(location);
	}

	public changeStepperStep (state: SignupStepsState) {
		this.stepTrace.push(this.stepper.selectedIndex);
		this.stepper.selectedIndex = state;
	}

	public goBack () {
		this.stepper.selectedIndex = this.stepTrace.pop();
	}

	public emailAndPasswordFormComplete (data: EmailAndPasswordData) {
		this.emailAndPasswordData = {...this.emailAndPasswordData, ...data};

		if (this.skipEmailVerification && this.mode === SignupMode.EDUCATION) {
			this.handleUserCreation();
		} else {
			this.changeStepperStep(SignupStepsState.EMAIL_VERIFICATION);
		}

		this.cdr.detectChanges();

		// don`t load if student/reviewer signing-up
		if (this.requiresAccountInfo && this.mode === SignupMode.EDUCATION) {
			// Load up the orgs in the background while email verification is ocurring
			this.loadOrgs();
		}
	}

	public loadOrgs () {
		this.signUpService.getAllOrgs()
			.pipe(take(1))
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe((allOrgs) => {
				this.allOrgs = allOrgs;
				this.cdr.detectChanges();
			});
	}

	public navigateToStudentStep () {
		this.changeStepperStep(SignupStepsState.STUDENT_SIGNUP_MESSAGE);
	}

	public navigateToEmailAndPasswordStep () {
		this.changeStepperStep(SignupStepsState.EMAIL_AND_PASSWORD);
	}

	public emailVerificationComplete (data: EmailVerificationResponse) {
		this.emailVerificationData = data;
		this.allowedOrgs = data.allowed_orgs;
		// Handle standard signup, no more steps
		// Skip other steps if user was invited to the course/group with Reviewer/Presenter Role
		if (this.isStandardOrGuestReview || !this.requiresAccountInfo) {
			return this.handleUserCreation();
		}

		if (data.in_lti_org_allowed_domain) {
			this.changeStepperStep(SignupStepsState.LTI_CONFIRMATION);
		} else if (data.invited_orgs.length === 1) {
			this.org = this.emailVerificationData.invited_orgs[0];
			this.changeStepperStep(SignupStepsState.ACCOUNT_CREATE);
		} else {
			this.changeStepperStep(SignupStepsState.ORG_SEARCH);
		}

		this.cdr.detectChanges();
	}

	public onLTIVerficationProceed () {
		if (this.emailVerificationData.invited_orgs.length === 1) {
			this.org = this.emailVerificationData.invited_orgs[0];
			this.changeStepperStep(SignupStepsState.ACCOUNT_CREATE);
		} else {
			this.changeStepperStep(SignupStepsState.ORG_SEARCH);
		}
		this.cdr.detectChanges();
	}

	public inviteUsers (data: OrgCreateData) {
		this.org = data;
		this.changeStepperStep(SignupStepsState.ORG_INVITE);
		this.cdr.detectChanges();
	}

	public orgCreateComplete (data: OrgCreateData) {
		this.org = data;
		this.changeStepperStep(SignupStepsState.ACCOUNT_CREATE);
		this.cdr.detectChanges();
	}

	public orgSelectionComplete (selectedOrg: SignupOrg) {
		this.org = selectedOrg;
		this.changeStepperStep(SignupStepsState.ACCOUNT_CREATE);
		this.cdr.detectChanges();
	}

	public createOrg ($event) {
		this.searchTerm = $event.searchTerm;
		this.changeStepperStep(SignupStepsState.ORG_CREATE);
		this.cdr.detectChanges();
	}

	public loadAccountCreate () {
		if (this.infoKey) {
			return this.ssoData;
		}
		return true;
	}

	public ngOnDestroy (): void {
		this.componentDestroyed.next(true);
		this.componentDestroyed.complete();
	}

	private handleUserCreation () {
		const data: StudentRegisterRequest = {
			first_name: this.emailAndPasswordData.firstName,
			last_name: this.emailAndPasswordData.lastName,
			course_code: this.courseInvite?.course?.uuid,
			invite_uuid: this.courseInvite?.invite?.uuid,
			email: this.emailAndPasswordData.email,
			password: this.emailAndPasswordData.password,
			email_signature: this.courseInvite?.invite?.email_signature,
			accepted_terms_at: new Date()
		};

		this.authService.registerStudent(data).then(() => {
			const navigationData: StateGoNamedParameters = {
				to: this.isStandardOrGuestReview ? 'STANDARDCOMPLETE' : States.DASHBOARD_ROOT
			};

			if(this.isStandardOrGuestReview) {
				navigationData.params = {
					email: data.email,
					password: data.password
				};
			}

			this.navigate(navigationData);
			this.cdr.detectChanges();
		}).catch((e) => {
			if (e.error.message === BACKEND_MESSAGE_EMAIL_IN_USE) {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'sign-up-error-email-in-use'
				});
			} else {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'sign-up-error-other'
				});
			}
		});
	}
}
