import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import * as dayjs from 'dayjs';

@Component({
	selector: 'customer-survey-snack-bar',
	template: require('./customer-survey-snack-bar.component.html'),
	styles: [require('./customer-survey-snack-bar.component.scss')]
})
export class CustomerSurveySnackBarComponent implements AfterViewInit {
	public static readonly localStorageKey = 'customer-survey';
	@ViewChild('firstFocusableElement') public firstFocusableElement!: ElementRef;
	public form = new FormGroup({
		rating: new FormControl(null, [Validators.required]),
		feedback: new FormControl('')
	});
	public hoveredRating: number = null;

	constructor (
		private ngxUserService: NgxUserService,
		private snackbarRef: MatSnackBarRef<CustomerSurveySnackBarComponent>,
		private ngxGoToastService: NgxGoToastService,
		private snackbar: MatSnackBar,
		@Inject(userServiceToken) private userService: UserService
	) {}

	public static open (snackBar: MatSnackBar) {
		return snackBar.openFromComponent(
			CustomerSurveySnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end'
			}
		);
	}

	public static canShow (userId) {
		// TODO - remove oldKey and logic looking at old key after October 5, 2024
		const oldKey = localStorage.getItem(CustomerSurveySnackBarComponent.localStorageKey);
		const newKey = localStorage.getItem(`${userId}-` + CustomerSurveySnackBarComponent.localStorageKey);

		// Always show if no cached customer survey
		if (!oldKey && !newKey) {
			return true;
		}

		let nextShowDate;
		if (newKey) {
			nextShowDate = dayjs(newKey);
		} else {
			// oldKey was set as the date it was last shown so we need to add 90 days to that
			nextShowDate = dayjs(oldKey).add(90, 'day');
		}
		const currentDate = dayjs();
		return currentDate.isAfter(nextShowDate);
	}

	public ngAfterViewInit (): void {
		this.snackbarRef.afterOpened().subscribe(() => {
			this.firstFocusableElement.nativeElement.focus();
		});
	}

	public close (snoozed?: boolean) {
		const nextShowDate = snoozed ? dayjs().add(24, 'hour').toString() : dayjs().add(90, 'day').toString();
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + CustomerSurveySnackBarComponent.localStorageKey, nextShowDate);

		this.snackbarRef.dismiss();
	}

	public submit () {
		if (this.form.valid) {
			this.snackbarRef.dismiss();
			this.ngxUserService.sendFeedback(this.form.controls.rating.value, this.form.controls.feedback.value)
				.subscribe({
					next: () => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.SUCCESS,
							message: 'customer-feedback-thanks'
						});
						localStorage
							.setItem(`${this.userService.currentUser.user_id}-` + CustomerSurveySnackBarComponent.localStorageKey, dayjs().add(90, 'day').toString());
					},
					error: () => {
						// re open
						CustomerSurveySnackBarComponent.open(this.snackbar);
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message: 'customer-feedback-error'
						});
					}
				});
		}
	}
}
