import { Component, Inject } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';

@Component({
	selector: 'draft-tour-snack-bar',
	template: require('./draft-tour-snack-bar.component.html'),
	styles: [require('./draft-tour-snack-bar.component.scss')]
})
export class DraftTourSnackBarComponent {

	constructor (
		private snackbarRef: MatSnackBarRef<DraftTourSnackBarComponent>,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public static open (snackBar: MatSnackBar) {
		return snackBar.openFromComponent(
			DraftTourSnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end'
			}
		);
	}

	public getMessageTranslation (): string {
		if (this.selectedService.getGroup()?.hasInstructorRole(true)) {
			return 'drafts-instructor-snackbar-message';
		}
		return 'drafts-presenter-snackbar-message';
	}

	public close () {
		this.snackbarRef.dismiss();
	}
}
