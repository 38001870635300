import { Component, Input } from '@angular/core';
import { ErrorMessages } from './access-denied.constants';

@Component({
	selector: 'ngx-access-denied',
	template: require('./access-denied.component.html'),
	styles: [require('./access-denied.component.scss')]
})
export class NgxAccessDeniedComponent {
	@Input() public errorMessage: string;
	public ErrorMessages = ErrorMessages;

	constructor () {}
}
