import { ui } from 'angular';

/* @ngInject */
export const uiDatepickerConfig = (
	uibDatepickerConfig: ui.bootstrap.IDatepickerConfig,
	uibDatepickerPopupConfig: ui.bootstrap.IDatepickerPopupConfig
) => {
	uibDatepickerConfig.showWeeks = false;
	uibDatepickerPopupConfig.showWeeks = false;
};
