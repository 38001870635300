import { States } from 'go-modules/enums/states.enum';
import type { StateService, StateParams } from '@uirouter/angularjs';

export class ResetPasswordController {
	public email: string = this.$stateParams.email;
	public expiry: number = Number(this.$stateParams.expiry);
	public hash: string = this.$stateParams.hash;

	/* @ngInject */
	constructor (
		private $stateParams: StateParams,
		private $state: StateService,
		private $location: angular.ILocationService
	) {}

	public forgotPassword (): void {
		this.$location.path('/').replace();
		this.$state.go(States.AUTH_FORGOT, {}, {reload: true});
	}

	public login (): void {
		this.$location.path('/').replace();
		this.$state.go(States.AUTH_LOGIN, {}, {reload: true});
	}
}

