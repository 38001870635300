
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormatTimeModule } from 'ngx/go-modules/src/pipes/format-time/format-time.module';
import { CardContainerModule } from 'ngx/go-modules/src/components/card-container/card-container.module';
import { DotIndicatorModule } from 'ngx/go-modules/src/components/dot-indicator/dot-indicator.module';
import { EllipsisWithCountModule } from 'ngx/go-modules/src/components/ellipsis-with-count/ellipsis-with-count.module';
import { SessionMenuModule } from 'ngx/go-modules/src/components/activity-view/session-menu/session-menu.module';
import { ThumbnailModule } from 'ngx/go-modules/src/components/thumbnail/thumbnail.module';
import { SessionCardComponent } from './session-card.component';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { LoadingModule } from 'ngx/go-modules/src/components/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		CardContainerModule,
		FormatTimeModule,
		EllipsisWithCountModule,
		ThumbnailModule,
		GoMaterialModule,
		DotIndicatorModule,
		SessionMenuModule,
		MenuModule,
		LoadingModule
	],
	declarations: [
		SessionCardComponent
	],
	entryComponents: [SessionCardComponent],
	exports: [
		SessionCardComponent
	]
})
export class SessionCardModule {}
