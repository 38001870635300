export enum SortOrder {
	DEFAULT = 'default',
	NEWEST = 'newest',
	OLDEST = 'oldest',
	ASC = 'az',
	DESC = 'za'
}

export const SortOrderTranslations: Record<SortOrder, string> = {
	default: 'common_custom',
	newest: 'common_newest',
	oldest: 'common_oldest',
	az: 'common_asc',
	za: 'common_desc'
};
