import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { UserSettingsComponent } from 'ngx/go-modules/src/components/user-settings/user-settings.component';
import { UserDetailsComponent } from 'ngx/go-modules/src/components/user-details/user-details.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { EmailNotificationsComponent } from 'ngx/go-modules/src/components/user-settings/email-notifications/email-notifications.component';
import { DefaultActivitySettingsModule } from './default-activity-settings/default-activity-settings.module';
import { UnlinkSsoDialogModule } from '../dialogs/unlink-sso-dialog/unlink-sso-dialog.module';
import { SsoProviderButtonModule } from '../sso-provider-button/sso-provider-button.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		ReactiveFormsModule,
		GoSelectModule,
		FormsModule,
		GoTooltipModule,
		DefaultActivitySettingsModule,
		UnlinkSsoDialogModule,
		SsoProviderButtonModule
	],
	exports: [
		UserSettingsComponent,
		UserDetailsComponent,
		BillingHistoryComponent,
		EmailNotificationsComponent
	],
	declarations: [
		UserSettingsComponent,
		UserDetailsComponent,
		BillingHistoryComponent,
		EmailNotificationsComponent
	],
	entryComponents: [
		UserSettingsComponent,
		UserDetailsComponent,
		BillingHistoryComponent,
		EmailNotificationsComponent
	]
})
export class UserSettingsModule {}
