import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BreadCrumbLayoutComponent } from './breadcrumb-layout/breadcrumb-layout.component';
import { BreadCrumbItemViewComponent } from './breadcrumb-layout/breadcrumb-item-view/breadcrumb-item-view.component';
import { BreadCrumbsComponent } from './breadcrumbs.component';
import { ArchiveBreadCrumbsComponent } from './archive-breadcrumbs/archive-breadcrumbs.component';
import { FolderBreadCrumbsComponent } from './folder-breadcrumbs/folder-breadcrumbs.component';
import { LicensingBreadcrumbsComponent } from './licensing-breadcrumbs/licensing-breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { OrgMenuModule } from 'ngx/go-modules/src/components/menus/org-menu/org-menu.module';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { ActivityMenuModule } from 'ngx/go-modules/src/components/menus/activity-menu/activity-menu.module';
import { ExpiringLicenseModule } from 'ngx/go-modules/src/components/breadcrumbs/folder-breadcrumbs/expiring-license/expiring-license.module';
import { SettingsBreadCrumbsComponent } from './settings-breadcrumbs/settings-breadcrumbs.component';
import { FolderMenuModule } from '../menus/folder-menu/folder-menu.module';
import {
	LicensePlanBreadcrumbModule
} from 'ngx/go-modules/src/components/breadcrumbs/folder-breadcrumbs/license-plan-breadcrumb/license-plan-breadcrumb.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		OrgMenuModule,
		FolderMenuModule,
		MenuModule,
		ActivityMenuModule,
		ExpiringLicenseModule,
		LicensePlanBreadcrumbModule
	],
	declarations: [
		BreadCrumbLayoutComponent,
		BreadCrumbItemViewComponent,
		BreadCrumbsComponent,
		ArchiveBreadCrumbsComponent,
		FolderBreadCrumbsComponent,
		LicensingBreadcrumbsComponent,
		SettingsBreadCrumbsComponent
	],
	exports: [
		BreadCrumbLayoutComponent,
		BreadCrumbItemViewComponent,
		BreadCrumbsComponent,
		ArchiveBreadCrumbsComponent,
		FolderBreadCrumbsComponent,
		LicensingBreadcrumbsComponent,
		SettingsBreadCrumbsComponent
	]
})
export class BreadcrumbsModule {}
