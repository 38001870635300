import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

/**
 * A modal container for the user-details component
 */
@Component({
	selector: 'edit-user-details-dialog',
	template: require('./edit-user-details-dialog.component.html'),
	styles: [require('./edit-user-details-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserDetailsDialogComponent implements OnInit, OnDestroy {
	public componentDestroyed$$ = new Subject();

	constructor (
		public dialogRef: GoDialogRef,
		private eventService: EventService,
		private router: Router,
		@Inject('Window') private window: Window,
		@Inject(GO_MODAL_DATA) public data: {
			sessionId: string;
		}
	) {}

	public ngOnInit (): void {
		this.eventService.listen(EVENT_NAMES.ACCOUNT_LOCKED)
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((event) => {
				this.window.sessionStorage.setItem('accountLockTime', event.data.accountLockTime);
				this.close();
				this.router.navigate(['/login'], { queryParams: { session: this.data.sessionId } });
			});
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	/**
	 * Close the dialog
	 */
	public close () {
		this.dialogRef.close();
	}
}
