import { noop } from 'angular';
import template from './modal.html';
import { MessageModal } from '../message/modal.factory';
import { VideoShareModalController } from './modal.controller';
import { VideoShareEmailErrorModal } from '../video-share-email-error/modal.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class VideoShareModal {
	public static readonly NG1_INJECTION_NAME = 'videoShareModal' as const;

	/* @ngInject */
	constructor (
		private $q: angular.IQService,
		private goModalBootstrap,
		private ngxGoToastService: NgxGoToastService,
		private messageModal: MessageModal,
		private $translate: ng.translate.ITranslateService,
		private videoShareEmailErrorModal: VideoShareEmailErrorModal
	) {}

	public open (options) {
		options.template = template;
		options.controller = VideoShareModalController;
		options.windowClass = options.name = 'video-share';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}

	public shareSession (options): ng.IPromise<any> {
		return this.$q((resolve, reject) => {
			this.open(options).result.then((result) => {
				if (result.invalidEmails.length > 0) {
					this.videoShareEmailErrorModal.open({
						modalData: {
							invalidEmails: result.invalidEmails,
							showContinueButton: options.modalData.showContinueButton
						}
					}).result.then(() => {
						reject({ error: 'invalidEmails'});
					}).catch(noop);
				} else {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'modal-video-share_send-invite-success'
					});
					resolve(null);
				}
			}).catch((err) => {
				if (err && err.status === 422
					&& err.data.message.includes('This video has reached the maximum amount of guest reviewers')) {
					this.messageModal.open({
						modalData: {
							title: 'modal-video-share_share-fail_title',
							message: this.$translate.instant('modal-video-share_share-fail-max-users_message',
								{ limit: clientSettings.SessionShareGuestReviewerLimit })
						}
					});
				}
				reject(err);
			});
		});
	}
}

export const videoShareModalToken = upgradeNg1Dependency(VideoShareModal);
