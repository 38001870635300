import { SelectedService } from 'go-modules/services/selected/selected.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

/* @ngInject */
export class SuperBarController {
	public searchValue: string;
	private user: any;

	constructor (
		public selectedService: SelectedService,
		public featureFlag: FeatureFlag,
		private goModal,
		private $location: angular.ILocationService,
		private ModelRouter,
		private Group,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService,
		private $window: angular.IWindowService
	) {}

	public $onInit () {
		if (!this.user.is_root_user) {
			throw new Error('SuperBar::Not Authorized!');
		}
	}

	public openUserSearch (initialQuery) {
		if (initialQuery?.length > 2) {
			this.goModal.open({
				modal: 'userSearch',
				firstFocusSelector: '.search-input',
				modalData: {
					currentUser: this.user,
					initialQuery
				}
			}).result.then((resource) => {
				if (resource && resource.session_id) {
					this.$location.path('/sessions/' + resource.session_id);
				} else if (resource instanceof this.Group.GroupModel) {
					this.ModelRouter.navigateToGroup(resource, this.user);
				}
			});
		}
	}

	public clearSearch () {
		this.searchValue = '';
		this.$timeout(() => {
			this.$element[0].querySelector<HTMLInputElement>('.search-input').focus();
		});
	}
}
