import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardContainerComponent } from './card-container.component';
import { FormatDatePipe } from 'ngx/go-modules/src/pipes/format-date/format-date.pipe';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		CardContainerComponent,
		FormatDatePipe
	],
	exports: [
		CardContainerComponent,
		FormatDatePipe
	]
})
export class CardContainerModule {}
