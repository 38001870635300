export enum ArchiveFilter {
	ALL = 'all',
	VIDEO = 'video',
	FOLDER = 'folder',
	ACTIVITY = 'activity',
}

export const ArchiveFilterTranslations: Record<ArchiveFilter, string> = {
	all: 'common_all',
	video: 'common_video',
	folder: 'common_course',
	activity: 'common_activity'
};
