import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface InvalidEmailsErrorDialogData {
	title: string;
	message: string;
	additionalMessage?: string;
	emails: string[];
}

@Component({
	selector: 'invalid-emails-error-dialog',
	template: require('./invalid-emails-error-dialog.component.html'),
	styles: [require('./invalid-emails-error-dialog.component.scss')]
})
export class InvalidEmailsErrorDialogComponent {
	public constructor (
		public dialogRef: MatDialogRef<InvalidEmailsErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: InvalidEmailsErrorDialogData
	) {}

	// A small helper to open the dialog with all the correct typing
	public static open (
		dialog: MatDialog,
		config?: MatDialogConfig<InvalidEmailsErrorDialogData>
	): MatDialogRef<InvalidEmailsErrorDialogComponent, boolean> {
		return dialog.open<InvalidEmailsErrorDialogComponent, InvalidEmailsErrorDialogData>
		(InvalidEmailsErrorDialogComponent, config);
	}

	public close () {
		this.dialogRef.close();
	}
}
