import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { requestMonitorConfig } from './request-monitor.config';
import { requestMonitorFactory } from './request-monitor.factory';

export const requestMonitorModule = angular
	.module('request-monitor', [angularTranslate])
	.config(requestMonitorConfig)
	.factory('requestMonitor', requestMonitorFactory)
	.name;
