
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { OrgMenuComponent } from './org-menu.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatMenuModule
	],
	declarations: [
		OrgMenuComponent
	],entryComponents: [OrgMenuComponent],
	exports: [
		OrgMenuComponent
	]
})
export class OrgMenuModule {}
