import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GRID_CONSTANTS } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid.constants';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';

@Component({
	selector: 'archive-grid-view',
	template: require('./archive-grid-view.component.html'),
	styles: [require('./archive-grid-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxArchiveGridViewComponent {
	public minWidth: number = GRID_CONSTANTS.minWidth;
	public maxWidth: number = GRID_CONSTANTS.maxWidth;
	public minGap: number = GRID_CONSTANTS.minGap;

	constructor (public archiveListDataSource: ArchivedListDataSource) {}
}
