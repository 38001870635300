import {
	Component,
	ContentChildren,
	Input,
	QueryList
} from '@angular/core';
import { BreadCrumbItemViewComponent } from './breadcrumb-item-view/breadcrumb-item-view.component';

@Component({
	selector: 'breadcrumb-layout',
	template: require('./breadcrumb-layout.component.html'),
	styles: [require('./breadcrumb-layout.component.scss')]
})

export class BreadCrumbLayoutComponent {
	@Input() public showMobileBackButton = true;
	@ContentChildren(BreadCrumbItemViewComponent) public items: QueryList<BreadCrumbItemViewComponent>;

	public onBack () {
		const length = this.items.length;
		this.items.get(length - 2).action.emit();
	}
}
