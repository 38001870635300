import * as angular from 'angular';
import './style.less';
import { templatesRun } from './templates.run';
import { modelRouterModule } from '../model-router/index';
import { licenseManagementViewRoute } from './license-management-view-route.component';
import { ngxLicenseManagementModule } from 'ngx/go-modules/src/components/license-management';

export const licenseManagementViewRouteModule = angular
	.module('licenseManagementViewRouteModule', [
		modelRouterModule,
		ngxLicenseManagementModule
	])
	.component('licenseManagementViewRoute', licenseManagementViewRoute)
	.run(templatesRun)
	.name;
