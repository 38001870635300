import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	UnlinkSsoDialogComponent
} from 'ngx/go-modules/src/components/dialogs/unlink-sso-dialog/unlink-sso-dialog.component';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule
	],
	declarations: [
		UnlinkSsoDialogComponent
	],
	exports: [
		UnlinkSsoDialogComponent
	],
	entryComponents: [
		UnlinkSsoDialogComponent
	]
})
export class UnlinkSsoDialogModule {}
