import * as angular from 'angular';
import { HeaderViewComponent } from './header-view.component';
import { responsiveViewModule } from 'go-modules/responsive-view';

export const headerViewModule = angular
	.module('header-view', [
		responsiveViewModule
	])
	.component('headerView', HeaderViewComponent)
	.name;
