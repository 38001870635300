import angular from 'angular';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { States } from 'go-modules/enums/states.enum';
import { emailValidator } from 'ngx/go-modules/src/form-validator/email.validator/email.validator';
import { goPasswordValidator } from 'ngx/go-modules/src/form-validator/go-password/go-password.validator';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import type { EmailAndPasswordData } from 'ngx/dashboard/src/interfaces/sign-up/email-and-password-data';
import { SignupMode } from 'ngx/dashboard/src/components/signup/signup-mode';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import type { CourseInvite } from 'ngx/dashboard/src/components/signup/course-invite';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';

@Component({
	selector: 'email-and-password-form',
	template: require('./email-and-password-form.component.html'),
	styles: [
		require('./email-and-password-form.component.scss'),
		require('./email-and-password-form-sm.component.scss'),
		require('./email-and-password-form-xs.component.scss')
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailAndPasswordFormComponent implements OnInit {
	@Output()
	public onComplete = new EventEmitter<EmailAndPasswordData>();
	@Output()
	public onNavigate = new EventEmitter<States>();

	@Input()
	public set lockEmailWith (email: string) {
		this.lockEmailField = !!email;
		this.form.controls.email.setValue(email);
	}

	@Input()
	public mode: SignupMode;

	@Input()
	public courseInvite: CourseInvite;

	public form: FormGroup = new FormGroup({
		email: new FormControl(null, [Validators.required, emailValidator()]),
		password: new FormControl(null, [Validators.required, goPasswordValidator()])
	});

	public lockEmailField = false;
	public loading = false;
	public httpError = false;

	public SIGNUPMODES = SignupMode;

	public titleString;

	constructor (
		public featureFlag: NgxFeatureFlagService,
		private authService: NgxAuthService,
		private signUpService: SignUpService,
		private cdr: ChangeDetectorRef,
		@Inject('Window') private window: Window
	) {}

	public ngOnInit (): void {
		this.form.addControl('firstName', new FormControl(null, [Validators.required]));
		this.form.addControl('lastName', new FormControl(null, [Validators.required]));
		this.titleString =  this.mode === SignupMode.GUEST_REVIEW ? 'guest-review-signup_title' : 'common_welcome-to-goreact';
	}

	public navigateToLogin () {
		this.onNavigate.emit(States.AUTH_LOGIN);
	}

	public submit () {
		this.httpError = false;

		if (this.form.valid) {
			this.loading = true;
			this.cdr.detectChanges();
			this.signUpService.sendVerificationEmail(this.form.controls.email.value)
				.subscribe(() => {
					this.loading = false;
					this.onComplete.emit(this.form.value);
					this.cdr.detectChanges();
				}, (e: HttpErrorResponse) => {
					this.httpError = e.error.message;
					this.loading = false;
					this.cdr.detectChanges();
				});
		}
	}

	public signupWithSso (provider) {
		this.loading = true;

		const params: any = {};
		// Session retrieved from video share
		const urlParams = new URLSearchParams(this.window.location.search);
		const externalSession = urlParams.get('session');

		if (this.courseInvite) {
			params.groupConfirmed = true;
			if (this.courseInvite.course) {
				params.course = this.courseInvite.course.uuid;
			}
			if (this.courseInvite.invite) {
				params.invite = this.courseInvite.invite.uuid;
			}
		} else if (externalSession != null) {
			params.session = externalSession;
		}

		this.authService.getAuthUrl(provider, params)
			.then((res: any) => {
				this.window.location.href = res.url;
			})
			.catch(angular.noop);
	}
}
