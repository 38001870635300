import * as angular from 'angular';
import { goTourModule } from 'go-modules/go-tour';
import { common } from 'go-modules/models/common';
import { ActivityEditorAiMarkersTour } from './activity-editor-ai-markers-tour.service';

export const ActivityEditorAiMarkersTourModule = angular.module('activity-editor-ai-markers-tour', [
	goTourModule,
	common
])
	.service(ActivityEditorAiMarkersTour.NG1_INJECTION_NAME, ActivityEditorAiMarkersTour)
	.name;
