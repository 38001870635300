import { clientSettings } from 'go-modules/models/common/client.settings';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export function TagSetModel ($resource, $http) {
	const baseUrl = `${clientSettings.GoReactV2API}/tag_sets`,
		resourceUrl = baseUrl + '/:id/';

	const tagSetModel = $resource(resourceUrl, {
		id: '@id',
		group_id: '@group_id'
	}, {
		update: {
			method: 'PUT',
			transformResponse: [$http.defaults.transformResponse[0], function (tagSet) {
				return tagSet;
			}]
		},
		canUpdate: {
			method: 'GET',
			url: resourceUrl + 'can-update'
		}
	});

	// properties
	tagSetModel.prototype.id = null;
	tagSetModel.prototype.title = '';
	tagSetModel.prototype.created_by = null;
	tagSetModel.prototype.created_at = null;
	tagSetModel.prototype.kind = 'tagset';
	tagSetModel.prototype.saving = false;

	/**
	 * Get tag set id
	 *
	 * @returns {int}
	 */
	tagSetModel.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Set whether tag set is saving
	 *
	 * @returns {boolean}
	 */
	tagSetModel.prototype.setSaving = function (value) {
		this.saving = !!value;
	};

	/**
	 * Whether tag set is saving
	 *
	 * @returns {boolean}
	 */
	tagSetModel.prototype.isSaving = function () {
		return this.saving;
	};

	/**
	 * Whether tag set is saved
	 *
	 * @returns {boolean}
	 */
	tagSetModel.prototype.isSaved = function () {
		return this.getId() > 0;
	};

	return tagSetModel;
}

TagSetModel.NG1_INJECTION_NAME = 'TagSetModel' as const;
export const tagSetModelToken = upgradeNg1Dependency(TagSetModel);
