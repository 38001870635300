import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-welcome-tour-dialog',
	template: require('./welcome-tour-dialog.component.html'),
	styles: [require('./welcome-tour-dialog.component.scss')]
})
export class WelcomeTourDialogComponent {

	public constructor (
		public dialogRef: MatDialogRef<WelcomeTourDialogComponent>
	) {}

	public close () {
		this.dialogRef.close();
	}

	public startTour () {
		this.dialogRef.close(true);
	}
}
