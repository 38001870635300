import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FolderMenuComponent } from './folder-menu.component';
import { MoveFolderDialogModule } from 'ngx/go-modules/src/components/dialogs/move-folder-dialog/move-folder-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		GoToastModule,
		MoveFolderDialogModule
	],
	declarations: [
		FolderMenuComponent
	],
	entryComponents: [
		FolderMenuComponent
	],
	exports: [
		FolderMenuComponent
	],
	providers: [
		TranslatePipe
	]
})
export class FolderMenuModule {}
