import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[];

	readonly userChoice: Promise<{
	  outcome: 'accepted' | 'dismissed';
	  platform: string;
	}>;

	prompt(): Promise<void>;
}

export class PWAService {
	public static readonly NG1_INJECTION_NAME = 'pwaService' as const;
	private A2HSPrompt: BeforeInstallPromptEvent;

	/* @ngInject */
	constructor (
		private $window: ng.IWindowService
	) {}

	public init () {
		// The serviceWorker will be null locally when not using https (https is required for serviceWorker).
		if (this.$window.navigator == null || this.$window.navigator.serviceWorker == null) return;

		// Install the service worker
		// TODO: Need to push this to V2 and figure out how to path correctly
		this.$window.navigator.serviceWorker.register('/dist/pwa/sw.js', { scope: '../../' });

		// Setup add to home screen
		this.$window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
			e.preventDefault();
			this.setA2HSPrompt(e);
		});
	}

	public setA2HSPrompt (e: BeforeInstallPromptEvent) {
		this.A2HSPrompt = e;
	}

	public getA2HSPrompt () {
		return this.A2HSPrompt;
	}

	public launchPrompt () {
		return this.A2HSPrompt.prompt();
	}
}

export const pwaServiceToken = upgradeNg1Dependency(PWAService);
