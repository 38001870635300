import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
	selector: 'rate-limit-dialog',
	template: require('./rate-limit-dialog.component.html'),
	styles: [require('./rate-limit-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateLimitDialogComponent implements OnDestroy {
	public retryAfter: number;
	public countdown$: BehaviorSubject<number>;
	private subscription: Subscription;

	constructor (
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<RateLimitDialogComponent>
	) {
		this.dialogRef.disableClose = true;
		this.retryAfter = this.data.retryAfter / 1000;
		this.countdown$ = new BehaviorSubject<number>(this.retryAfter);
		this.startCountdown();
	}

	public ngOnDestroy () {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private startCountdown () {
		this.subscription = interval(1000).pipe(
			takeWhile(() => this.countdown$.getValue() > 0)
		).subscribe({
			next: () => {
				this.countdown$.next(this.countdown$.getValue() - 1);
			},
			complete: () => this.dialogRef.close()
		});
	}
}
