import { Component } from '@angular/core';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';

@Component({
	selector: 'session-list-search-result-info',
	template: require('./session-list-search-result-info.component.html'),
	styles: [require('./session-list-search-result-info.component.scss')]
})
export class SessionListSearchResultInfoComponent {

	constructor (
		public sessionListDataSource: SessionListDataSource
	) {}

}
