import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {
	public transform (value: any[], key: string): any[] {
		if (!value || !key) return value;

		// Check if the key indicates reverse sorting
		const reverse = key.charAt(0) === '-';
		if (reverse) {
			key = key.slice(1);
		}

		const sortedArray = value.slice();

		sortedArray.sort((a, b) => {
			// Handle numbers
			if (typeof a[key] === 'number' && typeof b[key] === 'number') {
				return reverse ? b[key] - a[key] : a[key] - b[key];
			}

			// Handle dates
			if (a[key] instanceof Date && b[key] instanceof Date) {
				return reverse
					? b[key].getTime() - a[key].getTime()
					: a[key].getTime() - b[key].getTime();
			}

			// Handle strings or other types
			const comparison = String(a[key]).localeCompare(String(b[key]));
			return reverse ? comparison * -1 : comparison;
		});

		return sortedArray;
	}
}
