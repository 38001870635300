import template from './panel.component.html';
import { SourceMediaPanelController as controller } from './panel.controller';
import './style.less';

export default {
	bindings: {
		sourceMedia: '<',
		onRemove: '&'
	},
	controller,
	require: {
		activityEditorController: '^activityEditor'
	},
	template
} as ng.IComponentOptions;
