import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { sessionManagerToken } from 'go-modules/session-manager/session-manager.service';
import { SessionInterceptor } from 'go-modules/session-manager/session-manager.interceptor';
import { clientSettings } from 'go-modules/models/common/client.settings';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor (@Inject(sessionManagerToken) private sessionManager) {
	}

	public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Configure header if goreact request
		if (req.url.indexOf(clientSettings.GoReactV2API) === 0 ||
			req.url.indexOf(clientSettings.GoReactV1API) === 0) {
			const request = SessionInterceptor.request({}, this.sessionManager);
			const headers = {'Content-Type': 'application/json', ...request.headers};
			req = req.clone({
				setHeaders: headers,
				withCredentials: true
			});
		} else {
			req = req.clone({
				setHeaders: {'Content-Type': 'application/json'}
			});
		}

		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					SessionInterceptor.response(event.body, this.sessionManager);
				}
			}),
			catchError((e) => {
				return throwError(() => SessionInterceptor.responseError(e, this.sessionManager));
			})
		);
	}
}
