import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { ActivityInstructionsModalController } from './modal.controller';
import template from './modal.html';

/* @ngInject */
export class ActivityInstructionsModal {
	public static readonly NG1_INJECTION_NAME = 'activityInstructionsModal' as const;

	/* @ngInject */
	constructor (private goModalBootstrap) {}

	public open (options) {
		options.template = template;
		options.controller = ActivityInstructionsModalController;
		options.windowClass = options.name = 'activity-instructions-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}

export const activityInstructionsModalToken = upgradeNg1Dependency(ActivityInstructionsModal);
