import template from './session-editor-panel.template.html';
import {sessionEditorPanelController} from './session-editor-panel.controller';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class SessionEditorPanelService {
	public static readonly NG1_INJECTION_NAME = 'SessionEditorPanel' as const;

	/* @ngInject */
	constructor (private SidePanel) {}

	public open (options) {
		return this.SidePanel.open({
			template,
			bindings: {
				session: options.session,
				user: options.user,
				group: options.group,
				activity: options.activity,
				activeTab: options.activeTab,
				options
			},
			controller: sessionEditorPanelController,
			windowClass: 'session-editor-panel',
			resolve: {
				loadSession: [() => {
					return options.session.$get({
						'session_id': options.session.session_id,
						'with[]': ['externalSessionUsers', 'externalSessionUsers.user', 'internalReviewers']
					});
				}]
			}
		});
	};
}

export const sessionEditorPanelServiceToken = upgradeNg1Dependency(SessionEditorPanelService);
