import type { FeatureFlagSettings } from 'go-modules/feature-flag/feature-flag-types';
import { FeatureTests } from 'go-modules/feature-flag/feature-flag-tests';

export interface Theme {
	titlePrefix: string;
	cssVars: {
		[key: string]: string;
	};
}

export interface Partner {
	guid: string;
	has_unlimited_billing: boolean;
}

export interface GoLanguage {
	name: string;
	code: string;
}

// if adding a new property here that is set up on the frontend
// consider if it should be preserved  in watchForSettingChanges
export interface GoSettings {
	FullstoryOrgId: string;
	GoReactV2API: string;
	GoReactV1API: string;
	GoReactV2LTI: string;
	AdminToolsUrl: string;
	VideoShareUrl: string;
	TokenName: string;
	LogLevel: string;
	AiMarkersLimit: number;
	SharedCollectionLimit: number;
	SessionShareGuestReviewerLimit: number;
	CommentTextCharacterLimit: number;
	languages: GoLanguage[];
	themes: {
		[key: string]: Theme;
	};
	featureFlags: FeatureFlagSettings<FeatureTests>;
	synchronizedFeatureFlags: string[];
	partners: Partner[];
	orgList: number[];
	currentFolder: any; // GroupModel
	orgSettings: any;
	userCreatedAt: string|Date;
	userId: number;
	watson: {
		speech: {
			inactivityTimeoutInSeconds: number;
		};
	};
	zoom: {
		client_id: string;
		redirect_uri: string;
	};
}

declare global {
	interface Window {
		goSettings: GoSettings;
		zESettings: any;
	}
}

export const clientSettings: GoSettings = window.goSettings as GoSettings;

export const watchForSettingChanges = <P extends (fn: () => any, ms: number) => any>(
	everyMs: number,
	poller: P,
	win: Window = window
): ReturnType<P> => {
	return poller(async () => {
		const oldScript = win.document.getElementById('settings.js') as HTMLScriptElement;
		const parent = oldScript.parentElement;
		const newScript = win.document.createElement('script');
		newScript.id = oldScript.id;

		// Update the cachebust to now
		const url = new URL(oldScript.src, win.location.href);
		url.searchParams.set('v', Date.now().toString());
		newScript.src = url.toString();

		newScript.addEventListener('load', () => {
			// Preserve these properties
			win.goSettings.partners = clientSettings.partners;
			win.goSettings.orgList = clientSettings.orgList;
			win.goSettings.orgSettings = clientSettings.orgSettings;
			win.goSettings.userCreatedAt = clientSettings.userCreatedAt;
			win.goSettings.currentFolder = clientSettings.currentFolder;

			// Remove all the old settings and replace with the new ones.
			Object.keys(clientSettings).forEach((key) => delete clientSettings[key]);
			Object.assign(clientSettings, win.goSettings);
		});

		parent.replaceChild(newScript, oldScript);
	}, everyMs);
};
