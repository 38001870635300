import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FeatureFlagSyncInterceptor } from './feature-flag-sync.interceptor';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { FeatureTestsService } from 'ngx/go-modules/src/services/feature-flag/feature-flag-tests.service';
import { UADetect } from 'go-modules/detect/ua-detect.service';


@NgModule({
	providers: [
		NgxFeatureFlagService,
		FeatureTestsService,
		UADetect,
		{ provide: HTTP_INTERCEPTORS, useClass: FeatureFlagSyncInterceptor, multi: true }
	]
})
export class FeatureFlagSyncInterceptorModule {}
