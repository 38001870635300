import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import 'tinymce';
import 'angular-ui-tinymce';
import { NgxRichTextEditorComponent } from './rich-text-editor.component';

export { NgxRichTextEditorModule } from './rich-text-editor.module';
export const ngxRichTextEditorModule = angular.module('ngxRichTextEditorModule', [])
	.directive('ngxRichTextEditor', downgradeComponent({ component: NgxRichTextEditorComponent }))
	.value('uiTinymceConfig', {
		baseUrl: 'https://staticassets.goreact.com/prod-client/app/'
	})
	.name;
