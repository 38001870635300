import {
	BehaviorSubject,
	catchError,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	filter,
	map,
	Observable,
	of,
	shareReplay,
	Subject,
	switchMap,
	tap,
	concat
} from 'rxjs';
import { SelectedService, selectedServiceToken, SelectedInterface } from 'go-modules/services/selected/selected.service';
import { Inject, Injectable } from '@angular/core';
import { NgxCourseService } from '../course/course.service';
import type { CourseFolder } from '../course/interfaces/course-folder.interface';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import * as dayjs from 'dayjs';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import { NgxGroupService } from '../group/group.service';
import { accumulateArr, accumulateMap } from 'ngx/go-modules/src/rxjs/accumulate/accumulate';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { NgxFeatureFlagService } from '../feature-flag/feature-flag.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';

export enum FilterByOptions {
	ALL = 'all',
	CURRENT = 'current',
	EXPIRED = 'expired'
}

export const FilterByOptionsTranslations: Record<FilterByOptions, string> = {
	all: 'common_all',
	current: 'common_current',
	expired: 'common_expired'
};

export interface CurrentFolderListParams {
	orgId: number;
	end_date: string|null;
	instructor_id: number|null;
}

export const DEBOUNCE_TIME = 50;
export const FOLDER_FILTER_KEY = 'folder-filter';
export const FOLDER_SORT_KEY = 'folder-sort';

@Injectable({
	providedIn: 'root'
})
export class FolderListDataSource extends BaseDataSource<CourseFolder, CurrentFolderListParams> {
	public filtered = false;
	private orgId$$ = new BehaviorSubject<number>(null);
	private sortBy$$ = new BehaviorSubject<SortOrder>(SortOrder.NEWEST);
	private filterBy$$ = new BehaviorSubject<string>(FilterByOptions.CURRENT);
	private add$$ = new Subject<CourseFolder>();
	private edit$$ = new Subject<CourseFolder>();
	private remove$$ = new Subject<CourseFolder>();
	private instructorId$$ = new BehaviorSubject<number|null>(null);
	private reloadStats$$ = new BehaviorSubject<boolean|null>(null);
	private statsLoadingFailed = false;

	constructor (
		public ngxCourseService: NgxCourseService,
		private ngxGoToastService: NgxGoToastService,
		private featureFlag: NgxFeatureFlagService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(groupToken) private group,
		private ngxGroupService: NgxGroupService
	) {
		super();

		// folderListDataSource is injected in NgxSessionService
		// even though it isn't connected, everything in the constructor is executed
		// so we need to check if the selectedService has an org
		// because in video sharing it doesn't have an org
		if (this.selectedService.getOrg()) {
			this.setOrgId(this.selectedService.getOrg().org_id);
		}
		this.setupFilters();
		this.buildParams();

		this.selectedService.selectedSubject.asObservable()
			.pipe(
				map((selected: SelectedInterface) => selected.org?.group_id || null),
				filter((orgId) => this.orgId$$.getValue() !== orgId)
			)
			.subscribe((orgId: number) => {
				this.setOrgId(orgId);
			});

		this.results$ = this.getFolderList();
	}

	public connect (): Observable<CourseFolder[]> {
		if (this.statsLoadingFailed) {
			this.statsLoadingFailed = false;
			this.reload();
		}
		return this.results$;
	}

	public disconnect (): void {
	}

	public sortBy (sortByOption: SortOrder) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + FOLDER_SORT_KEY, sortByOption);
		this.sortBy$$.next(sortByOption);
	}

	public getCurrentSort () {
		return this.sortBy$$.getValue();
	}

	public filterBy (filterByOption: FilterByOptions) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + FOLDER_FILTER_KEY, filterByOption);
		this.filtered = filterByOption !== FilterByOptions.ALL;
		this.filterBy$$.next(filterByOption);
	}

	public getCurrentFilter () {
		return this.filterBy$$.getValue();
	}

	public setOrgId (orgId: number) {
		this.orgId$$.next(orgId);
	}

	public setInstructorId (instructorId: number) {
		this.instructorId$$.next(instructorId);
	}

	public setLoading (loading: boolean) {
		this.loading$$.next(loading);
	}

	public addFolder (folder: CourseFolder) {
		this.add$$.next(folder);
	}

	public reloadStats () {
		this.reloadStats$$.next(true);
	}

	/**
	 * Note: This will not work if group_id is changed.
	 */
	public editFolder (editedFolder: CourseFolder) {
		this.edit$$.next(editedFolder);
	}

	public removeFolder (folder: CourseFolder) {
		this.remove$$.next(folder);
	}

	private sortFolders (folderList: CourseFolder[], sort: SortOrder) {
		return folderList.sort((f1, f2) => {
			if (sort === SortOrder.NEWEST) {
				return new Date(f2.created_at).getTime() - new Date(f1.created_at).getTime();
			}

			if (sort === SortOrder.OLDEST) {
				return new Date(f1.created_at).getTime() - new Date(f2.created_at).getTime();
			}

			if (sort === SortOrder.ASC) {
				if (f1.name < f2.name) {
					return -1;
				}

				if (f1.name > f2.name) {
					return 1;
				}

				return 0;
			}

			if (f1.name > f2.name) {
				return -1;
			}

			if (f1.name < f2.name) {
				return 1;
			}

			return 0;
		});
	}

	private setupFilters () {
		const selectedFilter = localStorage.getItem(`${this.userService.currentUser.user_id}-` + FOLDER_FILTER_KEY);
		if (selectedFilter && Object.values(FilterByOptions).includes(selectedFilter as FilterByOptions)) {
			this.filterBy(selectedFilter as FilterByOptions);
		}

		const selectedSort = localStorage.getItem(`${this.userService.currentUser.user_id}-` + FOLDER_SORT_KEY);
		const sortOrderOptions = Object.values(SortOrder).filter((option) => option !== SortOrder.DEFAULT);
		if (selectedSort && sortOrderOptions.includes(selectedSort as SortOrder)) {
			this.sortBy(selectedSort as SortOrder);
		}
	}

	private buildParams () {
		this.params$ = combineLatest([
			this.orgId$$.pipe(distinctUntilChanged()),
			this.filterBy$$.pipe(distinctUntilChanged()),
			this.instructorId$$,
			this.reload$$
		], (orgId, filterBy, instructorId, _reload) => {
			const today = dayjs().format('YYYY-MM-DD');
			return {
				orgId,
				end_date: filterBy === FilterByOptions.ALL ? null : (filterBy === FilterByOptions.CURRENT ? `>${today}` : `<${today}`),
				instructor_id: instructorId
			} as CurrentFolderListParams;
		});
	}

	private errorHandler (err) {
		this.onError$$.next(err);
		this.setLoading(false);
		return EMPTY;
	}

	private getFolderList (): Observable<CourseFolder[]> {
		return this.params$.pipe(
			debounceTime(DEBOUNCE_TIME),
			tap(() => this.setLoading(true)),
			switchMap((currentFolderListParams: CurrentFolderListParams) => {
				return this.ngxCourseService
					.getCourses(currentFolderListParams.orgId, currentFolderListParams)
					.pipe(
						catchError(this.errorHandler.bind(this)),
						map((courses) => ({ courses, currentFolderListParams }))
					);
			}),
			switchMap(({ courses, currentFolderListParams }) => {
				// Fetch stats and map them to courses
				const statsEmission = this.reloadStats$$.asObservable().pipe(
					switchMap(() => {
						return this.ngxCourseService
							.getFolderStats(currentFolderListParams.orgId, currentFolderListParams)
							.pipe(
								catchError(() => {
									this.statsLoadingFailed = true;
									this.ngxGoToastService.createToast({
										type: GoToastStatusType.ERROR,
										message: 'folder-list_data-source-failed-stats'
									});
									// No need to emit folders without stats again
									return EMPTY;
								})
							);
					}),
					map((stats) => courses.map((course) =>
						({ ...course, stats: stats[course.group_id] })
					))
				);

				return concat(of(courses), statsEmission);
			}),
			map((response: CourseFolder[]) => {
				this.setLoading(false);
				return response.map((folder) => {
					return this.group.model(folder, true);
				});
			}),
			switchMap((folderList: CourseFolder[]) => {
				const added$ = this.add$$.pipe(accumulateArr());
				const editted$ = this.edit$$.pipe(accumulateMap('group_id'));
				const removed$ = this.remove$$.pipe(accumulateArr());
				return combineLatest({added: added$, editted: editted$, removed: removed$}).pipe(
					map(({added, editted, removed}) =>
						folderList.concat(added)
							.map((folder) => editted[folder.group_id] ?? folder)
							.filter((folder) =>
								!removed.some((removedFolder) => removedFolder.group_id === folder.group_id))
					)
				);
			}),
			shareReplay(1),
			switchMap((folderList: CourseFolder[]) => {
				return this.sortBy$$.pipe(
					map((sort) => this.sortFolders(folderList, sort))
				);
			}),
			catchError(this.errorHandler.bind(this))
		);
	}
}
