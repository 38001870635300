
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { SessionMenuComponent } from './session-menu.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatMenuModule
	],
	declarations: [
		SessionMenuComponent
	],entryComponents: [SessionMenuComponent],
	exports: [
		SessionMenuComponent
	]
})
export class SessionMenuModule {}
