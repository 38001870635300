import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FilterViewLayoutModule } from 'ngx/go-modules/src/components/filter-view-layout/filter-view-layout.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { NgxActivityTableViewComponent } from './activity-table-view/activity-table-view.component';
import { NgxActivityGridViewComponent } from './activity-grid-view/activity-grid-view.component';
import { NgxFolderViewComponent } from './folder-view.component';
import { NgxActivityDueDateModule } from 'ngx/go-modules/src/components/folder-view/activity-due-date/activity-due-date.module';
import { NgxActivityCardModule } from 'ngx/go-modules/src/components/folder-view/activity-card/activity-card.module';
import { GridVirtualScrollViewportModule } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid-virtual-scroll-viewport.module';
import { DataSourceZeroStateModule } from 'ngx/go-modules/src/components/data-source-zero-state/data-source-zero-state.module';
import { NgxActivityListStatusModule } from 'ngx/go-modules/src/components/folder-view/activity-list-status/activity-list-status.module';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { ActivityMenuModule } from 'ngx/go-modules/src/components/menus/activity-menu/activity-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { DotIndicatorModule } from 'ngx/go-modules/src/components/dot-indicator/dot-indicator.module';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { CommentIndicatorModule } from 'ngx/go-modules/src/components/comment-indicator/comment-indicator.module';
import { OutsideClickModule } from 'ngx/go-modules/src/directives/outside-click';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CourseContextMenuModule } from 'ngx/go-modules/src/components/folder-view/course-context-menu/course-context-menu.module';
import { ActivityReorderModule } from 'ngx/go-modules/src/components/sidepanels/activity-reorder/activity-reorder.module';
import { FolderZeroStateComponent } from './folder-zero-state/folder-zero-state.component';
import { CopyActivitiesDialogModule } from 'ngx/go-modules/src/components/dialogs/copy-activities-dialog/copy-activities-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FilterViewLayoutModule,
		NgxActivityDueDateModule,
		GridVirtualScrollViewportModule,
		NgxActivityCardModule,
		DataSourceZeroStateModule,
		NgxActivityListStatusModule,
		ActivityMenuModule,
		MatMenuModule,
		DotIndicatorModule,
		GoSelectModule,
		NgxZeroStateModule,
		CommentIndicatorModule,
		OutsideClickModule,
		GoTooltipModule,
		MenuModule,
		ReactiveFormsModule,
		CourseContextMenuModule,
		ActivityReorderModule,
		CopyActivitiesDialogModule
	],
	declarations: [
		NgxFolderViewComponent,
		NgxActivityTableViewComponent,
		NgxActivityGridViewComponent,
		FolderZeroStateComponent
	],
	entryComponents: [
		NgxFolderViewComponent,
		NgxActivityTableViewComponent,
		NgxActivityGridViewComponent
	],
	exports: [
		NgxFolderViewComponent,
		NgxActivityTableViewComponent,
		NgxActivityGridViewComponent,
		FolderZeroStateComponent
	],
	providers: [
		TranslatePipe,
		ActivityListDataSource
	]
})
export class NgxFolderViewModule {}
