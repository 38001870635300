import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';

export const DEBOUNCE_TIME = 400;

@Component({
	selector: 'session-search',
	template: require('./session-search.component.html'),
	styles: [require('./session-search.component.scss')]
})
export class SessionSearchComponent implements OnInit, OnDestroy {
	@ViewChild('textInput')
	public textInput: ElementRef;
	public searchText: string = '';
	private searchTextUpdate$$: Subject<string> = new Subject<string>();
	private searchTextUpdateSubscription: Subscription;

	constructor (
		private sessionListDataSource: SessionListDataSource
	) {}

	public ngOnInit () {
		this.searchTextUpdateSubscription = this.searchTextUpdate$$.pipe(
			debounceTime(DEBOUNCE_TIME),
			distinctUntilChanged()
		).subscribe((text) => {
			this.searchText = text;
			this.sessionListDataSource.setSearchTerm(this.searchText);
		});
	}

	public ngOnDestroy () {
		this.sessionListDataSource.setSearchTerm('');
		this.searchTextUpdateSubscription.unsubscribe();
	}

	public onSearchQueryUpdate (text: string) {
		this.searchTextUpdate$$.next(text);
	}

	public clearText () {
		this.searchText = '';
		this.searchTextUpdate$$.next('');
		this.textInput.nativeElement.focus();
	}
}
