
        var result = require("!!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[8].use[2]!../../../../../../node_modules/sass-loader/dist/cjs.js!./activity-table-view.component.scss");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    