import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModifyCollectionsPanelComponent } from './modify-collections-panel.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		GoTooltipModule,
		FocusOnFirstInvalidFieldModule
	],
	declarations: [
		ModifyCollectionsPanelComponent
	],
	entryComponents: [
		ModifyCollectionsPanelComponent
	],
	exports: [
		ModifyCollectionsPanelComponent
	]
})
export class ModifyCollectionsPanelModule {}
