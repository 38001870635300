import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';
import { DownloadPWAModal, downloadPWAModalToken } from 'go-modules/modals/download-pwa/modal.factory';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { SameNameTourService } from 'ngx/go-modules/src/tours/same-name-tour/same-name-tour.service';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

@Component({
	selector: 'dashboard-header',
	template: require('./dashboard-header.component.html'),
	styles: [require('./dashboard-header.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
	@Input() public showSideNavToggle$: BehaviorSubject<boolean>;
	@Output() public toggleSideNav: EventEmitter<any> = new EventEmitter();

	public currentUser;
	public adminToolsUrl: string;
	public isLTI = false;
	public helpUrls = helpUrlsLookup;
	public environmentVarsService: EnvironmentVarsService;
	private userServiceSub;
	private componentDestroyed$$ = new Subject();

	constructor (
		private cdr: ChangeDetectorRef,
		public featureFlag: NgxFeatureFlagService,
		public breadCrumbStateService: BreadCrumbStateService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(downloadPWAModalToken) private downloadPWAModal: DownloadPWAModal,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject($stateToken) private $state,
		private authService: NgxAuthService,
		private sameNameTour: SameNameTourService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		this.isLTI = this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
		this.currentUser = this.userService.currentUser;
		this.adminToolsUrl = clientSettings.AdminToolsUrl;
		this.userServiceSub = this.userService.userSubject
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe(() => {
				this.currentUser = this.userService.currentUser;
				this.cdr.detectChanges();
			});

		const startDate = dayjs('2023-09-01');
		const endDate = dayjs('2023-09-21');
		const userCreatedAt = dayjs(this.currentUser.created);
		if (this.currentUser.first_name === this.currentUser.last_name &&
			userCreatedAt.isBetween(startDate, endDate)) {
			this.sameNameTour.getTour().start();
		}
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.userServiceSub.unsubscribe();
	}

	public showAccountSettings () {
		return !(
			this.$state.is(States.DASHBOARD_WIZARD) ||
			this.$state.is(States.DASHBOARD_QUICKSTART_GUIDE)
		);
	}

	public openAccountSettingsPage () {
		this.$state.go(States.DASHBOARD_SETTINGS);
	}

	public openMobileApp () {
		this.downloadPWAModal.open();
	}

	public shouldShowAdminTools () {
		return this.authService.isAdmin() || this.currentUser.is_root_user;
	}

	public logout () {
		this.authService.logout().then(() => {
			this.$state.go(States.AUTH_LOGIN);
		});
	}

	public isMasked () {
		return this.masquerade.isMasked();
	}

	public unMask () {
		this.masquerade.unMask();
	}

	public shouldShowSaveAndExitButton () {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.FOLLOW_LTI_RETURN_URL)
			&& !!this.getLaunchPresentationUrl()
			&& !(this.$state.is(States.DASHBOARD_WIZARD)
			|| this.$state.is(States.DASHBOARD_SESSION_VIEW));
	};

	public getLaunchPresentationUrl () {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.LAUNCH_PRESENTATION_RETURN_URL);
	}
}
