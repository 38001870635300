import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import type { OrgCreateData } from 'ngx/dashboard/src/interfaces/sign-up/org-create-data';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import { defaultSeperator, multiValueValidate } from 'ngx/go-modules/src/validators/multi-value/multi-value.validator';

@Component({
	selector: 'org-invite',
	template: require('./org-invite.component.html'),
	styles: [require('./org-invite.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgInviteComponent {
	@Input()
	public org: SignupOrg | OrgCreateData;

	@Output()
	public onComplete = new EventEmitter<OrgCreateData>();

	@Output()
	public onBack = new EventEmitter<void>();

	public form = new FormGroup({
		invites: new FormControl(null, [multiValueValidate(Validators.email)])
	});

	constructor () {}

	public submit () {
		if (this.form.valid) {
			(this.org as OrgCreateData).invites = this.form.controls.invites.value ?
				this.form.controls.invites.value
					.split(defaultSeperator)
					.filter((email) => email !== '') // Remove leading/trailing seperators resulting in empty values
					.filter((v, i, a) => a.indexOf(v) === i) // Remove duplicate values
					.map((email) => ({email, role: 'instructor'})) :
				[];
			this.onComplete.emit(this.org as OrgCreateData);
		}
	}

	public skip () {
		(this.org as OrgCreateData).invites = [];
		this.onComplete.emit(this.org as OrgCreateData);
	}

	public getInvalidEmails (): string {
		return this.form.controls.invites.getError('multi')
			.map((error) => error.value)
			.join(', ');
	}
}
