import {
	INgModelController,
	IFormController
} from 'angular';

export class FormValidationService {
	constructor (private formController: IFormController) {}

	/**
	 * Whether a form control error should be displayed to the user
	 */
	public shouldDisplayError (controlName: string): boolean {
		const ngModelController: INgModelController = this.formController[controlName] as INgModelController;
		if (!ngModelController) {
			return false;
		}
		return (ngModelController.$touched || this.formController.$submitted)
			&& Object.keys(ngModelController.$error).length > 0;
	}
}
