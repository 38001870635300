import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	Optional
} from '@angular/core';
import {CdkStep, CdkStepper, STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
	selector: 'go-step',
	template: require('./go-step.component.html'),
	styles: [require('./go-step.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: CdkStep, useExisting: GoStepComponent}]
})
export class GoStepComponent extends CdkStep {
	@Input()
	public hideIndicator = false;

	constructor (stepper: CdkStepper, @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?) {
		super(stepper, stepperOptions);
	}

}
