// Angular polyfills
import 'core-js/features/reflect';
import 'zone.js';
import 'reflect-metadata';

// Angular dependencies
import * as angular from 'angular';

// Other app dependencies
import 'go-modules/zendesk-settings';
import './css/style.less';
import 'go-styles/src/goreact-style.less';
import 'go-ui/material-theme.scss';
import 'go-ui/go-ui.scss';
import { dashboardModule } from './app.module.ajs';
import { goBootstrapModule } from './downgraded-app.module';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { translateConfig } from 'go-modules/translation-helper/translate.config';
import { translateRun } from './runs/translate.run';
import { ngxAuthServiceModule } from 'ngx/go-modules/src/services/auth';
import { groupServiceModule } from 'go-modules/services/group';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';

// Translation config module
const transModule = angular.module('bootTransModule', [
	goLocalizeHelperModule,
	ngxAuthServiceModule,
	groupServiceModule,
	ngxGoToastModule
]).config(translateConfig)
	.run(translateRun)
	.name;

angular.bootstrap(document, [dashboardModule, goBootstrapModule, transModule], {
	strictDi: true
});
