import {
	ChangeDetectionStrategy,
	Component
} from '@angular/core';

@Component({
	selector: 'go-logo',
	template: require('./go-logo.component.html'),
	styles: [require('./go-logo.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoLogoComponent {
	constructor () {}
}
