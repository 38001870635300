import template from './panel.component.html';
import { RubricPanelController as controller } from './panel.controller';
import './style.less';

export default {
	bindings: {
		rubricTitle: '@',
		rubricRole: '@',
		rubricTemplate: '<',
		rubricReviewersAccess: '<',
		onEdited: '&',
		onRemoved: '&',
		onUpdatedCheckbox: '&'
	},
	controller,
	require: {
		activityEditorController: '^activityEditor'
	},
	template
} as ng.IComponentOptions;
