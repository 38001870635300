import template from '../templates/recording-time-monitor.html';

/** Recording time monitor (Isolate Scope) */
/* @ngInject */
export const recordingTimeMonitor = (Time) => {
	return {
		restrict: 'A',
		scope: {
			timeAvailable: '=',
			monitorTime: '='
		},
		template,
		link (scope, element) {
			const NORMAL = 30, LOW = 10;
			const states = ['normal', 'low', 'verylow'];
			const STATE = {
				NORMAL: 0,
				LOW: 1,
				VERYLOW: 2
			};

			// timeLeft property listener
			scope.$watch('timeAvailable', (value) => {

				// round milliseconds to seconds
				value = Math.round(value / 1000) * 1000;

				if (value === -0) {
					value = 0;
					scope.timeAvailable = 0;
				}

				element.removeClass(states.join(' '));
				const minutesLeft = Time.millisecondsToMins(value);
				if (minutesLeft >= NORMAL) {
					element.addClass(states[STATE.NORMAL]);
				} else if (minutesLeft < NORMAL && minutesLeft >= LOW) {
					element.addClass(states[STATE.LOW]);
				} else if (minutesLeft < LOW && minutesLeft < LOW) {
					element.addClass(states[STATE.VERYLOW]);
				}
			});
		}
	};
};
