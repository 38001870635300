import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'student-signup-message',
	template: require('./student-signup-message.component.html'),
	styles: [
		require('./student-signup-message.component.scss')
	]
})
export class StudentSignupMessageComponent {
	@Output() public onBack = new EventEmitter();
}
