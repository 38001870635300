export enum MinePeerFilter {
	ALL = 'all',
	MINE = 'mine',
	PEER = 'peer'
}

export const MinePeerFilterTranslations: Record<MinePeerFilter, string> = {
	all: 'filter-all',
	mine: 'filter-mine',
	peer: 'filter-peer'
};
