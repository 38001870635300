import template from './skip-links.component.html';
import { SkipLinksController as controller } from './skip-links.controller';
import './style.less';

export const skipLinkComponent = {
	template,
	controller,
	bindings: {
		options: '<'
	}
} as ng.IComponentOptions;
