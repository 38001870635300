import uiRouter from '@uirouter/angularjs';
import * as angular from 'angular';
import { dashboardRootViewComponent } from './dashboard-root-view.component';
import { DashboardUtilsService } from './services/dashboard-utils.service';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { goPageModule } from 'go-modules/go-page';
import { skipLinksModule } from 'go-modules/acessibility-skip-links';
import { SelectedModule } from 'go-modules/services/selected';
import { CourseModule } from 'go-modules/services/course';
import { detectModule } from 'go-modules/detect';
import { featureFlagModule } from 'go-modules/feature-flag';
import { uploadManagerModule } from 'go-modules/services/upload-manager';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';
import { responsiveViewModule } from 'go-modules/responsive-view';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { dashboardServicesModule } from 'dashboard/services';
import { lazyPaymentPanelModule } from 'go-modules/payment-panel/lazy-index';
import { newDashboardModule } from 'ngx/go-modules/src/components/dashboard';
import { ngxFoldersViewModule } from 'ngx/dashboard/src/components/folders-view';
import { ngxTermsServiceModule } from 'ngx/go-modules/src/services/terms';
import { goTourModule } from 'go-modules/go-tour';

export const dashboardRootViewModule = angular
	.module('dashboard-root-view-module', [
		uiRouter,
		fullstoryModule,
		goPageModule,
		skipLinksModule,
		SelectedModule,
		CourseModule,
		detectModule,
		featureFlagModule,
		uploadManagerModule,
		ngxGoModalServiceModule,
		lazyPaymentPanelModule,
		responsiveViewModule,
		goPubnubModule,
		dashboardServicesModule,
		newDashboardModule,
		ngxFoldersViewModule,
		ngxTermsServiceModule,
		goTourModule
	])
	.component('dashboardRootView', dashboardRootViewComponent)
	.service('dashboardUtilsService', DashboardUtilsService)
	.name;
