import template from './super-bar.component.html';
import { SuperBarController as controller } from './super-bar.controller';

export const superBarComponent = {
	controller,
	template,
	bindings: {
		user: '<'
	}
};
