
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { CoursePaymentWallWrapperDirective } from 'ngx/go-modules/src/angularjs-wrappers/course-paywall.directive';
import { DataSourceZeroStateComponent } from './data-source-zero-state.component';
import { LoadingModule } from 'ngx/go-modules/src/components/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		NgxZeroStateModule,
		TranslateModule,
		LoadingModule
	],
	declarations: [
		DataSourceZeroStateComponent,
		CoursePaymentWallWrapperDirective
	],
	entryComponents: [
		DataSourceZeroStateComponent
	],
	exports: [
		DataSourceZeroStateComponent
	]
})
export class DataSourceZeroStateModule {}
