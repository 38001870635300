import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { LicenseSeatsManagementComponent } from './license-seats-management.component';
import { SearchInputModule } from 'ngx/go-modules/src/components/search-input/search-input.module';
import { HighestRolePipeModule } from 'ngx/go-modules/src/pipes/highest-role.pipe.ts/highest-role-pipe.module';
import { MatSortModule } from '@angular/material/sort';
import { RemoveUserConfirmDialogComponent } from './remove-user-confirm-dialog/remove-user-confirm-dialog.component';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		GoMaterialModule,
		SearchInputModule,
		HighestRolePipeModule,
		MatSortModule,
		NgxZeroStateModule
	],
	declarations: [
		LicenseSeatsManagementComponent,
		RemoveUserConfirmDialogComponent
	],
	entryComponents: [
		LicenseSeatsManagementComponent,
		RemoveUserConfirmDialogComponent
	],
	providers: [
		TranslatePipe
	],
	bootstrap: [LicenseSeatsManagementComponent],
	exports: [LicenseSeatsManagementComponent]
})
export class LicenseSeatsManagementModule {}
