import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export enum ZeroStates {
	BATCH_CREATE_SESSION = 1,
	NO_VIDEOS = 2,
	START_ACTIVITY = 3
}

@Component({
	selector: 'session-zero-state',
	template: require('./session-zero-state.component.html'),
	styles: [require('./session-zero-state.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionZeroStateComponent implements OnInit, OnDestroy {
	@Output() public startActivity = new EventEmitter();
	@Output() public viewInstructions = new EventEmitter();
	public zeroStates = ZeroStates;
	public componentDestroyed$$ = new Subject();
	public canStartActivity$$ = new BehaviorSubject<boolean>(false);
	public getSessionCreationDisabledMessage$$ = new BehaviorSubject<string>('');

	constructor (
		public sessionListDataSource: SessionListDataSource,
		private cdr: ChangeDetectorRef,
		private ngxActivityService: NgxActivityService,
		private dialog: MatDialog,
		private translate: TranslateService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>
	) {}

	public ngOnInit (): void {
		this.getLicenseAndValidate();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		this.canStartActivity$$.complete();
		this.getSessionCreationDisabledMessage$$.complete();
	}

	public getZeroStateStatus () {
		if (this.hasReviewerRoleOrHigher()) {
			if (this.shouldDisplayCreateVideosForPresentersZeroState()) {
				return ZeroStates.BATCH_CREATE_SESSION;
			}
			return ZeroStates.NO_VIDEOS;
		}
		return ZeroStates.START_ACTIVITY;
	}

	public shouldDisplayCreateVideosForPresentersZeroState () {
		const activity = this.selectedService.getActivity();
		return this.hasInstructorRoleOrHigher() &&
			activity && activity.live_session_enabled &&
			!activity.is_conversation &&
			!activity.has_single_recording_attempt;
	}

	public getActivityName () {
		return this.selectedService.getActivity().name;
	}

	public createBatchSessions () {
		const activity = this.selectedService.getActivity();
		const session = this.Session.model({
			group_id: activity.group_id,
			activity_id: activity.activity_id,
			batch: true
		});
		session.save()
			.then(() => {
				this.sessionListDataSource.reload();
				this.cdr.detectChanges();
			})
			.catch((result) => {
				if (result.status === 422 && result.data.error === 'NoPaidPresentersException') {
					this.dialog.open(MessageDialogComponent, {
						data: {
							title: this.translate.instant('activity-view_no-paid-students-title'),
							message: this.translate.instant('activity-view_no-paid-students-message')
						}
					});
				}
			});
	}

	private hasInstructorRoleOrHigher () {
		return this.selectedService.getGroup().hasInstructorRole(true);
	}

	private hasReviewerRoleOrHigher () {
		return this.selectedService.getGroup().hasReviewerRole(true);
	}

	private validateCanStartActivity () {
		this.canStartActivity$$.next(this.ngxActivityService.canStartActivity());
		this.getSessionCreationDisabledMessage$$.next(this.ngxActivityService.getSessionCreationDisabledMessage());
	}

	private getLicenseAndValidate () {
		this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.componentDestroyed$$),
				filter((selected: any) => selected.group)
			)
			.subscribe(() => {
				this.validateCanStartActivity();
			});
	}
}
