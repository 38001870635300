import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	LicensePlanBadgeComponent
} from 'ngx/go-modules/src/components/license-plan-badge/license-plan-badge.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LicensePlanBadgeComponent
	],
	entryComponents: [
		LicensePlanBadgeComponent
	],
	exports: [
		LicensePlanBadgeComponent
	]
})
export class LicensePlanBadgeModule {}
