import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { catchError, map, Observable, switchMap } from 'rxjs';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import type { Account } from 'ngx/go-modules/src/interfaces/account';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	constructor (
		private http: HttpClient,
		private ngxGroupService: NgxGroupService,
		private signupService: SignUpService,
		@Inject(groupToken) private groupModel,
		@Inject(userServiceToken) public userService: UserService
	) {}

	public getUserOrgAccount (userId: number, orgId: number) {
		return this.http.get<Account>(`${clientSettings.GoReactV2API}/orgs/${orgId}/users/${userId}/account`);
	}

	public getOrgAccounts (orgId: number) {
		return this.http.get<Account[]>(`${clientSettings.GoReactV2API}/groups/${orgId}/org-accounts`);
	}

	public createUserAccount (orgId: number): Observable<Account> {
		return this.signupService.getUseTypes(true).pipe(
			map((useTypes: UseType[]) => useTypes.find(
				(useType) => useType.slug === 'other'
			)),
			switchMap((customType: UseType) => this.ngxGroupService.createAccount(
				{
					name: this.userService.currentUser.fullname,
					use_type_id: customType.use_type_id,
					org_id: orgId
				}
			)),
			map((account) => this.groupModel.model(account))
		);
	}

	public getOrCreateUserAccount (userId: number, orgId: number): Observable<Account> {
		return this.getUserOrgAccount(userId, orgId)
			.pipe(catchError((error) => {
				if (error.status === 404) {
					return this.createUserAccount(orgId);
				}

				throw error;
			}));
	}
}
