import { Component, Inject } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';

@Component({
	selector: 'feedback-hold',
	template: require('./feedback-hold.component.html'),
	styles: [require('./feedback-hold.component.scss')]
})
export class FeedbackHoldComponent {
	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(activityToken) private  activityModel
	) {}

	public toggleFeedbackHold (): void {
		this.activityModel.model(this.selectedService.getActivity()).save();
	}
}
