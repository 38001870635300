import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgLocalization, I18nPluralPipe } from '@angular/common';

@Pipe({ name: 'monthsToYearsMonths' })
export class MonthsToYearsMonthsPipe implements PipeTransform {
	constructor (private translate: TranslateService, private localization: NgLocalization) {}

	public transform (months: number | string): string {
		months = Number(months);

		const i18nPipe: I18nPluralPipe = new I18nPluralPipe(this.localization);
		const monthsMapping = {
			'=0': 'common_months_0',
			'=1': 'common_month',
			'other': 'common_months'
		};
		const yearsMapping = {
			'=0': 'common_years_0',
			'=1': 'common_year',
			'other': 'common_years'
		};

		if (months < 12) {
			const pluralMonth = i18nPipe.transform(months, monthsMapping);
			return `${this.translate.instant(pluralMonth, { months })}`;
		}

		const years = Math.floor(months / 12);
		const remainingMonths = months % 12;

		const pluralYear = i18nPipe.transform(years, yearsMapping);
		const pluralMonth = i18nPipe.transform(remainingMonths, monthsMapping);

		const translatedYear = this.translate.instant(pluralYear, { years });
		const translatedMonth = this.translate.instant(pluralMonth, { months: remainingMonths });


		return translatedYear + (remainingMonths ? ' ' + translatedMonth : '');
	}
}
