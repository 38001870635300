import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from 'ngx/dashboard/src/dashboard.module';
import { GoModulesModule } from 'ngx/go-modules/src/go-modules.module';
import { GoTranslateLoader } from 'ngx/go-modules/src/services/go-translation/go-translate-loader';
import { retry } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InterceptorsModule } from 'ngx/go-modules/src/services/interceptors';

function initializeApp (translate: TranslateService) {
	// Loads translations prior to app initializing instead of lazy loading
	return () => translate.use('en')
		.pipe(retry(2))
		.toPromise()
		// eslint-disable-next-line no-console
		.catch(console.error);
}

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: GoTranslateLoader.factory,
				deps: [HttpClient]
			}
		}),
		BrowserAnimationsModule,
		DashboardModule,
		GoModulesModule,
		InterceptorsModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [TranslateService],
			multi: true
		}
	]
})
export class AppModule {
	constructor () {}

	public ngDoBootstrap () {}
}
