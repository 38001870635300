import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { DashboardNavigationComponent } from './dashboard-navigation/dashboard-navigation.component';
import { AlphabeticalByKeyPipeModule } from 'ngx/go-modules/src/pipes/alphabetical/alphabetical-by-key-pipe.module';
import { SuggestionPanelModule } from 'ngx/go-modules/src/components/sidepanels/suggestion-panel/suggestion-panel.module';
import { DashboardComponent } from './dashboard.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { PWAInstallPromptModule } from 'ngx/dashboard/src/components/pwa-install-prompt/pwa-install-prompt.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomParamsDialogModule } from 'ngx/go-modules/src/components/dialogs/custom-params-dialog/custom-params-dialog.module';
import { RateLimitInterceptor } from 'ngx/go-modules/src/services/interceptors/rate-limit-interceptor/rate-limit-interceptor.service';
import { CustomerSurveySnackBarModule } from 'ngx/go-modules/src/components/snack-bars/customer-survey/customer-survey-snack-bar.module';
import { DraftTourSnackBarModule } from 'ngx/go-modules/src/components/snack-bars/draft-tour/draft-tour-snack-bar.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		BreadcrumbsModule,
		AlphabeticalByKeyPipeModule,
		SuggestionPanelModule,
		GoTooltipModule,
		PWAInstallPromptModule,
		HttpClientModule,
		CustomParamsDialogModule,
		CustomerSurveySnackBarModule,
		DraftTourSnackBarModule
	],
	declarations: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	entryComponents: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	exports: [
		DashboardComponent,
		DashboardHeaderComponent,
		DashboardNavigationComponent
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: RateLimitInterceptor, multi: true }
	]
})
export class DashboardModule {}
