import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { MoveFolderDialogComponent } from './move-folder-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		A11yModule
	],
	declarations: [
		MoveFolderDialogComponent
	],
	entryComponents: [
		MoveFolderDialogComponent
	],
	exports: [
		MoveFolderDialogComponent
	],
	providers: [
		TranslatePipe
	]
})
export class MoveFolderDialogModule {}
