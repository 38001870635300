import * as angular from 'angular';
import * as ngMessages from 'angular-messages';
import * as angularMessages from 'angular-messages';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angulartics from 'angulartics';

import { formValidationModule } from '../form-validation';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { richDropdownModule } from '../rich-dropdown';
import { goDateTimePickerModule } from '../datetimepicker';
import { activityEditor } from './activity-editor.component';
import { activityModel } from '../models/activity';
import { rubricTemplateModel } from '../models/rubric-template';
import { tagModel } from '../models/tag';
import { tagSetModel } from '../models/tag-set';
import { rubricEditorModalModule } from '../modals/rubric-editor';
import { tagEditorModule } from '../tag-editor';
import { uiDecimal } from '../ui-decimal';
import { activityInstructionsEditorModalModule } from '../modals/activity-instructions-editor';
import { toolbarModule } from '../toolbar';
import { panel } from '../panel';
import { goPopoverModule } from '../go-popover';
import { templates } from './templates';
import { helpUrlsModule } from '../help-urls';
import { markerSetPanelComponent } from './marker-set-panel';
import { rubricPanelComponent } from './rubric-panel';
import { instructionsPanelComponent } from './instructions-panel';
import { resourcesPanelComponent } from './resources-panel';
import { sourceMediaPanelComponent } from './source-media-panel';
import { aiMarkerSetPanelComponent } from './ai-marker-set-panel';

import 'angular-material/modules/js/input/input.css';
import './style.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.

// tslint:disable-next-line:import-spacing
import /*{ goModal } from*/ 'go-modules/modals';
// tslint:disable-next-line:import-spacing
import /*{ libraryCollectionViewerModalModule } from*/ 'go-modules/modals/library-collection-viewer';
// tslint:disable-next-line:import-spacing
import /*{ mediaThumbnailModule } from*/ '../media-thumbnail';
// tslint:disable-next-line:import-spacing
import /*{ activityAttachments } from*/ '../activity-attachments';
import { ngxMarkerDisplayModule } from 'ngx/go-modules/src/components/marker-display';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';
import { ActivityEditorTourModule } from './activity-editor-tour';
import { SelectedModule } from 'go-modules/services/selected';
import { ActivityEditorAiMarkersTourModule } from './activity-editor-ai-markers-tour';

export const activityEditorModule = angular.module('go.activity-editor', [
	uiDecimal,
	angularMessages,
	angularTranslate,
	uiTooltip,
	ngMessages,
	formValidationModule,
	richDropdownModule,
	goDateTimePickerModule,
	activityModel,
	rubricTemplateModel,
	tagModel,
	tagSetModel,
	tagEditorModule,
	rubricEditorModalModule,
	activityInstructionsEditorModalModule,
	angulartics,
	panel,
	toolbarModule,
	goPopoverModule,
	helpUrlsModule,
	goLocalizeHelperModule,
	ngxMarkerDisplayModule,
	ngxGoModalServiceModule,
	ActivityEditorTourModule,
	SelectedModule,
	ActivityEditorAiMarkersTourModule,

	// TODO: use module strings directly until circular dependency is fixed
	'go.modal',
	'modal.library-collection-viewer',
	'go.mediaThumbnail',
	'go.activityAttachments'
])
	.run(templates)
	.component('activityEditor', activityEditor)
	.component('markerSetPanel', markerSetPanelComponent)
	.component('rubricPanel', rubricPanelComponent)
	.component('instructionsPanel', instructionsPanelComponent)
	.component('resourcesPanel', resourcesPanelComponent)
	.component('sourceMediaPanel', sourceMediaPanelComponent)
	.component('aiMarkerSetPanel', aiMarkerSetPanelComponent);
