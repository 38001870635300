import {
	ChangeDetectionStrategy,
	Component,
	Inject
} from '@angular/core';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';

@Component({
	selector: 'settings-breadcrumbs',
	template: require('./settings-breadcrumbs.component.html'),
	styles: [require('./settings-breadcrumbs.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsBreadCrumbsComponent {
	constructor (
		@Inject($stateToken) private $state
	) {}

	public goToSettings = () => {
		this.$state.go(States.DASHBOARD_SETTINGS);
	};
}
