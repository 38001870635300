import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'comment-indicator',
	template: require('./comment-indicator.component.html'),
	styles: [require('./comment-indicator.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentIndicatorComponent {
	@Input() public unviewedComments: number;

	constructor () {}
}
