import { NgModule } from '@angular/core';
import { OrgPickerComponent } from 'ngx/go-modules/src/components/org-picker/org-picker.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		GoMaterialModule,
		CommonModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		OrgPickerComponent
	],
	entryComponents: [
		OrgPickerComponent
	],
	exports: [
		OrgPickerComponent
	]
})
export class OrgPickerModule {}
