import { clientSettings } from 'go-modules/models/common/client.settings';
import { SessionStorageService } from './session-storage.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class SessionManagerService {
	public static readonly NG1_INJECTION_NAME = 'sessionManager' as const;

	public static DEFAULT_USER_ACTIVITY_DETECTION_DELAY = 30 * 1000; // every 30 seconds
	public static DEFAULT_USER_ACTIVITY_DETECTION_MAX_DURATION = 5 * 1000 * 60; // 5 minutes
	public static ILLEGAL_BASE_STRING = 'Illegal base64url string!';

	public refreshInProgress: boolean = false;
	public activityDetectionTimeoutPromise: ng.IPromise<any>;
	public config = {
		authHeader: 'Authorization',
		tokenType: 'Bearer',
		tokenStorageKey: 'token',
		tokenStorageKeyPrefix: 'goreact-',
		minRefreshThreshold: 0.25
	};
	public subscribers = {
		begin: [],
		ended: [],
		timeout: []
	};
	public sessionTimeoutId: ReturnType<typeof setTimeout>;

	/* @ngInject */
	constructor (
		private $rootScope: ng.IRootScopeService,
		private sessionManagerConfig,
		private $q: ng.IQService,
		private $interval: ng.IIntervalService,
		private UserActivityDetectionService,
		private sessionStorage: SessionStorageService,
		private $log: ng.ILogService,
		private $cookies: ng.cookies.ICookiesService
	) {
		// sets config prefix on start
		this.sessionStorage.setPrefix(this.config.tokenStorageKeyPrefix);

		// Update session manager config
		const configs = this.sessionManagerConfig.get();
		Object.keys(configs).forEach((key) => {
			this.updateConfig(key, configs[key]);
		});

		// Begin client session immediately
		this.sessionManagerConfig
			.accessTokenGetter()
			.then((accessToken) => {
				this.begin(accessToken);
			});

		const clearTimeout = this.clearTimeout.bind(this);
		const off = this.off.bind(this);
		const beginUserActivityDetection = this.beginUserActivityDetection.bind(this);
		const endUserActivityDetection = this.endUserActivityDetection.bind(this);

		// Destroy session manager when root scope is destroyed
		this.$rootScope.$on('$destroy', () => {
			clearTimeout();
			off();
		});

		// When the session begins anew, start user activity detection
		this.on('begin', () => {
			beginUserActivityDetection();
		});

		// When the session ends, end the user activity detection process
		this.on('timeout', () => {
			endUserActivityDetection();
		});

		// On instantiation, end session if it is expired
		if (!this.isEnded() && this.isExpired()) {
			this.end();
		}
	}

	public getConfig (key) {
		if (key) {
			return this.config[key];
		}
		return this.config;
	};

	/**
	 * Update config.
	 *
	 * @param key
	 * @param value
	 */
	public updateConfig (key, value) {
		this.config[key] = value;

		if (key === 'tokenStorageKeyPrefix') {
			this.sessionStorage.setPrefix(this.config.tokenStorageKeyPrefix);
		}
	};

	public refreshToken () {
		// Wrap in $q when in case refreshTokenGetter is still a noop
		if (!this.refreshInProgress) {
			this.refreshInProgress = true;
			return this.$q.when(this.sessionManagerConfig.refreshTokenGetter(this.getAccessToken()))
				.then(this.begin.bind(this))
				.finally(() => {
					this.refreshInProgress = false;
				});
		}

		return this.$q.when(); // always return a promise
	}

	public getAccessToken () {
		// TODO remove AuthCookieCopy when we no longer use local storage tokens
		const token = this.sessionStorage.get(this.config.tokenStorageKey);
		const cookieToken = this.$cookies.get(clientSettings.TokenName + '_copy');
		if (cookieToken && token !== cookieToken) {
			this.setAccessToken(cookieToken, true);
			return cookieToken;
		}
		return token;
	};

	public isExpired (offsetSeconds = 0) {

		// Is this a self-demo experiment?
		const selfDemoCookie = this.$cookies.get('is_self_demo_experiment');
		if (selfDemoCookie === 'true') {
			return true;
		}

		const token = this.getAccessToken();
		if (!token) {
			return true;
		}

		const date = this.getTokenExpirationDate(token);
		if (date === null) {
			return false;
		}

		return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
	}

	public getTokenExpirationDate (token) {
		const decoded = this.decodeToken(token);

		if (typeof decoded.exp === 'undefined') {
			return null;
		}

		const d = new Date(0); // The 0 here is the key, which sets the date to the epoch
		d.setUTCSeconds(decoded.exp);

		return d;
	}

	public decodeToken (token) {
		const parts = token.split('.');

		if (parts.length !== 3) {
			throw new Error('JWT must have 3 parts');
		}

		const decoded = this.urlBase64Decode(parts[1]);
		if (!decoded) {
			throw new Error('Cannot decode the token');
		}

		return JSON.parse(decoded);
	};

	public urlBase64Decode (str) {
		let output = str.replace(/-/g, '+').replace(/_/g, '/');
		switch (output.length % 4) {
			case 0:
			{
				break;
			}
			case 2:
			{
				output += '==';
				break;
			}
			case 3:
			{
				output += '=';
				break;
			}
			default:
			{
				throw SessionManagerService.ILLEGAL_BASE_STRING;
			}
		}
		return decodeURIComponent(escape(window.atob(output))); //polifyll https://github.com/davidchambers/Base64.js
	}

	public getRemainingDuration () {
		let result = -1;
		const token = this.getAccessToken();

		if (token && this.decodeToken(token).hasOwnProperty('exp')) {
			const tokenExpiration = this.decodeToken(token).exp * 1000;
			result = tokenExpiration - Date.now();
			if (result < 0) {
				result = 0;
			}
		}

		return result;
	};

	public getTimeRemainingBeforeRefreshNeeded () {
		const initialDuration = this.getInitialDuration();
		const remainingDuration = this.getRemainingDuration();
		// if less than a quarter of time remains then refresh
		const result = remainingDuration - (initialDuration * this.config.minRefreshThreshold);
		return Math.max(0, result);
	};

	public beginUserActivityDetection () {
		// If the session is expired already, don't run detection
		if (this.isExpired()) {
			return this.$q.when();
		}

		const maxDuration = Math.min(
			this.getRemainingDuration(),
			SessionManagerService.DEFAULT_USER_ACTIVITY_DETECTION_MAX_DURATION
		);
		// Clear previous timeout
		this.$interval.cancel(this.activityDetectionTimeoutPromise);
		// Run user activity detection
		return this.UserActivityDetectionService.run(maxDuration)
			.then(() => {
				// When user activity is detected and a refresh is needed, refresh the session.
				const timeRemainingBeforeRefreshNeeded = this.getTimeRemainingBeforeRefreshNeeded();
				if (timeRemainingBeforeRefreshNeeded === 0) {
					this.refreshToken();
				} else {
					// When user activity has been detected but a refresh is not yet needed,
					// wait until a refresh is needed before running user activity detection again.
					const count = 1;
					this.activityDetectionTimeoutPromise = this.$interval(
						this.beginUserActivityDetection.bind(this),
						timeRemainingBeforeRefreshNeeded,
						count
					);
				}
			})
			.catch(() => {
				const remainingSessionDuration = this.getRemainingDuration();
				const delay =
					remainingSessionDuration > SessionManagerService.DEFAULT_USER_ACTIVITY_DETECTION_DELAY
						? SessionManagerService.DEFAULT_USER_ACTIVITY_DETECTION_DELAY
						: 0;
				// When no user activity is detected, wait for a given interval and try again.
				this.activityDetectionTimeoutPromise =
					this.$interval(this.beginUserActivityDetection.bind(this), delay, 1);
			});
	};

	public endUserActivityDetection () {
		this.UserActivityDetectionService.stop();
	};

	public on (type, callback) {
		if (Object.prototype.toString.call(this.subscribers[type]) !== '[object Array]') {
			throw new Error('Event ' + type + ' not supported by sessionManager');
		}
		this.subscribers[type].push(callback);
	};

	public clearTimeout () {
		clearTimeout(this.sessionTimeoutId);
	};

	public begin (token = null) {

		// Default to current token if token not given
		if (token) {
			this.setAccessToken(token);
		}

		// Handle access token expiration
		const remainingDuration = this.getRemainingDuration();
		if (remainingDuration >= 0) {

			// Clear session timeout
			this.clearTimeout();

			// Set timeout for remaining duration
			this.sessionTimeoutId = setTimeout(() => {
				if (!this.isEnded()) {
					if (this.isExpired()) {
						this.broadcast('timeout');
						this.end();
					} else {
						// If we get to this point, then the token must have
						// been changed without the session being aware of it.
						// We could run an interval timer but this is
						// a rare scenario and why consume additional
						// resources to decode the token and read the expiration.
						// Just begin again and set another timeout using
						// the actual session duration.
						this.begin();
					}
				}
			}, remainingDuration);

			this.broadcast('begin');
		}
	}

	public setAccessToken (token, skipCurrentAccessCheck: boolean = false) {
		let currentAccessToken = '';
		// prevent call stack exceeded from going in circles
		if (!skipCurrentAccessCheck) {
			currentAccessToken = this.getAccessToken();
		}
		if (currentAccessToken !== token) {
			try {
				this.decodeToken(token);
				this.sessionStorage.set(this.config.tokenStorageKey, token);
			} catch (error) {
				this.$log.error(error);
			}
		}
	}

	public broadcast (type) {
		if (Object.prototype.toString.call(this.subscribers[type]) !== '[object Array]') {
			throw new Error('Event ' + type + ' not supported by sessionManager');
		}

		let i = this.subscribers[type].length;
		while (i--) {
			this.subscribers[type][i](this);
		}
	}

	public off (type, callback = null) {
		if (!type) {
			for (const subscriberType in this.subscribers) {
				if (this.subscribers.hasOwnProperty(subscriberType)) {
					this.off(subscriberType);
				}
			}
		} else if (Object.prototype.toString.call(this.subscribers[type]) === '[object Array]') {
			let i = this.subscribers[type].length;
			while (i--) {
				if (!callback || callback === this.subscribers[type][i]) {
					this.subscribers[type].splice(i, 1);
				}
			}
		}
	};

	public getRequestHeaders () {
		const headers = {},
			authHeader = this.getAuthHeader();

		// Set auth header
		if (authHeader) {
			headers[this.config.authHeader] = authHeader;
		}

		return headers;
	};

	public getAuthHeader () {
		let result = '';
		const token = this.getAccessToken();
		if (token) {
			result = this.getAuthPrefix() + token;
		}
		return result;
	};

	public isRefreshNeeded () {
		let result = false;
		const initialDuration = this.getInitialDuration();

		if (!this.isExpired() && initialDuration > 0) {
			result = this.getTimeRemainingBeforeRefreshNeeded() === 0;
		}

		return result;
	};

	public getInitialDuration () {
		let result = -1;
		const token = this.getAccessToken();

		if (token) {
			const decodedToken = this.decodeToken(token);

			// Check to see if token has an expiration
			if (decodedToken.hasOwnProperty('exp')) {

				// Throw an error if no issued at property exists
				if (!decodedToken.hasOwnProperty('iat')) {
					throw new Error('Expected token to have "iat" property');
				}

				result = (decodedToken.exp - decodedToken.iat) * 1000;
			}
		}

		return result;
	};

	public getAuthPrefix () {
		return this.config.tokenType + ' ';
	}

	public end () {
		// Clear session timeout
		this.clearTimeout();
		// Check to see if we still
		// have an access token
		if (this.isEnded()) {
			return;
		}
		// remove auth cookie _copy
		this.$cookies.remove(clientSettings.TokenName + '_copy', { domain: '.goreact.com', path: '/' });
		this.$cookies.remove(clientSettings.TokenName + '_copy', { path: '/' });
		// Clear session storage
		this.sessionStorage.clear();
		this.broadcast('ended');
	};

	/**
	 * Whether session has ended
	 *
	 * @returns {boolean}
	 */
	public isEnded () {
		return !this.getAccessToken();
	};

	public isActive () {
		return !this.isExpired();
	};

	public isLockedOut (responseData) {
		return !!responseData?.remainingAccountLockSeconds;
	}

	public getAccountLockedTime (): number {
		let remainingAccountLockTimeMinutes = 0;
		const accountLockTime = sessionStorage.getItem('accountLockTime');

		if (accountLockTime) {
			sessionStorage.removeItem('accountLockTime');
			remainingAccountLockTimeMinutes = this.convertToMinutes(accountLockTime);
		}
		return remainingAccountLockTimeMinutes;
	}

	public convertToMinutes (timeInSeconds): number {
		timeInSeconds = parseInt(timeInSeconds, 10);
		return Math.ceil(timeInSeconds / 60);
	}
}

export const sessionManagerToken = upgradeNg1Dependency(SessionManagerService);
