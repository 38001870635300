import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DotIndicatorComponent } from './dot-indicator.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoTooltipModule
	],
	declarations: [
		DotIndicatorComponent
	],
	exports: [
		DotIndicatorComponent
	]
})
export class DotIndicatorModule {}
