import * as angular from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export enum FEEDBACK_TYPES  {
	RECORD_VIDEO = 'RECORD_VIDEO',
	RECORD_AUDIO = 'RECORD_AUDIO',
	UPLOAD = 'UPLOAD',
	LIBRARY = 'LIBRARY',
	YOUTUBE = 'YOUTUBE'
}

export interface FeedbackType {
	created_at: Date;
	id: number;
	name: FEEDBACK_TYPES;
	pivot: object;
	updated_at: Date;
}

export enum ActivityTemplateModelType {
	STANDARD = 'standard',
	STIMULUS_RESPONSE = 'stimulus_response',
	FEEDBACK_ONLY = 'feedback_only',
	CONVERSATION_BOARD = 'conversation_board'
};

/* @ngInject */
export function ActivityTemplateModel (
	$resource,
	cacheManager,
	activityTemplateModelInterceptor
) {

	const resourceUrl = `${clientSettings.GoReactV2API}/activity_templates`;

	const activityTemplateModel = $resource(resourceUrl, {id: '@id'}, {
		query: {
			method: 'GET',
			isArray: true,
			interceptor: activityTemplateModelInterceptor
		}
	});

	activityTemplateModel.FEEDBACK_TYPES = FEEDBACK_TYPES;

	activityTemplateModel.TYPE = ActivityTemplateModelType;

	activityTemplateModel.RULE = {
		NONE: 'none',
		READ: 'read',
		WRITE: 'write',
		ACTIVE: 'active'
	};

	activityTemplateModel.RULES = [
		activityTemplateModel.RULE.NONE,
		activityTemplateModel.RULE.READ,
		activityTemplateModel.RULE.WRITE,
		activityTemplateModel.RULE.ACTIVE
	];

	/**
	 * Get activity template model and check cache
	 *
	 * @param data
	 * @returns {Object}
	 */
	activityTemplateModel.model = function (data) {
		let newInstance = new activityTemplateModel(data);

		if (newInstance.getId() > 0) {
			const cachedInstance = activityTemplateModel.getCache().get(newInstance.getId());

			// if instance is already in the cache,
			// the new instance should use that reference
			if (cachedInstance) {
				angular.extend(cachedInstance, newInstance);
				newInstance = cachedInstance;
			} else {
				activityTemplateModel.getCache().put(newInstance.getId(), newInstance);
			}
		}

		return newInstance;
	};

	/**
	 * Get / set activity template in cache
	 */
	activityTemplateModel.getCache = function () {
		return cacheManager('activityTemplate');
	};

	/**
	 * Get id
	 *
	 * @returns {string}
	 */
	activityTemplateModel.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Get slug
	 *
	 * @returns {string}
	 */
	activityTemplateModel.prototype.getType = function () {
		return this.slug;
	};

	/**
	 * Whether property rule is set to 'none'
	 */
	activityTemplateModel.prototype.isNone = function (property: string): boolean {
		return this[property] === activityTemplateModel.RULE.NONE;
	};

	/**
	 * Whether property rule is set to 'read'
	 */
	activityTemplateModel.prototype.isRead = function (property: string): boolean {
		return this[property] === activityTemplateModel.RULE.READ;
	};

	/**
	 * Whether property rule is set to 'write'
	 */
	activityTemplateModel.prototype.isWrite = function (property: string): boolean {
		return this[property] === activityTemplateModel.RULE.WRITE;
	};

	/**
	 * Whether property rule is set to 'active'
	 */
	activityTemplateModel.prototype.isActive = function (property: string): boolean {
		return this[property] === activityTemplateModel.RULE.ACTIVE;
	};

	/**
	 * Whether a given template is fundamentally different from another template.
	 *
	 * There are certain properties that exist on templates that drastically impact
	 * whether or not one template can safely be changed to another template. A given
	 * template is considered "fundamentally different" from another template when
	 * certain property rules don't match the supplied template's property rules.
	 */
	activityTemplateModel.prototype.isFundamentallyDifferentFrom = function (template: any): boolean {
		const properties: string[] = [
			'source_media',
			'has_response_media'
		];

		// Iterate over each of these properties and compare each
		// property rule with the supplied template's property rule.
		// When a difference is detected between the two, we know that
		// the supplied template fundamentally differs from this template.
		for (const property of properties) {
			if (this[property] !== template[property]) {
				return true;
			}
		}

		return false;
	};

	/**
	 * Whether slides are enabled
	 *
	 * @returns {boolean}
	 */
	activityTemplateModel.prototype.isSlidesEnabled = function () {
		return !this.isNone('is_slides_enabled');
	};

	return activityTemplateModel;
}

ActivityTemplateModel.NG1_INJECTION_NAME = 'ActivityTemplateModel' as const;
export const activityTemplateModelToken = upgradeNg1Dependency(ActivityTemplateModel);
