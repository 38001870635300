import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { activityTemplateModel } from '../activity-template';
import { mediaModel } from '../media';

import { ActivityModelFactory } from './activity.factory';
import { activityFilter } from './activity.filter';
import { PrivacyLevel } from './privacy-level';
import { activitiesWithIdFilter } from './activities-with-id.filter';
import { activityModelTransformer } from './activity-transformer.service';
import { activityModelInterceptor } from './activity-interceptor.service';
import { ActivityInstructionModel } from './activity-instruction';
import { ActivitySourceMediaModel } from './activity-source-media';
import { attachmentModel } from '../attachment';
import { featureFlagModule } from 'go-modules/feature-flag';

// TODO: Resolve circular dependency
import '../session';

export {
	ActivityInstructionModel,
	ActivitySourceMediaModel,
	PrivacyLevel
};

export const activityModel = angular.module('go.models.activity', [
	angularResource,
	common,
	activityTemplateModel,
	mediaModel,
	attachmentModel,
	'go.models.session',
	featureFlagModule
])
	.service('activityModelInterceptor', activityModelInterceptor)
	.service('activityModelTransformer', activityModelTransformer)
	.filter('activityFilter', activityFilter)
	.filter('activitiesWithIdFilter', activitiesWithIdFilter)
	.factory(ActivityModelFactory.NG1_INJECTION_NAME, ActivityModelFactory)
	.name;
