export const BREAKPOINTS_NUM = {
	SCREEN_XXS: 320,
	SCREEN_XXSM: 375,
	SCREEN_XSM: 425,
	SCREEN_XS: 480,
	SCREEN_TABLET: 580,
	SCREEN_SM: 768,
	SCREEN_MD: 850,
	SCREEN_LG: 1050,
	SCREEN_XL: 1200
};

export const BREAKPOINTS = Object.keys(BREAKPOINTS_NUM)
	.reduce((breakpoints, nextKey) => {
		breakpoints[nextKey] = `${BREAKPOINTS_NUM[nextKey]}px`;
		return breakpoints;
	}, {} as { [key: string]: string });
