import { NgModule } from '@angular/core';
import { AccessibleDropListDirective } from './accessible-drop-list.directive';

@NgModule({
	declarations: [
		AccessibleDropListDirective
	],
	exports: [
		AccessibleDropListDirective
	]
})
export class AccessibleDropListModule {}
