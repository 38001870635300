import { NgModule } from '@angular/core';
import { HighestRolePipe } from './highest-role.pipe';

@NgModule({
	declarations: [
		HighestRolePipe
	],
	exports: [
		HighestRolePipe
	]
})
export class HighestRolePipeModule {}
