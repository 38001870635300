import { NgModule } from '@angular/core';
import { MonthsToYearsMonthsPipe } from './months-years.pipe';

@NgModule({
	declarations: [
		MonthsToYearsMonthsPipe
	],
	exports: [
		MonthsToYearsMonthsPipe
	]
})
export class MonthsToYearsMonthsPipeModule {}
