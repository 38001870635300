import template from './modal.html';
import { ActivityInstructionsEditorModalController } from './modal.controller';
import { IModalInstanceService } from 'angular-ui-bootstrap';

export class ActivityInstructionsEditorModal {
	private defaultOptions: any;

	/* @ngInject */
	constructor (private goModalBootstrap: any, protected ActivityModel: any) {
		this.defaultOptions = {
			backdrop: 'static',
			bindToController: true,
			controller: ActivityInstructionsEditorModalController,
			controllerAs: '$ctrl',
			goreact: true, // For new modal styles
			keyboard: false,
			template
		};
	}

	public open (options: any): IModalInstanceService {
		const modalOptions = {
			...this.defaultOptions,
			...options,
			windowClass: options.windowClass || '',
			modalData: options.modalData || {}
		};
		modalOptions.windowClass += ' activity-instructions-editor-modal';

		if (!modalOptions.modalData.instructionsType) {
			throw new Error('Required parameter `instructionsType` was not supplied');
		}

		return this.goModalBootstrap.open(modalOptions);
	}
}
