import { TransactionMethod, TransactionModel, TransactionPaymentType } from './transaction.schema';
import { clientSettings } from '../common/client.settings';
import { DataTransformer } from '../common/data-transformer';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class TransactionFactory {
	public static readonly NG1_INJECTION_NAME = 'TransactionModel' as const;

	/* @ngInject */
	constructor (
		private $http: ng.IHttpService
	) {}

	/**
	 * Get the transactions for a user
	 * @param userId number
	 * @param method string
	 */
	public getTransactions (
		userId: number,
		method: TransactionMethod[] = [TransactionMethod.CARD],
		type: TransactionPaymentType[] = [TransactionPaymentType.PURCHASE],
		groupId?: number,
		createdAfter?: string
	): ng.IPromise<TransactionModel[]> {
		const queryParams = [
			'method=' + method.join(','),
			'payment_type=' + (type.join(','))
		];

		if (groupId) {
			queryParams.push('group_id=' + groupId);
		}

		if (createdAfter) {
			queryParams.push('created_at_after=' + createdAfter);
		}

		return this.$http.get(`${clientSettings.GoReactV2API}/users/${userId}/transactions?${queryParams.join('&')}`)
			.then(this.transformResponse);
	}

	/**
	 * Resend the email receipt for a transaction to the purchaser
	 * @param userId number
	 * @param transactionId number
	 */
	public resendEmail (userId: number, transactionId: number): ng.IHttpPromise<null> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users/${userId}/transactions/${transactionId}/resend-email`, null);
	}

	private transformResponse (response: ng.IHttpResponse<TransactionModel[]>): TransactionModel[] {
		return response.data.map((model: TransactionModel) => {
			model.created_at = DataTransformer.transformToDate(model, 'created_at');
			model.modified_at = DataTransformer.transformToDate(model, 'modified_at');
			model.deleted_at = DataTransformer.transformToDate(model, 'deleted_at');
			return model;
		});
	}
}

export const transactionFactoryToken = upgradeNg1Dependency(TransactionFactory);
