import template from './modal.html';
import { CreateCourseModalController as controller } from './modal.controller';

export class CreateCourseModalService {

	private defaultOptions = {
		template,
		controller,
		windowClass: 'goreact-modal create-course-modal',
		ariaLabelledBy: 'create-course-modal-title',
		keyboard: false,
		backdrop: 'static',
		controllerAs: '$ctrl',
		bindToController: true,
		goreact: true // For new modal styles
	};

	/** @ngInject */
	constructor (private goModalBootstrap) {}

	public open (options) {
		Object.assign(options, this.defaultOptions);
		return this.goModalBootstrap.open(options);
	}
}
