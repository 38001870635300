import { ActivityEditorController } from '../activity-editor.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export interface Bindings {
	activityEditorController: ActivityEditorController;
	sourceMedia: any;
	onRemove: () => void;
}

export class SourceMediaPanelController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public sourceMedia: any;
	public onRemove: () => void;

	public mediaThumbnailOptions: any;
	private eventSubscription: any;

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private mediaPreviewModal: any,
		private eventService: EventService
	) {
		this.mediaThumbnailOptions = {
			actions: false,
			hideTitle: true,
			hideDuration: true
		};
	}

	public $onInit (): void {
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				if (this.sourceMedia.media &&
					parseInt(this.sourceMedia.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					Object.assign(this.sourceMedia.media, ev.data);
				}
			});
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public openMediaPreviewModal (edit: boolean) {
		const modalData = {
			media: this.sourceMedia.media,
			edit,
			allowDownload: true,
			groupId: this.activityEditorController.activity.group_id
		};

		return this.mediaPreviewModal.open({
			modalData
		}).result;
	}

}
