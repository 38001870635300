import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { StartTestActivityModalController } from './modal.controller';
import template from './modal.html';

/* @ngInject */
export function startTestActivityModal (goModalBootstrap) {

	function open (options: any = {}) {
		options.template = template;
		options.controller = StartTestActivityModalController;
		options.windowClass = options.name = 'start-test-activity-modal';
		options.backdropClass = 'start-test-activity-modal-backdrop';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return goModalBootstrap.open(options);
	}

	return { open };
}

startTestActivityModal.NG1_INJECTION_NAME = 'startTestActivityModal' as const;
export const startTestActivityModalToken = upgradeNg1Dependency(startTestActivityModal);
