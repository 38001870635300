import type { NgModuleRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as localeData from 'dayjs/plugin/localeData';
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
import { lastValueFrom } from 'rxjs';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { tmh } from 'angular-dynamic-locale';

import 'dayjs/locale/nl.js';
import 'dayjs/locale/en.js';

export enum Language {
	NL = 'nl',
	EN = 'en' // Ensure this is always last
}

/** @ngInject */
export class GoLocalizeHelperService {

	public static readonly NG1_INJECTION_NAME = 'goLocalizeHelper' as const;

	constructor (
		private $translate: angular.translate.ITranslateService,
		private tmhDynamicLocale: tmh.IDynamicLocale,
		// eslint-disable-next-line angular/no-private-call
		private $$angularLazyModuleRef,
		private $q: ng.IQService,
		private $document: ng.IDocumentService
	) {}

	public configure (language: Language, translate: TranslateService = null): ng.IPromise<void> {
		const ng1TranslatePromise = this.$translate.use(language);
		this.tmhDynamicLocale.set(language);
		dayjs.locale(language);
		this.$document[0].documentElement.setAttribute('lang', language);
		return this.$q.all({
			// eslint-disable-next-line angular/no-private-call
			injector: this.$$angularLazyModuleRef.promise as ng.IPromise<NgModuleRef<any>>,
			ng1TranslatePromise
		}).then((result) => {
			// If we are being bootted from an ngx context we may not
			// have access to the ngx translate service yet. We eventually
			// will, but not yet
			if (translate === null && result.injector == null) {
				return;
			}

			const translateService = translate || result.injector.get(TranslateService);
			return this.$q.when(lastValueFrom(translateService.use(language)));
		});
	}

	public get longDateFormat () {
		return dayjs().localeData().longDateFormat('L');
	}

	public reset () {
		this.configure(Language.EN);
	}
}

export const goLocalizeHelperToken = upgradeNg1Dependency(GoLocalizeHelperService);
