import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { OrgContextMenuComponent } from './org-context-menu.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatMenuModule,
		GoMaterialModule
	],
	declarations: [
		OrgContextMenuComponent
	],
	entryComponents: [
		OrgContextMenuComponent
	],
	exports: [
		OrgContextMenuComponent
	],
	providers: [
		TranslatePipe
	]
})
export class OrgContextMenuModule {}
