import { IPromise } from 'angular';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { SessionStorageService } from 'go-modules/session-manager/session-storage.service';
import * as angular from 'angular';
import { UserService } from 'go-modules/models/user/user.service';
import { InviteRole } from 'go-modules/models/invite/invite.schema';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export const MASKED_KEY = 'masked-user';

export class Masquerade {
	public static readonly NG1_INJECTION_NAME = 'masquerade' as const;

	/* @ngInject */
	constructor (
		private authService: AuthService,
		private $window: angular.IWindowService,
		private sessionStorage: SessionStorageService,
		private userService: UserService
	) {}

	public as (asUser, redirect: string = this.$window.location.href): IPromise<any> {
		return this.authService
			.loginAs(asUser.user_id)
			.then((response) => {
				const user = response.data.user;
				if (user.masked_user_id) {
					const currentUser = this.userService.currentUser;
					this.sessionStorage.set(MASKED_KEY, {
						user_id: user.masked_user_id,
						redirectTo: this.$window.location.href,
						isInstructor: (currentUser && currentUser.isInstructor) || false,
						isRootUser: (currentUser && currentUser.is_root_user) || false,
						asUser
					});
				}

				this.$window.location.href = redirect;
			});
	}

	public unMask (): IPromise<any> {
		const maskedUser = this.sessionStorage.get(MASKED_KEY);

		if (!maskedUser) {
			throw new Error('User is not masked');
		}

		return this.as(maskedUser, maskedUser.redirectTo).then(() => {
			this.sessionStorage.remove(MASKED_KEY);
		});
	}

	public isMasked (): boolean {
		return !!this.sessionStorage.get(MASKED_KEY);
	}

	/**
	 * maskedAsStudent
	 *
	 * Checks if maskedUser's role is presenter.
	 *
	 * @returns {boolean}
	 */
	public maskedAsStudent (): boolean {
		const maskedUser = this.sessionStorage.get(MASKED_KEY);
		return this.isMasked() && maskedUser.asUser.role === InviteRole.PRESENTER;
	}

	/**
	 * shouldHideElements
	 *
	 * Validates if the maskedUser is instructor and not root user to hide privacy concerned controls.
	 *
	 * @returns {boolean}
	 */
	public shouldHideElements (): boolean {
		const maskedUser = this.sessionStorage.get(MASKED_KEY);
		return maskedUser.isInstructor && !maskedUser.isRootUser;
	}
}

export const masqueradeToken = upgradeNg1Dependency(Masquerade);
