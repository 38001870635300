import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { CodeInputComponent } from './code-input/code-input.component';
import { EmailVerificationComponent } from './email-verification.component';
import { EmailVerificationDialogComponent } from './email-verification-dialog/email-verification-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		GoTooltipModule,
		A11yModule
	],
	declarations: [
		CodeInputComponent,
		EmailVerificationComponent,
		EmailVerificationDialogComponent
	],
	entryComponents: [
		CodeInputComponent,
		EmailVerificationComponent,
		EmailVerificationDialogComponent
	],
	exports: [
		CodeInputComponent,
		EmailVerificationComponent,
		EmailVerificationDialogComponent
	]
})
export class EmailVerificationModule {}
