import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { LicenseService } from './license.service';
export { LicenseService as NgxLicenseService } from './license.service';

export const ngxLicenseServiceModule = angular.module('ngxLicenseServiceModule', [])
	.factory('ngxLicenseService', downgradeInjectable(LicenseService) as any)
	.name;

