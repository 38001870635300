import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Taken from angular's validators.ts to ignore empty fields
 */
function isEmptyInputValue (value: any): boolean {
	return value == null ||
		((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

export const defaultSeperator =  /[,;\s]+/;

/**
 * A validator that allows you to run other validators over comma/space seperated data
 * By default the control's value is split by either commas, semicolons, or whitespace.
 */
export function multiValueValidate (validationFn: ValidatorFn, seperator: RegExp = defaultSeperator): ValidatorFn {
	return function (control: AbstractControl): ValidationErrors | null {
		if (isEmptyInputValue(control.value)) {
			return null; // don't validate empty values to allow optional controls
		}

		// Get the values, either the control contains a list or a string
		let values: any[];
		if (typeof control.value === 'string') {
			values = control.value.split(seperator);
		} else if (Array.isArray(control.value)) {
			values = control.value;
		} else {
			throw new Error("The control's value is not null, string, or array, so multiInputValidate doesn't work");
		}

		// Allow leading/trailing seperators by removing an empty first/last value
		if (isEmptyInputValue(values[0])) {
			values.shift();
		}
		if (isEmptyInputValue(values[values.length - 1])) {
			values.pop();
		}

		// Ask if any of the values fails validation.
		const errors: {multi: (ValidationErrors & {value: any})[]} = {multi: []};
		for (const value of values) {
			// And ask the original validation, and push errors into the array
			const result = validationFn(new FormControl(value));
			if (result !== null) {
				errors.multi.push({
					value, ...result
				});
			}
		}

		return errors.multi.length > 0 ? errors : null;
	};
}
