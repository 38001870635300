import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output
} from '@angular/core';

@Component({
	selector: 'service-bootstrap',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBootstrapComponent implements AfterViewInit{
	@Output()
	public initialized: EventEmitter<void> = new EventEmitter();

	public ngAfterViewInit (){
		this.initialized.emit();
	}
}
