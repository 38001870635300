import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';

@Component({
	selector: 'activity-reorder',
	template: require('./activity-reorder.component.html'),
	styles: [require('./activity-reorder.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityReorderComponent implements OnInit, OnDestroy, AfterViewInit {
	public activities: Activity[];
	public activeItem: number;
	public listSubscription: Subscription;
	public originalIndex: { [key: string]: number } = {};
	@ViewChildren('listItem') public listItems: QueryList<ElementRef>;

	constructor (
		private dialogRef: GoDialogRef,
		private activityListDataSource: ActivityListDataSource,
		private activityService: NgxActivityService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {}

	public ngOnInit () {
		this.activityListDataSource.connect().subscribe((activities) => {
			this.activities = activities.sort((a,b) => {
				const initialSortValue = a.sort_index - b.sort_index;
				if (initialSortValue !== 0) return initialSortValue;
				return a.activity_id - b.activity_id;
			});
			this.updateOriginalIndex();
		});
	}

	public ngAfterViewInit () {
		this.listSubscription = this.listItems.changes.subscribe(() => {
			this.setFocus();
		});
	}

	public ngOnDestroy () {
		this.listSubscription.unsubscribe();
	}

	public setFocus () {
		this.listItems.toArray()[this.activeItem].nativeElement.focus();
	}

	public drop (event: CdkDragDrop<[]>) {
		moveItemInArray(this.activities, event.previousIndex, event.currentIndex);
		this.activeItem = event.currentIndex;
		this.setFocus();
		this.updateOriginalIndex();
	}

	public onActiveItemChange ({currentIndex, newIndex}) {
		moveItemInArray(this.activities, currentIndex, newIndex);
		this.activeItem = newIndex;
		this.setFocus();
	}

	public onFinalizePosition () {
		this.updateOriginalIndex();
	}

	public updateOriginalIndex () {
		this.activities.forEach((activity, index) => {
			this.originalIndex[activity.activity_id] = index;
		});
	}

	public onCancelReorder ({currentIndex, itemId}) {
		const originalIndex = this.originalIndex[itemId];
		moveItemInArray(this.activities, currentIndex, originalIndex);
		this.activeItem = originalIndex;
		this.setFocus();
	}

	public cancel () {
		this.dialogRef.close();
	}

	public save () {
		const payload = this.activities.map((activity, i) => {
			activity.sort_index = i;
			return {
				activity_id: activity.activity_id,
				sort_index: i
			};
		});
		this.activityService.updateSort(this.selectedService.getGroup().group_id, payload)
			.subscribe({
				next: () => {
					this.activityListDataSource.sortBy(SortOrder.DEFAULT);
					this.activities.forEach((activity) => {
						this.activityListDataSource.editActivity(activity);
					});
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'activity-reorder-panel_success'
					});
					this.dialogRef.close();
				},
				error: () => {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'activity-reorder-panel_failed'
					});
					this.dialogRef.close();
				}
			});
	}
}
