import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { default as scrollIntoViewIfNeeded } from 'scroll-into-view-if-needed';
import { GoTourPosition } from './go-tour.service';

// Must keep in sync with arrow width in tour-step.less
const ARROW_MARGIN = 5;
export const ARROW_WIDTH = 14 + ARROW_MARGIN;
export interface PlacementConfig {
	noArrow: boolean;
}

export class PlacementEngine {
	public static readonly NG1_INJECTION_NAME = 'PlacementEngine';
	public readonly POSITION = GoTourPosition;

	// DEV-12226, added adjustment for when tour is on corner side of screen
	private offsetAdjustment = 10;

	/* @ngInject */
	constructor (private $window, private $timeout) {}

	private ignoreClicks (event) {
		event.stopPropagation();
	};

	public getElementAbsolutePositions (element, padding = 0) {

		const box = element.getBoundingClientRect();

		const body = this.$window.document.body;
		const docEl = this.$window.document.documentElement;

		const scrollTop = this.$window.pageYOffset || docEl.scrollTop || body.scrollTop;
		const scrollLeft = this.$window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		const clientTop = docEl.clientTop || body.clientTop || 0;
		const clientLeft = docEl.clientLeft || body.clientLeft || 0;

		let top = box.top + scrollTop - clientTop;
		let left = box.left + scrollLeft - clientLeft;
		let height = element.offsetHeight;
		let width = element.offsetWidth;

		if (padding > 0) {
			top -= padding;
			left -= padding;
			height += padding * 2;
			width += padding * 2;
		}

		return {
			top: Math.round(top),
			left: Math.round(left),
			width,
			height
		};
	};

	// Given an element, set tour shadow around
	// it without moving it or altering it's styles
	public setTourShadowAroundElement (element, padding) {
		scrollIntoViewIfNeeded(element, true);
		const elementPositioning = this.getElementAbsolutePositions(element, padding);
		this.applyShadowsForPositioning(elementPositioning);
	};

	public reset () {
		[].forEach.call(this.$window.document.querySelectorAll('.go-tour-backdrop-shadow'), (shadow) => {
			shadow.removeEventListener('click', this.ignoreClicks);
			shadow.parentNode.removeChild(shadow);
		});
	};

	// Given an element to place, an element to place it next to, and a position
	// find the ideal coordinates for the element to place
	public setPlacementForItem (elementToPlace, elementToPlaceOn, position, elementPadding, config: PlacementConfig) {

		const positions = this.getElementAbsolutePositions(elementToPlaceOn, elementPadding);
		this.$window
			.document
			.documentElement
			.style.setProperty('--tour-arrow-display', config?.noArrow ? 'none' : 'block');
		this.$window
			.document
			.documentElement.style.setProperty('--tour-arrow-position', '50%'); // this.reset arrow

		// Timeout gives tourstep time to render
		// so that it's actual height can be used
		// to calculate center
		this.$timeout(() => {
			if (position === this.POSITION.TOP) {
				this.handleTop(elementToPlace, positions);
			} else if (position === this.POSITION.LEFT) {
				this.handleLeft(elementToPlace, positions);
			} else if (position === this.POSITION.RIGHT) {
				this.handleRight(elementToPlace, positions);
			} else if (position === this.POSITION.BOTTOM) {
				this.handleBottom(elementToPlace, positions);
			}
		});
	};

	private applyShadowsForPositioning (positions) {
		const leftColumnWidth = positions.left,
			midColumnWidth = positions.width,
			rightColumnWidth = this.$window.innerWidth - (positions.left + positions.width),
			topRowHeight = positions.top,
			midRowHeight = positions.height,
			bottomRowHeight = this.$window.innerHeight - (positions.top + positions.height),
			leftColumnLeft = 0,
			midColumnLeft = positions.left,
			rightColumnLeft = positions.left + positions.width,
			topRowTop = 0,
			midRowTop = positions.top,
			bottomRowTop = positions.top + positions.height;

		// Create 8 quadrants surrouding the element to highlight
		// [ ] [ ] [ ]
		// [ ]  X  [ ]
		// [ ] [ ] [ ]

		const quadrants = [
			{top: topRowTop, left: leftColumnLeft, height: topRowHeight, width: leftColumnWidth}, // TopLeft
			{top: topRowTop, left: midColumnLeft, height: topRowHeight, width: midColumnWidth}, // TopMid
			{top: topRowTop, left: rightColumnLeft, height: topRowHeight, width: rightColumnWidth}, // TopRight
			{top: midRowTop, left: leftColumnLeft, height: midRowHeight, width: leftColumnWidth}, // MidLeft
			{top: midRowTop, left: midColumnLeft, height: midRowHeight, width: midColumnWidth, center: true}, // Special opacity 0
			{top: midRowTop, left: rightColumnLeft, height: midRowHeight, width: rightColumnWidth}, // MidRight
			{top: bottomRowTop, left: leftColumnLeft, height: bottomRowHeight, width: leftColumnWidth}, // BottomLeft
			{top: bottomRowTop, left: midColumnLeft, height: bottomRowHeight, width: midColumnWidth}, // BottomMid
			{top: bottomRowTop, left: rightColumnLeft, height: bottomRowHeight, width: rightColumnWidth}  // Bottom Right
		];

		quadrants.forEach((quadrant) => {
			const shadow = this.$window.document.createElement('div');
			shadow.classList.add('go-tour-backdrop-shadow');

			if (quadrant.center) {
				shadow.classList.add('go-tour-element-cover');
			}

			shadow.style.top = `${quadrant.top}px`;
			shadow.style.left = `${quadrant.left}px`;
			shadow.style.height = `${quadrant.height}px`;
			shadow.style.width = `${quadrant.width}px`;
			this.$window.document.body.appendChild(shadow);
		});
	};

	private handleTop (elementToPlace, positions) {

		// Find bottom of element
		const bottom = positions.top + positions.height + ARROW_WIDTH;
		elementToPlace.style.top = bottom + 'px';

		// Find where center is of element placing near
		let center, exactCenter;
		center = exactCenter = positions.left + positions.width / 2 - elementToPlace.offsetWidth / 2;

		// Adjust if this position pushes part of box off screen
		if (center + elementToPlace.offsetWidth > this.$window.innerWidth) {
			center = this.$window.innerWidth - elementToPlace.offsetWidth - this.offsetAdjustment;
		}

		// Adjust if this position is less than half the width of our element being placed
		if (center < 0) {
			center = 0;
		}

		// Adjust arrow of tour step
		if (center !== exactCenter) {
			const percent = exactCenter - center + (elementToPlace.offsetWidth / 2);
			elementToPlace.style.setProperty('--tour-arrow-position', percent + 'px');
		}

		elementToPlace.style.left = center + 'px';
	};

	private handleBottom (elementToPlace, positions) {

		// Find top of element
		const top = positions.top - elementToPlace.offsetHeight - ARROW_WIDTH;
		elementToPlace.style.top = `${top}px`;

		// Find where center is of element placing near
		let center, exactCenter;
		center = exactCenter = positions.left + positions.width / 2 - elementToPlace.offsetWidth / 2;

		// Adjust if this position pushes part of box off screen
		if (exactCenter + elementToPlace.offsetWidth > this.$window.innerWidth) {
			center = this.$window.innerWidth - elementToPlace.offsetWidth - this.offsetAdjustment;
		}

		// Adjust if this position is less than half the width of our element being placed
		if (center < 0) {
			center = 0;
		}

		// Adjust arrow of tour step
		if (center !== exactCenter) {
			const percent = exactCenter - center + (elementToPlace.offsetWidth / 2);
			this.$window.document.documentElement.style.setProperty('--tour-arrow-position', percent + 'px');
		}

		elementToPlace.style.left = `${center}px`;
	};

	private handleRight (elementToPlace, positions) {

		const pageHeight = this.$window.innerHeight;

		// Find left edge of element
		const left = positions.left - elementToPlace.offsetWidth - ARROW_WIDTH;
		elementToPlace.style.left = left + 'px';

		// Find where center of element is
		let center = positions.top + positions.height / 2 - elementToPlace.offsetHeight / 2;

		// Adjust if bottom will go off screen
		if (center + elementToPlace.offsetHeight > pageHeight) {
			center = pageHeight - elementToPlace.offsetHeight;
		}

		// Adjust if we go off the top
		if (center < 0) {
			center = positions.top;
		}

		elementToPlace.style.top = center + 'px';
	};

	private handleLeft (elementToPlace, positions) {

		const pageHeight = this.$window.innerHeight;

		// Find left edge of element
		const left = positions.left + positions.width + ARROW_WIDTH;
		elementToPlace.style.left = left + 'px';

		// Find where center of element is
		let center = positions.top + positions.height / 2 - elementToPlace.offsetHeight / 2;

		// Adjust if bottom will go off screen
		if (center + elementToPlace.offsetHeight > pageHeight) {
			center = pageHeight - elementToPlace.offsetHeight;
		}

		// Adjust if we go off the top
		if (center < 0) {
			center = 0;
		}

		elementToPlace.style.top = center + 'px';
	};
}

export const placementEngineToken = upgradeNg1Dependency(PlacementEngine);
