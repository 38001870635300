import {
	Directive,
	EventEmitter,
	HostListener,
	Output,
	ElementRef
} from '@angular/core';

@Directive({
	selector: '[outsideClick]'
})
export class OutsideClickDirective {
	@Output() public outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

	constructor (private elementRef: ElementRef) {}

	@HostListener('window:mousedown', ['$event'])
	public onClick (event: MouseEvent): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.outsideClick.emit(event);
		}
	}
}
