import { clientSettings } from '../../models/common/client.settings';


export const LOCAL_STORAGE_WATSON_USAGE_KEY = 'watson-transcription-usage';

/* @ngInject */
export class WatsonUsageTrackingService {
	constructor (
		private $http: ng.IHttpService
	) {}

	public savePendingTranscriptionUsage () {
		if (localStorage.getItem(LOCAL_STORAGE_WATSON_USAGE_KEY)) {
			const usage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_WATSON_USAGE_KEY));
			return this.$http.post(`${clientSettings.GoReactV2API}/transcription_usages`, usage)
				.then(() => {
					localStorage.removeItem(LOCAL_STORAGE_WATSON_USAGE_KEY);
				});
		}
	};
}
