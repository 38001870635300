import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultSeperator, multiValueValidate } from 'ngx/go-modules/src/validators/multi-value/multi-value.validator';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, filter, ignoreElements, map, share, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';
import type { LicenseHoldersAndInvitesResponse } from 'ngx/go-modules/src/services/license/license.service';
import type { SalesforceLicense } from 'ngx/go-modules/src/interfaces/licenses';
import type { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export const NETWORK_LOST_TRANSLATION_KEY = 'invite-user-dialog_error-connection-lost';
export const UNKNOWN_ERROR_TRANSLATION_KEY = 'invite-user-dialog_error-unknown';

export interface InviteUserDialogData {
	salesforceLicense: SalesforceLicense;
}

@Component({
	selector: 'invite-user-dialog',
	template: require('./invite-user-dialog.component.html'),
	styles: [require('./invite-user-dialog.component.scss')]
})
export class InviteUserDialogComponent implements OnInit {
	public form = new FormGroup({
		emails: new FormControl(null, [Validators.required, multiValueValidate(Validators.email)]),
		admin: new FormControl(false)
	});

	public inviteHolders$ = new Subject<void>();
	public submitting$: Observable<boolean> = of(false);
	public errorMessage$: Observable<string> = of(null);

	public constructor (
		private dialogRef: MatDialogRef<InviteUserDialogComponent>,
		private licenseService: LicenseService,
		@Inject(MAT_DIALOG_DATA) private data: InviteUserDialogData
	) {}

	// A small helper to open the dialog with all the correct typing
	public static open (
		dialog: MatDialog,
		config?: MatDialogConfig<InviteUserDialogData>
	): MatDialogRef<InviteUserDialogComponent, LicenseHoldersAndInvitesResponse> {
		return dialog.open<
		InviteUserDialogComponent,
		InviteUserDialogData,
		LicenseHoldersAndInvitesResponse
		>(InviteUserDialogComponent, config);
	}

	private static errorHandler (err: HttpErrorResponse): string {
		if (err.status === 0) {
			return NETWORK_LOST_TRANSLATION_KEY;
		}

		return UNKNOWN_ERROR_TRANSLATION_KEY;
	}

	public ngOnInit (): void {
		// Create a pipe from submit to closing the dialog
		this.inviteHolders$.pipe(
			filter(() => this.form.valid),
			switchMap(() => {
				// Build the invites
				const invites = this.form.controls.emails.value
					.split(defaultSeperator)
					.filter((email) => email !== '') // Remove leading/trailing seperators resulting in empty values
					.filter((v, i, a) => a.indexOf(v) === i) // Remove duplicate values
					.map((email) => ({email, is_admin: this.form.controls.admin.value}));

				// Do the http call and share the result (make the observer hot)
				const inviteRequest$ = this.licenseService.addLicenseHolders(
					this.data.salesforceLicense.license_id,
					invites
				).pipe(share());

				// Create a pipe that only pays attention to errors and displays them
				this.errorMessage$ = inviteRequest$.pipe(
					ignoreElements(),
					catchError((err: HttpErrorResponse) => of(err)),
					map(InviteUserDialogComponent.errorHandler)
				);

				// Create a pipe that handles while we are submitting, and resets if we fail
				this.submitting$ = inviteRequest$.pipe(
					ignoreElements(),
					startWith(true),
					catchError(() => of(false))
				);

				// And return the pipe that ignores errors
				return inviteRequest$.pipe(
					catchError(() => EMPTY)
				);
			})
		).subscribe((result: LicenseHoldersAndInvitesResponse) => this.dialogRef.close(result));
	}

	public cancel () {
		this.dialogRef.close();
	}

	public getInvalidEmails (): string {
		return this.form.controls.emails.getError('multi')
			.map((error) => error.value)
			.join(', ');
	}
}
