
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSessionViewComponent } from 'ngx/go-modules/src/components/feedback-session/session-view/session-view.component';
import { FeedbackSessionDirective } from 'ngx/go-modules/src/angularjs-wrappers/feedback-session.directive';
import { FeedbackSessionHeaderDirective } from 'ngx/go-modules/src/angularjs-wrappers/feedback-session-header.directive';
import { LoadingModule } from 'ngx/go-modules/src/components/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		LoadingModule
	],
	declarations: [
		NgxSessionViewComponent,
		FeedbackSessionDirective,
		FeedbackSessionHeaderDirective
	],
	entryComponents: [
		NgxSessionViewComponent
	],
	exports: [
		NgxSessionViewComponent
	]
})
export class NgxSessionViewModule {}
