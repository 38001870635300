/** @ngInject */
export const requestMonitorConfig = ($httpProvider) => {
	const saveMethods = ['POST', 'PUT', 'DELETE'];

	const isSaveMethod = (method) => {
		return saveMethods.indexOf(method) >= 0;
	};

	/** @ngInject */
	$httpProvider.interceptors.push((requestMonitor, $q) => {
		return {
			response (response) {
				// track successful PUT, POST, DELETE
				if (isSaveMethod(response.config.method)) {
					requestMonitor.setSuccess(true);
				}

				return response;
			},

			responseError (response) {
				// track failed PUT, POST, DELETE
				if (isSaveMethod(response.config.method)) {
					requestMonitor.setSuccess(false);
				}

				return $q.reject(response);
			}
		};
	});
};
