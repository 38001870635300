import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum UserType {
	Presenter,
	Instructor
}

@Component({
	selector: 'user-type-selection-dialog',
	template: require('./user-type-selection-dialog.component.html'),
	styles: [require('./user-type-selection-dialog.component.scss')]
})
export class UserTypeSelectionDialog {

	constructor (private dialogRef: MatDialogRef<UserTypeSelectionDialog>) {}

	public selectInstructor () {
		this.dialogRef.close(UserType.Instructor);
	}

	public selectPresenter () {
		this.dialogRef.close(UserType.Presenter);
	}

	public cancel () {
		this.dialogRef.close();
	}
}
