import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'folders-zero-state',
	template: require('./folders-zero-state.component.html'),
	styles: [require('./folders-zero-state.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoldersZeroStateComponent{
	@Output() public createFolder = new EventEmitter();
	@Output() public openInvite = new EventEmitter();
	@Input() public canInvite = false;

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		private folderListDataSource: FolderListDataSource,
		private translateService: TranslateService) {}

	public getTitle (): Observable<string> {
		if (this.folderListDataSource.filtered) {
			return this.translateService.get('folder_zero_state-no-folders');
		}
		if (this.userService.currentUser.isInstructor) {
		  	return this.translateService.get('common_welcome-to-goreact');
		}
		return this.translateService.get('folder_zero_state-join-organization');
	}

	public getSubText (): Observable<string> {
		if (this.folderListDataSource.filtered) {
			return this.translateService.get('folder_zero_state-no-folders-subtext');
		}
		if (this.userService.currentUser.isInstructor) {
			return this.translateService.get('folder_zero_state-welcome-subtext');
		}
		return this.translateService.get('folder_zero_state-join-organization-subtext');
	}

	public showButtons (): boolean {
		return this.userService.currentUser.isInstructor && !this.folderListDataSource.filtered;
	}

	public showGraphic (): boolean {
		return !this.folderListDataSource.filtered;
	}

}
