import * as angular from 'angular';
import 'angulartics'; // Does not support exporting as TS with types

import { activityModel } from '../models/activity';
import { mediaModel } from '../models/media';
import { attachmentModel } from '../models/attachment';
import componentDefinition from './attachments.component';

import './style.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// import /*{ goAttachment } from*/ '../attachment'; -- commented to satisfy eslint
import '../attachment';

export const activityAttachments = angular.module('go.activityAttachments', [
	'angulartics',
	activityModel,
	attachmentModel,
	mediaModel,

	// TODO: use module strings directly until circular dependency is fixed
	'go.attachment'
])
	.component('activityAttachments', componentDefinition)
	.name;
