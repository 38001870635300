import * as angular from 'angular';
import * as PubNub from 'pubnub';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { UserNotificationHandler } from '../user-notifications/user-notifications.service';
import { Subject } from 'rxjs';
import { UserService } from 'go-modules/models/user/user.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

export interface PubnubConfiguration {
	publishKey: string;
	subscribeKey: string;
	origin: string;
	ssl: boolean;
	uuid: string;
}

const pubnubDefaults: PubnubConfiguration = {
	publishKey: 'demo',
	subscribeKey: 'demo',
	origin: '',
	ssl: true,
	uuid: ''
};

/* @ngInject */
export class PubnubService {
	public static readonly NG1_INJECTION_NAME = 'pubnub' as const;
	public environmentVarsService: EnvironmentVarsService;
	public scopePhase = new Subject();

	public _pubnub: PubNub;
	private config: PubnubConfiguration;
	private allChannels = [];
	private allListeners = [];

	constructor (
		private $log: ng.ILogService,
		private $window: ng.IWindowService,
		private $injector: ng.auto.IInjectorService,
		private userNotificationHandler: UserNotificationHandler,
		private userService: UserService,
		private selectedService: SelectedService,
		private MediaModel: any
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public configure (config) {
		if (!this._pubnub) {
			const conf = {
				publishKey: config.pub_key,
				subscribeKey: config.sub_key,
				origin: config.origin,
				ssl: config.ssl,
				uuid: config.uuid
			};
			this.config = {...pubnubDefaults, ...conf};
			this._pubnub = new PubNub(this.config);
			this._pubnub.setUUID(this.config.uuid = (
				this.config.uuid ||
				this._pubnub.getUUID()
			));
		}
	}

	public reset () {
		this._pubnub = null;
	}

	/**
	 * Subscribe to a channel
	 *
	 * @param channels
	 * @param messageHandler
	 */
	public sub (channels, subscriberName, messageHandler?) {
		if (!channels) {
			throw new Error('No pubnub channel provided for subscribe');
		}

		if (!Array.isArray(channels)) {
			channels = [channels];
		}

		const listener = {
			message: (msg) => {

				const message = msg.message;

				// Don't fire handler for channels we arent
				// listening on for this handler
				if (channels.indexOf(msg.channel) === -1) {
					return;
				}

				// Handle user notifications
				if (message.userNotification) {
					try {
						return this.userNotificationHandler
							.getHandler(message.userNotification.eventName)
							.execute(message.userNotification);
					} catch (e) {
						this.$log.error('PUBNUB WANTED TO DIE', e);
					}
				}

				if (angular.isObject(message)) {
					const timestamp = parseInt(message.timestamp, 10);
					message.mine = message.uuid === this.config.uuid;
					message.duration = timestamp > 0 ? new Date().getTime() - timestamp : 0;
				}

				// Ignore all message sent by me
				if (message.mine) {
					return;
				}

				if (messageHandler) {
					try {
						messageHandler(message);
					} catch (e) {
						this.$log.error('PUBNUB WANTED TO DIE', e);
					}
				}
			}
		};

		this._pubnub.addListener(listener);
		this.allChannels = this.allChannels.concat(channels);
		this.allListeners.push({
			id: subscriberName,
			listener
		});

		this._pubnub.subscribe({
			channels
		});
	}

	/**
	 * Publish a message
	 *
	 * @param channel
	 * @param message
	 * @param callback
	 */
	public pub (channel, message, callback?) {
		if (!channel) {
			throw new Error('No pubnub channel provided for publish');
		}

		if (angular.isObject(message)) {
			message.uuid = this.config.uuid;
			message.timestamp = new Date().getTime();
		}

		this._pubnub.publish({
			channel,
			message
		}, callback);
	}

	/**
	 * Unsubscribe from a channel
	 *
	 * @param channels
	 */
	public unsub (channels, subscriberName) {
		// on logout we clear pubnub data before a $onDestroy reaches unsub.
		if (this._pubnub === null) {
			return;
		}

		if (!channels) {
			throw new Error('No pubnub channels provided for unsubscribe');
		}

		if (!Array.isArray(channels)) {
			channels = [channels];
		}

		channels.forEach((channel) => {
			const count = this.allChannels.filter((c) => (c === channel)).length;
			if (count === 1) {
				this._pubnub.unsubscribe({
					channels: [channel]
				});
			}
			this.allChannels.splice(this.allChannels.indexOf(channel), 1);
		});


		const listener = this.allListeners.find((l) => l.id === subscriberName).listener;
		this._pubnub.removeListener(listener);
		this.allListeners = this.allListeners.filter((ob) => ob.id !== subscriberName);
	}

	/**
	 * Connect to pubnub channel / disconnect from old
	 */
	public updateChannels () {
		const group = this.selectedService.getGroup();
		const channels = [];
		const userChannel = this.environmentVarsService.get(EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL);
		if (userChannel) {
			channels.push(userChannel);
		}

		if (this.selectedService.getLastGroup()) {
			channels.unshift(this.selectedService.getLastGroup().channel);
			this.unsub(channels, 'dashboard');
			channels.shift();
		}

		channels.unshift(group.channel);
		this.sub(channels, 'dashboard', (message) => this.messageHandler(message));
	}

	/**
	 * All incoming messages are received here (LTI Refresh & Dashboard)
	 *
	 * @param message
	 */
	public messageHandler (message) {
		if (message._user) {// user message
			this.$window.alert(message._user.text);
		} else {// system message
			const group = this.selectedService.getGroup();
			angular.forEach(message, (item, property: string) => {
				switch (property) {
					case 'user':
						const user = group.getUser(item.user_id);

						// change role in group if current user
						if (parseInt(item.user_id, 10) === parseInt(this.userService.currentUser.user_id, 10)) {
							group.role = item.role;
						}

						if (user) {
							angular.forEach(item, (_value, key) => {
								if (user.hasOwnProperty(key)) {
									user[key] = item[key];
								}
							});
						}
						break;
					case 'session':
						this.$injector.get<EventService>('eventService').broadcast(EVENT_NAMES.ACTIVITY_VIEW_SESSION_SYNC, item);
						break;
					case 'media':
						const media = this.MediaModel.model(item);
						this.$injector.get<EventService>('eventService').broadcast(EVENT_NAMES.MEDIA_SYNC, media);
						break;
				}
			});
		}

		this.scopePhase.next(true);
	}
}

export const pubnubToken = upgradeNg1Dependency(PubnubService);
