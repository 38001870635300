import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { SessionZeroStateComponent } from './session-zero-state.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgxZeroStateModule
	],
	declarations: [
		SessionZeroStateComponent
	],
	entryComponents: [
		SessionZeroStateComponent
	],
	exports: [
		SessionZeroStateComponent
	]
})
export class SessionZeroStateModule {}
