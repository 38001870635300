import { NgModule } from '@angular/core';
import { AlphabeticalByKeyPipe } from './alphabetical-by-key.pipe';

@NgModule({
	declarations: [
		AlphabeticalByKeyPipe
	],
	exports: [
		AlphabeticalByKeyPipe
	]
})
export class AlphabeticalByKeyPipeModule {}
