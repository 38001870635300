import template from './modal.html';
import { DownloadPWAModalController as controller } from './modal.controller';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export class DownloadPWAModal {
	public static readonly NG1_INJECTION_NAME = 'downloadPWAModal';

	/* @ngInject */
	constructor (private goModalBootstrap) {}

	public open () {
		const options = {} as any;
		options.template = template;
		options.controller = controller;
		options.windowClass = options.name = 'download-pwa-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}

export const downloadPWAModalToken = upgradeNg1Dependency(DownloadPWAModal);
