import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultSeperator, multiValueValidate } from 'ngx/go-modules/src/validators/multi-value/multi-value.validator';
import { BehaviorSubject } from 'rxjs';
import type { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LibraryService, ShareLibraryItemResponse } from 'ngx/go-modules/src/services/library/library.service';

export interface ShareLibraryItemDialogData {
	collection_id: number;
	item_id: number;
}

@Component({
	selector: 'share-library-item-dialog',
	template: require('./share-library-item-dialog.component.html'),
	styles: [require('./share-library-item-dialog.component.scss')]
})
export class ShareLibraryItemDialogComponent {
	public form = new FormGroup({
		emails: new FormControl(null, [Validators.required, multiValueValidate(Validators.email)]),
		admin: new FormControl(false)
	});
	public isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor (
		@Inject(MAT_DIALOG_DATA) private data: ShareLibraryItemDialogData,
		private dialogRef: MatDialogRef<ShareLibraryItemDialogData>,
		private libraryService: LibraryService
	) {}

	public static open (
		dialog: MatDialog,
		config?: MatDialogConfig<ShareLibraryItemDialogData>
	): MatDialogRef<ShareLibraryItemDialogComponent, ShareLibraryItemResponse> {
		return dialog.open<
		ShareLibraryItemDialogComponent,
		ShareLibraryItemDialogData,
		ShareLibraryItemResponse
		>(ShareLibraryItemDialogComponent, config);
	}

	public submit () {
		if (!this.form.valid) {
			return;
		}

		this.isSaving$.next(true);

		const invites = this.getUniqueEmails();
		this.libraryService.shareCollectionItem({
			items: [{
				collection_id: this.data.collection_id,
				item_id: this.data.item_id
			}],
			users: invites
		}).subscribe({
			next: (result: ShareLibraryItemResponse) => this.dialogRef.close(result),
			error: () => {
				this.dialogRef.close({
					invalidUsers: invites
				});
			}
		});
	}

	public cancel () {
		this.dialogRef.close();
	}

	public getInvalidEmails (): string {
		return this.form.controls.emails.getError('multi')
			.map((error) => error.value)
			.join(', ');
	}

	private getUniqueEmails (): string[] {
		const emails = this.form.controls.emails.value.split(defaultSeperator);
		return emails.filter((email) => email.trim() !== '').filter((v, i, a) => a.indexOf(v) === i);
	}
}
