import { ActivityEditorController } from '../activity-editor.controller';

export interface Bindings {
	activityEditorController: ActivityEditorController;
	aiPrompts: any;
	activity: any;
	onEdit: () => void;
	onRemove: () => void;
}

export class AiMarkerSetPanelController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public aiPrompts: any;
	public activity: any;
	public onEdit: () => void;
	public onRemove: () => void;

	public markers;

	/* @ngInject */
	constructor () {}

	public $onChanges (changes: ng.IOnChangesObject): void {
		if (changes.aiPrompts) {
			this.markers = this.aiPrompts.map((prompt) => {
				return prompt.tag;
			});
		}
	}
}
