import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { Observable } from 'rxjs';

export const ACTIVE_FEATURE_FLAG_HEADER_KEY = 'Goreact-Active-Feature-Flags';

@Injectable({
	providedIn: 'root'
})
export class FeatureFlagSyncInterceptor implements HttpInterceptor {

	constructor (private featureFlag: NgxFeatureFlagService) {
	}

	public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const flags = this.featureFlag.getSynchronizedFlags();
		const headers = {};
		if (flags.length > 0) {
			headers[ACTIVE_FEATURE_FLAG_HEADER_KEY] = flags.join(',');
			req = req.clone({
				setHeaders: headers
			});
		}

		return next.handle(req);
	}
}
