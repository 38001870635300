import template from './org-settings.template.html';
import { OrgSettingsController } from './org-settings.controller';
import './style.less';

export const OrgSettingsComponent = {
	template,
	controller: OrgSettingsController,
	bindings: {
		group: '<',
		user: '<',
		mayMask: '&',
		loginAs: '&'
	}
};
