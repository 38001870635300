import { FallbackRoute } from './fallback-route.service';

export interface AppSessionManagerConfig {
	user: any;
}

export class AppSessionManagerService {
	public static PROVIDER_NAME: string = 'goreact';
	public appSessionManagerConfig: AppSessionManagerConfig;

	/* @ngInject */
	constructor (
		public goModal,
		public sessionManager,
		public $window,
		public fallbackRouteService: FallbackRoute
	) { }

	/**
	 * Handles configure.
	 *
	 * @returns {void}
	 */
	public configure (appSessionManagerConfig: AppSessionManagerConfig): void
	{
		if(this.appSessionManagerConfig) return;

		// Set user for fallback key
		this.fallbackRouteService.setUser(appSessionManagerConfig.user);

		// handle session timeout
		this.sessionManager.on('timeout', () => {
			const modalData = {user: appSessionManagerConfig.user} as {
				user: any;
				external: boolean;
			};

			this.goModal.open({
				modal: 'sessionTimeout',
				modalData
			}).result.then(() => {
				// Always completely reload, so people don't stay on old versions of the app since
				// we deploy everyday
				this.$window.location.reload();
			});
		});
		this.appSessionManagerConfig = appSessionManagerConfig;
	}

	public reset () {
		this.appSessionManagerConfig = null;
		this.sessionManager.clearTimeout();
		this.sessionManager.off();
	}
}
