import * as angular from 'angular';
import { AppDataService } from '../services/app-data.service';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';
import { States } from 'go-modules/enums/states.enum';
import { UserService } from 'go-modules/models/user/user.service';
import type { StateParams, StateProvider, StateService, UrlRouterProvider } from '@uirouter/angularjs';
import { SanitizerService } from 'go-modules/services/xss/sanitizer.service';

/* @ngInject */
export const routesConfig = (
	$locationProvider: angular.ILocationProvider,
	$stateProvider: StateProvider,
	$urlRouterProvider: UrlRouterProvider
) => {
	// html5 mode
	$locationProvider.html5Mode({ enabled: true, requireBase: false });

	const licenseManagementResolve = {
		appInit: ['AppData', (AppData: AppDataService): ng.IPromise<void> => {
			return AppData.initAppData();
		}],
		routeGuard: [
			'appInit', 'userService', '$state', 'AppData',
			(appInit: void,
				userService: UserService,
				$q: ng.IQService,
				AppData: AppDataService
			) => {
				// Injecting this makes the route guard wait for the
				// initAppData resolve above, which set the current user.
				void appInit;

				// array.every return true on empty array
				const allPartnersHaveUnlimitedBilling = AppData.partners.length ?
					AppData.partners.every((value) => value.has_unlimited_billing) : false;

				// If we are not an instructor nor root, abort
				if ((!userService.currentUser.isInstructor && !userService.currentUser.is_root_user) ||
					allPartnersHaveUnlimitedBilling) {
					return $q.reject();
				}
			}]
	};

	$stateProvider
		.state(States.AUTH, {
			url: '/auth?invite&course&redirect&info-key',
			resolve: {
				isLoggedIn: (
					sessionManager: SessionManagerService,
					$state: StateService,
					$stateParams: StateParams,
					$window: angular.IWindowService,
					sanitizerService: SanitizerService
				) => {
					if (sessionManager.isActive()) {
						if (!$stateParams.invite && !$stateParams.course) {
							if($stateParams.redirect) {
								$window.location.href = sanitizerService.isValidRedirectUrl($stateParams.redirect) ? $stateParams.redirect : '/dashboard';
							} else {
								$state.go(States.DASHBOARD_ROOT);
							}
						}
					}
				}
			},
			params: {
				course: {
					dynamic: true,
					value: null
				}
			}
		})
		.state(States.AUTH_LOGIN, {
			url: '/login',
			template: '<login-view></login-view>'
		})
		.state(States.AUTH_SIGNUP, {
			url: '/signup',
			params: {
				courseInfo: null,
				inviteInfo: null
			},
			template: '<signup-view></signup-view>'
		})
		.state(States.AUTH_FORGOT, {
			url: '/forgot',
			template: '<forgot-password></forgot-password>'
		})
		.state(States.AUTH_RESET, {
			url: '/reset/:email/:expiry/:hash',
			template: '<reset-password></reset-password>'
		})
		.state(States.AUTH_COURSE_CONFIRM, {
			url: '/group-confirm',
			template: '<group-confirm></group-confirm>'
		})
		.state(States.LICENSE_MANAGEMENT, {
			url: 'license-management/:orgId',
			template: '<license-management-view-route></license-management-view-route>',
			resolve: licenseManagementResolve,
			// In most cases, license-management displays all licenses a user has access to.
			// However, this won't work for roots as it would load everything. So roots need
			// to limit the licenses they see to just a specific org.
			params: {
				orgId: {
					value: null,
					squash: true
				}
			}
		})
		.state(States.LICENSE_SEATS_MANAGEMENT, {
			url: 'license-management/:licenseId/seats',
			template: '<license-seats-management></license-seats-management>',
			resolve: licenseManagementResolve,
			params: {
				licenseId: null,
				licenseHolderEmail: null
			}
		})
		.state(States.DASHBOARD_NO_GROUPS, {
			url: 'no-access',
			template: '<no-groups-route></no-groups-route>'
		})
		.state(States.DASHBOARD_BLOCKED, {
			url: 'blocked',
			template: '<access-denied-route></access-denied-route>'
		})
		.state(States.DASHBOARD_ROOT, {
			url: '/?showAccountSettingsPanel',
			template: '<dashboard-root-view></dashboard-root-view>',
			resolve: {
				appInit: (AppData: AppDataService) => {
					return AppData.initAppData();
				}
			},
			params: {
				showAccountSettingsPanel: {
					dynamic: true,
					value: null
				},
				welcome: null
			}
		})
		.state(States.DASHBOARD_SESSION_VIEW, {
			url: 'sessions/:session_id?viewMode&feedbackMode',
			template: '<ngx-session-view></ngx-session-view>',
			params: {
				viewMode: null,
				feedbackMode: null
			}
		})
		.state(States.DASHBOARD_ACTIVITY_VIEW, {
			url: 'activities/:activity_id',
			template: '<ngx-activity-view></ngx-activity-view>'
		})
		.state(States.DASHBOARD_FOLDER_VIEW, {
			url: 'folder/:folder_id',
			template: '<ngx-folder-view></ngx-folder-view>'
		})
		.state(States.DASHBOARD_FOLDERS, {
			url: 'folders/:id',
			template: '<ngx-folders-view></ngx-folders-view>'
		})
		.state(States.DASHBOARD_ARCHIVE, {
			url: 'archive',
			template: '<ngx-archive-view></ngx-archive-view>'
		})
		.state(States.DASHBOARD_SETTINGS, {
			url: 'settings?tab&link-result&provider',
			template: '<ngx-user-settings></ngx-user-settings>',
			params: {
				'tab': null,
				'link-result': null,
				'provider': null
			}
		});

	$urlRouterProvider.otherwise('/');
};
