import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxActivityListStatusComponent } from 'ngx/go-modules/src/components/folder-view/activity-list-status/activity-list-status.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		NgxActivityListStatusComponent
	],
	entryComponents: [
		NgxActivityListStatusComponent
	],
	exports: [
		NgxActivityListStatusComponent
	]
})
export class NgxActivityListStatusModule {}
