import angular from 'angular';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges
} from '@angular/core';
import type { PrivacyOption } from 'go-modules/activity-editor/activity-editor.controller';
import { PrivacyLevel } from 'go-modules/models/activity';
import { ActivityTemplateModelType, activityTemplateModelToken } from 'go-modules/models/activity-template/activity-template.factory';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import type { ActivityFactory } from 'go-modules/models/activity/activity.schema';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription, debounceTime, distinctUntilChanged, filter, first, forkJoin, from, takeUntil } from 'rxjs';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { tagSetModelToken } from 'go-modules/models/tag-set/tag-set.factory';
import { LibraryCollectionViewerModal, libraryCollectionViewerModalToken } from 'go-modules/modals/library-collection-viewer/modal.service';
import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';

export const DEBOUNCE_TIME = 500;
@Component({
	selector: 'default-activity-settings',
	template: require('./default-activity-settings.component.html'),
	styles: [require('./default-activity-settings.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultActivitySettingsComponent implements OnChanges, OnDestroy {
	@Input() public account;

	public activityTemplates = [];
	public privacyTypeLookup = null;
	public privacyTypes = [];
	public filteredPrivacyTypes = [];

	public activityForm: FormGroup;
	public valueChangeSubscription: Subscription;
	public defaultActivity$$ = new BehaviorSubject(null);
	public showSaveButton$ = new BehaviorSubject(false);
	public isSaving$ = new BehaviorSubject(false);
	public selectedMarkerSet: any;

	private templateOrder = [
		ActivityTemplateModelType.STANDARD,
		ActivityTemplateModelType.STIMULUS_RESPONSE,
		ActivityTemplateModelType.FEEDBACK_ONLY
	];

	private destroyed$$ = new Subject();

	constructor (
		private activityService: NgxActivityService,
		private fb: FormBuilder,
		private ngxGoToastService: NgxGoToastService,
		private cdr: ChangeDetectorRef,
		public featureFlag: NgxFeatureFlagService,
		@Inject(libraryCollectionViewerModalToken) private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		@Inject(activityToken) private activityModel: ActivityFactory,
		@Inject(activityTemplateModelToken) private activityTemplateModel,
		@Inject(tagSetModelToken) public tagSetModel)
	{

		const activity = this.defaultActivity$$.asObservable()
			.pipe(takeUntil(this.destroyed$$), filter((item) => !!item), first());

		forkJoin([activity, from(this.activityTemplateModel.query({}).$promise)])
			.pipe(takeUntil(this.destroyed$$))
			.subscribe(([, templates]: [any, any[]]) => {
				this.activityTemplates = templates.sort((a, b) =>
					this.templateOrder.indexOf(a.getType()) - this.templateOrder.indexOf(b.getType())
				);
			});
	}

	public ngOnDestroy (): void {
		this.destroyed$$.next(true);
		this.destroyed$$.complete();
		this.valueChangeSubscription?.unsubscribe();
	}

	public initForm () {
		this.generatePrivacyLists(this.defaultActivity$$.getValue().template);
		let template = this.defaultActivity$$.getValue().template;
		// Some account does not have a use_type.
		// In that case set make standard as default.
		if (!template.slug) {
			template = this.activityTemplates
				.find((value) => value.getType() === ActivityTemplateModelType.STANDARD);
		}

		const isFeedbackPublished = this.defaultActivity$$.getValue().is_feedback_published;
		const privacy = this.determineInitiallySelectedPrivacySetting();

		this.activityForm = this.fb.group({
			template: [template, Validators.required],
			privacyType: [privacy, Validators.required],
			is_feedback_published: [isFeedbackPublished, Validators.required]
		});

		this.valueChangeSubscription?.unsubscribe();
		this.valueChangeSubscription = this.activityForm.valueChanges
			.pipe(
				debounceTime(DEBOUNCE_TIME),
				distinctUntilChanged()
			).subscribe((value) => {
				const templateChanged = value.template !== this.defaultActivity$$.getValue().template;
				const isFeedbackPublishedChanged =
					value.is_feedback_published !== this.defaultActivity$$.getValue().is_feedback_published;
				const privacyTypeChanged = value.privacyType !== this.determineInitiallySelectedPrivacySetting();
				this.showSaveButton$.next(templateChanged || isFeedbackPublishedChanged || privacyTypeChanged);
			});

		this.determineInitiallySelectedMarkerSet();
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if (changes.account && this.account) {
			this.getDefaultActivityModel();
		}
	}

	public getDefaultActivityModel () {
		this.activityService.getDefault(this.account.getId())
			.subscribe((result) => {
				this.defaultActivity$$.next(this.activityModel.model(result));
				this.initForm();
			});
	}

	public onActivityValueChanged () {
		this.generatePrivacyLists();
		this.activityForm.patchValue({
			privacyType: null
		});
	}

	/**
	 * Determines the privacy setting that should initially be selected
	 */
	private determineInitiallySelectedPrivacySetting (): PrivacyOption {
		switch (this.defaultActivity$$.getValue().getPrivacyLevel()) {
			case PrivacyLevel.CLOSED_PEER_REVIEW:
				return this.privacyTypeLookup.CLOSED_PEER_REVIEW;
			case PrivacyLevel.OPEN_PEER_REVIEW:
			case PrivacyLevel.OPEN_FEEDBACK:
				return this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING;
			case PrivacyLevel.PRIVATE:
			default:
				return this.privacyTypeLookup.PRIVATE;
		}
	}

	/**
	 * Defines and updates what is shown in the privacy settings dropdown
	 */
	public generatePrivacyLists (template?): void {
		const currentTemplate = template ??
			this.activityForm.get('template').value;

		const isActive = currentTemplate.isActive('has_response_media');

		this.privacyTypeLookup = {
			PRIVATE: isActive ?
				{
					title: 'activity-editor-new_private',
					description: 'activity-editor-new_private-description',
					peerReview: false,
					publicFeedback: false
				} :
				{
					title:
					'activity-editor-new_private',
					description: 'activity-editor-new_private-description-filtered',
					peerReview: false,
					publicFeedback: false
				},
			CLOSED_PEER_REVIEW:
				{
					title: 'activity-editor-new_closed-peer-review',
					description: 'activity-editor-new_closed-peer-review-description',
					peerReview: true,
					publicFeedback: false
				},
			EVERYONE_SEES_EVERYTHING: isActive ?
				{
					title: 'activity-editor-new_everyone-sees-everything',
					description: 'activity-editor-new_everyone-sees-everything-description',
					peerReview: true,
					publicFeedback: true
				} :
				{
					title: 'activity-editor-new_everyone-sees-everything',
					description: 'activity-editor-new_everyone-sees-everything-description-filtered',
					peerReview: false,
					publicFeedback: true
				}
		};

		this.privacyTypes = isActive ? [
			this.privacyTypeLookup.PRIVATE,
			this.privacyTypeLookup.CLOSED_PEER_REVIEW,
			this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING
		] : [
			this.privacyTypeLookup.PRIVATE,
			this.privacyTypeLookup.EVERYONE_SEES_EVERYTHING
		];
	}

	public save () {
		if(this.activityForm.invalid) {
			this.activityForm.markAllAsTouched();
			return;
		}

		this.isSaving$.next(true);
		const activity = this.defaultActivity$$.getValue();
		activity.template = this.activityForm.value.template;
		activity.is_feedback_published = this.activityForm.value.is_feedback_published;
		activity.public_feedback = this.activityForm.value.privacyType.publicFeedback;
		activity.peer_review =  this.activityForm.value.privacyType.peerReview;

		if (this.featureFlag.isAvailable('ACTIVITY_EDITOR_SHOW_DEFAULT_MARKER')) {
			activity.tag_set_id = this.selectedMarkerSet?.id ?? null;
		}

		activity.save().then(() => {
			this.showSaveButton$.next(false);
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'common-save-success'
			});
		}).catch(() => {
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: 'common-save-failed'
			});
		}).finally(() => this.isSaving$.next(false));
	}

	public openMarkerSelectionModal (): void {
		this.libraryCollectionViewerModal.open({
			modalData:{
				filterType: [CONTENT_TYPES.MARKERS],
				mode: MODES.SELECT,
				collectionManagement: true
			}
		}).result.then((markers) => {
			this.selectedMarkerSet = new this.tagSetModel(markers);
			this.showSaveButton$.next(true);
			this.cdr.detectChanges();
		}).catch(angular.noop);
	}

	public handleMarkerSetEdited ({markerSet}): void {
		this.selectedMarkerSet = markerSet;
		this.showSaveButton$.next(true);
	}

	public handleMarkerSetRemoved (): void {
		this.selectedMarkerSet = null;
		this.showSaveButton$.next(true);
	}

	private determineInitiallySelectedMarkerSet (): any {
		const activity = this.defaultActivity$$.getValue();
		if (activity.tag_set_id > 0) {
			this.selectedMarkerSet = this.tagSetModel.get({
				id: activity.tag_set_id
			});
		}
	}
}
