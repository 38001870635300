import { Injectable, QueryList } from '@angular/core';
import { GoDropdownOptionComponent } from './go-dropdown-option.component';
import { GoDropdownComponent } from './go-dropdown.component';

@Injectable()
export class GoDropdownService {
	public options: QueryList<GoDropdownOptionComponent>;
	private select: GoDropdownComponent;

	public register (select: GoDropdownComponent) {
		this.select = select;
	}

	public getSelect (): GoDropdownComponent {
		return this.select;
	}
}
