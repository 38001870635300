import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { emailValidator } from 'ngx/go-modules/src/form-validator/email.validator/email.validator';
import { ResetPasswordSources } from 'ngx/go-modules/src/enums/';
import { BehaviorSubject, EMPTY, catchError, finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ngx-forgot-password',
	template: require('./forgot-password.component.html'),
	styles: [require('./forgot-password.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent  implements OnInit {

	@Input() public sessionId: string;
	@Input() public routeSource: ResetPasswordSources = ResetPasswordSources.DASHBOARD;
	@Output() public onBack: EventEmitter<void> = new EventEmitter();

	public form: FormGroup;
	public requestSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor (
		private authService: NgxAuthService,
		private matDialog: MatDialog,
		private translate: TranslateService
	) {}

	public ngOnInit () {
		this.form = new FormGroup({
			email: new FormControl(null, [Validators.required, emailValidator()])
		});
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		this.loading$.next(true);
		this.authService.requestPassword(form.get('email').value, this.sessionId, this.routeSource)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					if (response.status === 401 && response.error.remainingAccountLockSeconds) {
						let timeInSeconds = parseInt(response.error.remainingAccountLockSeconds, 10);
						timeInSeconds = Math.ceil(timeInSeconds / 60);

						MessageDialogComponent.open(
							this.matDialog,
							{
								data: {
									title: this.translate.instant('forgot-password_send_verification_code_title'),
									message: this.translate.instant(
										'email-verification_lock_message',
										{ time: timeInSeconds }
									)
								}
							});
						return EMPTY;
					}
				}),
				finalize(() => {
					this.loading$.next(false);
				})
			)
			.subscribe(() => {
				this.requestSuccess$.next(true);
			});
	}

	public navigateBack () {
		this.onBack.emit();
	}

	public disableRequestPasswordButton () {
		return this.form.controls.email.hasError('required') || this.form.controls.email.hasError('email');
	}
}
