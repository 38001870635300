import template from './group-settings-panel.html';
import { GroupSettingsPanelController as controller } from './group-settings-panel.controller';
import type { Options } from 'go-modules/models/group-dep/group.factory';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import type { SidePanelService } from 'go-modules/side-panel/side-panel.service';
import type { IModalInstanceService } from 'angular-ui-bootstrap';

export class GroupSettingsPanelService {
	public static readonly NG1_INJECTION_NAME = 'GroupSettingsPanel' as const;

	/* @ngInject */
	constructor (private SidePanel: SidePanelService) {}

	public open (group, user, section, options: Options): IModalInstanceService {
		return this.SidePanel.open({
			template,
			controller,
			bindings: {
				section,
				user,
				options
			},
			windowClass: 'group-settings-side-panel',
			resolve: {
				/* @ngInject */
				updatedGroup: ($q) => {
					const deferred = $q.defer();

					// success handler
					const success = (savedGroup) => {
						if (savedGroup.hasInstructorRole(true) || user.is_root_user) {
							deferred.resolve(savedGroup);
						} else {
							deferred.reject(savedGroup);
						}
					};

					// error handler
					const error = (response) => {
						deferred.reject(response);
					};

					// get group
					group.getData(options).then(success).catch(error);

					return deferred.promise;
				}
			}
		});
	}
}

export const groupSettingsPanelServiceToken = upgradeNg1Dependency(GroupSettingsPanelService);

