import { IFilterService, IScope, noop } from 'angular';
import { ActivityEditorController } from '../activity-editor.controller';
import { IModalInstanceService } from 'angular-ui-bootstrap';

export interface Bindings {
	activityEditorController: ActivityEditorController;
	rubricTitle: string;
	rubricRole: string;
	rubricTemplate: any;
	rubricReviewersAccess: boolean;
	onEdited: ({rubricTemplate: any}) => void;
	onRemoved: ({rubricTemplate: any}) => void;
	onUpdatedCheckbox: ({allowAccess: boolean}) => void;
}

export const RUBRIC_VIEWER_PREVIEW_MODE = 'live';
export const RUBRIC_VIEWER_EDIT_MODE = 'edit';

export class RubricPanelController implements Bindings {

	// Bindings
	public activityEditorController: ActivityEditorController;
	public rubricTitle: string;
	public rubricRole: string;
	public rubricTemplate: any;
	public rubricReviewersAccess: any;
	public onEdited: ({rubricTemplate: any}) => void;
	public onRemoved: ({rubricTemplate: any}) => void;
	public onUpdatedCheckbox: ({allowAccess: boolean}) => void;

	public canReviewersSeeRubrics: boolean = false;
	public isLoading: boolean;

	/* @ngInject */
	constructor (
		private $scope: IScope,
		private $filter: IFilterService,
		private RubricTemplateModel: any,
		private rubricEditorModal: any,
		private confirmModal,
		private messageModal
	) {
		this.isLoading = false;
	}

	public $onInit (): void {
		if (!this.rubricTemplate) {
			throw new Error('Property binding `rubricTemplate` is required!');
		} else if (!(this.rubricTemplate instanceof this.RubricTemplateModel)) {
			throw new Error('Property binding `rubricTemplate` must be of type RubricTemplateModel!');
		}
		this.canReviewersSeeRubrics = !!this.rubricReviewersAccess;
		this.initAsync().then(() => this.$scope.$digest());
	}

	public getTitle (): string {
		return this.rubricRole === 'student'
			? this.$filter('translate')('activity-editor-new_student-rubrics')
			: this.$filter('translate')('activity-editor-new_instructor-rubrics');
	}

	public isInstructorRubric (): boolean {
		return this.rubricRole === 'instructor';
	}

	public async handlePreview (): Promise<void> {
		try {
			await this.openPreviewModal().result;
		} catch (error) {
			// Do nothing when the modal is dismissed (unless it is an actual error)
			if (error instanceof Error) {
				throw error;
			}
		}
	}

	public handleEdit () {
		try {
			this.RubricTemplateModel.checkUsage({id: this.rubricTemplate.id})
				.$promise.then((response) => {
					//if rubric is in use on this assignment then no editing allowed
					if (response.in_use &&
						response.activities.includes(this.activityEditorController.activity.activity_id)) {
						return this.messageModal.open({
							modalData: {
								title: 'modal-rubric-edit_title',
								message: 'modal-rubric-edit_message',
								resolveBtnClass: 'primary-btn'
							}
						});
					} else {
						//if no library item exists then just edit it
						if (!response.has_library_item){
							this.openRubricEditorModal(RUBRIC_VIEWER_EDIT_MODE).result.then((template) => {
								this.rubricTemplate = template;
								this.onEdited({rubricTemplate: this.rubricTemplate});
							});
						//else create a copy of the rubric and then edit it
						} else {
							this.confirmModal.open({
								size: 'sm',
								modalData: {
									title: 'modal-rubric-edit-copy_title',
									message: 'modal-rubric-edit-decouple_message',
									yes: 'common_create-copy',
									no: 'common_cancel'
								}
							}).result.then(() => {
								this.RubricTemplateModel.copy({
									id: this.rubricTemplate.id
								}).$promise.then((res) => {
									this.rubricTemplate = res;
									this.openRubricEditorModal(RUBRIC_VIEWER_EDIT_MODE, false)
										.result.then((template) => {
											this.rubricTemplate = template;
											this.onEdited({rubricTemplate: this.rubricTemplate});
										});
								}).catch(noop);
							}).catch(noop);
						}
					}
				});
		} catch (error) {
			// Do nothing when the modal is dismissed (unless it is an actual error)
			if (error instanceof Error) {
				throw error;
			}
		}
	}

	public handleRemove () {
		//if rubric is in use on this activity, don't let it be removed
		this.RubricTemplateModel.checkUsage({id: this.rubricTemplate.id})
			.$promise.then((response) => {
				if (response.in_use &&
					response.activities.includes(this.activityEditorController.activity.activity_id)) {
					this.messageModal.open({
						modalData: {
							title: 'modal-rubric-delete_title',
							message: 'modal-rubric-delete_message',
							resolveBtnClass: 'primary-btn'
						}
					});
				//else just remove with no confirmation
				} else {
					this.onRemoved({rubricTemplate: this.rubricTemplate});
				}
			});
	}

	public toggleReviewersRubricsAccess (): void {
		this.onUpdatedCheckbox({allowAccess: this.canReviewersSeeRubrics});
	}

	protected async initAsync (): Promise<void> {
		try {
			this.isLoading = true;
			await this.resolveRubricTemplate();
		} finally {
			this.isLoading = false;
		}
	}

	protected async resolveRubricTemplate (): Promise<void> {
		if (this.rubricTemplate.hasOwnProperty('$promise')) {
			await this.rubricTemplate.$promise;
		}
	}

	protected openPreviewModal (): IModalInstanceService {
		const previewOnly: boolean = true;
		return this.openRubricEditorModal(RUBRIC_VIEWER_PREVIEW_MODE, previewOnly);
	}

	protected openRubricEditorModal (mode: string, previewOnly: boolean = false):
	IModalInstanceService {
		const modalData: any = {
			options: {
				mode,
				previewOnly,
				postData: {
					group_id: this.activityEditorController.activity.group_id
				},
				schemaId: this.rubricTemplate.id
			}
		};

		return this.rubricEditorModal.open({modalData});
	}
}
