import { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { ORG_TYPE_LABELS, ORG_TYPES } from 'go-modules/org-settings/org-settings.constants';

export class OrgSettingsController {
	// Bindings
	public group;
	public user;
	public mayMask: ({account, user, role}) => void;
	public loginAs: ({user: any}) => void;

	// Local properties
	public accountFilter;
	public accounts = [];
	public selectedOrgType = null;
	public options = {selectedAccount: false};
	public groups = this.groupsIndex;
	public adminToolsUrl = '';
	private orgTypes = [
		{ key: ORG_TYPE_LABELS.EDU, value: ORG_TYPES.HIGHER_ED },
		{ key: ORG_TYPE_LABELS.K12, value: ORG_TYPES.K12 },
		{ key: ORG_TYPE_LABELS.BIZ, value: ORG_TYPES.PROFESSIONAL_ORG },
		{ key: ORG_TYPE_LABELS.OTHER, value: ORG_TYPES.PERSONAL_USE }
	];

	/* @ngInject */
	constructor (
		private Group,
		private groupsIndex: GroupsIndexService,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService
	) {}

	public $onInit () {
		this.adminToolsUrl = `${clientSettings.AdminToolsUrl}#/tool/org-settings?searchType=org&group=${this.group.group_id}`;
		// Set the selected org type when the
		// org has already been assigned one.
		if (this.group.org_type) {
			this.selectedOrgType = this.orgTypes.filter((orgType) => this.group.org_type === orgType.key).shift();
		}

		this.Group.getOrgAccountsWithStats(this.group.group_id).then((accounts) => this.accounts = accounts);
	};

	public clearSearch () {
		this.accountFilter = '';
		this.$timeout(() => {
			this.$element[0].querySelector<HTMLInputElement>('.search-input').focus();
		});
	}
}
