export enum ScoreFilter {
	ALL = 'all',
	SCORED = 'scored',
	NO_SCORE = 'no_score'
}

export const ScoreFilterTranslations: Record<ScoreFilter, string> = {
	all: 'filter-all',
	scored: 'filter-scored',
	no_score: 'common_unscored'
};

export enum PaginationFilter {
	RECORDING = 'recordings',
	USER = 'user',
	PEER = 'peer',
	UNGRADED = 'ungraded',
	GRADED = 'graded',
	ARCHIVED = 'archived',
	SEARCH = 'search'
}

export const scorePaginationMap = new Map([
	[ScoreFilter.ALL, PaginationFilter.RECORDING],
	[ScoreFilter.SCORED, PaginationFilter.GRADED],
	[ScoreFilter.NO_SCORE,  PaginationFilter.UNGRADED]
]);

export const paginationScoreMap = new Map([
	[PaginationFilter.RECORDING, ScoreFilter.ALL],
	[PaginationFilter.GRADED, ScoreFilter.SCORED],
	[PaginationFilter.UNGRADED,  ScoreFilter.NO_SCORE]
]);

