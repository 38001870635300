import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { BehaviorSubject } from 'rxjs';

export interface ConfirmPasswordDialogData {
	user: any;
	sessionId?: string;
	provider: string;
};

@Component({
	selector: 'confirm-password-dialog',
	template: require('./confirm-password-dialog.component.html'),
	styles: [require('./confirm-password-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmPasswordDialogComponent {

	public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public error: any;
	public form: FormGroup;

	constructor (
		public dialogRef: MatDialogRef<ConfirmPasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmPasswordDialogData,
		@Inject($stateToken) private $state,
		private eventService: EventService,
		private ngxAuthService: NgxAuthService,
		private translate: TranslateService
	) {}

	public ngOnInit () {
		this.form = new FormGroup({
			currentPassword: new FormControl(null, [Validators.required])
		});
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		this.loading$.next(true);
		this.ngxAuthService.connectSsoAccount({
			password: form.get('currentPassword').value,
			session: this.data.sessionId
		}, this.data.provider).subscribe({
			next: (response) => {
				this.dialogRef.close(response);
			},
			error: (response) => {
				this.error = null;
				this.loading$.next(false);
				const { status, error } = response;

				switch(status) {
					case 400:
						this.error = this.translate.instant('modal-password-confirm_incorrect-password');
						break;
					case 401:
						const options = {
							accountLockTime: error.remainingAccountLockSeconds
						};
						return this.ngxAuthService.logout(options).then(() => {
							this.dialogRef.close();

							if (this.data.sessionId) {
								this.eventService.broadcast(EVENT_NAMES.ACCOUNT_LOCKED, options);
							} else {
								this.$state.go(States.AUTH_LOGIN);
							}
						});
					default:
						this.error = this.translate.instant('unknown-error-description');
				}
			}
		});
	}

	public close () {
		this.dialogRef.close();
	}
}
