import * as angular from 'angular';

import { activityEditorModule } from 'go-modules/activity-editor';
import * as angularTranslate from 'angular-translate';
import { SidePanelModule } from '../side-panel';
import { activityTemplateModel } from '../models/activity-template';
import { confirmModalModule } from '../modals/confirm';
import { groupDepModel } from '../models/group-dep';
import { activityModel } from '../models/activity';

import { ActivityEditorPanel } from './activity-editor-panel.service';

import './style.less';
import { ngxDowngradeModalServiceModule } from 'ngx/go-modules/src/services/downgrade-modal';

export const activityEditorPanelModule = angular.module('activity-editor-panel', [
	activityEditorModule.name,
	angularTranslate,
	SidePanelModule,
	activityTemplateModel,
	groupDepModel,
	activityModel,
	confirmModalModule,
	ngxDowngradeModalServiceModule
])
	.service(ActivityEditorPanel.NG1_INJECTION_NAME, ActivityEditorPanel);
