import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from 'ngx/go-modules/src/services/environment-vars/environments';

export enum ACTIVITY_SOURCES {
	NEW = 'new',
	LIBRARY = 'library',
	PREVIOUS = 'previous'
}

@Component({
	selector: 'activity-source-dialog',
	template: require('./activity-source-dialog.component.html'),
	styles: [require('./activity-source-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitySourceDialogComponent implements OnInit {
	public environmentVarsService: EnvironmentVarsService;
	public isDefaultLti: boolean = false;
	public sources = ACTIVITY_SOURCES;

	constructor (
		public dialogRef: GoDialogRef
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		this.isDefaultLti = environment.name === ENVIRONMENTS.LTI && environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT;
	}

	public close (source?: ACTIVITY_SOURCES) {
		this.dialogRef.close(source);
	}
}
