
import { ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { $stateParamsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state-params.upgrade';
import { StateParams } from '@uirouter/angularjs';
import { BehaviorSubject, Subject, combineLatest, filter, of, take, takeUntil, tap } from 'rxjs';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { States } from 'go-modules/enums/states.enum';
import { SessionListDataSource } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import type { ActivityFactory } from 'go-modules/models/activity/activity.factory';
@Component({
	selector: 'ngx-session-view',
	template: require('./session-view.component.html'),
	styles: [require('./session-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxSessionViewComponent implements OnInit, OnDestroy {
	public options$$: BehaviorSubject<any> = new BehaviorSubject({});
	public eventSubscription;
	public otherSessions;
	public isFullyLoaded$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public componentDestroyed$$ = new Subject();

	constructor (
		public sessionListDataSource: SessionListDataSource,
		private ngxSessionService: NgxSessionService,
		private eventService: EventService,
		private injector: Injector,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject($stateParamsToken) public $stateParams: StateParams,
		@Inject($stateToken) private $state,
		@Inject(sessionToken) private sessionModel: ReturnType<typeof SessionModel>,
		@Inject(activityToken) private activityModel: ActivityFactory
	) {}

	public ngOnInit () {
		this.setSessionAndLicense();

		const eventNames = [
			EVENT_NAMES.FEEDBACK_SESSION_EXITED,
			EVENT_NAMES.SPEED_GRADER_CHANGE
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => Object.values<string>(eventNames).includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_SESSION_EXITED:
						this.$state.go(States.DASHBOARD_ACTIVITY_VIEW, {
							activity_id: this.selectedService.getActivity().activity_id
						});
						break;
					case EVENT_NAMES.SPEED_GRADER_CHANGE:
						const newSession = this.otherSessions.find((s) => s.session_id === ev.data.newSessionId);
						if (newSession) {
							this.selectedService.setSession(newSession);
						} else {
							this.selectedService.setSession(null);
						}
						this.$state.go(States.DASHBOARD_SESSION_VIEW, {
							session_id: ev.data.newSessionId
						});
						break;
				}
			});
	}

	public sessionReady (selectedSession) {
		if (!selectedSession.isAwaitingStart()) {
			this.sessionListDataSource.connect()
				.pipe(take(1)).subscribe((data) => {
					const sessionIds = data.map((s) => s.session_id);
					if (!sessionIds.includes(this.selectedService.getSession().session_id)) {
						this.sessionListDataSource.addSession(this.selectedService.getSession());
					}

					this.options$$.next({
						speedGraderList: sessionIds
					});
					this.otherSessions = data;

					// trigger ng1 digest cycle
					this.injector.get('$rootScope').$new().$evalAsync();
				});
		}

		// save is_fully_loaded to session so we dont have to load it again
		this.sessionListDataSource.editSession(this.selectedService.getSession());
	}

	public ngOnDestroy (): void {
		this.eventSubscription?.unsubscribe();
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	/**
	 * Set session and license in selected service if not available
	 * Complete loading after all info retrieved
	 * @private
	 */
	private setSessionAndLicense () {
		const selectedSession = this.selectedService.getSession();

		let sessionObservable;
		if (selectedSession &&
			selectedSession.is_fully_loaded &&
			parseInt(selectedSession.session_id, 10) === parseInt(this.$stateParams.session_id, 10)
		) {
			sessionObservable = of(selectedSession);
			this.sessionReady(selectedSession);
		} else {
			sessionObservable = this.ngxSessionService.getSession(
				this.$stateParams.session_id,
				[
					'activity',
					'activity.attachments',
					'activity.recording_instructions',
					'activity.feedback_instructions',
					'player_time_sync_events',
					'activity.source_media',
					'activity.aiPrompts'
				]
			).pipe(
				tap((session)=> {
					session = this.sessionModel.model(session);
					this.selectedService.setSession(session);
					this.sessionReady(session);
					session.is_fully_loaded = true;
					if (!this.selectedService.getActivity()) {
						this.selectedService.setActivity(this.activityModel.model(session.activity));
					}
				})
			);
		}

		const licenseObservable = this.selectedService.selectedSubject
			.asObservable()
			.pipe(
				takeUntil(this.componentDestroyed$$),
				filter((selected: any) => selected.group)
			);

		combineLatest([sessionObservable, licenseObservable])
			.pipe(
				takeUntil(this.componentDestroyed$$)
			)
			.subscribe(() => {
				this.isFullyLoaded$$.next(true);
			});
	}
}
