import { UserService } from 'go-modules/models/user/user.service';
import type { StateParams } from '@uirouter/angularjs';
import type { DashboardUtilsServiceInterface } from '../dashboard-root/services/dashboard-utils.service';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';

/* @ngInject */
export class LicenseSeatsManagementController implements ng.IController {
	public myAccount;
	public license;
	public licenseHolderEmail;

	/* @ngInject */
	constructor (
		public userService: UserService,
		public $stateParams: StateParams,
		private $location: angular.ILocationService,
		private dashboardUtilsService: DashboardUtilsServiceInterface,
		private ngxLicenseService: LicenseService,
		private $scope: ng.IScope
	) {}

	public $onInit (): void {
		this.myAccount = this.dashboardUtilsService.myAccount;

		if (this.$stateParams.licenseId != null) {
			this.ngxLicenseService.getLicense(this.$stateParams.licenseId)
				.subscribe((license) => {
					this.license = license;
					this.$scope.$evalAsync();
				});
		}
		this.licenseHolderEmail = this.$stateParams.licenseHolderEmail;
	}

	public backToLicenseManagement ()
	{
		let path = '/license-management/';
		if (this.userService.currentUser.is_root_user) {
			path += this.license.salesforce_license.org_id;
		}
		this.$location.path(path);
		this.$scope.$apply();
	}
}
