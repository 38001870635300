import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthTemplateComponent } from 'ngx/go-modules/src/components/auth-template/auth-template.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		AuthTemplateComponent
	],
	exports: [
		AuthTemplateComponent
	]
})
export class AuthTemplateModule {}
