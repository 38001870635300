import * as angular from 'angular';
import { PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { AppDataService } from 'dashboard/services/app-data.service';
import { AppSessionManagerService } from 'dashboard/services/app-session-manager.service';
import { FallbackRoute } from 'dashboard/services/fallback-route.service';
import { GroupsIndexService } from 'go-modules/groups-index/groups-index.service';
import { FineUploaderService } from 'go-modules/fine-uploader/fine-uploader.service';
import { AppEnvService } from 'go-modules/go-app-env/app-env.service';
import { AnalyticsService } from 'go-modules/services/analytics/analytics.service';
import { ZendeskService } from 'go-modules/services/zendesk/zendesk.service';
import { BannerNotificationsService } from 'go-modules/services/banner-notifications/banner-notifications.service';
import { UserService } from 'go-modules/models/user/user.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import type { LogoutSubscribeOptions } from 'ngx/go-modules/src/services/auth/interfaces/logout-subscribe-options.interface';
import { PWAService } from 'go-modules/services/pwa/pwa.service';
import { GoLocalizeHelperService } from 'go-modules/translation-helper/go-localize-helper.service';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import type { SessionManagerService } from 'go-modules/session-manager/session-manager.service';

export interface Locals {
	selected: any;
	currentUser: any;
}

interface AppBaseScope extends ng.IScope, Locals {}

export class AppBaseController {
	/* @ngInject */
	constructor (
		public $scope: AppBaseScope,
		private selectedService: SelectedService,
		private authService: AuthService,
		private ngxAuthService: NgxAuthService,
		private pubnub: PubnubService,
		private fullstoryService: FullstoryService,
		private sessionManager: SessionManagerService,
		private AppData: AppDataService,
		private appSessionManagerService: AppSessionManagerService,
		private fallbackRouteService: FallbackRoute,
		private groupsIndex: GroupsIndexService,
		private fineUploader: FineUploaderService,
		private appEnv: AppEnvService,
		private analyticsService: AnalyticsService,
		private goLocalizeHelper: GoLocalizeHelperService,
		private zendeskService: ZendeskService,
		private bannerNotificationsService: BannerNotificationsService,
		private $document,
		private userService: UserService,
		pwaService: PWAService
	) {
		// Setup PWA as early as possible to prevent native install prompts
		pwaService.init();

		this.$scope.selected = this.selectedService.getSelected();
		this.$scope.currentUser = this.userService.currentUser;

		this.selectedService.selectedSubject.subscribe((selected) => {
			this.$scope.selected = selected;
		});

		const body = angular.element(this.$document[0].body);
		this.userService.userSubject.subscribe(() => {
			this.$scope.currentUser = this.userService.currentUser;
			if (this.userService.currentUser.is_root_user) {
				body.addClass('super');
			}
		});

		// Reset all services that had been instantiated with user information
		this.authService.authLogoutSubject.subscribe((options?: LogoutSubscribeOptions) => {
			this.resetUserRelatedServices(options);
		});

		this.ngxAuthService.authLogoutSubject.subscribe((options?: LogoutSubscribeOptions) => {
			this.resetUserRelatedServices(options);
		});
	}

	public resetUserRelatedServices (options?: LogoutSubscribeOptions) {
		const body = angular.element(this.$document[0].body);

		this.pubnub.reset();
		this.sessionManager.end();
		this.fullstoryService.clearUser();
		this.AppData.reset();
		this.appSessionManagerService.reset();
		if (this.userService.currentUser) {
			this.fallbackRouteService.setUser(null);
		}
		this.groupsIndex.clear();
		this.fineUploader.reset();
		this.appEnv.reset();
		this.analyticsService.reset();
		this.goLocalizeHelper.reset();
		this.zendeskService.reset();
		this.bannerNotificationsService.reset();
		this.userService.clear();
		this.selectedService.clear();

		if (body.hasClass('super')) {
			body.removeClass('super');
		}

		sessionStorage.clear();

		if (options?.accountLockTime) {
			sessionStorage.setItem('accountLockTime', options.accountLockTime);
		}
	}
}
