import { Injectable, Inject } from '@angular/core';
import { $uiRouterGlobalsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/ui-router-globals.upgrade';
import { $transitionsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/transitions.upgrade';
import { States as VerticalNavState } from 'ngx/go-modules/src/enums/states';
import { States } from 'go-modules/enums/states.enum';
import { Observable, shareReplay, map } from 'rxjs';
import type { Transition, TransitionService, UIRouterGlobals } from '@uirouter/angularjs';
import type { Observer, TeardownLogic } from 'rxjs';
import { EnvironmentVarsService } from '../environment-vars/environment-vars.service';
import { ENVIRONMENTS, LTI_ENVIRONMENT_MODES } from '../environment-vars/environments';

@Injectable({
	providedIn: 'root'
})
export class BreadCrumbStateService {

	public breadcrumbsState$: Observable<VerticalNavState>;
	public navState$: Observable<States>;
	public showMobileBackButton$: Observable<boolean>;
	public isLTI: boolean = false;
	public environment;

	private folderViewStates: States[] = [
		States.DASHBOARD_ACTIVITY_VIEW,
		States.DASHBOARD_FOLDERS,
		States.DASHBOARD_FOLDER_VIEW,
		States.DASHBOARD_SESSION_VIEW
	];
	private licenseManagementViewStates: States[] = [
		States.LICENSE_MANAGEMENT,
		States.LICENSE_SEATS_MANAGEMENT
	];
	private archiveViewStates: States[] = [
		States.DASHBOARD_ARCHIVE
	];
	private userSettingStates: States[] = [
		States.DASHBOARD_SETTINGS
	];

	constructor (
		@Inject($uiRouterGlobalsToken) private $uiRouterGlobals: UIRouterGlobals,
		@Inject($transitionsToken) private $transitions: TransitionService
	) {
		this.navState$ = new Observable((observer: Observer<States>) => {
			observer.next(this.$uiRouterGlobals.current.name as States);
			return this.$transitions.onSuccess({}, (transition: Transition) => {
				observer.next(transition.to().name as States);
			}) as TeardownLogic;
		}).pipe(shareReplay({ bufferSize: 1, refCount: true }));

		this.breadcrumbsState$ = this.navState$.pipe(map((state: States) => this.getStateFromRouter(state)));

		const environmentVarsService = EnvironmentVarsService.getInstance();
		this.environment = environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		this.isLTI = this.environment.name === ENVIRONMENTS.LTI;
		this.showMobileBackButton$ = this.navState$.pipe(map((state: States) => {
			// always show back button if not in LTI
			if (!this.isLTI) {
				return true;
			}
			// in normal lti, show back button if not in activity view
			if (this.environment.mode === LTI_ENVIRONMENT_MODES.DEFAULT) {
				if (state === States.DASHBOARD_ACTIVITY_VIEW) {
					return false;
				} else {
					return true;
				}
			// in course-level lti, show back button if not in folder view
			} else {
				if (state === States.DASHBOARD_FOLDER_VIEW) {
					return false;
				} else {
					return true;
				}
			}
		}));
	}

	private getStateFromRouter (state: States): VerticalNavState {
		if (this.folderViewStates.includes(state)) {
			return VerticalNavState.FOLDER_VIEW;
		} else if (this.licenseManagementViewStates.includes(state)) {
			return VerticalNavState.LICENSE_MANAGEMENT_VIEW;
		} else if (this.archiveViewStates.includes(state)) {
			return VerticalNavState.ARCHIVE_VIEW;
		} else if (this.userSettingStates.includes(state)) {
			return VerticalNavState.USER_SETTINGS;
		} else {
			return VerticalNavState.NO_BREADCRUMBS;
		}
	}

}
