import * as angular from 'angular';
import { superBarComponent } from './super-bar.component';

import './super-bar.less';
import { modelRouterModule } from '../model-router';
import { groupDepModel } from 'go-modules/models/group-dep';
import { goModal } from 'go-modules/modals';
import { SelectedModule } from 'go-modules/services/selected';
import { ngxOrgPickerModule } from 'ngx/go-modules/src/components/org-picker';
import { ngxFolderMenuModule } from 'ngx/go-modules/src/components/menus/folder-menu';
import { ngxOrgMenuModule } from 'ngx/go-modules/src/components/menus/org-menu';
import { ngxMenuButtonModule } from 'ngx/go-modules/src/components/menus/menu-button';
import { ngxAccountMenuModule } from 'ngx/go-modules/src/components/menus/account-menu';

export const superBarModule = angular
	.module('super-bar', [
		modelRouterModule,
		groupDepModel,
		goModal,
		SelectedModule,
		ngxOrgPickerModule,
		ngxMenuButtonModule,
		ngxFolderMenuModule,
		ngxOrgMenuModule,
		ngxAccountMenuModule
	])
	.component('superBar', superBarComponent)
	.name;
