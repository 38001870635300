import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { PAYMENT_TYPES } from 'go-modules/payment-panel/payment-panel.controller';

export interface CoursePaywallOnPayEvent {
	type: PAYMENT_TYPES;
}
@Directive({
	selector: 'course-payment-wall'
})
export class CoursePaymentWallWrapperDirective extends UpgradeComponent {
	@Input() public user;
	@Input() public course;
	@Input() public lti;
	@Output() public onCredit: EventEmitter<void>;
	@Output() public onPay: EventEmitter<CoursePaywallOnPayEvent>;

	constructor (
		elementRef: ElementRef,
		injector: Injector
	) {
		super('coursePaymentWall', elementRef, injector);
	}
}
