import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FeedbackHoldComponent } from './feedback-hold.component';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		GoMaterialModule,
		GoTooltipModule
	],
	declarations: [
		FeedbackHoldComponent
	],
	entryComponents: [
		FeedbackHoldComponent
	],
	exports: [
		FeedbackHoldComponent
	]
})
export class FeedbackHoldModule {}
