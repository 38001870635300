import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'copy-activities-dialog',
	template: require('./copy-activities-dialog.component.html'),
	styles: [require('./copy-activities-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class CopyActivitiesDialogComponent implements OnInit, OnDestroy {
	public form = new FormGroup({
		shouldCopy: new FormControl(false),
		folder: new FormControl(null)
	});
	private shouldCopySubscription: Subscription;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			availableFolders: CourseFolder[];
		}
	) {}

	public ngOnInit () {
		this.shouldCopySubscription = this.form
			.get('shouldCopy')
			.valueChanges.subscribe((value) => {
				if (value) {
					this.form.get('folder').setValidators([Validators.required]);
				} else {
					this.form.get('folder').clearValidators();
				}
				this.form.get('folder').updateValueAndValidity();
			});
	}

	public ngOnDestroy () {
		if (this.shouldCopySubscription) {
			this.shouldCopySubscription.unsubscribe();
		}
	}

	public cancel () {
		this.dialogRef.close(null);
	}

	public confirm () {
		if (this.form.valid) {
			this.dialogRef.close(this.form.value.folder);
		}
	}
}
