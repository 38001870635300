import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export enum Sizes {
	XXSMALL = 320,
	XSMALL = 480,
	TABLET = 580,
	SMALLER = 640,
	SMALL = 768,
	MEDIUM = 992,
	LARGE = 1200
}

export enum Views {
	SIDEBAR = 'SIDEBAR',
	ACTIVITY_VIEW = 'ACTIVITY_VIEW',
	FEEDBACK_PANEL_MOBILE_VIEW = 'FEEDBACK_PANEL_MOBILE_VIEW'
}

export class ResponsiveViewService {
	public static readonly NG1_INJECTION_NAME = 'responsiveView' as const;

	public currentWidth: number;
	public activeView: Views = Views.SIDEBAR;

	private subscribers: Set<() => void> = new Set();
	private views = {
		SIDEBAR: () => !this.isAtMost(Sizes.SMALL) || this.activeView === Views.SIDEBAR,
		ACTIVITY_VIEW: () => !this.isAtMost(Sizes.SMALL) || this.activeView === Views.ACTIVITY_VIEW,
		FEEDBACK_PANEL_MOBILE_VIEW: () => !this.isAtLeast(Sizes.SMALLER)
	};

	/* @ngInject */
	constructor (private $window: ng.IWindowService) {
		this.currentWidth = this.$window.innerWidth;
		// A bad jank happens between the css changing before the js when using Debounce. This will
		// need to be addressed if we notice this method causing a negative performance impact.
		this.$window.addEventListener('resize', this.runSubscribers);
	}

	public showView (key: string): boolean {
		const fn = this.views[key];
		return fn ? fn() : false;
	}

	public setActiveView (view: Views): void {
		this.activeView = view;
	}

	/**
	 * Given a value from Sizes and a callback, this function
	 * will execute the provided callback any time a resize or
	 * zoom event causes the browser's width to cross the
	 * `expectedSize` threshold in either direction.
	 */
	public subscribe (sizes: any[], cb: () => void): any {
		const subscribers = sizes.map((size) => {
			const wrapped = this.wrapSubscriber(size, cb);
			this.subscribers.add(wrapped);
			return wrapped;
		});

		return () => {
			subscribers.forEach((subscriber) => this.subscribers.delete(subscriber));
		};
	}

	/**
	 * Returns whether the screen's current width is at least `size`
	 */
	public isAtLeast (size: Sizes): boolean {
		return this.$window.innerWidth >= size;
	}

	/**
	 * Returns whether the screen's current width is at most `size`
	 */
	public isAtMost (size: Sizes): boolean {
		return this.$window.innerWidth <= size;
	}

	private runSubscribers = () => {
		this.subscribers.forEach((sub) => sub());
		this.currentWidth = this.$window.innerWidth;
	};

	private wrapSubscriber (expectedSize: Sizes, cb: () => void): () => void {
		return () => {
			const oldWidth = this.currentWidth;
			const newWidth = this.$window.innerWidth;
			const crossedThreshold = (oldWidth > expectedSize && newWidth <= expectedSize)
				|| (oldWidth <= expectedSize && newWidth > expectedSize);
			if (crossedThreshold) cb();
		};
	}
}

export const responsiveViewToken = upgradeNg1Dependency(ResponsiveViewService);
