import { GoBannerService } from 'go-modules/go-banner/go-banner.service';
import { OfflineMode } from 'go-modules/go-offline-mode/offline-mode.service';

export class BannerNotificationsService {
	public announcements = [];

	/* @ngInject */
	constructor (
		private Announcement,
		private goBannerService: GoBannerService,
		private offlineMode: OfflineMode,
		private $rootScope: angular.IRootScopeService,
		private $log
	) {}

	/**
	 * Sets up notifications banner.
	 */
	public async configure (): Promise<void> {
		try {
			this.announcements = await this.Announcement.query();

			if (Array.isArray(this.announcements)) {
				this.announcements.forEach((banner) => this.goBannerService.addBanner(banner));
			}
			this.offlineMode.attachOfflineBannerWatcher();

			this.$rootScope.$on('$destroy', () => {
				this.offlineMode.detachOfflineBannerWatcher();
			});
		} catch(error) {
			this.$log.error(error);
		}
	}

	public reset (): void {
		this.announcements = [];
		this.goBannerService.clearBanners();
	}
}
