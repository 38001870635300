import { Pipe, PipeTransform } from '@angular/core';
import type { UserRole } from 'ngx/go-modules/src/interfaces';
import { Roles } from 'ngx/go-modules/src/enums';

@Pipe({
	name: 'highestRole'
})
export class HighestRolePipe implements PipeTransform {
	public transform (value: string[] | UserRole[]): string | UserRole {
		if (!value || !value.length) {
			return;
		}

		const roleOrder = Object.values(Roles);
		const isString = typeof value[0] === 'string';

		return value.sort((previous, current) => {
			let prevValue;
			let currentValue;

			if (isString) {
				prevValue = roleOrder.indexOf(previous);
				currentValue = roleOrder.indexOf(current);
			} else {
				prevValue = roleOrder.indexOf(previous.role);
				currentValue = roleOrder.indexOf(current.role);
			}

			return prevValue > currentValue ? -1 : 1;
		})[0];
	}
}
