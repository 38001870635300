import template from './activity-report.component.html';
import { ActivityReportController as controller } from './activity-report.controller';

export const activityReportComponent = {
	template,
	controller,
	bindings: {
		activityId: '<',
		groupId: '<'
	}
} as ng.IComponentOptions;
