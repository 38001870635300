import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { sessionModel } from '../models/session';
import { activityModel } from '../models/activity';
import { helpUrlsModule } from '../help-urls/index';
import { tagEditorModule } from '../tag-editor';

import { activityReportComponent } from './activity-report.component';

import './style.less';
import { ngxMarkerDisplayModule } from 'ngx/go-modules/src/components/marker-display';
import { ngxZeroStateModule } from 'ngx/go-modules/src/components/zero-state';

export const activityReport = angular.module('activity-report', [
	sessionModel,
	activityModel,
	angularTranslate,
	helpUrlsModule,
	tagEditorModule,
	ngxMarkerDisplayModule,
	ngxZeroStateModule
])
	.component('activityReport', activityReportComponent);
