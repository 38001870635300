import template from './panel.component.html';
import { AiMarkerSetPanelController as controller } from './panel.controller';
import './style.less';

export default {
	bindings: {
		aiPrompts: '<',
		activity: '<',
		onEdit: '&',
		onRemove: '&'
	},
	controller,
	require: {
		activityEditorController: '?^activityEditor'
	},
	template
} as ng.IComponentOptions;
