import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { CollectionDestinationComponent } from './collection-destination.component';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { ManageLibraryCollectionFolderModule } from '../manage-library-collection-folder/manage-library-collection-folder.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		A11yModule,
		FocusOnFirstInvalidFieldModule,
		ManageLibraryCollectionFolderModule
	],
	declarations: [
		CollectionDestinationComponent
	],
	entryComponents: [
		CollectionDestinationComponent
	],
	exports: [
		CollectionDestinationComponent
	]
})
export class CollectionDestinationModule {}
