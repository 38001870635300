import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { LicensesTableComponent } from './licenses-table/licenses-table.component';
import { LicenseHolderComponent } from './licenses-table/license-holder/license-holder.component';
import { InviteUserDialogComponent } from './licenses-table/license-holder/invite-user-dialog/invite-user-dialog.component';
import { LicenseManagementComponent } from './license-management.component';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { LicensePlanBadgeModule } from 'ngx/go-modules/src/components/license-plan-badge/license-plan-badge.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GoTooltipModule,
		TranslateModule,
		GoMaterialModule,
		FocusOnFirstInvalidFieldModule,
		NgxZeroStateModule,
		LicensePlanBadgeModule
	],
	declarations: [
		LicenseManagementComponent,
		LicensesTableComponent,
		LicenseHolderComponent,
		InviteUserDialogComponent
	],
	entryComponents: [
		LicenseManagementComponent,
		LicensesTableComponent,
		InviteUserDialogComponent
	],
	providers: [
		TranslatePipe
	]
})
export class LicenseManagementModule {}
