import * as angularticsGoogleAnalytics from 'angulartics-google-analytics';
import { AnalyticsConfigInterface, AnalyticsUserEvent, AnalyticsUserInterface } from './analytics.interface';
import * as angular from 'angular';

export enum GADimensions {
	LaunchTarget = 'dimension1'
};

export enum LaunchTargets {
	Browser = 'browser',
	PWA = 'pwa',
	TWA = 'twa'
};

/* @ngInject */
export class AnalyticsService {
	public analyticsConfig: AnalyticsConfigInterface;

	constructor (
		public $analytics: angularticsGoogleAnalytics,
		public $window: ng.IWindowService,
		public User,
		private $log: angular.ILogService
	) {}

	public configure (analyticsConfig: AnalyticsConfigInterface) {
		if(this.analyticsConfig) return;

		// set analytics user
		this.$analytics.setUsername(analyticsConfig.user.user_id);

		const googleAnalytics = analyticsConfig.analytics;

		if (googleAnalytics && googleAnalytics.user) {
			/* eslint-disable */
			(function (i, s, o, g, r, a, m) {i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
				(i[r].q = i[r].q || []).push(arguments); }, i[r].l = 1 * new Date().getTime(); a = s.createElement(o),
					m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
			})(this.$window, this.$window.document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
			/* eslint-enable */

			this.pushGoogleAnalyticsUser(analyticsConfig.user);

			// Intentionally don't send page view, let angulartics do that
			this.$window.ga('create', googleAnalytics.user, 'auto');

			// Send GA our Launch Target (web, web installed PWA or app store PWA)
			this.$window.ga('set', GADimensions.LaunchTarget, this.getPWADisplayMode());

			this.analyticsConfig = analyticsConfig;
		}
	}

	public reset () {
		this.analyticsConfig = null;
	}

	private getPWADisplayMode (): LaunchTargets {
		const isStandalone = this.$window.matchMedia('(display-mode: standalone)').matches;
		if (this.$window.document.referrer.startsWith('android-app://')) {
			return LaunchTargets.TWA;
		} else if (this.$window.navigator.standalone || isStandalone) {
			return LaunchTargets.PWA;
		}
		return LaunchTargets.Browser;
	}

	private pushGoogleAnalyticsUser (user: AnalyticsUserInterface) {
		try {
			this.$window.dataLayer.push({ user_id: String(user.user_id), event: AnalyticsUserEvent.UserAuthenticated });
		} catch (err) {
			this.$log.error(err);
		}
	}
}
