import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { AccessibleDropListModule } from 'ngx/go-modules/src/directives/accessible-drop-list/accessible-drop-list.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ManageCollectionsPanelComponent } from './manage-collections-panel.component';
import { ModifyCollectionsPanelModule } from 'ngx/go-modules/src/components/library/modify-collections-panel/modify-collections-panel.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		GoTooltipModule,
		FocusOnFirstInvalidFieldModule,
		DragDropModule,
		AccessibleDropListModule,
		ModifyCollectionsPanelModule
	],
	declarations: [
		ManageCollectionsPanelComponent
	],
	entryComponents: [
		ManageCollectionsPanelComponent
	],
	exports: [
		ManageCollectionsPanelComponent
	]
})
export class ManageCollectionsPanelModule {}
