import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoStepComponent } from './go-step/go-step.component';
import { GoStepperComponent } from './go-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
	imports: [
		CommonModule,
		CdkStepperModule
	],
	declarations: [
		GoStepperComponent,
		GoStepComponent
	],
	entryComponents: [
		GoStepperComponent,
		GoStepComponent
	],
	exports: [
		GoStepperComponent,
		GoStepComponent
	]
})
export class GoStepperModule {}
