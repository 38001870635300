import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordComponent } from 'ngx/go-modules/src/components/auth/forgot-password/forgot-password.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { AuthTemplateModule } from 'ngx/go-modules/src/components/auth-template/auth-template.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		GoMaterialModule,
		AuthTemplateModule
	],
	declarations: [
		ForgotPasswordComponent
	],
	entryComponents: [
		ForgotPasswordComponent
	],
	exports: [
		ForgotPasswordComponent
	]
})
export class ForgotPasswordModule {}
