import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { GRID_CONSTANTS } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid.constants';
import { FolderListDataSource } from 'ngx/go-modules/src/services/folder-list-datasource/folder-list-datasource';

@Component({
	selector: 'folders-grid-view',
	template: require('./folders-grid-view.component.html'),
	styles: [require('./folders-grid-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoldersGridViewComponent {
	@Output() public goToFolder: EventEmitter<any>  = new EventEmitter();
	public minWidth: number = GRID_CONSTANTS.minWidth;
	public maxWidth: number = GRID_CONSTANTS.maxWidth;
	public minGap: number = GRID_CONSTANTS.minGap;

	constructor (
		public folderListDataSource: FolderListDataSource
	) {}
}
