import { NgModule } from '@angular/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CommonModule } from '@angular/common';
import { MenuButtonComponent } from './menu-button.component';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';

@NgModule({
	imports: [
		GoMaterialModule,
		CommonModule,
		MenuModule
	],
	declarations: [
		MenuButtonComponent
	],
	entryComponents: [
		MenuButtonComponent
	],
	exports: [
		MenuButtonComponent
	]
})
export class MenuButtonModule {}
