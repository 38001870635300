import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { ArchiveFilter, ArchiveFilterTranslations } from 'ngx/go-modules/src/enums/archive-filter';
import { SortOrder, SortOrderTranslations } from 'ngx/go-modules/src/enums/sort-order-main';
import { VIEWS } from 'ngx/go-modules/src/enums/views';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { ViewStateDataSource } from 'ngx/go-modules/src/services/view-state-datasource/view-state.datasource';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'ngx-archive-view',
	template: require('./archive-view.component.html'),
	styles: [require('./archive-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class NgxArchiveViewComponent implements OnInit, OnDestroy {
	public readonly views = VIEWS;
	public readonly componentDestroyed = new Subject();
	public currentView: string;
	public form: FormGroup;
	public filterOptions: ArchiveFilter[] = Object.values(ArchiveFilter);
	public sortByOptions: SortOrder[] = Object.values(SortOrder).filter((option) => option !== SortOrder.DEFAULT);
	public archiveFilterTranslations = ArchiveFilterTranslations;
	public sortOrderTranslations = SortOrderTranslations;
	public currentView$: Observable<VIEWS>;

	constructor (
		public translatePipe: TranslatePipe,
		public archivedListDataSource: ArchivedListDataSource,
		public viewStateDataSource: ViewStateDataSource,
		@Inject(uaDetectToken) public UADetect: UADetectClass
	) {}

	public ngOnInit () {
		this.currentView$ = this.viewStateDataSource.viewState$;

		this.initializeForm();
		this.form.get('sortBy').valueChanges
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe((selected) => this.archivedListDataSource.sortBy(selected));

		this.form.get('filterBy').valueChanges
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe((selected) => this.archivedListDataSource.filterBy(selected));
	}

	public ngOnDestroy (): void {
		this.componentDestroyed.next(true);
		this.componentDestroyed.complete();
	}

	private initializeForm () {
		this.form = new FormGroup({
			sortBy: new FormControl(this.archivedListDataSource.currentSort),
			filterBy: new FormControl(this.archivedListDataSource.currentFilter)
		});
	}
}
