import { EventEmitter, Input, Output, Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'marker-set-panel'
})
export class MarkerSetPanelDirective extends UpgradeComponent {
	@Input() public markerSet;
	@Input() public activity;
	@Output() public onEdited: EventEmitter<any>;
	@Output() public onRemoved: EventEmitter<any>;

	constructor (
		elementRef: ElementRef,
		injector: Injector
	) {
		super('markerSetPanel', elementRef, injector);
		const $scope = injector.get('$scope');
		/**
		 * We have to fire the evalAsync otherwise it won't fire the ng1 digest cycle
		 * https://stackoverflow.com/q/58986644/1248889
		 */
		$scope.$evalAsync();
	}
}
