import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FilterViewLayoutModule } from 'ngx/go-modules/src/components/filter-view-layout/filter-view-layout.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { NgxArchiveViewComponent } from './archive-view.component';
import {
	NgxArchiveTableViewComponent
} from 'ngx/go-modules/src/components/archive/archive-view/archive-table-view/archive-table-view.component';
import { OutsideClickModule } from 'ngx/go-modules/src/directives/outside-click';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import {
	NgxArchiveGridViewComponent
} from 'ngx/go-modules/src/components/archive/archive-view/archive-grid-view/archive-grid-view.component';
import { NgxArchiveCardModule } from 'ngx/go-modules/src/components/archive/archive-card/archive-card.module';
import { GridVirtualScrollViewportModule } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid-virtual-scroll-viewport.module';
import { ArchivedListDataSource } from 'ngx/go-modules/src/services/archive';
import { MenuModule } from 'ngx/go-modules/src/components/menus/menu/menu.module';
import { ArchiveMenuModule } from 'ngx/go-modules/src/components/archive/archive-menu/archive-menu.module';
import { ArchiveZeroStateComponent } from './archive-zero-state/archive-zero-state.component';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { EllipsisWithCountModule } from 'ngx/go-modules/src/components/ellipsis-with-count/ellipsis-with-count.module';
import { ThumbnailModule } from 'ngx/go-modules/src/components/thumbnail/thumbnail.module';
import { LoadingModule } from 'ngx/go-modules/src/components/loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FilterViewLayoutModule,
		GoTooltipModule,
		OutsideClickModule,
		GoMaterialModule,
		NgxArchiveCardModule,
		GridVirtualScrollViewportModule,
		MenuModule,
		ArchiveMenuModule,
		NgxZeroStateModule,
		ReactiveFormsModule,
		GoSelectModule,
		EllipsisWithCountModule,
		ThumbnailModule,
		LoadingModule
	],
	declarations: [
		NgxArchiveViewComponent,
		NgxArchiveTableViewComponent,
		NgxArchiveGridViewComponent,
		ArchiveZeroStateComponent
	],
	entryComponents: [
		NgxArchiveViewComponent,
		NgxArchiveTableViewComponent,
		NgxArchiveGridViewComponent
	],
	exports: [
		NgxArchiveViewComponent,
		NgxArchiveTableViewComponent,
		NgxArchiveGridViewComponent
	],
	providers: [
		TranslatePipe,
		ArchivedListDataSource
	]
})
export class NgxArchiveViewModule {}
