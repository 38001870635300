import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Roles, SortOrder } from 'ngx/go-modules/src/enums';
import type { LicenseSeatRole, LicenseSeatsResponse } from 'ngx/go-modules/src/interfaces/licenses';
import type { LicenseSeatsQueryParams } from 'ngx/go-modules/src/interfaces/licenses/license-seats-query-params';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { Inject } from '@angular/core';

export interface LicenseSeatsdataSourceElement {
	expanded: boolean;
	user_id: number;
	full_name: string;
	first_name: string;
	last_name: string;
	email: string;
	last_login_at: string;
	total_courses: number;
	total_sessions: number;
	roles: LicenseSeatsRoleDataSourceElement[];
	removing: boolean;
}

export interface LicenseSeatsRoleDataSourceElement {
	course_name: string;
	instructor_name: string;
	role: string;
	last_login_at: null;
	total_courses: null;
	session_count: number;
	can_be_removed: boolean;
	raw_data: LicenseSeatRole;
	removing: boolean;
}

export type Endpoint = (licenseId: number, queryParams: LicenseSeatsQueryParams) => Observable<any>;

export const DEBOUNCE_TIME = 50;

export class LicenseSeatsDataSource extends DataSource<LicenseSeatsdataSourceElement> {
	public displayColumns: string[];
	public roleColumns: string[];
	public currentPage: number;
	public lastPage: number;
	public dataLength: number;
	public onError: Subject<any> = new Subject();
	// This may come back if we change the rules on who takes a seat
	// public role = new BehaviorSubject<Roles>(null);
	public login = new BehaviorSubject<any>(null);

	private dataStream = new ReplaySubject<LicenseSeatsdataSourceElement[]>();
	private page = new BehaviorSubject<number>(null);
	private perPage = new BehaviorSubject<number>(null);
	private sortBy = new BehaviorSubject<string>(null);
	private sortOrder = new BehaviorSubject<SortOrder>(null);
	private searchTerm = new BehaviorSubject<string>(null);
	private loaded = new BehaviorSubject<boolean>(false);
	private reloadDataSource = new BehaviorSubject(null);
	private onDisconnect = new Subject();

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		public endpointFunc: Endpoint,
		public licenseId: number,
		public debounce = 50,
		public initialSortBy = 'name',
		public initialOrder = SortOrder.ASC,
		public initialPage = 1,
		public initialPerPage = 15
	) {
		super();
		this.displayColumns = ['full_name', 'last_login_at', 'total_courses', 'total_sessions', 'remove_action'];
		this.roleColumns = ['course_name', 'last_login_at', 'total_courses', 'session_count', 'remove_action'];
		this.setSortBy(initialSortBy);
		this.setSortOrder(initialOrder);
		this.setPage(initialPage);
		this.setPerPage(initialPerPage);
		this.setSearchTerm('');
		this.observeChanges();
	}

	public get isLoaded (): Observable<boolean> {
		return this.loaded;
	}

	public connect (): Observable<LicenseSeatsdataSourceElement[]> {
		return this.dataStream;
	}

	public setData (data: LicenseSeatsdataSourceElement[]) {
		this.dataStream.next(data);
	}

	public setSortBy (sortBy: string) {
		this.sortBy.next(sortBy);
	}

	public setSortOrder (sortOrder: SortOrder) {
		this.sortOrder.next(sortOrder);
	}

	// This may come back if we change the rules on who takes a seat
	// public setRole (role: Roles) {
	// 	this.role.next(role);
	// }

	public setLogin (login: any) {
		this.login.next(login);
	}

	public setPage (page: number) {
		this.page.next(page);
	}

	public setPerPage (page: number) {
		this.perPage.next(page);
	}

	public setSearchTerm (searchTerm: string) {
		this.searchTerm.next(searchTerm);
	}

	public toggleRoleList (element: LicenseSeatsdataSourceElement) {
		element.expanded = !element.expanded;
	}

	public canBeRemove (element: LicenseSeatsdataSourceElement) {
		if (this.userService.currentUser.is_root_user) {
			return true;
		}
		return element.roles.every((item) => {
			return item.can_be_removed &&
			item.role !== Roles.OWNER &&
			element.user_id !== this.userService.currentUser.user_id;
		});
	}

	public reload () {
		this.reloadDataSource.next(true);
	}

	public disconnect (): void {}

	public destroy (): void {
		this.sortOrder.complete();
		this.page.complete();
		this.perPage.complete();
		this.login.complete();
		this.onDisconnect.next(true);
		this.onDisconnect.complete();
	}

	private observeChanges () {
		combineLatest([
			this.sortBy,
			this.sortOrder,
			this.page,
			this.perPage,
			this.searchTerm,
			this.login,
			this.reloadDataSource
		]).pipe(
			takeUntil(this.onDisconnect),
			tap(() => this.loaded.next(false)),
			debounceTime(this.debounce),
			map(([sortBy, sortOrder, page, perPage, searchTerm, login]): LicenseSeatsQueryParams => ({
				sort_by: sortBy,
				sort_order: sortOrder,
				page,
				per_page: perPage,
				search_term: searchTerm,
				login
			})),
			switchMap((queryData: LicenseSeatsQueryParams) => this.endpointFunc(this.licenseId, queryData)),
			catchError((error) => {
				this.onError.next(error);
				return of(null);
			}),
			map(this.mapResponse.bind(this))
		)
			.subscribe((data: LicenseSeatsdataSourceElement[]) => {
				this.loaded.next(true);
				this.setData(data);
			});
	}

	private mapResponse (response: LicenseSeatsResponse): LicenseSeatsdataSourceElement[]  {
		if(response) {
			this.currentPage = response.meta.current_page;
			this.lastPage = response.meta.last_page;
			this.dataLength = response.data.length;

			return response.data.map((data: any) => {
				return {
					...data,
					expanded: false,
					roles: data.roles.map(this.mapRoles)
				};
			});
		}
	}

	private mapRoles (value: LicenseSeatRole): LicenseSeatsRoleDataSourceElement {
		return {
			course_name: value.course.name,
			instructor_name: value.course.created_by.full_name,
			role: value.role,
			last_login_at: null,
			total_courses: null,
			session_count: value.course.sessions_count,
			can_be_removed: value.can_be_removed,
			raw_data: value,
			removing: false
		} as LicenseSeatsRoleDataSourceElement;
	}
}
