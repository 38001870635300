import * as angular from 'angular';
import { GroupCreatorService } from './group-creator.service';
import { allModelsModule, groupsIndexModule } from 'go-modules/index';
import { createCourseModalModule } from 'go-modules/modals/create-course';

export const groupCreatorModule = angular
	.module('group-creator', [
		allModelsModule,
		groupsIndexModule,
		createCourseModalModule
	])
	.service('GroupCreator', GroupCreatorService)
	.name;
