import type { CourseInvite } from 'ngx/dashboard/src/components/signup/course-invite';
import { Roles } from 'ngx/go-modules/src/enums';

export class SignupUtil {
	public static requiresAccountInfo (courseInvite: CourseInvite): boolean {
		if (!courseInvite?.course && !courseInvite?.invite) {
			return true;
		}
		if (courseInvite.course && !courseInvite.invite) {
			return false;
		}

		return courseInvite.invite.role !== Roles.REVIEWER && courseInvite.invite.role !== Roles.PRESENTER;
	}
}
