import { InviteFactory, InviteModel } from 'go-modules/models/invite/invite.schema';
import * as angular from 'angular';
import { noop } from 'angular';
import { UserService } from 'go-modules/models/user/user.service';
import { MessageModal } from 'go-modules/modals/message/modal.factory';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

/* @ngInject */
export class ManageUsersController implements ng.IController {
	public invites = {};
	public currentInvites = [] as InviteModel[];
	public selectedInvite = null;
	public selectedUser = null;

	constructor (
		private $scope,
		private $log: angular.ILogService,
		private $stateParams,
		private $location: angular.ILocationService,
		private Group,
		private UserGroupModel,
		private goModal,
		private messageModal: MessageModal,
		private ModelRouter,
		private inviteFactory: InviteFactory,
		private userService: UserService,
		private $translate: ng.translate.ITranslateService,
		private ngxDowngradeModalService: DowngradeModalService
	) {}

	public $onInit (): void {
		// check for user in route
		if (this.$stateParams.userId) {
			this.$scope.group.getDataPromise.then(() => {
				const user = this.$scope.group.users.find((u) => {
					return u.user_id === this.$stateParams.userId;
				});

				this.selectUser(user);

				// check action
				if (user != null && this.$stateParams.action === 'remove') {
					this.openRemoveUser(this.selectedUser);
				}
			}).catch(this.$log.error);
		}

		// load invites
		this.setInvites(this.getGroupId());
	}

	public getGroupId = (): number => {
		return this.$scope.group ? this.$scope.group.group_id : this.$stateParams.groupId;
	};

	/** Select a user */
	public selectUser = (user) => {
		if (this.selectedUser === user) {
			return user;
		}
		this.selectedUser = user;
		return user;
	};

	/**
	 * User sort function
	 *
	 * @param user
	 * @returns number
	 */
	public userSort = (user) => {
		if (!user || !user.role) {
			return false;
		}
		return this.Group.rolePrecedence[user.role];
	};

	/** Select an invite */
	public selectInvite = (invite: InviteModel): InviteModel => {
		if (this.selectedInvite === invite) {
			return;
		}
		this.selectedInvite = invite;
		return invite;
	};

	/** Resend an invite */
	public resendInvite = (invite: InviteModel): ng.IPromise<InviteModel> => {
		return invite.$resend({group_id: this.$scope.group.group_id})
			.then(() => {
				invite.lastSent = new Date();
				return invite;
			});
	};

	// open remove invite modal
	public openRemoveInvite = (invite: InviteModel): ng.IPromise<any> => {
		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-remove-invite_remove'),
			message: this.$translate.instant('modal-remove-invite_sure-delete-invite', {email: invite.email}),
			confirmText: this.$translate.instant('common_remove')
		}).then(() => {
			this.removeInvite(invite);
		}).catch(noop);
	};

	// Check if the user can be removed
	// This check is done when the user presses the remove user option because this endpoint is expensive.
	public checkIfCanRemoveUser = (user) => {
		if (this.userService.currentUser.is_root_user) {
			this.openRemoveUser(user);
			return;
		}

		this.UserGroupModel.getCanRemoveUser({ group_id: this.$scope.group.group_id, user_id: user.user_id })
			.$promise.then((result) => {
				return result.can_remove ?
					this.openRemoveUser(user) :
					this.openDeactivateUser(user, true);
			});
	};

	// open block user modal
	public openDeactivateUser = (user, redirected: boolean = false): ng.IPromise<any> => {
		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-deactivate-user_title'),
			message: redirected ?
				this.$translate.instant('modal-redirected-to-deactivate-user_sure-deactivate-user', {user: user.fullname, group: this.$scope.group.name}) :
				this.$translate.instant('modal-deactivate-user_sure-deactivate-user', {user: user.fullname, group: this.$scope.group.name}),
			confirmText: this.$translate.instant('modal-deactivate-user_deactivate-button')
		}).then(() => {
			this.deactivateUserInGroup(user, this.$scope.group);
		}).catch(noop);
	};

	// open remove user modal
	public openRemoveUser = (user): ng.IPromise<any> => {
		let note = null;
		if (user.role === 'presenter' && user.billing_entity_type === 'user') {
			note = this.$translate.instant('modal-remove-user_credit-will-be-given');
		}
		return this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-remove-user_remove-user'),
			message: this.$translate.instant('modal-remove-user_sure-remove-user', {user: user.fullname, group: this.$scope.group.name}),
			confirmText: this.$translate.instant('common_remove'),
			note
		}).then(() => {
			this.removeUserFromGroup(user, this.$scope.group);
		}).catch(noop);
	};

	// open edit User
	public openEditUser = (user): ng.IPromise<void> => {
		return this.goModal.open({
			modal: 'editUser',
			modalData: {
				user,
				currentUser: this.userService.currentUser,
				group: this.$scope.group,
				selected: this.$scope.selected,
				edit: true
			}
		}).result.then((resource) => {
			if (resource && resource.session_id) {
				this.$location.path('/sessions/' + resource.session_id);
			} else if (resource instanceof this.Group.GroupModel) {
				this.ModelRouter.navigateToGroup(resource, user);
			}
			this.$scope.$close();
		}).catch(this.$log.error);
	};

	// open invite modal
	public openInviteModal = (group): ng.IPromise<void> => {
		return this.goModal.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.Group.model(group)
			}
		}).result.then((invites: InviteModel[]) => {
			this.currentInvites = this.currentInvites || [];
			this.currentInvites = this.currentInvites.concat(invites);
			this.$scope.group.getData();
		}).catch(this.$log.error);
	};

	public reactivateUserToGroup = (user, group): ng.IPromise<any> => {
		const oldDeletedAt = user.deleted_at;

		// Move the user back into the undeleted section
		delete user.deleted_at;

		// undelete their paid user role
		return group.reactivateUser(user).catch((err) => {
			// If the request fails, put them back in the deleted list
			user.deleted_at = oldDeletedAt;
			this.$log.error(err);

			// And inform the user it failed
			this.messageModal.open({
				modalData: {
					title: 'group-settings_users-management-failed-title',
					message: 'group-settings_users-management-failed-message'
				}
			});
		});
	};

	private deactivateUserInGroup = (user, group): ng.IPromise<any> => {
		// This won't match the DB exactly, but that's okay.
		// We just need to inform angularjs to move this to the deactivated section
		user.deleted_at = (new Date()).toISOString();

		// Delete their user role without marking unpaid
		return group.deactivateUser(user).catch((err) => {
			// If the request fails, put them back in the active list
			delete user.deleted_at;
			this.$log.error(err);

			// And inform the user it failed
			this.messageModal.open({
				modalData: {
					title: 'group-settings_users-management-failed-title',
					message: 'group-settings_users-management-failed-message'
				}
			});
		});
	};

	/** Remove user from a group */
	private removeUserFromGroup = (user, group): ng.IPromise<any> => {
		const oldBillingEntityId = user.billing_entity_id;

		// This won't match the DB exactly, but that's okay.
		// We just need to inform angularjs to move this to the deleted section
		user.deleted_at = (new Date()).toISOString();
		delete user.billing_entity_id;

		// delete user from group
		return group.deleteUser(user).catch((err) => {
			// If the request fails, put them back in the active list
			delete user.deleted_at;
			user.billing_entity_id = oldBillingEntityId;
			this.$log.error(err);

			// And inform the user it failed
			this.messageModal.open({
				modalData: {
					title: 'group-settings_users-management-failed-title',
					message: 'group-settings_users-management-failed-message'
				}
			});
		});
	};

	/** Set the invites for the current group */
	private setInvites = (groupId): void => {
		if (groupId) {
			if (!this.invites[groupId]) {
				this.inviteFactory.query({groupId})
					.$promise.then((invites) => {
						this.invites[groupId] = invites;
						this.currentInvites = invites;
					}).catch(this.$log.error);
			} else if (!this.$stateParams.profile) {
				this.currentInvites = this.invites[groupId];
			}
		} else {
			this.currentInvites = [];
		}
	};

	/** Remove an invite */
	private removeInvite = (invite: InviteModel): ng.IPromise<InviteModel> => {
		this.currentInvites.splice(this.currentInvites.indexOf(invite), 1);
		return invite.$delete();
	};
}
