import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
	selector: 'pay-with-code',
	template: require('./pay-with-code.component.html'),
	styles: [require('./pay-with-code.component.scss')]
})
export class PayWithCodeComponent implements OnDestroy {
	@Output() public onCodeChange = new EventEmitter();
	public code = new FormControl(null, [Validators.required]);
	private componentDestroyed$$ = new Subject();

	constructor () {
		this.code.valueChanges
			.pipe(takeUntil(this.componentDestroyed$$), debounceTime(10))
			.subscribe((value) => {
				this.onCodeChange.emit(value);
			});
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}
}
