import * as angular from 'angular';
import { ModelRouterService } from './model-router.service';
import * as ngroute from 'angular-route';
import { groupDepModel } from 'go-modules/models/group-dep';
import { SelectedModule } from 'go-modules/services/selected';

export const modelRouterModule = angular
	.module('model-router', [
		ngroute,
		groupDepModel,
		SelectedModule,
		'group-settings-panel' // circular dependcy on model-router messes up webpack
	])
	.service('ModelRouter', ModelRouterService)
	.name;
