import { AnnouncementSchema } from 'go-modules/models/announcement/announcement.schema';
import { GoBannerHelper } from './go-banner-helper.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

const goListTemplate = `
	<go-banner-list banners="data.banners"></go-banner-list>
`;

interface GoBannerScope extends ng.IScope {
	data: { banners: AnnouncementSchema[] };
}

export class GoBannerService {
	public static readonly NG1_INJECTION_NAME = 'goBannerService' as const;

	private scope: GoBannerScope;
	private goListEl: HTMLElement;

	/* @ngInject */
	constructor (
		private $rootScope: ng.IRootScopeService,
		private $compile: ng.ICompileService,
		private $window: ng.IWindowService,
		private $document: ng.IDocumentService,
		private goBannerHelper: GoBannerHelper
	) {
		this.scope = this.$rootScope.$new() as GoBannerScope;
		this.scope.data = {
			banners: []
		};
	}

	// Add banner and inject into
	// DOM if we haven't already injected
	public addBanner (banner: AnnouncementSchema) {

		// Don't add banners that shouldn't show
		if (!this.goBannerHelper.shouldDisplayBanner(banner)) {
			return;
		}

		// Don't show banners already showing
		if (this.isCurrentlyDisplayed(banner)) {
			return;
		}

		this.getGoListEl();
		this.scope.data.banners.push(banner);
		this.updateBodyClass();
	}

	public removeBanner (banner: AnnouncementSchema) {
		this.scope.data.banners.splice(this.scope.data.banners.indexOf(banner), 1);
		this.updateBodyClass();
	}

	public clearBanners () {
		this.scope.data.banners = [];
	}

	public destroy () {
		this.scope?.$destroy();
		this.goListEl?.parentElement?.removeChild(this.goListEl);
	}

	private getGoListEl (): HTMLElement {
		if (this.goListEl) {
			return this.goListEl;
		}
		this.goListEl = this.$compile(goListTemplate)(this.scope)[0];
		this.$window.document.body.insertBefore(this.goListEl, this.$window.document.body.firstChild);
		return this.goListEl;
	}

	private updateBodyClass () {
		const lastBanner = this.scope.data.banners[this.scope.data.banners.length - 1];

		// Cleanup everything
		const removals = [];
		this.$document[0].body.classList.forEach((className) => {
			if (className.startsWith('go-banner-active')) {
				removals.push(className);
			}
		});
		removals.forEach((className) => this.$document[0].body.classList.remove(className));

		if (lastBanner) {
			this.$document[0].body.classList.add('go-banner-active');
			this.$document[0].body.classList.add(`go-banner-active-${lastBanner.type}`);
		}
	}

	private isCurrentlyDisplayed (banner: AnnouncementSchema): boolean {
		return this.scope.data.banners.find((existingBanner) => existingBanner.name === banner.name) != null;
	}
}

export const goBannerServiceToken = upgradeNg1Dependency(GoBannerService);
