import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import type { Activity } from 'ngx/go-modules/src/interfaces/activity';
import * as dayjs from 'dayjs';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';

@Component({
	selector: 'ngx-activity-due-date',
	template: require('./activity-due-date.component.html'),
	styles: [require('./activity-due-date.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityDueDateComponent {
	@Input() public date: any;
	@Input() public activity: Activity;
	@Input() public withDateLabel: boolean = true;

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {}

	public isPastDue () {
		if (this.selectedService.getGroup().hasReviewerRole(true)) {
			return dayjs().isAfter(this.activity.due_at) &&
				this.activity.total_score > 0 &&
				this.activity.num_graded < this.activity.usage;
		}
		return dayjs().isAfter(this.activity.due_at) && this.activity.have_i_submitted === false;
	}
}
