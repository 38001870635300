
import { DataSource, isDataSource, _RecycleViewRepeaterStrategy, _ViewRepeaterItemInsertArgs } from '@angular/cdk/collections';
import { CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, Input, IterableChangeRecord, IterableDiffers, NgZone, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { debounceTime, filter, first, interval, isObservable, map, Observable, Observer, of, Subscription } from 'rxjs';
import { CdkVirtualForContextOverride } from './cdk-virtual-for-of-context-override';
import { CdkVirtualForOfOverride } from './cdk-virtual-for-of-override';

const MIN_ITEMS = 2;

/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[cdkGridVirtualForOverride]', providers: [_RecycleViewRepeaterStrategy]})
// @ts-expect-error CDK Is complex
export class CdkVirtualForOfOverrrideDirective extends CdkVirtualForOf<any> implements OnDestroy  {
	public itemMinWidth = 0;
	public itemMaxWidth = 0;
	public minGap = 0;

	private columnCount = 0;
	private itemHeight = 0;
	private listItem: any;
	private resizeObserver: Subscription;
	private listItemSubscription: Subscription;
	private viewportSize = 0;
	private averageSize = 0;
	private dataSource;

	constructor (
		differs: IterableDiffers,
		viewRepeater: _RecycleViewRepeaterStrategy<any, any, CdkVirtualForContextOverride>,
		template: TemplateRef<CdkVirtualForContextOverride>,
		viewContainerRef: ViewContainerRef,
		ngZone: NgZone,
		public viewport: CdkVirtualScrollViewport,
		public cdr: ChangeDetectorRef
	) {
		super(viewContainerRef, template, differs, viewRepeater, viewport, ngZone);
	}

	public ngOnDestroy (): void {
		this.listItemSubscription.unsubscribe();
		this.resizeObserver.unsubscribe();
		this.disconnectDataSource();
		super.ngOnDestroy();
	}

	@Input()
	public set cdkGridVirtualForOverrideOf (data: any[] | Observable<any[]> | DataSource<any>) {
		let listItem: Observable<readonly any[]> = of([]);
		this.disconnectDataSource();

		if(Array.isArray(data)) {
			listItem = of(data);
		} else if(isObservable(data)) {
			listItem = data;
		} else if (isDataSource(data)) {
			listItem = data.connect(null);
			this.dataSource = data;
		}

		if(this.listItemSubscription) {
			this.listItemSubscription.unsubscribe();
		}

		this.listItemSubscription = listItem.subscribe((item: any[]) => {
			this.listItem = item;
			this.observerViewPortSize();
		});
	}

	private disconnectDataSource () {
		this.dataSource?.disconnect();
		this.dataSource = null;
	}

	private get privateAccess (): CdkVirtualForOfOverride {
		return this as unknown as CdkVirtualForOfOverride;
	}

	private observerViewPortSize (): void {
		this.resizeObserver?.unsubscribe();

		this.resizeObserver = new Observable((observer: Observer<number>) => {
			const windowObserver = new ResizeObserver((entries) => {
				observer.next(this.viewportSize = entries[0].contentRect.width);
			});

			windowObserver.observe(this.viewport.elementRef.nativeElement);

			// cleanup
			return () => {
				windowObserver.disconnect();
			};
		}).pipe(debounceTime(30)).subscribe((width: number) => {
			this.viewportSize = width;
			this.calculateItems();
		});
	}

	private calculateItems () {
		const minWidth = this.itemMinWidth;
		const maxWidth = this.itemMaxWidth;
		this.averageSize = (minWidth + maxWidth) / 2;
		let columnCount = Math.round(this.viewportSize / this.averageSize);

		// Apply gap and remove column if total size is greater than viewport width
		const totalGap = (columnCount - 1) * this.minGap;
		const widthAfterGapApplied = (columnCount * this.averageSize) + totalGap;

		if (widthAfterGapApplied > this.viewportSize) {
			columnCount -= 1;
		}

		const actualMinItems = this.viewportSize >= 748 ? 4 : this.viewportSize >= 585 ? 3 : MIN_ITEMS;
		this.columnCount = Math.max(columnCount, actualMinItems);

		const rows = this.chunkItems(this.columnCount);
		this.cdkVirtualForOf = rows;

		this.privateAccess._updateContext();
		this.cdr.detectChanges();

		// Assuming all grid has the same height, get the first grid and get the element height
		if(this.listItem.length) {
			interval(1)
				.pipe(
					map(() => this.viewport.elementRef.nativeElement.querySelector('.cdk-virtual-scroll-content-wrapper').firstElementChild),
					filter((value) => !!value),
					first()
				).subscribe((element: any) => {
					const wrapper = this.viewport.elementRef.nativeElement.querySelector('.cdk-virtual-scroll-content-wrapper') as HTMLDivElement;
					wrapper.style.rowGap = `${this.minGap}px`;
					this.itemHeight = element.offsetHeight + this.minGap;
					(this.viewport as any)._scrollStrategy._itemSize = this.itemHeight;
					(this.viewport as any).setTotalContentSize(this.itemHeight * (this.listItem.length - 1));
					(this.viewport as any).checkViewportSize();
				});
		}
	}

	private chunkItems (chunk: number) {
		const items = [];
		const itemsLength = this.listItem.length;

		for (let i = 0; i < itemsLength; i += chunk) {
			items.push(this.listItem.slice(i, i + chunk));
		}
		return items;
	}

	 /** Update the computed properties on the `GridVirtualForContext`. */
	private _updateComputedContextProperties (context: CdkVirtualForContextOverride) {
		context.first = context.index === 0;
		context.last = context.index === context.count - 1;
		context.even = context.index % 2 === 0;
		context.odd = !context.even;
		context.columnCount = this.columnCount;
		context.columnMinWidth = this.itemMinWidth;
		context.columnMaxWidth = this.itemMaxWidth;
		context.gap = this.minGap;
	}

	private _getEmbeddedViewArgs (
		record: IterableChangeRecord<any>,
		index: number
	  ): _ViewRepeaterItemInsertArgs<CdkVirtualForContextOverride> {
		// Note that it's important that we insert the item directly at the proper index,
		// rather than inserting it and the moving it in place, because if there's a directive
		// on the same node that injects the `ViewContainerRef`, Angular will insert another
		// comment node which can throw off the move when it's being repeated for all items.
		return {
			templateRef: this.privateAccess._template,
		  	context: {
				columnCount: this.columnCount,
				columnMinWidth: this.itemMinWidth,
				columnMaxWidth: this.itemMaxWidth,
				gap: this.minGap,
				$implicit: record.item,
				// It's guaranteed that the iterable is not "undefined" or "null" because we only
				// generate views for elements if the "cdkVirtualForOf" iterable has elements.
				cdkVirtualForOf: this.privateAccess.__cdkVirtualForOf,
				gridVirtualForOf: this.privateAccess.__cdkVirtualForOf,
				index: -1,
				count: -1,
				first: false,
				last: false,
				odd: false,
				even: false
		  },
		  index
		};
	  }
}
