import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WelcomeDialogComponent } from './welcome-dialog.component';
import { WelcomeStepComponent } from './welcome/welcome-step.component';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		A11yModule
	],
	declarations: [
		WelcomeDialogComponent,
		WelcomeStepComponent,
		ChoosePlanComponent
	],
	entryComponents: [
		WelcomeDialogComponent,
		WelcomeStepComponent,
		ChoosePlanComponent
	],
	exports: [
		WelcomeDialogComponent,
		WelcomeStepComponent,
		ChoosePlanComponent
	]
})
export class WelcomeDialogModule {}
