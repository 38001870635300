import { NgModule } from '@angular/core';
import {
	ActivityInstructionsDialogComponent
} from 'ngx/go-modules/src/components/dialogs/activity-Instructions-dialog/activity-instructions-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityInfoDirective } from 'ngx/go-modules/src/angularjs-wrappers/activity-info.directive';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		TranslateModule,
		A11yModule
	],
	declarations: [
		ActivityInstructionsDialogComponent,
		ActivityInfoDirective
	],
	exports: [
		ActivityInstructionsDialogComponent
	],
	entryComponents: [
		ActivityInstructionsDialogComponent
	]
})
export class ActivityInstructionsDialogModule {}
