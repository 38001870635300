import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxActivityDueDateComponent } from 'ngx/go-modules/src/components/folder-view/activity-due-date/activity-due-date.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		NgxActivityDueDateComponent
	],
	entryComponents: [
		NgxActivityDueDateComponent
	],
	exports: [
		NgxActivityDueDateComponent
	]
})
export class NgxActivityDueDateModule {}
