/* eslint-disable max-classes-per-file */
import { debounce } from 'lodash';
import { ResizeSensor } from 'css-element-queries';
import { ResponsiveViewService, Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { fromEventPattern, Subscription } from 'rxjs';
import { TrapIndexConfig } from 'go-modules/trap-tab-index/trap-tab-index.service';
import dayjs from 'dayjs';
import { UserTourType } from 'go-modules/models/tour/tour.factory';
import { UserService } from 'go-modules/models/user/user.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { PlacementConfig } from './placement-engine.factory';

export enum GoTourPosition {
	LEFT = 'left',
	RIGHT = 'right',
	BOTTOM = 'bottom',
	TOP = 'top'
}

interface GoTourScope extends ng.IScope {
	tour: GoTour;
	step: GoTourStep;
}

const ARIA_HIDDEN_ATTRIBUTE_NAME = 'data-go-tour-aria-hidden-count';

export interface GoTourStep {
	title: string;
	tourTitleAriaLabel: string;
	template: string;
	selector: string;
	placement: GoTourPosition;
	element?: JQLite;
	scope?: GoTourScope;
	highlightElement?: HTMLElement;
	elementPadding?: number;
}

interface GoTourConstraints {
	/**
	 * The date the tour is "launched" on the site.
	 *
	 * If `isOnboarding` is true, then all users created after this date will see the tour
	 *
	 * If `isOnboarding` is false, all users created before this date will see the tour
	 *
	 * DO NOT SET THE DATE TO SOME TIME FAR IN THE PAST AND SET `isOnboarding` to TRUE DO NOT BE LAZY
	 */
	tourStartDate: Date;
	/**
	 * Set to true for tours that should be seen by new signups only
	 *
	 * Set to false to show new product features to existing users
	 */
	isOnboarding: boolean;

	maxTourViews?: number;
}

export interface GoTourConfig {
	tourStepClass?: string;
	viewTrackKey: string;
	constraints: GoTourConstraints;
	steps: GoTourStep[];
	noArrow?: boolean;
	noStepsCounter?: boolean;
	afterStart?: () => void;
}

export class GoTourService {
	public static readonly NG1_INJECTION_NAME = 'goTour';
	public environmentVarsService: EnvironmentVarsService;
	public previousTourStartPromise: ng.IPromise<any>;
	private disableForE2es: boolean = false;
	private tourIsActive: boolean = false;
	private E2E_COOKIE_NAME: string = 'e2e-test-run';
	private sensor: ResizeSensor;
	private windowResizeSubscription: Subscription;
	private allowedE2ETourKeys = ['welcome-modal-tour'];

	/** @ngInject */
	constructor (
		public $compile: ng.ICompileService,
		public $cookies: ng.cookies.ICookiesService,
		public $interval: ng.IIntervalService,
		public $log: ng.ILogService,
		public $q: ng.IQService,
		public $rootScope: ng.IRootScopeService,
		public $templateCache: ng.ITemplateCacheService,
		public $timeout: ng.ITimeoutService,
		public $window: ng.IWindowService,
		public helpUrls,
		public masquerade,
		public PlacementEngine,
		public responsiveView: ResponsiveViewService,
		public UserTour: UserTourType,
		public TrapTabIndex,
		public userService: UserService,
		private featureFlag: FeatureFlag
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.disableForE2es = !!this.$cookies.get(this.E2E_COOKIE_NAME);
		this.previousTourStartPromise = this.$q.resolve();
	}

	public defineTour (config: GoTourConfig): GoTour {

		const misconfigured = config.steps.some((step) => {
			return !step.tourTitleAriaLabel ||
				!step.placement ||
				!step.template ||
				!step.selector;
		});

		if (misconfigured) {
			throw new Error('Misconfigured tour steps');
		}

		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return new GoTour(config, this, this.featureFlag);
	}

	public canTourStart (key: string): boolean {
		return !this.tourIsActive && (!this.disableForE2es || this.allowedE2ETourKeys.includes(key));
	}

	public setTourActivity (active: boolean): void {
		this.tourIsActive = active;
	}

	public markTourViewed (key: string, setMaxViews?: number): ng.IPromise<any> {
		return this.UserTour.viewTour({name: key, views: setMaxViews}).$promise;
	}

	public canTourBeViewed (key: string, constraints: GoTourConstraints): ng.IPromise<any> {
		if (this.masquerade.isMasked()) {
			return this.$q.reject({message: 'Tour not shown to masked users'});
		}

		if (!this.canTourStart(key)) {
			return this.$q.reject({message: 'Another tour is active or tours are disabled'});
		}

		const canSeeTourPromise = this.$q((resolve, reject) => {
			this.UserTour.get({name: key}).$promise
				.then((tour) => {
					const result = tour;
					if (result.hasOwnProperty('id')) {
						resolve(result);
					} else {
						resolve({});
					}
				})
				.catch((err) => {
					this.$log.error(err);
					reject();
				});
		});

		const allDataReadyPromise = this.$q.all({
			tour: canSeeTourPromise,
			user: this.userService.currentUser
		});

		return this.$q((resolve, reject) => {
			allDataReadyPromise.then((result: any) => {
				const tourViews = parseInt(result.tour?.views, 10);
				if ( (constraints.maxTourViews && isNaN(tourViews)) ||
					(!isNaN(tourViews) && constraints.maxTourViews > tourViews)) {
					return resolve();
				}

				if (Object.keys(result.tour).length > 0) {
					return reject({message: 'User has seen the tour.'});
				}
				// If this is an onboarding tour it should show to all users
				// Made after the constraint date.
				if (constraints.isOnboarding) {
					return dayjs(this.userService.currentUser.created).isAfter(constraints.tourStartDate) ?
						resolve() :
						reject({message: 'User was before onboarding start date'});
				}

				// Otherwise, show to all users made before the tourStartDate
				return dayjs(this.userService.currentUser.created).isBefore(constraints.tourStartDate) ?
					resolve() :
					reject({message: 'User joined GoReact after this tour feature was added'});
			}).catch(reject);
		});
	}

	public attachTourStep (element: HTMLElement, step: GoTourStep, config: GoTourConfig) {
		this.PlacementEngine.setTourShadowAroundElement(step.highlightElement, step.elementPadding);
		this.$window.document.body.appendChild(element);
		this.applyAriaHidden(element);
		step.scope.tour.trapTabStop = this.TrapTabIndex.start({
			targetElement: element,
			trapTabEvent: true,
			autoFocusFirstElement: true,
			onEscape: () => {
				step.scope.tour.next();
			}
		} as TrapIndexConfig);
		this.PlacementEngine.setPlacementForItem(
			element,
			step.highlightElement,
			step.placement,
			step.elementPadding,
			{noArrow: config.noArrow} as PlacementConfig
		);
	}

	public addWatchers (step: GoTourStep, config: PlacementConfig) {

		const handleChanges = () => {
			this.PlacementEngine.reset();
			this.PlacementEngine.setTourShadowAroundElement(step.highlightElement, step.elementPadding);
			this.PlacementEngine.setPlacementForItem(
				step.element[0],
				step.highlightElement,
				step.placement,
				step.elementPadding,
				{noArrow: config.noArrow} as PlacementConfig
			);
		};

		this.sensor = new ResizeSensor(this.$window.document.querySelector(step.selector), handleChanges);

		const watchWindow = debounce(handleChanges, 200);

		const windowResizeObserver = fromEventPattern(() => {
			this.$window.addEventListener('resize', watchWindow);
		}, () => {
			this.$window.removeEventListener('resize', watchWindow);
		});

		this.windowResizeSubscription = windowResizeObserver.subscribe();
	}

	public removeWatchers () {
		this.sensor.detach();
		this.windowResizeSubscription.unsubscribe();
	}

	// Wait for an element to be present or error if it can't be
	// found after 3 seconds
	public waitForElementBySelector (selector: string, attempt: number = 1): ng.IPromise<HTMLElement> {
		const MAX_ATTEMPTS = 9, waitTime = 333;

		return this.$q((resolve, reject) => {
			let el = this.$window.document.body.querySelector(selector) as HTMLElement;

			if (el) return resolve(el);

			const intervalId = this.$interval(() => {
				if (attempt > MAX_ATTEMPTS) {
					this.$interval.cancel(intervalId);
					return reject(`Could not find ${selector} to attach tour step to`);
				}

				el = this.$window.document.body.querySelector(selector) as HTMLElement;

				if (el) {
					this.$interval.cancel(intervalId);
					return resolve(el);
				}

				attempt++;
			}, waitTime);
		});
	}

	/**
	 * These methods were stolen from ui-bootstrap to mark everything
	 * but the tour as aria-hidden, as well as making sure things go back to the way they are supposed
	 * to be afterwards.
	 */
	public unhideBackgroundElements () {
		this.$window.document.querySelectorAll('[' + ARIA_HIDDEN_ATTRIBUTE_NAME + ']').forEach((hiddenEl) => {
			const ariaHiddenCount = parseInt(hiddenEl.getAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME), 10);
			const newHiddenCount = ariaHiddenCount - 1;
			hiddenEl.setAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME, newHiddenCount.toString());

			if (!newHiddenCount) {
				hiddenEl.removeAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME);
				hiddenEl.removeAttribute('aria-hidden');
			}
		});
	}

	private getSiblings (el: HTMLElement) {
		return Array.prototype.filter.call(el.parentElement.children, (child) => {
			return child !== el[0];
		});
	}

	private applyAriaHidden (el: HTMLElement) {
		if (!el || el.tagName === 'BODY') {
			return;
		}

		this.getSiblings(el).forEach((sibling) => {
			const elemIsAlreadyHidden = sibling.getAttribute('aria-hidden') === 'true';
			let ariaHiddenCount = parseInt(sibling.getAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME), 10);

			if (!ariaHiddenCount) {
				ariaHiddenCount = elemIsAlreadyHidden ? 1 : 0;
			}

			sibling.setAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME, ariaHiddenCount + 1);
			sibling.setAttribute('aria-hidden', 'true');
		});

		return this.applyAriaHidden(el.parentElement);
	}
}

export class GoTour {

	public helpUrls: any;
	public env: EnvironmentVarsService;
	public curStep: number;
	public trapTabStop: () => void | null;
	public originallyFocusedElement;

	public started: boolean = false;
	public endCallBack: () => void;

	constructor (
		private config: GoTourConfig,
		private tourFactory: GoTourService,
		private featureFlag: FeatureFlag
	) {
		// Expose these to be available on DOM
		this.helpUrls = this.tourFactory.helpUrls;
		this.env = this.tourFactory.environmentVarsService;
	}

	public getConfig () {
		return this.config;
	}

	public start (endCallBack = () => void(0)): ng.IPromise<void> {
		const environment = this.env.get(EnvironmentVarsService.VAR.ENVIRONMENT) as
			{name: ENVIRONMENTS};

		// Don't show tours on mobile (in non-LTI environments)
		// OR when feature flag to disable tour is enabled
		if ((environment.name !== ENVIRONMENTS.LTI && this.tourFactory.responsiveView.isAtMost(Sizes.SMALL)) ||
			this.featureFlag.isAvailable('DISABLE_TOURS')) {
			return this.tourFactory.$q.resolve();
		}

		if (this.started && !this.config.constraints.maxTourViews) {
			return this.tourFactory.$q.reject('This tour was already started');
		}

		this.started = true;

		this.endCallBack = endCallBack;

		// Each tour that tries to start needs to at least wait for
		// any tours that asked to start before them to finish asking.
		// Otherwise, if something asks while something else is in the middle of
		// asking, we might incorrectly say a tour is running even though the first
		// one may fail to start and we mark it not started. In other words,
		// we could get into a situation where no tour starts even though one should
		this.tourFactory.previousTourStartPromise = this.tourFactory
			.previousTourStartPromise
			.finally(() => {
				return this.tourFactory.canTourBeViewed(this.config.viewTrackKey, this.config.constraints)
					.then(() => {
						this.curStep = -1;
						this.tourFactory.setTourActivity(true);
						this.originallyFocusedElement = this.tourFactory.$window.document.activeElement;
						this.config.afterStart?.();
						return this.next();
					}).catch((error) => {
						this.tourFactory.$log.info('Tour not started', error, this);
						this.started = false;
						this.tourFactory.setTourActivity(false);
						return this.tourFactory.$q.resolve({message: 'Tour not started', error, tour: this});
					});
			});

		return this.tourFactory.previousTourStartPromise;
	}

	public next (): ng.IPromise<void> {
		this.curStep++;
		this.unloadStep(this.config.steps[this.curStep - 1]);
		const nextStep = this.config.steps[this.curStep];
		if (nextStep) {
			return this.loadStep(nextStep);
		}
		return this.end();
	}

	public prev (): ng.IPromise<void> {
		this.curStep--;
		this.unloadStep(this.config.steps[this.curStep + 1]);
		const prevStep = this.config.steps[this.curStep];
		if (prevStep) {
			return this.loadStep(prevStep);
		}
		return this.end();
	}

	public end (): ng.IPromise<void> {
		return this.tourFactory.$q((resolve, reject) => {
			if (this.started) {
				this.tourFactory.$timeout(() => {
					const currentStep = this.config.steps[this.curStep];
					this.unloadStep(currentStep);
					this.tourFactory.setTourActivity(false);
					this.tourFactory
						.markTourViewed(this.config.viewTrackKey)
						.then(() => {
							// Re-focus on this step's element
							if (this.originallyFocusedElement && this.originallyFocusedElement.focus) {
								this.originallyFocusedElement.focus();
							}

							resolve();
						})
						.catch(reject)
						.finally(() => {
							this.endCallBack();
						});
				});
			}

			resolve();
		});
	}

	public canTourBeViewed () {
		return this.tourFactory.canTourBeViewed(this.config.viewTrackKey, this.config.constraints);
	}

	public markAsViewed () {
		return this.tourFactory.markTourViewed(this.config.viewTrackKey);
	}

	private loadStep (step: GoTourStep): ng.IPromise<void> {

		return this.tourFactory.waitForElementBySelector(step.selector).then((attachElement) => {
			this.tourFactory.addWatchers(step, {noArrow: this.config.noArrow} as PlacementConfig);

			const tourStepElement = this.buildTourStep(step);
			const tourScope = this.tourFactory.$rootScope.$new() as GoTourScope;
			tourScope.tour = this;
			tourScope.step = step;

			// Add listener for window click advance
			const windowClicked = (evt: MouseEvent) => {
				const target = evt.target as HTMLElement;
				if (!target.closest('tour-step')) {
					this.tourFactory.$rootScope.$apply(() => this.next());
				}
			};

			const ignoreClicks = (evt: Event) => {
				evt.stopPropagation();
			};

			// Compile
			const compiled = this.tourFactory.$compile(tourStepElement)(tourScope);
			compiled[0].addEventListener('click', ignoreClicks);

			this.tourFactory.$window.addEventListener('click', windowClicked);

			// Set props
			step.element = compiled;
			step.scope = tourScope;
			step.highlightElement = attachElement;

			// Add cleanup
			tourScope.$on('$destroy', () => {
				this.trapTabStop();
				this.tourFactory.$window.removeEventListener('click', windowClicked);
				step.element[0].removeEventListener('click', ignoreClicks);
				this.tourFactory.unhideBackgroundElements();
			});

			// Attach
			this.tourFactory.attachTourStep(compiled[0], step, this.config);
		}).catch((error) => {
			this.tourFactory.$log.error(error);
			this.end();
		});
	}

	private buildTourStep (step: GoTourStep): string {
		return `
			<tour-step
				tour="tour"
				aria-labelledby="tour-step-title"
				step="step"
				role="dialog"
				aria-live="polite"
				class="${this.config.tourStepClass} {{step.placement}}">
				${step.template}
			</tour-step>
		`;
	}

	private unloadStep (step?: GoTourStep) {
		if (!step) return;

		this.tourFactory.removeWatchers();
		this.tourFactory.PlacementEngine.reset(step.highlightElement);
		delete step.highlightElement;

		if (step.element && step.scope) {
			step.element.remove();
			step.scope.$destroy();
		}
	}
}

export const goTourServiceToken = upgradeNg1Dependency(GoTourService);
