import * as angular from 'angular';
import { GoPage } from 'go-modules/go-page/go-page.service';
import type { CourseInvite } from 'ngx/dashboard/src/components/signup/course-invite';
import type { StateGoNamedParameters } from 'ngx/dashboard/src/components/signup/account-create/account-create.component';
import { SignupMode } from 'ngx/dashboard/src/components/signup/signup-mode';
import type { StateParams, StateService, StateOrName } from '@uirouter/angularjs';
import type { InviteValidation } from 'ngx/dashboard/src/interfaces/invite-validation.interface';
import type { CourseIdValidationDataInterface } from 'ngx/dashboard/src/interfaces/auth-response-course-id.interface';

export class StudentViewController {
	public course: CourseIdValidationDataInterface;
	public courseInvite: CourseInvite;
	public shouldShowStudentForm = false;
	public coldSignup = false;
	public invite: InviteValidation;
	public SIGNUPMODES = SignupMode;
	public infoKey;

	/* @ngInject */
	constructor (
		private $state: StateService,
		private $stateParams: StateParams,
		private goPage: GoPage,
		private translateFilter: angular.translate.ITranslateFilter,
		private $translate: angular.translate.ITranslateService
	) {
		this.course = this.$stateParams.courseInfo;
		this.invite = this.$stateParams.inviteInfo;
		this.infoKey = this.$stateParams['info-key'];

		if (this.course) {
			this.courseInvite = {
				invite: this.invite,
				course: this.course
			};
		}
	}

	public $onInit () {
		this.$translate.onReady()
			.then(() => this.goPage.setTitle(this.translateFilter('document-title_user-signup')));
	}

	// Allow either passing in the state directly or destructuring the params
	public onNavigate (event: StateGoNamedParameters | StateOrName): void {
		if (typeof event === 'object' && 'to' in event) {
			const {to, params, options} = event;
			this.$state.go(to, params, options);
		} else {
			this.$state.go(event);
		}
	}
}
