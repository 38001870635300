import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoSidepanelComponent } from './component/go-sidepanel.component';
import { GoAnchorModule } from 'ngx/go-modules/src/directives/go-anchor/go-anchor.module';
import { A11yModule } from '@angular/cdk/a11y';
import { GoMonitorTabEventModule } from 'ngx/go-modules/src/directives/go-monitor-tab-event';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		GoAnchorModule,
		A11yModule,
		GoMonitorTabEventModule
	],
	declarations: [
		GoSidepanelComponent
	],
	exports: [
		GoSidepanelComponent
	],
	entryComponents: [
		GoSidepanelComponent
	]
})
export class GoSidepanelModule {}
