import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SsoProviderButtonComponent } from './sso-provider-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatMenuModule,
		LoadingModule
	],
	exports: [
		SsoProviderButtonComponent
	],
	declarations: [
		SsoProviderButtonComponent
	],
	entryComponents: [
		SsoProviderButtonComponent
	]
})
export class SsoProviderButtonModule {}
