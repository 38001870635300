import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { States } from 'go-modules/enums/states.enum';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import type { ActivityFactory } from 'go-modules/models/activity/activity.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';

@Component({
	selector: 'ngx-activity-card',
	template: require('./activity-card.component.html'),
	styles: [require('./activity-card.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityCardComponent implements OnInit {
	@ViewChild(MenuComponent)
	public menuComponent: MenuComponent;
	@ViewChild('cardContainer', {read: ElementRef})
	public cardContainer: ElementRef;

	@Input() public activity: any;

	public group: any;
	public selectedActivity: any;

	constructor (
		@Inject($stateToken) private $state,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(activityToken) private activityModel: ActivityFactory
	) {}

	public ngOnInit () {
		this.group = this.selectedService.getGroup();
	}

	public shouldShowActivityStatus (): boolean {
		return this.activity.total_score > 0 &&
			((this.selectedService.getGroup().hasReviewerRole(true) && this.activity.usage > 0) ||
			this.selectedService.getGroup().hasPresenterRole() && this.activity.have_i_submitted);
	}

	public goToActivity (activity) {
		this.selectedService.setActivity(this.activityModel.model(activity));
		this.$state.go(States.DASHBOARD_ACTIVITY_VIEW, {
			activity_id: activity.activity_id
		});
	}

	public openMenu (event: MouseEvent, activity) {
		event.stopPropagation();
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.canOpenMenu()) {
			return;
		}
		event.preventDefault();
		this.selectedActivity = this.activityModel.model(activity);
		this.menuComponent.openMenu(event, this.cardContainer.nativeElement);
 	}

	public canOpenMenu (): boolean {
		return this.group.hasInstructorRole(true) || this.userService.currentUser.is_root_user;
	}

	public unviewedState (activity): boolean {
		return activity.unviewed_sessions > 0;
	}
}
