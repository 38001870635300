import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { clientSettings } from 'go-modules/models/common/client.settings';

@Component({
	selector: 'feature-flag-dialog',
	template: require('./feature-flag-dialog.component.html'),
	styles: [require('./feature-flag-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureFlagDialogComponent implements OnInit {
	public featureFlags: any;

	constructor (
		public dialogRef: GoDialogRef
	) {}

	public ngOnInit () {
		this.featureFlags = JSON.parse(JSON.stringify(clientSettings.featureFlags));
		const featureFlagOverrides = JSON.parse(localStorage.getItem('featureFlagOverrides'));
		if (featureFlagOverrides) {
			for (const [objKey, objValue] of Object.entries(featureFlagOverrides)) {
				this.featureFlags[objKey] = objValue;
			}
		}
	}

	public close () {
		this.dialogRef.close();
	}

	public reset () {
		localStorage.removeItem('featureFlagOverrides');
		this.dialogRef.close();
	}

	public toggleFlag (flag) {
		const existingOverrides = JSON.parse(localStorage.getItem('featureFlagOverrides')) || {};
		existingOverrides[flag.key] = flag.value;
		localStorage.setItem('featureFlagOverrides', JSON.stringify(existingOverrides));
	}

	public toggleComplexFlag (flag, complexFlag, complexValues) {
		const existingOverrides = JSON.parse(localStorage.getItem('featureFlagOverrides')) || {};
		if (!existingOverrides[flag.key]) {
			existingOverrides[flag.key] = this.featureFlags[flag.key];
		}
		existingOverrides[flag.key][complexFlag.key][complexValues.key] = complexValues.value;
		localStorage.setItem('featureFlagOverrides', JSON.stringify(existingOverrides));
	}
}
