import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { CollectionItemViewerComponent } from './collection-item-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMediaThumbnailModule } from '../../media-thumbnail/media-thumbnail.module';
import { ShareLibraryItemDialogModule } from '../../dialogs/share-library-item-dialog/share-library-item-dialog.module';
import { InvalidEmailsErrorDialogModule } from '../../dialogs/invalid-emails-error-dialog/invalid-emails-error-dialog.module';
import { CollectionDestinationModule } from '../collection-destination/collection-destination.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgxMediaThumbnailModule,
		FormsModule,
		ReactiveFormsModule,
		ShareLibraryItemDialogModule,
		InvalidEmailsErrorDialogModule,
		CollectionDestinationModule
	],
	declarations: [
		CollectionItemViewerComponent
	],
	entryComponents: [
		CollectionItemViewerComponent
	],
	exports: [
		CollectionItemViewerComponent
	]
})
export class CollectionItemViewerModule {}
