import * as angular from 'angular';
import { goBannerModule } from 'go-modules/go-banner';
import { goOfflineModeModule } from 'go-modules/go-offline-mode';
import { announcementModelModule } from 'go-modules/models/announcement';
import { BannerNotificationsService } from './banner-notifications.service';

export const bannerNotificationsModule = angular.module('BannerNotificationsModule', [
	goBannerModule,
	announcementModelModule,
	goOfflineModeModule
])
	.service('bannerNotificationsService', BannerNotificationsService)
	.name;
