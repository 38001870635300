import { NgModule } from '@angular/core';
import { CopyToClipboardDirective } from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard.directive';
import {
	CopyToClipboardWrapperComponent
} from 'ngx/go-modules/src/directives/go-clipboard/copy-to-clipboard-wrapper/copy-to-clipboard-wrapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		MatTooltipModule
	],
	declarations: [
		CopyToClipboardDirective,
		CopyToClipboardWrapperComponent
	],
	exports: [
		CopyToClipboardDirective,
		CopyToClipboardWrapperComponent
	],
	entryComponents: [
		CopyToClipboardWrapperComponent
	]
})
export class CopyToClipboardDirectiveModule {}
