import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EllipsisWithCountComponent } from './ellipsis-with-count.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		EllipsisWithCountComponent
	],
	exports: [
		EllipsisWithCountComponent
	]
})
export class EllipsisWithCountModule {}
