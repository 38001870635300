import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { CreateCourseModalService } from './modal.service';
import { analyticsModule } from 'go-modules/services/analytics';
import { courseTemplateModule } from 'go-modules/course-template';
import { allModelsModule } from 'go-modules/models';
import { groupsIndexModule } from 'go-modules/groups-index';

export const createCourseModalModule = angular.module('modal.create-course', [
	goModalBootstrapModule,
	angularTranslate,
	analyticsModule,
	courseTemplateModule,
	allModelsModule,
	groupsIndexModule
])
	.service('createCourseModal', CreateCourseModalService)
	.name;
