import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { ActivityMenuComponent } from './activity-menu.component';
import { FeedbackHoldModule } from 'ngx/go-modules/src/components/feedback-hold/feedback-hold.module';
import { CollectionDestinationModule } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FeedbackHoldModule,
		CollectionDestinationModule
	],
	declarations: [
		ActivityMenuComponent
	],
	entryComponents: [
		ActivityMenuComponent
	],
	exports: [
		ActivityMenuComponent
	],
	providers: [
		TranslatePipe,
		LibraryService
	]
})
export class ActivityMenuModule {}
