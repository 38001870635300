import { NgModule } from '@angular/core';
import { AuthInterceptorModule } from './auth/auth-interceptor.module';
import { FeatureFlagSyncInterceptorModule } from './feature-flag-sync/feature-flag-sync.interceptor.module';


@NgModule({
	imports: [
		AuthInterceptorModule,
		FeatureFlagSyncInterceptorModule
	],
	exports: [
		AuthInterceptorModule,
		FeatureFlagSyncInterceptorModule
	]
})
export class InterceptorsModule {}
