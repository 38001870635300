export interface NotificationSettings {
	id: number;
	user_id: number;
	assignment_submitted_email_frequency: string;
	feedback_submitted_email_frequency?: string;
	new_assignment_email_frequency?: string;
	assignment_due_email_frequency?: string;
	assignment_graded_email_frequency?: string;
	created_at: string;
	updated_at: string;
}

export interface EmailFrequencyMatOption {
	name: string;
	value: string;
}

export enum NotificationFrequencies {
	IMMEDIATELY = 'Immediately',
	HOURLY = 'Hourly',
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	NEVER = 'Never'
}
