import {
	Component
} from '@angular/core';

@Component({
	selector: 'filter-view-layout',
	template: require('./filter-view-layout.component.html'),
	styles: [require('./filter-view-layout.component.scss')]
})
export class FilterViewLayoutComponent {

	constructor () {}
}
