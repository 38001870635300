import { CsvReportService, ReportConfig, ReportResponse } from '../csv-report.service';

export class ActivityReportService extends CsvReportService {
	/* @ngInject */
	constructor ($window: ng.IWindowService, private ActivityModel: any) {
	  super($window);
	}

	public async execute (activityId: number | string | null, groupId: number | string): Promise<void> {
		const response = await this.requestCsvData({
			activity_id: activityId,
			group_id: groupId
		});
		const data = response.data;
		let filename = response.headers('content-disposition') || 'filename="assignment-report.csv";';
		filename = /filename="(.*)";/.exec(filename)[1];
		this.downloadCsv(data, filename);
	}

	public async requestCsvData (config: ReportConfig): Promise<ReportResponse> {
	  return await this.ActivityModel.getSummaryReportCsv(config).$promise;
	}
}
