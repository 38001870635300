import { DataSource, _RecycleViewRepeaterStrategy } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, DoCheck, Input, IterableDiffers, NgZone, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { GridVirtualScrollViewportComponent } from './components/grid-virtual-scroll-viewport/grid-virtual-scroll-viewport.component';
import { CdkVirtualForContextOverride } from './override/cdk-virtual-for-of-context-override';
import { CdkVirtualForOfOverrrideDirective } from './override/cdk-virtual-for-of-override.directive';
/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[gridVirtualFor]',  providers: [_RecycleViewRepeaterStrategy]})
export class GridVirtualForOfDirective implements DoCheck, OnDestroy {
	@Input('gridVirtualForItemMinWidth') public itemMinWidth: number;
	@Input('gridVirtualForItemMaxWidth') public itemMaxWidth: number;
	@Input('gridVirtualForMinGap') public minGap: number;

	public virtualGrid?: CdkVirtualForOfOverrrideDirective;

	constructor (
		private differs: IterableDiffers,
		private viewRepeater: _RecycleViewRepeaterStrategy<any, any, CdkVirtualForContextOverride>,
		private template: TemplateRef<CdkVirtualForContextOverride>,
		private viewContainerRef: ViewContainerRef,
		private ngZone: NgZone,
		private viewport: GridVirtualScrollViewportComponent,
		private cdr: ChangeDetectorRef
	) {
	}

	public ngOnDestroy (): void {
		this.virtualGrid.ngOnDestroy();
	}

	// do check is not working on instantiate object
	public ngDoCheck (): void {
		this.virtualGrid?.ngDoCheck();
	}

	@Input()
	public set gridVirtualForOf (data: any[] | Observable<any[]> | DataSource<any>) {
		this.validateParams();

		if (this.virtualGrid) {
			this.virtualGrid.cdkGridVirtualForOverrideOf = data;
			return;
		}

		// wait for viewport to render
		this.viewport.cdkViewportLoaded$.subscribe((cdkViewport: CdkVirtualScrollViewport) => {
			this.virtualGrid =  new CdkVirtualForOfOverrrideDirective(
				this.differs,
				this.viewRepeater,
				this.template,
				this.viewContainerRef,
				this.ngZone,
				cdkViewport,
				this.cdr
			);
			this.virtualGrid.itemMinWidth = this.itemMinWidth;
			this.virtualGrid.itemMaxWidth = this.itemMaxWidth;
			this.virtualGrid.minGap = this.minGap;
			this.virtualGrid.cdkGridVirtualForOverrideOf = data;
		});
	}

	public validateParams () {
		if (!this.itemMinWidth || !this.itemMaxWidth) {
			throw new Error('itemMaxWidth and itemMinWidth should greater than 0');
		}

		if(this.itemMaxWidth < this.itemMinWidth * 3 / 2) {
			throw new Error('itemMaxWidth has to be at least 50% bigger than itemMinWidth');
		}
	}
}
