import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input, OnDestroy, OnInit
} from '@angular/core';
import { Transition, TransitionService, UIRouterGlobals } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { $transitionsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/transitions.upgrade';
import { $uiRouterGlobalsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/ui-router-globals.upgrade';
import { BehaviorSubject } from 'rxjs';
import { libraryCollectionViewerModalToken, LibraryCollectionViewerModal } from 'go-modules/modals/library-collection-viewer/modal.service';
import { AppDataService, appDataServiceToken } from 'dashboard/services/app-data.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { ZendeskService, zendeskToken } from 'go-modules/services/zendesk/zendesk.service';
import { ResponsiveViewService, responsiveViewToken, Sizes } from 'go-modules/responsive-view/responsive-view.service';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import { NgxSuggestionPanelComponent } from 'ngx/go-modules/src/components/sidepanels/suggestion-panel/suggestion-panel.component';
import { GroupType } from 'ngx/go-modules/src/enums/group-type';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { FeatureFlagDialogComponent } from 'ngx/go-modules/src/components/dialogs/feature-flag-dialog/feature-flag-dialog.component';
import { ENVIRONMENT_CONSTANTS } from 'go-modules/constants/environment-constants.constant';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { CustomParamsDialogComponent, CustomParamsDialogData } from '../../dialogs/custom-params-dialog/custom-params-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { clientSettings } from 'go-modules/models/common/client.settings';

@Component({
	selector: 'dashboard-navigation',
	template: require('./dashboard-navigation.component.html'),
	styles: [require('./dashboard-navigation.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardNavigationComponent implements OnInit, OnDestroy {
	@Input() public collapsed$: BehaviorSubject<boolean>;
	public transitionState$ = new BehaviorSubject<States>(null);
	public states = States;
	public unsubscribe;
	public nonFolderStates = [
		States.LICENSE_MANAGEMENT,
		States.LICENSE_SEATS_MANAGEMENT,
		States.DASHBOARD_ARCHIVE,
		States.DASHBOARD_SETTINGS
	];
	public licenseStates = [
		States.LICENSE_MANAGEMENT,
		States.LICENSE_SEATS_MANAGEMENT
	];
	public environmentVarsService: EnvironmentVarsService;
	private readonly PARTNER_GOREACT = 'GO_REACT';

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject($uiRouterGlobalsToken) private $uiRouterGlobals: UIRouterGlobals,
		@Inject($transitionsToken) private $transitions: TransitionService,
		@Inject($stateToken) private $state,
		@Inject(appDataServiceToken) public AppData: AppDataService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(libraryCollectionViewerModalToken) private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		@Inject(groupToken) private group,
		@Inject(zendeskToken) private zendeskService: ZendeskService,
		@Inject(responsiveViewToken) private responsiveView: ResponsiveViewService,
		@Inject('Window') private window: Window,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		private ngxGoSidepanelService: GoSidepanelService,
		private modal: GoModalService,
		private authService: NgxAuthService,
		private dialog: MatDialog
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public get whiteLabel (): boolean {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL) as boolean;
	}

	public get version (): string {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT).version;
	}

	public get isLTI (): boolean {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}

	public get orgs () {
		if (this.isLTI) {
			return this.selectedService.getOrg() ? [this.selectedService.getOrg()] : [];
		}

		return this.selectedService.getMyOrgs();
	}

	public get customParams () {
		return this.environmentVarsService.get(EnvironmentVarsService.VAR.CUSTOM_PARAMS);
	}

	public ngOnInit (): void {
		this.transitionState$.next(this.$uiRouterGlobals.current.name as States);

		this.$transitions.onSuccess({}, (transition: Transition) => {
			this.transitionState$.next(transition.to().name as States);
		});

		if (!this.isLTI) {
			this.zendeskService.updateZendeskWidget();
			this.unsubscribe = this.responsiveView.subscribe([Sizes.SMALL], () => {
				this.zendeskService.updateZendeskWidget();
			});
		}
	}

	public ngOnDestroy () {
		if(this.unsubscribe) this.unsubscribe();
	}

	public goToOrg (org) {
		this.selectedService.setOrg(this.group.model(org));

		if (!this.isLTI) {
			this.selectedService.setGroup(null);
		}

		this.$state.go(States.DASHBOARD_FOLDERS, {id: org.group_id});
	}

	public goToArchive () {
		this.$state.go(States.DASHBOARD_ARCHIVE);
	}

	public goToLicense () {
		this.$state.go(States.LICENSE_MANAGEMENT);
	}

	public openFeatureFlags () {
		this.modal.open(FeatureFlagDialogComponent);
	}

	public shouldShowFeatureFlags () {
		return this.userService.currentUser.is_root_user &&
			ENVIRONMENT_CONSTANTS.APP_ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD;
	}

	public openLibrary () {
		this.libraryCollectionViewerModal.open({});
	}

	public shouldShowLibraryButton () {
		if (this.hasNoSelectedGroupAndAccount()) {
			return false;
		}
		return this.userService.currentUser.isInstructor || !!this.AppData.my_account;
	}

	public shouldShowLicenseManagement () {
		const partners = this.isLTI ? clientSettings.partners : this.AppData.partners;
		const isGoReactPartner = partners.every((value) => value.guid === this.PARTNER_GOREACT);

		return (this.userService.currentUser.isInstructor || this.userService.currentUser.is_root_user) &&
			isGoReactPartner;
	}

	public activateZendeskWidget () {
		this.window.zE.activate();
	}

	public openSuggestionPanel () {
		this.ngxGoSidepanelService.open(NgxSuggestionPanelComponent);
	}

	public canInvite (_org): boolean {
		if(this.isLTI) return;

		const org = this.group.model(_org);
		const groups = this.selectedService.getGroups();
		const hasInstructorRole = groups.some((gr) => gr.org_id === org.group_id && gr.hasInstructorRole(true));
		return !!org && !org.placeholder &&
			(org.hasAdminRole(true) || hasInstructorRole || this.userService.currentUser.is_root_user);
	}

	public openInviteModal (event, _org): void {
		event.stopPropagation();
		const org = this.group.model(_org);
		if (!org.role) {
			org.role = this.group.role.INSTRUCTOR;
		}
		this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: org
			}
		});
	}

	public shouldShowCustomParams (): boolean {
		return this.isLTI && this.authService.isAdmin() && this.customParams;
	}

	public openCustomParamsModal (): void {
		this.dialog.open(CustomParamsDialogComponent, {
			data: {
				resource_link_id: this.customParams.resource_link_id,
				context_id: this.customParams.context_id,
				user_id: this.customParams.user_id
			} as CustomParamsDialogData
		});
	}

	private hasNoSelectedGroupAndAccount () {
		return this.selectedService.getGroup() === null &&
			(!this.selectedService.getAccount() || this.selectedService.getAccount().type !== GroupType.ACCOUNT);
	}
}
