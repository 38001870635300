import angular from 'angular';
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoTourService, goTourServiceToken } from 'go-modules/go-tour/go-tour.service';
import type { GoTour } from 'go-modules/go-tour/go-tour.service';
import { PlacementEngine, placementEngineToken } from 'go-modules/go-tour/placement-engine.factory';
import { WelcomeTourDialogComponent } from 'ngx/go-modules/src/components/dialogs/welcome-tour-dialog/welcome-tour-dialog.component';
import dayjs from 'dayjs';

import stepBreadcrumbs from './lti-refresh-tour-breadcrumbs.html';
import stepFilters from './lti-refresh-tour-filters-sorting.html';
import stepArchive from './lti-refresh-tour-archive.html';
import stepTools from './lti-refresh-tour-tools.html';
import stepAccountSettings from './lti-refresh-tour-account-settings.html';
import stepViewLayout from './lti-refresh-tour-view-layout.html';

export const LTI_REFRESH_TOUR_KEY = 'lti-refresh-tour';
export const LTI_REFRESH_TOUR_CONSTRAINTS = {
	isOnboarding: false,
	tourStartDate: dayjs('2024-03-25 00:00:00').toDate()
};

@Injectable({
	providedIn: 'root'
})
export class LtiRefreshTourService {
	private tour: GoTour;

	public constructor (
		private translateFilter: TranslateService,
		private dialog: MatDialog,
		private ngZone: NgZone,
		@Inject(goTourServiceToken) private goTour: GoTourService,
		@Inject(placementEngineToken) private placementEngine: PlacementEngine
	) {}

	public startTour (hasCourseLevelLTI: boolean) {
		this.goTour.canTourBeViewed(LTI_REFRESH_TOUR_KEY, LTI_REFRESH_TOUR_CONSTRAINTS).then(()=> {
			this.ngZone.run(() => {
				const dialogRef = this.dialog.open(WelcomeTourDialogComponent, { disableClose: true });
				dialogRef.afterClosed().subscribe((result) => {
					if (result) {
						this.getTour(hasCourseLevelLTI).start();
					} else {
						this.goTour.markTourViewed(LTI_REFRESH_TOUR_KEY);
					}
				});
			});
		}).catch(angular.noop);
	}

	public getTour (hasCourseLevelLTI: boolean): GoTour {
		const tourTitleAriaLabel = this.translateFilter.instant('lti-refresh-tour-aria_label');

		const breadcrumbsStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-breadcrumbs-title'),
			template: stepBreadcrumbs,
			selector: 'breadcrumbs',
			placement: this.placementEngine.POSITION.TOP
		};

		const filtersStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-filters-title'),
			template: stepFilters,
			selector: '.filter-bar > div',
			placement: this.placementEngine.POSITION.TOP
		};

		const archiveStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-archive-title'),
			template: stepArchive,
			selector: '.nav-content',
			placement: this.placementEngine.POSITION.LEFT
		};

		const toolsStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-tools-title'),
			template: stepTools,
			selector: '.nav-bottom',
			placement: this.placementEngine.POSITION.BOTTOM
		};

		const viewLayoutStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-view_layout-title'),
			template: stepViewLayout,
			selector: '.filter-option .view-switcher',
			placement: this.placementEngine.POSITION.RIGHT
		};

		const accountSettingsStep = {
			tourTitleAriaLabel,
			title: this.translateFilter.instant('lti-refresh-tour-account_settings-title'),
			template: stepAccountSettings,
			selector: '.header-menu',
			placement: this.placementEngine.POSITION.TOP
		};

		const steps = [];

		if (hasCourseLevelLTI) {
			steps.push(breadcrumbsStep);
		}

		steps.push(filtersStep);
		steps.push(archiveStep);
		steps.push(toolsStep);
		steps.push(viewLayoutStep);
		steps.push(accountSettingsStep);

		if (!this.tour) {
			this.tour = this.goTour.defineTour({
				viewTrackKey: LTI_REFRESH_TOUR_KEY,
				constraints: LTI_REFRESH_TOUR_CONSTRAINTS,
				steps
			});
		}

		return this.tour;
	}
}
