import template from './header-view.component.html';
import { HeaderViewController as controller } from './header-view.controller';
import './style.less';

export const HeaderViewComponent = {
	template,
	controller,
	transclude: {
		toolbar: 'headerToolbar',
		navigation: '?navigation'
	},
	bindings: {
		logoRoute: '<?'
	}
};
