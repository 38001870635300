import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { GoDropdownComponent } from './go-dropdown.component';
import { GoDropdownDropdownComponent } from './go-dropdown-dropdown.component';
import { GoDropdownOptionComponent } from './go-dropdown-option.component';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
	imports: [
		CommonModule,
		OverlayModule,
		PortalModule
	],
	declarations: [
		GoDropdownComponent,
		GoDropdownDropdownComponent,
		GoDropdownOptionComponent
	],
	exports: [
		GoDropdownComponent,
		GoDropdownDropdownComponent,
		GoDropdownOptionComponent
	],
	entryComponents: [
		GoDropdownComponent,
		GoDropdownDropdownComponent,
		GoDropdownOptionComponent
	],
	providers: [
	]
})
export class GoDropdownModule {}
