import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicensePlanBreadcrumbComponent} from 'ngx/go-modules/src/components/breadcrumbs/folder-breadcrumbs/license-plan-breadcrumb/license-plan-breadcrumb.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LicensePlanBadgeModule } from 'ngx/go-modules/src/components/license-plan-badge/license-plan-badge.module';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';

@NgModule({
	imports: [
		CommonModule,
		GoMaterialModule,
		TranslateModule,
		LicensePlanBadgeModule
	],
	declarations: [
		LicensePlanBreadcrumbComponent
	],
	entryComponents: [
		LicensePlanBreadcrumbComponent
	],
	exports: [
		LicensePlanBreadcrumbComponent
	],
	providers: [
		TranslatePipe
	]
})
export class LicensePlanBreadcrumbModule {}
