import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionFolderViewerComponent } from './collection-folder-viewer.component';
import { CollectionItemViewerModule } from '../collection-item-viewer/collection-item-viewer.module';
import { SearchPipeModule } from 'ngx/go-modules/src/pipes/search/search.pipe.module';
import { SearchPipe } from 'ngx/go-modules/src/pipes/search/search.pipe';
import { SortPipeModule } from 'ngx/go-modules/src/pipes/sort/sort.pipe.module';
import { ManageLibraryCollectionFolderModule } from '../manage-library-collection-folder/manage-library-collection-folder.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		CollectionItemViewerModule,
		SortPipeModule,
		SearchPipeModule,
		ManageLibraryCollectionFolderModule
	],
	declarations: [
		CollectionFolderViewerComponent
	],
	entryComponents: [
		CollectionFolderViewerComponent
	],
	exports: [
		CollectionFolderViewerComponent
	],
	providers: [
		SearchPipe
	]
})
export class CollectionFolderViewerModule {}
