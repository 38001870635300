import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class SessionStorageService {
	public static readonly NG1_INJECTION_NAME = 'sessionStorage' as const;

	private key: string;

	/* @ngInject */
	constructor (private $window: ng.IWindowService) {}

	public get prefix () {
		return this.key;
	}

	public getPrefix () {
		return this.key;
	}

	public setPrefix (prefix) {
		this.key = prefix || 'dms-';
	}

	public get (key) {
		key = this.resolveKey(key);
		return this.fromJson(this.$window.localStorage.getItem(key));
	};

	public set (key, value) {
		key = this.resolveKey(key);
		this.$window.localStorage.setItem(key, this.toJson(value));
	};

	public remove (key) {
		key = this.resolveKey(key);
		this.$window.localStorage.removeItem(key);
	};

	public clear () {
		for (const key in this.$window.localStorage) {
			if (key.indexOf(this.prefix) === 0) {
				this.$window.localStorage.removeItem(key);
			}
		}
	};

	public resolveKey (key) {
		key += '';
		if (key.indexOf(this.prefix) !== 0) {
			key = this.prefix + key;
		}
		return key;
	}

	public toJson (obj) {
		if (typeof obj === 'undefined') return undefined;
		return JSON.stringify(obj);
	}

	public fromJson (json) {
		return typeof json === 'string' ? JSON.parse(json) : json;
	}
}

export const sessionStorageToken = upgradeNg1Dependency(SessionStorageService);
