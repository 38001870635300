import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { NgxAccessDeniedComponent } from 'ngx/go-modules/src/components/access-denied/access-denied.component';

@NgModule({
	declarations: [
		NgxAccessDeniedComponent
	],
	entryComponents: [
		NgxAccessDeniedComponent
	],
	imports: [
		CommonModule,
		MatButtonModule,
		TranslateModule
	],
	exports: [
		NgxAccessDeniedComponent
	]
})
export class NgxAccessDeniedModule {}
