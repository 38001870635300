import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, Observable, finalize, shareReplay, tap } from 'rxjs';
import { NgxGroupService, Org } from 'ngx/go-modules/src/services/group/group.service';
import { AccountService } from 'ngx/go-modules/src/services/account/account.service';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import type { Account } from 'ngx/go-modules/src/interfaces/account';

@Component({
	selector: 'move-folder-dialog',
	template: require('./move-folder-dialog.component.html'),
	styles: [require('./move-folder-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class MoveFolderDialogComponent implements OnInit, OnDestroy {
	public orgs$: Observable<Org[]>;
	public accounts$: Observable<Account[]>;
	public orgsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public accountsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public selectedOrg: Org;
	public selectedAccount: Account;

	constructor (
		public dialogRef: GoDialogRef,
		private groupService: NgxGroupService,
		private accountService: AccountService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(GO_MODAL_DATA) private data: {
			folderId: number;
		}
	) {}

	public ngOnInit (): void {
		this.orgs$ = this.groupService.getAllOrgs(false).pipe(
			tap((orgs) => {
				orgs.sort((a, b) => a.name.localeCompare(b.name));
			}),
			finalize(() => {
				this.orgsLoading$.next(false);
			})
		);
	}

	public ngOnDestroy (): void {
		this.orgsLoading$.complete();
		this.accountsLoading$.complete();
	}

	public cancel () {
		this.dialogRef.close(false);
	}

	public confirm () {
		this.groupService.moveGroup(this.data.folderId, {
			new_parent_id: this.selectedAccount.group_id
		}).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'modal-move-folder-success'
				});
				this.dialogRef.close(true);
			},
			error: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'modal-move-folder-failed'
				});
				this.dialogRef.close(false);
			}
		});
	}

	public orgChanged (org) {
		this.accounts$ = this.accountService.getOrgAccounts(org.group_id).pipe(
			shareReplay(1),
			tap((accounts) => {
				accounts.sort((a, b) => a.name.localeCompare(b.name));
			}),
			finalize(() => {
				this.accountsLoading$.next(false);
			})
		);
	}
}
