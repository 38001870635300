import { ActivityReportService as ActivityReportServiceClass } from 'go-modules/csv-report/activity-report/activity-report.service';

export class ActivityReportPanelController {

	public activityId: string | number | null;
	public reportLevel: string;
	public groupId: string | number;

	/* @ngInject */
	constructor (private ActivityReportService: ActivityReportServiceClass) {}

	public getReportTitle (): string {
		if (this.reportLevel === 'course') {
			return 'activity-report-panel_course-report';
		} else {
			return 'activity-report-panel_activity-report';
		}
	}

	public downloadReport (){
		this.ActivityReportService.execute(this.activityId, this.groupId);
	}
}
