import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SessionAnalyticsComponent } from 'ngx/go-modules/src/components/feedback-session/session-analytics/session-analytics.component';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { NgxZeroStateModule } from 'ngx/go-modules/src/components/zero-state/zero-state.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { FeedbackGraphComponent } from './feedback-graph/feedback-graph.component';
import { MarkerDisplayModule } from 'ngx/go-modules/src/components/marker-display/marker-display.module';
import { FormatTimeModule } from 'ngx/go-modules/src/pipes/format-time/format-time.module';
import { PaceMetricGraphComponent } from './pace-metric-graph/pace-metric-graph.component';
import { WordMetricGraphComponent } from './word-metric-graph/word-metric-graph.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SessionAnalyticItemViewComponent } from './session-analytic-item-view/session-analytic-item-view.component';
import { PauseMetricGraphComponent } from './pause-metric-graph/pause-metric-graph.component';
import { AnalyticGraphZeroStateComponent } from './analytic-graph-zero-state/analytic-graph-zero-state.component';
import { TeaseWallModule } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.module';
import { GoSelectModule } from 'ngx/go-modules/src/directives/go-select/go-select.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';


@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		NgxZeroStateModule,
		HighchartsChartModule,
		MarkerDisplayModule,
		FormatTimeModule,
		MatExpansionModule,
		TeaseWallModule,
		GoSelectModule,
		MatSelectModule,
		FormsModule
	],
	exports: [
		SessionAnalyticsComponent,
		PauseMetricGraphComponent,
		FeedbackGraphComponent,
		PaceMetricGraphComponent,
		SessionAnalyticItemViewComponent
	],
	declarations: [
		SessionAnalyticsComponent,
		PauseMetricGraphComponent,
		FeedbackGraphComponent,
		PaceMetricGraphComponent,
		WordMetricGraphComponent,
		SessionAnalyticItemViewComponent,
		AnalyticGraphZeroStateComponent
	],
	entryComponents: [
		SessionAnalyticsComponent
	],
	providers: [
		TranslatePipe
	]
})
export class SessionAnalyticsModule {}
