import { States } from 'go-modules/enums/states.enum';
import { Observable, Observer, take } from 'rxjs';

/**
 * Remove CurrentFilter if page loaded outside the session view
 */
/* @ngInject */
export const removeCurrentFilter = ($transitions, $window, $state) => {
	new Observable((subscriber: Observer<void>) => {
		$transitions.onSuccess({}, (): void => {
			subscriber.next();
		});
	}).pipe(take(1))
		.subscribe(() => {
			if (!$state.is(States.DASHBOARD_SESSION_VIEW)) $window.sessionStorage.removeItem('currentFilter');
		});
};
