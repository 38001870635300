import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CustomParamsDialogData {
	resource_link_id: string;
	context_id: string;
	user_id: string;
}

@Component({
	selector: 'app-custom-params-dialog',
	template: require('./custom-params-dialog.component.html'),
	styles: [require('./custom-params-dialog.component.scss')]
})
export class CustomParamsDialogComponent {

	public constructor (
		public dialogRef: MatDialogRef<CustomParamsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: CustomParamsDialogData
	) {}

	public close () {
		this.dialogRef.close();
	}
}
