import { DatePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { GroupSettingsPanelService, groupSettingsPanelServiceToken } from 'go-modules/group-settings-panel/group-settings-panel.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { groupToken } from 'go-modules/models/group-dep/group.factory';
import { goModal, goModalToken } from 'go-modules/modals/go-modal.factory';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

@Component({
	selector: 'org-menu',
	template: require('./org-menu.component.html'),
	styles: [require('./org-menu.component.scss')],
	providers: [DatePipe]
})
export class OrgMenuComponent {
	@Input() public org;

	constructor (
		private dialog: MatDialog,
		private translate: TranslateService,
		private groupService: NgxGroupService,
		private ngxGoToastService: NgxGoToastService,

		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(groupSettingsPanelServiceToken) private groupSettingsPanel: GroupSettingsPanelService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(groupToken) private group
	) {}

	public inviteUsers () {
		this.goModalService.open({
			modal: 'inviteUsers',
			modalData: {
				group: this.org
			}
		});
	}

	public manageUsers () {
		this.openSettings('users');
	}

	public editOrg () {
		this.openSettings('info');
	}

	public openSettings (defaultTab: 'info' | 'users') {
		this.groupSettingsPanel.open(this.org, this.userService.currentUser, defaultTab, { firstFocusSelector: '.search-input' }).result.then(
			(group?) => {
				// group is not-null if changed
				if (group != null) {
					this.selectedService.setOrg(this.group.model({...this.selectedService.getOrg(), ...group}));
				}
			}
		);
	}

	public deleteOrg () {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translate.instant('course-menu_delete-org'),
				message: this.translate.instant('modal-remove-group_sure-delete', {type: this.translate.instant('group-settings_org'), name: this.org.name}),
				confirmText: this.translate.instant('common_delete')
			} as ConfirmDialogData
		});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm),
			switchMap(() => this.groupService.deleteGroup(this.org.group_id))
		).subscribe({
			next: () => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: this.translate.instant('group-menu_deleted', {type: this.translate.instant('group-settings_org')})
				});
			},
			error: () => this.ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: this.translate.instant('group-menu_delete_error', {type: this.translate.instant('group-settings_org')})
			})
		});
	}
}
