import template from './activity-editor-tour.html';
import { GoTour, GoTourService } from 'go-modules/go-tour/go-tour.service';
import dayjs from 'dayjs';
import { PlacementEngine } from 'go-modules/go-tour/placement-engine.factory';

export class ActivityEditorTour {
	public readonly startDate = dayjs('2024-03-18 8:00:00').toDate();
	public readonly title = 'activity-editor_tour_title' as const;
	public readonly viewTrackKey = 'activity-editor-tour' as const;
	public readonly selector = 'form.activity-editor-panel' as const;
	private tour: GoTour;

	/** @ngInject */
	constructor (
		private goTour: GoTourService,
		private $translate: ng.translate.ITranslateService,
		private PlacementEngine: PlacementEngine
	) {}

	public getTour (): GoTour {
		const placement = this.PlacementEngine.POSITION.RIGHT;
		const title = this.$translate.instant(this.title);
		if (!this.tour) {
			this.tour = this.goTour.defineTour({
				viewTrackKey: this.viewTrackKey,
				constraints: {
					isOnboarding: false,
					tourStartDate: this.startDate
				},
				tourStepClass: this.viewTrackKey,
				steps: [
					{
						tourTitleAriaLabel: title,
						title,
						template,
						selector: this.selector,
						placement
					}
				]
			});
		}

		return this.tour;
	};
}
