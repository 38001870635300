import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadCrumbStateService } from 'ngx/go-modules/src/services/breadcrumb-state/breadcrumb-state.service';
import { States } from 'ngx/go-modules/src/enums/states';
@Component({
	selector: 'breadcrumbs',
	template: require('./breadcrumbs.component.html'),
	styles: [require('./breadcrumbs.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadCrumbsComponent {

	public VerticalNavState = States;

	constructor (
		public breadCrumbStateService: BreadCrumbStateService
	) {}
}
