import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'breadcrumb-item-view',
	template: require('./breadcrumb-item-view.component.html'),
	styles: [require('./breadcrumb-item-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class BreadCrumbItemViewComponent {
	@Input() public tooltip?: string;
	@Output() public action: EventEmitter<any> = new EventEmitter();

	constructor () {}
}
