import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { ArchivedItem, ArchivedItemType } from 'ngx/go-modules/src/interfaces/archived/archived.interface';

@Component({
	selector: 'ngx-archive-card',
	template: require('./archive-card.component.html'),
	styles: [require('./archive-card.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxArchiveCardComponent {
	@ViewChild(MenuComponent) public menuComponent: MenuComponent;
	@ViewChild('cardContainer', {read: ElementRef}) public cardContainer: ElementRef;
	@Input() public archive: any;
	public RESOURCE_TYPE = ArchivedItemType;

	constructor () {}

	public openMenu (event: MouseEvent) {
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		event.preventDefault();
		this.menuComponent.openMenu(event, this.cardContainer.nativeElement);
	}

	public getNameList (archive: ArchivedItem) {
		return archive.presenters.map((item) => `${item.first_name} ${item.last_name}`);
	}
}
