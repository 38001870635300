import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GRID_CONSTANTS } from 'ngx/go-modules/src/components/grid-virtual-scroll-viewport/grid.constants';
import { ActivityListDataSource } from 'ngx/go-modules/src/services/activity-list-datasource/activity-list.datasource';

@Component({
	selector: 'activity-grid-view',
	template: require('./activity-grid-view.component.html'),
	styles: [require('./activity-grid-view.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxActivityGridViewComponent {
	public minWidth: number = GRID_CONSTANTS.minWidth;
	public maxWidth: number = GRID_CONSTANTS.maxWidth;
	public minGap: number = GRID_CONSTANTS.minGap;

	constructor (public activityListDataSource: ActivityListDataSource) {}
}
