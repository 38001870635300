import template from './modal.html';
import { ChangeEmailModalController } from './modal.controller';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export class ChangeEmailModal {
	public static readonly NG1_INJECTION_NAME = 'changeEmailModal' as const;

	constructor (private goModalBootstrap) {}

	public open (options): ng.ui.bootstrap.IModalServiceInstance {
		options.template = template;
		options.controller = ChangeEmailModalController;
		options.windowClass = options.name = 'change-email';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}

export const changeEmailModalToken = upgradeNg1Dependency(ChangeEmailModal);
